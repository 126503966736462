import React, { useState, useRef, useEffect } from "react";
import menuIcon from '../../../Assets/Icons/chevron-down-arrow.png';
import searchIcon from '../../../Assets/Icons/search-icon-b.png';
import ScheduleRemindersController from "../../Controllers/AdminScreens/ScheduleRemindersController";
import Loader from '../../../Components/Loader';
import closeIcon from '../../../Assets/Icons/close.png';

const ScheduleRemindersScreen = () => {

  const {
    filteredSchedules,
    loading,
    error,
    fetchSchedulesList,
  } = ScheduleRemindersController();
  const [view, setView] = useState("Month"); // Default view is 'Month'
  const [currentDate, setCurrentDate] = useState(new Date());
  // const filteredSchedules = {
  //   "2025-01-01": [{ time: "08:00 AM", title: "New Year's Party" }, { time: "10:00 AM", title: "Family Brunch" }],
  //   "2025-01-15": [{ time: "02:00 PM", title: "Team Meeting" }],
  //   "2025-01-20": [{ time: "09:00 AM", title: "Project Kickoff" }],
  //   "2025-01-25": [{ time: "03:30 PM", title: "Client Meeting" }],
  // };

  const prevDateRef = useRef(null);

  useEffect(() => {
    if (!currentDate) return; // Prevent execution if currentDate is undefined

    const month_id = currentDate.getMonth() + 1;
    const year_id = currentDate.getFullYear();

    console.log("Fetching data for:", { month_id, year_id, view });

    // Update prevDateRef before making API calls
    const prevMonth = prevDateRef.current ? prevDateRef.current.getMonth() + 1 : null;
    const prevYear = prevDateRef.current ? prevDateRef.current.getFullYear() : null;

    prevDateRef.current = new Date(currentDate); //  Ensure prevDateRef is up-to-date before API call

    if (view === "Month") {
      console.log("Fetching data for Month View");
      fetchSchedulesList(month_id, year_id);
    } else {
      console.log("Checking month change in Day/Week View");

      if (prevMonth !== month_id || prevYear !== year_id) {
        console.log("Month changed, fetching new data...");
        fetchSchedulesList(month_id, year_id);
      } else {
        console.log("Month not changed, no API call.");
      }
    }
  }, [currentDate, view]);


  console.log("Filtered Schedules:", filteredSchedules);

  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i % 12 || 12; // 8 AM to 8 PM
    const period = i < 12 ? "AM" : "PM";
    return `${hour}:00 ${period}`;
  });

  const getHeaderTitle = () => {
    if (view === "Month") {
      return currentDate.toLocaleString("default", { month: "long", year: "numeric" });
    } else if (view === "Week") {
      const weekNumber = Math.ceil((currentDate.getDate() - currentDate.getDay() + 7) / 7);
      return `Week ${weekNumber}, ${currentDate.toLocaleString("default", { month: "long", year: "numeric" })}`;
    } else if (view === "Day") {
      return currentDate.toLocaleString("default", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    }
  };

  const handlePrev = () => {
    const newDate = new Date(currentDate);
    if (view === "Day") {
      newDate.setDate(currentDate.getDate() - 1);
    } else if (view === "Week") {
      newDate.setDate(currentDate.getDate() - 7);
    } else if (view === "Month") {
      newDate.setMonth(currentDate.getMonth() - 1);
    }
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    if (view === "Day") {
      newDate.setDate(currentDate.getDate() + 1);
    } else if (view === "Week") {
      newDate.setDate(currentDate.getDate() + 7);
    } else if (view === "Month") {
      newDate.setMonth(currentDate.getMonth() + 1);
    }
    setCurrentDate(newDate);
  };

  const normalizeTime = (time) => {
    return time.replace(/^0/, "").toLowerCase();
  };

  const [selectedEvents, setSelectedEvents] = useState(null);

  const handleEventClick = (events, eventTime, dayKey) => {
    setSelectedEvents({ events, eventTime, dayKey });
  };

  const closePopup = () => {
    setSelectedEvents(null);
  };

  const renderDayView = () => {
    const dayKey = currentDate.toISOString().split("T")[0];
    const daySchedules = filteredSchedules[dayKey] || [];

    // Group events by hour using 24-hour format
    const groupedEvents = daySchedules.reduce((acc, event) => {
      const eventHour = parseHourTo24Format(event.time); // Convert to 24-hour format
      acc[eventHour] = acc[eventHour] ? [...acc[eventHour], event] : [event];
      return acc;
    }, {});

    return (
      <div style={styles.dayViewContainer}>
        {hours.map((hour, index) => {
          const hour24 = parseHourTo24Format(hour); // Convert display hour to 24-hour format
          const events = groupedEvents[hour24] || []; // Get events for this hour

          return (
            <div key={index} style={styles.dayRow}>
              <div style={styles.hourLabel}>{hour}</div>
              <div style={styles.eventContainer}>
                {events.length > 0 && (
                  <div key={index} style={styles.eventRow}>
                    {events.map((schedule, i) => (
                      <div
                        key={i}
                        style={{
                          ...styles.scheduleItem,
                          height: "90%",
                          width: `${100 / events.length}%`,
                          maxWidth: "150px",
                        }}
                        onClick={() => handleEventClick(events, schedule.time, dayKey)}
                      >
                        <span style={styles.eventTime}>{schedule.time}</span>
                        <span style={styles.eventTitle}>{schedule.title}</span>
                        <span style={{ ...styles.eventTitle, fontWeight: "400" }}>{schedule.sc_desc}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
        })}

        {/* Popup for Showing Events of a Specific Time Slot */}
        {selectedEvents && (
          <div style={styles.popupOverlay} onClick={() => setSelectedEvents(null)}>
            <div style={styles.popup} onClick={(e) => e.stopPropagation()}>
              {/* Close Icon */}
              <img
                src={closeIcon}
                alt="Close"
                style={styles.closeIcon}
                onClick={() => setSelectedEvents(null)}
              />

              <h3 style={{ fontSize: "16px", marginBottom: '5px' }}>
                Events on{" "}
                {new Date(selectedEvents.dayKey).toLocaleDateString("default", {
                  weekday: "long",
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}{" "}
                at {selectedEvents.eventTime}
              </h3>

              {selectedEvents.events.map((event, i) => (
                <div key={i} style={styles.popupEventItem}>
                  <span style={styles.eventTime}>{event.time}</span>
                  <span style={styles.eventTitle}>{event.title}</span>
                  <span style={styles.eventTitle}>{event.sc_desc}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderWeekView = () => {
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Move to Sunday

    const weekDates = Array.from({ length: 7 }, (_, i) =>
      new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + i)
    );

    return (
      <div style={styles.weekContainer}>
        {/* Time Column */}
        <div style={styles.timeColumn}>
          {hours.map((hour, index) => (
            <div key={index} style={styles.timeCell}>
              {hour}
            </div>
          ))}
        </div>

        {/* Schedule Table */}
        <div style={styles.scheduleTable}>
          {/* Table Header */}
          <div style={styles.weekHeader}>
            {weekDates.map((date, index) => (
              <div key={index} style={styles.weekDayHeader}>
                {date.toLocaleDateString("default", { weekday: "short", month: "short", day: "numeric" })}
              </div>
            ))}
          </div>

          {/* Table Body */}
          {hours.map((hour, rowIndex) => (
            <div key={rowIndex} style={styles.weekRow}>
              {weekDates.map((date, colIndex) => {
                const dayKey = new Date(date).toLocaleDateString('en-CA'); // YYYY-MM-DD format
                const daySchedules = filteredSchedules[dayKey] || [];

                const normalizedHour = parseHourTo24Format(hour); // Convert "8:00 AM" -> 8, "8:00 PM" -> 20

                const scheduleMatches = daySchedules.filter((schedule) => {
                  const eventHour = parseHourTo24Format(schedule.time);
                  return eventHour === normalizedHour; // Match by hour
                });

                return (
                  <div key={colIndex} style={styles.scheduleCell}>
                    {scheduleMatches.slice(0, 2).map((schedule, i) => (
                      <div
                        key={i}
                        style={styles.scheduleItem}
                        onClick={() => handleEventClick(scheduleMatches, schedule.time, dayKey)}
                      >
                        <div style={styles.eventTime}>{schedule.time}</div>
                        <div style={styles.eventTitle}>{schedule.sc_desc}</div>
                      </div>
                    ))}
                    {scheduleMatches.length > 2 && (
                      <div
                        style={styles.moreEvents}
                        onClick={() => handleEventClick(scheduleMatches, scheduleMatches[0].time, dayKey)}
                      >
                        +{scheduleMatches.length - 2} more
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>

        {/* Popup for Showing More Events */}
        {selectedEvents && (
          <div style={styles.popupOverlay} onClick={closePopup}>
            <div style={styles.popup} onClick={(e) => e.stopPropagation()}>
              {/* Close Icon */}
              <img
                src={closeIcon}
                alt="Close"
                style={styles.closeIcon}
                onClick={closePopup}
              />

              <h3 style={{ fontSize: "16px", marginBottom: '5px' }}>
                Events on{" "}
                {new Date(selectedEvents.dayKey).toLocaleDateString("default", {
                  weekday: "long",
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </h3>
              {selectedEvents.events.map((event, i) => (
                <div key={i} style={styles.popupEventItem}>
                  <span style={styles.eventTime}>{event.time}</span>
                  <span style={styles.eventTitle}>{event.title}</span>
                  <span style={styles.eventTitle}>{event.sc_desc}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const parseHourTo24Format = (timeString) => {
    if (!timeString) return null;

    const match = timeString.match(/(\d{1,2}):?(\d{2})?\s*(AM|PM)?/i);
    if (!match) return null;

    let hours = parseInt(match[1], 10);
    let minutes = match[2] ? parseInt(match[2], 10) : 0;
    const period = match[3] ? match[3].toUpperCase() : null;

    if (period === "PM" && hours !== 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;

    return hours; // Returns hour in 24-hour format
  };

  const [selectedDay, setSelectedDay] = useState(null);

  const handleDayClick = (events, dayKey) => {
    if (events.length > 0) {
      setSelectedDay({ events, dayKey });
    }
  };

  const renderMonthView = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    // Ensure consistency using UTC
    const firstDay = new Date(Date.UTC(year, month, 1)).getUTCDay();
    const lastDate = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();

    const days = [];

    // Add empty slots for the first row before the first day
    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }

    // Populate actual days
    for (let day = 1; day <= lastDate; day++) {
      days.push(new Date(Date.UTC(year, month, day)));
    }

    return (
      <div style={styles.monthGrid}>
        {/* Month Header (Days of the Week) */}
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
          <div key={index} style={styles.monthDayHeader}>
            {day}
          </div>
        ))}

        {/* Month Calendar Grid */}
        {days.map((date, index) => {
          const dayKey = date ? date.toISOString().split("T")[0] : null;
          const dayEvents = dayKey ? filteredSchedules[dayKey] || [] : [];

          return (
            <div
              key={index}
              style={styles.monthDayCell}
              onClick={() => handleDayClick(dayEvents, dayKey)}
            >
              {date && (
                <>
                  {/* Day Number */}
                  <span style={styles.dayNumber}>{date.getUTCDate()}</span>

                  {/* Events (Limit to 2 per day) */}
                  <div style={styles.monthEventWrapper}>
                    {dayEvents.slice(0, 2).map((event, i) => (
                      <div key={i} style={styles.monthEventItem}>
                        <span style={styles.monthEventTime}>{event.time}</span>
                        <span style={styles.monthEventTitle}>{event.sc_desc}</span>
                      </div>
                    ))}
                    {dayEvents.length > 2 && (
                      <div
                        style={styles.moreEvents}
                        onClick={(e) => { e.stopPropagation(); handleDayClick(dayEvents, dayKey); }}
                      >
                        +{dayEvents.length - 2} more
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}

        {/* Popup for Showing All Events of a Day */}
        {selectedDay && (
          <div style={styles.popupOverlay} onClick={() => setSelectedDay(null)}>
            <div style={styles.popup} onClick={(e) => e.stopPropagation()}>
              {/* Close Icon */}
              <img
                src={closeIcon}
                alt="Close"
                style={styles.closeIcon}
                onClick={() => setSelectedDay(null)}
              />

              <h3 style={{ fontSize: "16px", marginBottom: "5px" }}>
                Events on{" "}
                {new Date(selectedDay.dayKey).toLocaleDateString("default", {
                  weekday: "long",
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </h3>
              {selectedDay.events.map((event, i) => (
                <div key={i} style={styles.popupEventItem}>
                  <span style={styles.eventTime}>{event.time}</span>
                  <span style={styles.eventTitle}>{event.title}</span>
                  <span style={styles.eventTitle}>{event.sc_desc}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };


  return (
    <div>
      <h3 style={{ paddingLeft: "60px", paddingBottom: '10px', fontSize: '22px', fontWeight: '500' }} >Schedule & Reminders</h3>
      <div style={styles.container}>
        <div style={styles.header}>
          <div style={styles.arrowBtnDiv}>
            <button onClick={() => { handlePrev(); }} style={styles.btnStyle}>
              <img style={{ ...styles.btnImgStyle, transform: 'rotate(180deg)' }} src={menuIcon} alt="prev" />
            </button>
            <div style={styles.selectedDate}>{getHeaderTitle()}</div>
            <button onClick={() => { handleNext(); }} style={styles.btnStyle}>
              <img style={styles.btnImgStyle} src={menuIcon} alt="next" />
            </button>
          </div>
          <div style={{ margin: "auto", marginLeft: '18%' }}>
            {["Day", "Week", "Month"].map((v) => (
              <button
                key={v}
                onClick={() => setView(v)}
                style={{
                  ...styles.viewButton,
                  backgroundColor: view === v ? "red" : "#fff",
                  color: view === v ? "#fff" : "#333",
                }}
              >
                {v}
              </button>
            ))}
          </div>
          {/* <div style={styles.searchContainer}>
          <img src={searchIcon} alt="Search Icon" style={styles.icon} />
          <input type="text" style={styles.input} placeholder="Search" />
        </div> */}
        </div>
        <div style={styles.calendarContainer}>
          {loading && (
            <div style={styles.loaderOverlay}>
              <Loader loading={loading} color={'var(--primary-color)'} />
            </div>
          )}
          {view === "Day" && renderDayView()}
          {view === "Week" && renderWeekView()}
          {view === "Month" && renderMonthView()}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "90%",
    padding: "30px",
    boxSizing: "border-box",
    backgroundColor: '#FFFFFF',
    margin: 'auto',
    borderRadius: '10px'
  },
  header: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  gridContainer: {
    display: "grid",
    gridTemplateRows: "repeat(auto-fill, minmax(50px, 1fr))",
    border: "1px solid #ccc",
    borderRadius: "8px",
    overflow: "hidden",
  },
  monthGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "5px",
    padding: "10px",
  },

  hourRow: {
    display: "grid",
    gridTemplateColumns: "1fr 7fr",
    borderBottom: "1px solid #ccc",
  },
  hour: {
    padding: "10px",
    textAlign: "right",
    fontWeight: "bold",
    backgroundColor: "#f1f1f1",
    borderRight: "1px solid #ccc",
  },
  dayCell: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    minHeight: "100px",
    position: "relative",
    padding: "5px",
  },
  dayNumber: {
    fontWeight: "bold",
  },

  viewButton: {
    margin: "0 5px",
    padding: "5px 10px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  weekContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    overflowX: "auto",
  },
  timeColumn: {
    display: "flex",
    flexDirection: "column",
    // borderRight: "1px solid #ccc",
    marginRight: "5px",
    paddingTop: '20px'
  },
  timeCell: {
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#333",
    // borderBottom: "1px solid #ccc",
  },
  scheduleTable: {
    flex: 1,
  },
  weekHeader: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    backgroundColor: "#f8f8f8",
    borderBottom: "1px solid #ccc",
  },
  weekDayHeader: {
    textAlign: "center",
    padding: "10px 0",
    fontWeight: "bold",
    fontSize: "12px",
    borderRight: "1px solid #ccc",
    backgroundColor: '#FFFFFF'
  },
  weekRow: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    borderBottom: "1px solid #ccc",
  },
  scheduleCell: {
    borderRight: "1px solid #ccc",
    height: "35px",
    padding: "5px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
  },
  scheduleItem: {
    backgroundColor: "#e0f7fa",
    color: "#00796b",
    fontSize: "10px",
    padding: "2px 5px",
    borderRadius: "3px",
    marginBottom: "2px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: '80%',
    borderLeft: '3px solid #056e7b',
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100px",
    cursor: 'pointer',
  },
  btnStyle: {
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    padding: '3px',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  btnStyleRight: {
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    padding: '6px 3px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  btnImgStyle: {
    width: '16px',
    height: '16px',
    filter: 'brightness(1) invert(1)'
  },
  arrowBtnDiv: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  selectedDate: {
    color: '#18181B',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    wordWrap: 'break-word',
    backgroundColor: '#F4F4F5',
    padding: '6px'
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f5f5f5", // Background color matching the uploaded UI
    borderRadius: "8px", // Rounded corners
    padding: "5px 10px",
    width: "170px", // Adjust width as needed
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)", // Optional shadow
  },
  icon: {
    width: "16px", // Icon size
    height: "16px",
    marginRight: "8px", // Space between icon and input
  },
  input: {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "14px",
    color: "#7d7d7d", // Text color
    flex: 1, // Allow input to fill remaining space
  },
  placeholder: {
    color: "#b0b0b0", // Placeholder text color
  },
  dayViewContainer: {
    display: "grid",
    gridTemplateRows: "repeat(24, 70px)",
    marginTop: '50px',
  },

  dayRow: {
    display: "flex",
    // borderBottom: "1px solid #ddd",
    position: "relative",
    height: "50px",
    // alignItems: "center",
  },

  hourLabel: {
    width: "80px",
    textAlign: "right",
    paddingRight: "10px",
    fontWeight: "400",
    fontSize: "12px",
    color: '#71717A',
  },

  eventContainer: {
    flex: 1,
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: '20px',
    // marginBottom: '10px',
    borderTop: "1px solid #ddd",
  },
  eventRow: {
    display: "flex",
    gap: "5px",
  },
  eventTime: {
    fontWeight: "bold",
    fontSize: "11px",
    display: "block",
    marginBottom: "2px",
    wordWrap: 'break-word',
  },

  eventTitle: {
    fontSize: "12px",
    fontWeight: "600",
    textAlign: 'start',
    // textOverflow: 'ellipsis',
    whiteSpace: "normal",
    // overflow: "hidden",
    wordBreak: "break-word",
    wordWrap: 'break-word',
    display: "block",
  },

  monthDayHeader: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
    padding: "8px",
    borderBottom: "2px solid #ddd",
    backgroundColor: "#f5f5f5",
  },

  monthDayCell: {
    border: "1px solid #ddd",
    minHeight: "100px",
    padding: "5px",
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
  },

  monthEventWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "5px",
  },

  monthEventItem: {
    backgroundColor: "#e0f7fa",
    color: "#00796b",
    fontSize: "12px",
    padding: "5px",
    borderRadius: "5px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    borderLeft: '3px solid #056e7b',
    cursor: 'pointer',
  },

  monthEventTime: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "#004d40",
  },

  monthEventTitle: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#00695c",
    textAlign: "center",
    // textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100px",
    wordWrap: 'break-word',
    wordBreak: "break-word",
    display: "block",
  },

  calendarContainer: {
    position: "relative", // Needed for loader overlay
    minHeight: "500px", // Ensures space is reserved
  },
  loaderOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10, // Ensures loader appears above calendar
  },
  moreEvents: {
    fontSize: "10px",
    color: "#00796b",
    cursor: "pointer",
    textDecoration: "underline",
    display: "block",
    width: "100%",
    textAlign: "right",
    marginTop: "-10px",
    alignSelf: "flex-start",
  },
  popupOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "14px",
    height: "14px",
    cursor: "pointer",
  },
  popup: {
    backgroundColor: "#fff",
    padding: "10px",
    borderRadius: "10px",
    minWidth: "300px",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
    position: "relative",
    width: "30%",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  popupEventItem: {
    backgroundColor: "#e0f7fa",
    color: "#00796b",
    fontSize: "12px",
    padding: "4px 8px",
    borderRadius: "5px",
    marginBottom: "5px",
  },
  closeButton: {
    marginTop: "10px",
    backgroundColor: "#d32f2f",
    color: "#fff",
    border: "none",
    padding: "6px 10px",
    cursor: "pointer",
    borderRadius: "5px",
    display: "block",
    width: "100%",
  },
};

export default ScheduleRemindersScreen;
