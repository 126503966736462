import React, { useState, useRef, useEffect } from "react";
import Input from "../Input";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import search from '../../Assets/Icons/search.png';
import closeIcon from "../../Assets/Icons/close.png";
import uploadIcon from "../../Assets/Icons/upload.png";
import pluseIcon from "../../Assets/Icons/Pluse.png";
import { ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_UPDATE_DOCUMENT_RACK, ORG_TASK_ADD_DOCUMENT, ORG_ADD_DOCUMENT_RACK } from '../../ApiServices/BaseURL';
import { postData } from '../../ApiServices/ApiService';
import Loader from '../Loader';
import errorIcon from '../../Assets/Icons/error_message.png';

const ClientTaskDocumentAddModal = ({ isOpen, onClose, onValueChange, documentData, setSelectedDocument, clientId, docType, fetchKYCDocumentsList, taskId }) => {
  // console.log("documentData in rack update", documentData, docType)
  const sourceOptions = [
    { label: "Inward (Sent by Client)", value: 1 },
    { label: "Outward (Sent by Employee)", value: 2 },
  ];
  const documentTypeOptions1 = ["PDF", "Excel"];

  const documentTypeOptions = [
    { label: "Internal", value: 1 },
    { label: "Client Documents", value: 2 },
  ];
  const deliveryTypeOptions = [
    { label: "Email", value: "EMAIL" },
    { label: "Whatsapp", value: "WATSAPP" },
    { label: "Physical", value: "PHYSICAL" },
    { label: "Others", value: "OTHERS" },
  ];
  const [clientName, setClientName] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [documentType, setDocumentType] = useState();
  const [source, setSource] = useState();
  const [deliveryType, setDeliveryType] = useState();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [documentTypeError, setDocumentTypeError] = useState('')
  const [deliveryTypeError, setDeliveryTypeError] = useState('')
  const [documentNumberError, setDocumentNUmberError] = useState('')
  const [sourceError, setSourceError] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [base64File, setBase64File] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [updateKycError, setUpdateKycError] = useState('');
  const [documentUploadError, setDocumentUploadError] = useState('');
  const [success, setSuccess] = useState('');
  const [uploadDocumentLoading, setUploadDocumentLoading] = useState(false);
  const [uploadDocumentError, setUploadDocumentError] = useState('');
  const [clientSearchError, setClientSearchError] = useState('');
  const [description, setDescription] = useState('');

  const [documentNameError, setDocumentNameError] = useState('')

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      //console.log("Uploaded file:", file.name);
    }
  };

  useEffect(() => {
    if (isOpen && documentData) {
      //console.log("documentData", documentData);

      // Set client name
      setSearchUser(documentData?.client_name || '');
      setDescription(documentData?.doc_description || '');

      // Set document name
      setDocumentName(documentData?.doc_name || '');

      // Set document number
      setDocumentNumber(documentData?.doc_number || '');

      // Initialize document type based on doc_type
      const selectedDocumentType = documentTypeOptions.find(option => option.value === documentData?.doc_type);
      setDocumentType(selectedDocumentType?.value || documentTypeOptions[0]?.value);

      // Initialize source based on source
      const selectedSource = sourceOptions.find(option => option.value === documentData?.source);
      setSource(selectedSource?.value || sourceOptions[0]?.value);

      // Initialize delivery type based on delivery_type
      const selectedDeliveryType = deliveryTypeOptions.find(option => option.value === documentData?.delivery_type);
      setDeliveryType(selectedDeliveryType?.value || deliveryTypeOptions[0]?.value);
    }
  }, [isOpen, documentData]);

  const [searchUser, setSearchUser] = useState('');

  const handleFileChange = (event) => {
    setDocumentUploadError('')
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size; // File size in bytes
      const maxFileSize = 2 * 1024 * 1024;
      const fileExtension = fileName.split('.').pop(); // Extract the file extension

      //console.log("Uploaded File Name:", fileName);
      //console.log("File Extension:", fileExtension);
      setUploadedFileName(fileName);

      if (fileSize > maxFileSize) {
        setDocumentUploadError("File size exceeds the maximum limit of 2MB.");
        return;
      }

      const reader = new FileReader();

      try {
        reader.onload = () => {
          try {
            const base64String = reader.result.split(',')[1]; // Extract Base64 string from result
            //console.log("Base64 Encoded File:", base64String);

            setBase64File(base64String); // Add the Base64 string to the payload
            // Optionally, save the file extension in state or payload
            setFileExtension(fileExtension);
          } catch (parseError) {
            console.error("Error parsing file to Base64:", parseError);
            setDocumentUploadError("Failed to process the file. Please try again.");
          }
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          setDocumentUploadError("Failed to read the file. Please try again.");
        };

        reader.readAsDataURL(file); // Convert file to Base64 string
      } catch (readError) {
        console.error("File upload error:", readError);
        setDocumentUploadError("An unexpected error occurred during the file upload. Please try again.");
      }
    } else {
      setDocumentUploadError("No file selected. Please choose a file to upload.");
    }
  };

  const handleDrop = (e) => {
    const files = e.dataTransfer.files; // Get the files from the drop event
    if (files && files.length > 0) {
      //console.log("Dropped files:", files);
      handleFileChange({ target: { files } }); // Reuse handleFileChange for processing
    }
  };

  const handleClose = () => {
    setError('');
    onClose();
    setSuccess('');
    setBase64File('');
    setDocumentName('');
    setUpdateKycError('');
    setDeliveryType();
    setDocumentType();
    setDocumentNumber('')
    setSource();
    setUploadedFileName('')
    setDocumentNameError('');
    setDocumentUploadError('');
    setDocumentNUmberError('');
    setDeliveryTypeError('');
    setDocumentTypeError('');
    setSourceError('');
    setClientSearchError('');
    setSearchUser('');
    setDescription('')
  }

  const getDocType = (docType) => {
    return docType === 'internal' ? 1 : docType === 'external' ? 2 : null;
  };

  const clearFeilds = () => {
    setDocumentName('');
    setDescription('');
    setDocumentType('');
    setSource('');
    setDeliveryType('');
    setBase64File('');
  }

  const handleUploadDocument = async () => {

    try {
      setError('');
      setUploadDocumentError('')
      setDocumentNameError('');
      setDocumentUploadError('');
      setDocumentNUmberError('');
      setDeliveryTypeError('');
      setDocumentTypeError('');
      setSourceError('');
      setClientSearchError('');
      setSuccess('')
      // if (!searchUser) {
      //   setClientSearchError('Please enter valid text'); return;
      // } 
      if (!documentName) {
        setDocumentNameError("Please enter document name");
        return
      }
      // if(!description){
      //   setDocumentDescriptionError("Please enter document description");
      //   return
      // }
      // if(!documentNumber){
      //   setDocumentNUmberError("Please enter document number");
      //   return
      // }
      if (!source) {
        setSourceError("Please select source");
        return
      }
      if (!deliveryType) {
        setDeliveryTypeError("Please select delivery type");
        return
      }
      if(docType === 'external' && !documentType){
        setDocumentTypeError("Please select document type");
        return
      }
      if (!base64File) {
        setDocumentUploadError("Please upload document");
        return
      }
      // if (!searchUser) {
      //   setError('Please enter valid text'); return;
      // }         
      setUploadDocumentLoading(true);
      const docTypeValue = docType === "internal" ? 1 : docType === "external" ? documentType : null;
      // const docTypeValue = docType === "internal" ? 1 : docType === "external" ? 2 : null;
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, client_id: clientId, doc_name: documentName, doc_name_with_ext: `${documentName}.${fileExtension}`, doc_upload_via: deliveryType, doc_description: description, doc_base_64: base64File, doc_is_internal: docTypeValue, doc_source_id: source, doc_class: 'TASK', task_id: taskId }

      console.log('handleadddocumentRack payload', payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_DOCUMENT_RACK, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setUploadDocumentError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            clearFeilds();
            setTimeout(() => {
              handleClose();
              fetchKYCDocumentsList();
              // onValueChange();
              setSelectedDocument(null)
            }, 3000);
          } else {
            setUploadDocumentError(responseData?.info || 'Failed to upload document. Please try again.');
          }
        } else {
          setUploadDocumentError(responseData?.info || 'Failed to upload document.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setUploadDocumentError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setUploadDocumentError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setUploadDocumentError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setUploadDocumentError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setUploadDocumentError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to upload document. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setUploadDocumentError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setUploadDocumentError(`Failed to upload document. Please check your network connection and try again.`);
      }
    } finally {
      setUploadDocumentLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={() => { handleClose(); }}>
          <img src={closeIcon} alt="Close" style={styles.closeIcon} />
        </button>
        <h2 className="modal-title">Add Document</h2>
        <hr className="title-divider" />
        {/* <div className="filter-field" style={{ paddingBottom: '10px' }}>
                    <label>Client Name</label>
                    <TextInput
                        type="text"
                        placeholder="Enter client name"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        right={<img src={search} alt="Search" className="search-icon" />}
                    />
                </div> */}
        <div className="form-grid">

          <Input
            label="Document Name"
            placeholder="Enter Document Name"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            errorMessage={documentNameError}
            width={250}
          />

          {/* <Input
                type="number"
                label="Document Number"
                placeholder="Enter Document Number"
                value={documentNumber}
                // onChange={(e) => setDocumentNumber(e.target.value)}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) { // Allow only numbers
                      setDocumentNumber(value);
                  }
              }}
                width={250}
                errorMessage={documentNumberError}
                /> */}
          <Dropdown
            ref={(el) => (dropdownRefs.current["Source"] = el)}
            isOpen={openDropdown === "Source"}
            toggleDropdown={() => toggleDropdown("Source")}
            label="Source"
            options={sourceOptions}
            value={sourceOptions.find((option) => option.value === source)}
            onChange={(selectedOption) => setSource(selectedOption.value)}
            width={250}
            errorMessage={sourceError}
          />
          <Dropdown
            ref={(el) => (dropdownRefs.current["deliveryType"] = el)}
            isOpen={openDropdown === "deliveryType"}
            toggleDropdown={() => toggleDropdown("deliveryType")}
            label="Delivery Type"
            options={deliveryTypeOptions}
            value={deliveryTypeOptions.find((option) => option.value === deliveryType)}
            onChange={(selectedOption) => setDeliveryType(selectedOption.value)}
            width={250}
            errorMessage={deliveryTypeError}
          />
           {docType === 'external' && <Dropdown
            ref={(el) => (dropdownRefs.current["documentType"] = el)}
            isOpen={openDropdown === "documentType"}
            toggleDropdown={() => toggleDropdown("documentType")}
            label="Document Type"
            options={documentTypeOptions}
            value={documentTypeOptions.find((option) => option.value === documentType)}
            onChange={(selectedOption) => setDocumentType(selectedOption.value)}
            width={250}
            errorMessage={documentTypeError}
          /> }
        </div>
        <div style={styles.fullWidth}>
          <Input
            label="Description"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            width={'100%'}
          // errorMessage={documentDescriptionError}
          />
        </div>
        {!documentData && (
          <div
            style={styles.uploadContainer}
            onDragOver={(e) => e.preventDefault()} // Prevent default drag behavior
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDrop(e);
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling
              fileInputRef.current?.click(); // Programmatically trigger file input click
            }}
          >
            <div style={styles.uploadBox}>
              <span style={styles.uploadCircle}>
                <img src={pluseIcon} alt="Plus Icon" style={{ ...styles.iconImage, filter: 'brightness(0) invert(0)' }} />
              </span>
              <p style={uploadedFileName ? styles.uploadedFileName : styles.uploadText}>
                {uploadedFileName || // Show file name if uploaded
                  "Upload Document, Drop here or click on upload"}
              </p>
              <img src={uploadIcon} alt="Upload Icon" style={{ ...styles.uploadIconImage, flexShrink: 0, marginLeft: 'auto', }} />
              <input
                type="file"
                ref={fileInputRef}
                style={styles.fileInput}
                onChange={handleFileChange} // Handle file selection
                onClick={(e) => e.stopPropagation()} // Prevent double triggering
              />
            </div>
          </div>
        )}
        {documentUploadError && <div className="list-error-msg" style={{ display: 'flex', padding: '5px' }}><img
          src={errorIcon}
          alt="Error"
          style={{ width: "12px", height: "12px", marginRight: '4px' }}
        />{documentUploadError} </div>}
        <hr className="footer-divider" />
        <div className="button-container" style={{ alignItems: 'center' }}>
          <button
            className="add-client-button"
            onClick={handleUploadDocument}
            // onClick={documentData ? handleUpdateDocument : handleUploadDocument}
            disabled={uploadDocumentLoading}
            style={{
              paddingLeft: '30px',
              paddingRight: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px', // Add spacing between text and loader
              position: 'relative',
              opacity: uploadDocumentLoading ? 0.5 : 1, // Reduce opacity when loading
              cursor: uploadDocumentLoading ? 'not-allowed' : 'pointer',
            }}
          >
            <span>Add</span>
            {uploadDocumentLoading && (
              <Loader loading={uploadDocumentLoading} color={'var(--background-color)'} size="small" />
            )}
          </button>
          <button className="cancel-button" onClick={() => { handleClose(); }}>
            Cancel
          </button>
          {success && <div className="list-success-msg" style={{ marginBottom: '3px' }}>{success} </div>}
          {uploadDocumentError && <div className="list-error-msg" style={{ marginBottom: '3px' }}>{uploadDocumentError} </div>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  closeIcon: {
    width: "16px",
    height: "16px",
  },
  uploadContainer: {
    marginTop: '20px',
    width: '100%',
    // marginBottom:'20px'
  },
  uploadBox: {
    width: '100%',
    border: '1px dashed #609966',
    borderRadius: '10px',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative', // Position relative to contain the hidden input
  },
  uploadCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
  },
  plusIcon: {
    fontSize: '16px',
    color: '#333',
  },
  uploadText: {
    flex: 1,
    color: '#262626',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  uploadArrow: {
    fontSize: '18px',
    color: '#555',
  },
  fileInput: {
    display: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "10px",
    marginTop: "20px",
  },
  resourceImage: {
    width: "25px",
    height: "25px",
    backgroundColor: "#D9D9D9",
    borderRadius: "50%",
  },
  iconImage: {
    width: '15px',
    height: '15px',
    padding: '2px'
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  fullWidth: {
    gridColumn: "span 2",
    width: "100%", // Full width
    marginTop: '14px'
  },
};

export default ClientTaskDocumentAddModal;
