import React, { useState, useRef, useEffect } from "react";
import Input from "../Input";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import search from '../../Assets/Icons/search.png';
import closeIcon from "../../Assets/Icons/close.png";
import uploadIcon from "../../Assets/Icons/upload.png";
import pluseIcon from "../../Assets/Icons/Pluse.png";
import Loader from '../Loader';
import errorIcon from '../../Assets/Icons/error_message.png';

const CreateTaskDocumentModal = ({ isOpen, onClose, info, onSubmit }) => {
    const sourceOptions = [
        { label: "Inward (Sent by Client)", value: 1 },
        { label: "Outward (Sent by Employee)", value: 2 },
    ];
    const documentTypeOptions = [
        { label: "Internal", value: 1 },
        { label: "Client Documents", value: 2 },
    ];
    const deliveryTypeOptions = [
        { label: "Email", value: "EMAIL" },
        { label: "Whatsapp", value: "WATSAPP" },
        { label: "Physical", value: "PHYSICAL" },
        { label: "Others", value: "OTHERS" },
    ];
    const [documentName, setDocumentName] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");
    const [documentType, setDocumentType] = useState();
    const [source, setSource] = useState();
    const [deliveryType, setDeliveryType] = useState();
    const fileInputRef = useRef(null);
    const [error, setError] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [documentTypeError, setDocumentTypeError] = useState('')
    const [deliveryTypeError, setDeliveryTypeError] = useState('')
    const [sourceError, setSourceError] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [base64File, setBase64File] = useState('');
    const [fileExtension, setFileExtension] = useState('');
    const [documentUploadError, setDocumentUploadError] = useState('');
    const [success, setSuccess] = useState('');
    const [uploadDocumentLoading, setUploadDocumentLoading] = useState(false);
    const [uploadDocumentError, setUploadDocumentError] = useState('');
    const [clientSearchError, setClientSearchError] = useState('');

    const [documentNameError, setDocumentNameError] = useState('');
    const [description, setDescription] = useState('');

    const handleSelectedEmployee = (employee) => {
        setSelectedEmployee(employee); // Set selected client data
        setSearchUser(employee?.client_name); // Update TextInput with the selected client's name
    };

    const [searchUser, setSearchUser] = useState(info);

    const handleFileChange = (event) => {
        setDocumentUploadError('');
        const files = event.target.files;

        if (files && files.length > 0) {
            const file = files[0];
            const fileName = file.name;
            const fileSize = file.size; // File size in bytes
            const maxFileSize = 2 * 1024 * 1024;
            const fileExtension = fileName.split('.').pop(); // Extract the file extension
            console.log("fileName", fileName);

            setUploadedFileName(fileName);

            if (fileSize > maxFileSize) {
                setDocumentUploadError("File size exceeds the maximum limit of 2MB.");
                return;
            }

            const reader = new FileReader();

            try {
                reader.onload = () => {
                    try {
                        const base64String = reader.result.split(',')[1]; // Extract Base64 string from result
                        //console.log("Base64 Encoded File:", base64String);

                        setBase64File(base64String); // Add the Base64 string to the payload
                        // Optionally, save the file extension in state or payload
                        setFileExtension(fileExtension);
                    } catch (parseError) {
                        console.error("Error parsing file to Base64:", parseError);
                        setDocumentUploadError("Failed to process the file. Please try again.");
                    }
                };

                reader.onerror = (error) => {
                    console.error("Error reading file:", error);
                    setDocumentUploadError("Failed to read the file. Please try again.");
                };

                reader.readAsDataURL(file); // Convert file to Base64 string
            } catch (readError) {
                console.error("File upload error:", readError);
                setDocumentUploadError("An unexpected error occurred during the file upload. Please try again.");
            }
        } else {
            setDocumentUploadError("No file selected. Please choose a file to upload.");
        }
    };

    const handleDrop = (e) => {
        const files = e.dataTransfer.files; // Get the files from the drop event
        if (files && files.length > 0) {
            //console.log("Dropped files:", files);
            handleFileChange({ target: { files } }); // Reuse handleFileChange for processing
        }
    };

    const handleClose = () => {
        setError('');
        onClose();
        setSuccess('');
        setBase64File('');
        setDocumentName('');
        setDeliveryType();
        setDocumentType();
        setDocumentNumber('')
        setSource();
        setUploadedFileName('')
        setDocumentNameError('');
        setDocumentUploadError('');
        setDeliveryTypeError('');
        setDocumentTypeError('');
        setSourceError('');
        setClientSearchError('');
        setSearchUser('');
        setDescription('');
        setSelectedEmployee(null);
    }

    const clearFeilds = () => {
        setDocumentName('');
        setDescription('');
        setDocumentType('');
        setSource('');
        setDeliveryType('');
        setBase64File('');
        setSearchUser('');
        setSelectedEmployee('');
    }

    const handleSubmit = () => {
        setUploadDocumentError('')
        setDocumentNameError('');
        setDocumentUploadError('');
        setDeliveryTypeError('');
        setDocumentTypeError('');
        setSourceError('');
        setClientSearchError('')
        if (!documentType) {
            setDocumentTypeError("Please select document type");
            return
        }
        if (!source) {
            setSourceError("Please select source");
            return;
        }
        if (!deliveryType) {
            setDeliveryTypeError("Please select delivery type");
            return;
        }
        if (!base64File) {
            setDocumentUploadError("Please upload document");
            return
        }
        const finalDocumentName = documentName?.trim() ? documentName : uploadedFileName?.split('.')[0];
        if (!finalDocumentName) {
            setDocumentNameError("Please enter document name");
            return
        }

        const formData = {
            finalDocumentName,
            documentType,
            source,
            deliveryType,
            description,
            uploadedFileName,
            base64File,
            fileExtension,
        };

        onSubmit(formData);
    };

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={() => { handleClose(); }}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">{'Add Document'}</h2>
                <hr className="title-divider" />
                <div className="form-grid">
                    <div>
                        <div className="filter-field" style={{ width: '100%' }}>
                            <Input
                                label="Client Name"
                                type="text"
                                placeholder="Client Name"
                                value={searchUser}
                                width={270}
                                style={{ padding: '20px 10px' }}
                                isEdit={false}
                            />
                        </div>
                    </div>
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["documentType"] = el)}
                        isOpen={openDropdown === "documentType"}
                        toggleDropdown={() => toggleDropdown("documentType")}
                        label="Document Type"
                        options={documentTypeOptions}
                        value={documentTypeOptions.find((option) => option.value === documentType)}
                        onChange={(selectedOption) => setDocumentType(selectedOption.value)}
                        width={250}
                        errorMessage={documentTypeError}
                    />

                    <Input
                        label="Document Name"
                        placeholder="Enter Document Name"
                        // value={documentName}
                        value={documentName?.trim() ? documentName : uploadedFileName?.split('.')[0] || ""}
                        onChange={(e) => setDocumentName(e.target.value)}
                        errorMessage={documentNameError}
                        width={250}
                    />
                    {/* {modalMode === "edit" && (
                <Input
                type="number"
                label="Document No."
                placeholder="Enter Document Number"
                value={documentNumber}
                // onChange={(e) => setDocumentNumber(e.target.value)}
              //   onChange={(e) => {
              //     const value = e.target.value;
              //     if (/^\d*$/.test(value)) {
              //         setDocumentNumber(value);
              //     }
              // }}
              readOnly={modalMode === "edit"} 
                width={250}
                // errorMessage={documentNumberError}
                />
              )} */}
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["Source"] = el)}
                        isOpen={openDropdown === "Source"}
                        toggleDropdown={() => toggleDropdown("Source")}
                        label="Source"
                        options={sourceOptions}
                        value={sourceOptions.find((option) => option.value === source)}
                        onChange={(selectedOption) => setSource(selectedOption.value)}
                        width={250}
                        errorMessage={sourceError}
                    />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["Delivery"] = el)}
                        isOpen={openDropdown === "Delivery"}
                        toggleDropdown={() => toggleDropdown("Delivery")}
                        label="Delivery Type"
                        options={deliveryTypeOptions}
                        value={deliveryTypeOptions.find((option) => option.value === deliveryType)}
                        onChange={(selectedOption) => setDeliveryType(selectedOption.value)}
                        width={250}
                        errorMessage={deliveryTypeError}
                    />
                </div>
                <div style={styles.fullWidth}>
                    <Input
                        label="Description"
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        width={'100%'}
                    // errorMessage={documentDescriptionError}
                    />
                </div>
                <div
                    style={styles.uploadContainer}
                    onDragOver={(e) => e.preventDefault()} // Prevent default drag behavior
                    onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDrop(e);
                    }}
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent event bubbling
                        fileInputRef.current?.click(); // Programmatically trigger file input click
                    }}
                >
                    <div style={styles.uploadBox}>
                        <span style={styles.uploadCircle}>
                            <img src={pluseIcon} alt="Plus Icon" style={{ ...styles.iconImage, filter: 'brightness(0) invert(0)' }} />
                        </span>
                        <p style={uploadedFileName ? styles.uploadedFileName : styles.uploadText}>
                            {uploadedFileName || // Show file name if uploaded
                                "Upload Document, Drop here or click on upload"}
                        </p>
                        <img src={uploadIcon} alt="Upload Icon" style={{ ...styles.uploadIconImage, flexShrink: 0, marginLeft: 'auto', }} />
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={styles.fileInput}
                            onChange={handleFileChange} // Handle file selection
                            onClick={(e) => e.stopPropagation()} // Prevent double triggering
                        />
                    </div>
                </div>
                {documentUploadError && <div className="list-error-msg" style={{ display: 'flex', padding: '5px' }}><img
                    src={errorIcon}
                    alt="Error"
                    style={{ width: "12px", height: "12px", marginRight: '4px' }}
                />{documentUploadError} </div>}
                <hr className="footer-divider" />
                <div className="button-container" style={{ alignItems: 'center' }}>
                    <button
                        className="add-client-button"
                        disabled={uploadDocumentLoading}
                        style={{
                            paddingLeft: '30px',
                            paddingRight: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px', // Add spacing between text and loader
                            position: 'relative',
                            opacity: uploadDocumentLoading ? 0.5 : 1, // Reduce opacity when loading
                            cursor: uploadDocumentLoading ? 'not-allowed' : 'pointer',
                        }}
                        onClick={handleSubmit}
                    >
                        <span>{'Add'}</span>
                        {uploadDocumentLoading && (
                            <Loader loading={uploadDocumentLoading} color={'var(--background-color)'} size="small" />
                        )}
                    </button>
                    <button className="cancel-button" onClick={() => { handleClose(); }}>
                        Cancel
                    </button>
                    {success && <div className="list-success-msg" style={{ marginBottom: '3px' }}>{success} </div>}
                    {uploadDocumentError && <div className="list-error-msg" style={{ marginBottom: '3px' }}>{uploadDocumentError} </div>}
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "12px",
        height: "12px",
    },
    uploadContainer: {
        marginTop: '20px',
        width: '100%',
        // paddingBottom:'20px'
    },
    uploadBox: {
        width: '100%',
        border: '1px dashed #609966',
        borderRadius: '10px',
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain the hidden input
    },
    uploadCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    },
    plusIcon: {
        fontSize: '16px',
        color: '#333',
    },
    uploadText: {
        flex: 1,
        color: '#262626',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    uploadArrow: {
        fontSize: '18px',
        color: '#555',
    },
    fileInput: {
        display: "none",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        marginTop: "20px",
    },
    resourceImage: {
        width: "25px",
        height: "25px",
        backgroundColor: "#D9D9D9",
        borderRadius: "50%",
    },
    iconImage: {
        width: '15px',
        height: '15px',
        padding: '2px'
    },
    dropdownList: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
        padding: 0, // Remove default padding
        margin: 0, // Remove default margin
        listStyleType: 'none', // Remove the dots
    },
    dropdownItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee', // Default for all items
        backgroundColor: '#fff',
        hover: {
            backgroundColor: '#f0f0f0',
        },
    },
    fullWidth: {
        gridColumn: "span 2",
        width: "100%", // Full width
        marginTop: '14px'
    },
};

export default CreateTaskDocumentModal;
