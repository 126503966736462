import React ,{ useEffect,useState, useRef } from "react";
import { ORG_DELETE_SUB_SERVICE_CHECK, ORG_GET_SUB_SERVICE_LIST_SEARCH, ORG_GET_SUB_SERVICE_CHECK_LIST } from "../../../../ApiServices/BaseURL";
import { postData,getData } from "../../../../ApiServices/ApiService";

const useTaskCheckController = (onRefresh,servicesSearchList,typeCheckList, error) => {
   
  // const [subCategories, setSubCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [categorieInfo,setCategorieInfo] = useState({})

  const categoryCheckInfoRef = useRef(null);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [deleteError, setDeleteError] = useState({});
  const [deleteSuccess, setDeleteSuccess] = useState({});
  const typeCheckListRef = useRef(typeCheckList)
  const [searchTaskCheckListError, setSearchTaskCheckListError] = useState(error);
  const [searchTaskCheckListLoading, setSearchTaskCheckListLoading] = useState(false);
  const [serviceErrorMessage, setServiceErrorMessage] = useState('');
  const [serviceCategoryErrorMessage, setServiceCategoryErrorMessage] = useState('');

  useEffect(()=>{
    // fetchTaskCategorieList();
  },[])

  const categoryListRef = useRef([]);
  const subCategoryListRef = useRef([]);
  const [subLoading, setSubLoading] = useState(false)
  const [subError, setSubError] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const taskInfoRef = useRef(null);
  const [taskChecksList, setTaskChecksList] = useState([]);

  const servicesSearchListRef = useRef([]);
  // const subCategoryListRef = useRef([]);
  // const [subLoading, setSubLoading] = useState(false)
  // const [subError, setSubError] = useState('');

  // useEffect(()=>{
  //   // fetchTaskCategorieList();
  //   if(categories?.length > 0){
  //     categoryListRef.current = categories.map(service => ({
  //       label: service.service_name,
  //       value: service.service_id
  //     }));
  //   }
  // },[categories])

  useEffect(()=>{
    // fetchTaskCategorieList();
    if(servicesSearchList?.length > 0){
      servicesSearchListRef.current = servicesSearchList.map(service => ({
        label: service.service_name,
        value: service.service_id
      }));
    }
  },[servicesSearchList])

  useEffect(()=>{
    // subCategoryInfoRef.current = null;
    //console.log("Sub Categories",typeCheckList);
    // setTaskChecksList([]);
    setTaskChecksList(typeCheckList);
  },[typeCheckList])



  const fetchTaskSubCategorieList =async (serviceId)=>{
    try {
      //console.log('fetchTaskSubCategorieList serviceId @@',serviceId)
      setSubLoading(true);
      setSubError('');
      setServiceCategoryErrorMessage('');
      setFilters((prevFilters) => ({
        ...prevFilters,
        service_category: null,
    }));
      subCategoryListRef.current = [];
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_id": serviceId 
      }
        
      //console.log('fetchTaskSubCategorieList payload',payLoad);
    
        // Make the API call service_id=10006&
        const response = await postData(ORG_GET_SUB_SERVICE_LIST_SEARCH,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                subCategoryListRef.current = responseData?.dataJ.map(service => ({
                  label: service?.service_sub_name,
                  value: service?.service_sub_id
                }));
              } else {
                setServiceCategoryErrorMessage(responseData?.info || 'Data Not Found');
              }
            } else {
              setServiceCategoryErrorMessage(responseData?.info || 'Failed to fetch service category data. Please try again.');
            }
          } else {
            setServiceCategoryErrorMessage(responseData?.info || 'Failed to fetch service category data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setServiceCategoryErrorMessage('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setServiceCategoryErrorMessage(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setServiceCategoryErrorMessage(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setServiceCategoryErrorMessage('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setServiceCategoryErrorMessage(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setServiceCategoryErrorMessage('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setServiceCategoryErrorMessage(`Failed to fetch service category data. Please check your network connection and try again.`);
        }
      } finally {
        setSubLoading(false);
      }
  }

  // const fetchTaskCategorieList =async ()=>{
  //   try {
  //       setLoading(true);
  //       const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
  //       // Make the API call service_id=10006&
  //       const response = await getData(`${ORG_GET_SUB_SERVICE_CHECK_LIST}?emp_id_auth=10001`);
  //       // const response = await getData(`${ORG_GET_SUB_SERVICE_CHECK_LIST}?emp_id_auth=10001&service_sub_id=100001`);
  //       const responseData = await response.json();
    
  //       // Check for success (200) and internal status code
  //       if (response?.status === 200) {
  //         if (responseData?.statusCode === 200) {
  //           if (responseData?.message.toLowerCase() === 'success') {
  //             if (responseData?.dataJ?.length > 0) {
  //               setSubCategories(responseData?.dataJ);
  //             } else {
  //               setError(responseData?.info || 'Data Not Found');
  //             }
  //           } else {
  //             setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
  //           }
  //         } else {
  //           setError(responseData?.info || 'Failed to fetch Task Categorie data.');
  //         }
  //       } 
  //       // Token-related errors: 400, 401, 403
  //       else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
  //         if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
  //           setError('Unauthorized access. Your session may have expired. Please log in again.');
  //           // Optionally, redirect to the login page or refresh the token
  //           // Example: redirectToLogin();
  //         } else {
  //           setError(responseData?.info || 'Bad request. Please check the request parameters.');
  //         }
  //       } 
  //       // Internal Server Error: 500
  //       else if (response?.status === 500) {
  //         setError(responseData?.info || 'Internal Server Error. Please try again later.');
  //       } 
  //       // Unexpected or network-related errors
  //       else {
  //         setError('Unexpected error occurred. Please try again later.');
  //       }
  //     } catch (error) {
  //       // Handle various error scenarios
  //       if (error.response) {
  //         // Server responded with a status outside the 2xx range
  //         setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
  //       } else if (error.request) {
  //         // Request was made but no response was received
  //         setError('No response received from the server. Please check your network connection and try again.');
  //       } else {
  //         // An error occurred in setting up the request
  //         setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  // }

  const handleAddDocument = () => {
    categoryCheckInfoRef.current = null;
    setIsModalOpen(true);
};

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getStatusStyle = (status) => {
    switch (status) {
    //   case "2":
    //     return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" };
      case 0:
        //Active
        return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" };
      case 1:
        //Disabled
        return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" };
      default:
        return {};
    }
  };

  const handleEditService =(service)=>{
    setCategorieInfo(service);
    categoryCheckInfoRef.current = service;
    setIsModalOpen(true);

  }

  const OnAddUpdateSucess = () => {
    // fetchTaskCategorieList();
    if(onRefresh){
      //console.log('on sub refresh')
      onRefresh();
      setSubError('');
      setSearchTaskCheckListError('');
      setServiceErrorMessage('');
      setServiceCategoryErrorMessage('');
      setFilters({
        service: null,
        service_category: null,
      });
      setShowFilters(false);
    }
  }

  const handleDeleteTaskCheck = (service) => {
    // //console.log('handleDeleteTaskCheck service @', service);
    // showConfirmation(service);
    taskInfoRef.current = service;
    setIsDeleteModalOpen(true);
  }

  const showConfirmation = (service) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      callDeleteTaskCheck(service);
    } else {
      //console.log("Delete canceled");
    }
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
};

  const callDeleteTaskCheck = async (service) => {
    try {
      setDeleteError({});
      setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: ''}));
      setDeleteLoading((prev) => ({ ...prev, [service?.service_check_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_check_id" : service?.service_check_id
      }
  
      //console.log('callDeleteTaskCheck payload',payLoad);
  
      // Make the API call
      const response = await postData(ORG_DELETE_SUB_SERVICE_CHECK,payLoad);
      const responseData = await response.json();
      //console.log('callDeleteTaskCheck response Data',responseData);
  
      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setDeleteSuccess((prev) => ({ ...prev, [service?.service_check_id]: responseData?.info}));
            setTimeout(() => {
              setDeleteSuccess((prev) => ({ ...prev, [service?.service_check_id]: ''}));
              OnAddUpdateSucess();
              setCurrentPage(1);
            }, 3000);
          } else {
            setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: responseData?.info || 'Failed to delete service. Please try again.'}));
          }
        } else {
          setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: responseData?.info || 'Failed to delete service. Please try again...'}));
        }
      } 
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: 'Unauthorized access. Your session may have expired. Please log in again.'}));
        } else {
          setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: responseData?.info || 'Bad request. Please check the request parameters.'}));
        }
      } 
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: responseData?.info || 'Internal Server Error. Please try again later.'}));
      } 
      // Unexpected or network-related errors
      else {
        setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: 'Unexpected error occurred. Please try again later.'}));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete service. Please try again.'}`}));
      } else if (error.request) {
        setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: 'No response received from the server. Please check your network connection and try again.'}));
      } else {
        setDeleteError((prev) => ({ ...prev, [service?.service_check_id]: `Failed to delete service data. Please check your network connection and try again.` }));
      }
    } finally {
        setDeleteLoading((prev) => ({ ...prev, [service?.service_check_id]: false })); 
    }
  }

  const [showFilters, setShowFilters] = useState(false); // Control the visibility of filters
  const [filters, setFilters] = useState({
      service: null,
      service_category: null,
  });

  const handleFilterChange = (name, value) => {
    //console.log('handleFilterChange name @@',name, 'value', value);
      setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: value,
      }));
      setServiceErrorMessage('');
      setServiceCategoryErrorMessage('');
      if(name=== 'service'){
        fetchTaskSubCategorieList(value?.value);
      }
  };

  const applyFilters = () => {
      //console.log('Applying filters:', filters);
      fetchSearchTaskChecksList();
  };

  const resetFilters = () => {
    if (filters?.service || filters?.service_category) {
      setCurrentPage(1);
      onRefresh();
    }
    setSubError('');
    setSearchTaskCheckListError('');
    setServiceErrorMessage('');
    setServiceCategoryErrorMessage('');
    setFilters({
        service: null,
        service_category: null,
    });
  };

  const cancelFilters = () => {
    resetFilters();
    setShowFilters(!showFilters);
  }

  const toggleFilters = () => {
      setShowFilters(!showFilters);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // var taskList = typeCheckList;
  // if(taskChecksList?.length > 0){
  //   taskList = taskChecksList;
  // }else{
  //   taskList = typeCheckList;
  // }
  const totalPages = Math.ceil(taskChecksList?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = taskChecksList?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
  }
  };

  const fetchSearchTaskChecksList =async ()=>{
    try {
      //console.log('fetchSearchTaskChecksList  @@', filters)
      setSubError('');
      setServiceErrorMessage('');
      setServiceCategoryErrorMessage('');
      if (!filters?.service?.value) {
        setServiceErrorMessage('Please Select the Service'); return;
      }
      // if (!filters?.service_category?.value) {
      //   setServiceCategoryErrorMessage('Please Select the Service Category'); return;
      // }
      setSearchTaskCheckListLoading(true);
      setSearchTaskCheckListError('');
      setTaskChecksList([]);
      setCurrentPage(1);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      // Make the API call service_id=10006&
      const response = await getData(`${ORG_GET_SUB_SERVICE_CHECK_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&service_id=${filters?.service?.value || 0}&service_sub_id=${filters?.service_category?.value || 0}`);
      const responseData = await response.json();

        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                setTaskChecksList(responseData?.dataJ);
              } else {
                setSearchTaskCheckListError(responseData?.info || 'Data Not Found');
              }
            } else {
              setSearchTaskCheckListError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setSearchTaskCheckListError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setSearchTaskCheckListError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setSearchTaskCheckListError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setSearchTaskCheckListError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setSearchTaskCheckListError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setSearchTaskCheckListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setSearchTaskCheckListError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setSearchTaskCheckListError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        }
      } finally {
        setSearchTaskCheckListLoading(false);
      }
  }

  const maxVisiblePages = 3;

    const getVisiblePages = () => {
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
    };

    const visiblePages = getVisiblePages();

  return {
    // subCategories,
    isModalOpen,
    handleAddDocument,
    handleCloseModal,
    getStatusStyle,
    handleEditService,
    categorieInfo,
    categoryCheckInfoRef,
    OnAddUpdateSucess,
    handleDeleteTaskCheck,
    loading,
    deleteLoading,
    deleteError,
    error,
    showFilters,
    filters,
    handleFilterChange,
    applyFilters, resetFilters, cancelFilters,
    toggleFilters,
    categoryListRef,
    subCategoryListRef,
    subError,
    subLoading,
    tasks: currentTasks,
    currentPage,
    totalPages,
    handlePageChange,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    callDeleteTaskCheck,
    taskInfoRef,
    searchTaskCheckListError, searchTaskCheckListLoading,
    deleteSuccess,
    serviceErrorMessage, serviceCategoryErrorMessage,
    visiblePages,
  };
};

export default useTaskCheckController;
