import { useState, useRef, useEffect } from "react";
import { postData } from "../../../../ApiServices/ApiService";
import { ORG_EMP_SPECIALIZATION_UPDATE } from '../../../../ApiServices/BaseURL';

const UserSpecializationController = (userInfo, userSpecializationList, userSpecializationLoading, userSpecializationError, onRefresh) => {

    const [selUserSpecializationService, setSelUserSpecializationService] = useState(null);
    const [isUserSpecializationModalOpen, setIsUserSpecializationModalOpen] = useState(false);
    const [userSpecToggleUpdateError, setUserSpecToggleUpdateError] = useState({});
    const [userSpecToggleUpdateSuccess, setUserSpecToggleUpdateSuccess] = useState({});
    const [userSpecToggleUpdateLoading, setUserSpecToggleUpdateLoading] = useState({});
    const [toggleUpdatedMessages, setToggleUpdatedMessages] = useState([]);

    const handleCloseUserSpecializationModal = () => {
        setIsUserSpecializationModalOpen(false);
        setSelUserSpecializationService(null);
    };

    const handleEditSpecialization = (service) => {
        setSelUserSpecializationService(service);
        setIsUserSpecializationModalOpen(true);
    }

    const handleToggleChange = async (service, status) => {
        console.log('Toggle is now:', service, 'status:', status);
        const updatedService = {
            ...service,
            sub_service_list: service?.sub_service_list?.map(subService => ({
                ...subService,
                is_active: status ? 1 : 0
            }))
        };

        console.log("Updated Service Data specialization:", updatedService);
        await handleUpdateToggle(updatedService);
    }

    const getActiveSubServiceNames = (list) => {
        const activeSubServiceNames = list
            ?.filter((subservice) => subservice.is_active === 1)
            ?.map((subservice) => subservice.service_sub_name);

        // return activeSubServiceNames?.length > 0 ? activeSubServiceNames.join(", ") : "--";
        return {
            names: activeSubServiceNames?.length > 0 ? activeSubServiceNames.join(", ") : "--",
            isActive: activeSubServiceNames?.length > 0
        };
               
    };

    const handleUpdateToggle = async (updatedService) => {
        console.log('updatedService :', updatedService);

        try {
            setUserSpecToggleUpdateError((prev) => ({ ...prev, [updatedService?.service_id]: ''}));
            setUserSpecToggleUpdateLoading((prev) => ({ ...prev, [updatedService?.service_id]: true }));
            setUserSpecToggleUpdateSuccess((prev) => ({ ...prev, [updatedService?.service_id]:''}));

            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                emp_id_auth: storedUserInfo?.emp_id,
                emp_id: userInfo?.emp_id,
                service_id: updatedService?.service_id,
                sub_service_list: updatedService?.sub_service_list
            }

            console.log('handleUpdateToggle service payload', payLoad);
            const response = await postData(ORG_EMP_SPECIALIZATION_UPDATE, payLoad);
            const responseData = await response.json();
            //console.log('handleUpdate service Data', responseData);
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        // setToggleUpdatedMessages((prevDeleted) => [...prevDeleted, updatedService?.service_id]);
                        setUserSpecToggleUpdateSuccess((prev) => ({ ...prev, [updatedService?.service_id]:responseData?.info}));
                          setTimeout(() => {
                            setUserSpecToggleUpdateSuccess((prev) => ({ ...prev, [updatedService?.service_id]:''}));
                            onRefresh();
                          }, 2000);
                    } else {
                        setUserSpecToggleUpdateError((prev) => ({ ...prev, [updatedService?.service_id]:responseData?.info || 'Failed to update user specialization toggle.'}));
                    }
                } else {
                    setUserSpecToggleUpdateError((prev) => ({ ...prev, [updatedService?.service_id]:'Failed to update user specialization toggle. Please try again.'}));
                }
            } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setUserSpecToggleUpdateError((prev) => ({ ...prev, [updatedService?.service_id]:'Unauthorized access. Your session may have expired. Please log in again.'}));
                } else {
                    setUserSpecToggleUpdateError((prev) => ({ ...prev, [updatedService?.service_id]:responseData?.info || 'Bad request. Please check the request parameters.'}));
                }
            } else {
                setUserSpecToggleUpdateError((prev) => ({ ...prev, [updatedService?.service_id]:'Unable to update user specialization toggle.'}));
            }
        } catch (error) {
            if (error.response) {
                setUserSpecToggleUpdateError((prev) => ({ ...prev, [updatedService?.service_id]:`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update user specialization toggle. Please check your network connection.'}`}));
            } else if (error.request) {
                setUserSpecToggleUpdateError((prev) => ({ ...prev, [updatedService?.service_id]:'No response received from the server. Please check your network connection and try again.'}));
            } else {
                setUserSpecToggleUpdateError((prev) => ({ ...prev, [updatedService?.service_id]:`Failed to update user specialization toggle. Please check your network connection and try again.`}));
            }
        } finally {
            setUserSpecToggleUpdateLoading((prev) => ({ ...prev, [updatedService?.service_id]: false }));
        }
    };


    return {
        handleEditSpecialization,
        handleToggleChange,
        selUserSpecializationService,
        isUserSpecializationModalOpen,
        handleCloseUserSpecializationModal,
        getActiveSubServiceNames,
        userSpecToggleUpdateLoading, userSpecToggleUpdateError, userSpecToggleUpdateSuccess,
        toggleUpdatedMessages
    };

}

export default UserSpecializationController;