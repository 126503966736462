import React, { useState } from 'react';
import InvoiceReceiptNotesController from '../../../../Controllers/AdminScreens/Invoices/Settings/InvoiceReceiptNotesController';

const InvoiceReceiptNotesScreen = () => {
    const {
        narration,
        setNarration,
        notes,
        setNotes
    } = InvoiceReceiptNotesController();

    const [isEditing, setIsEditing] = useState(false);
    const [tempNarration, setTempNarration] = useState(narration);
    const [tempNotes, setTempNotes] = useState(notes);

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Receipt Narration & Notes</h2>

            {/* Narration Section */}
            <div style={styles.field}>
                <label style={styles.label}>Narration</label>
                {isEditing ? (
                    <textarea
                        value={tempNarration}
                        onChange={(e) => setTempNarration(e.target.value)}
                        style={styles.textarea}
                        rows={4}
                    />
                ) : (
                    <div style={styles.textValue}>{narration}</div>
                )}
            </div>

            {/* Notes Section */}
            <div style={styles.field}>
                <label style={styles.label}>Notes</label>
                {isEditing ? (
                    <textarea
                        value={tempNotes}
                        onChange={(e) => setTempNotes(e.target.value)}
                        style={styles.textarea}
                        rows={4}
                    />
                ) : (
                    <div style={styles.textValue}>{notes}</div>
                )}
            </div>

            <hr className="footer-divider" />

            {/* Action Buttons */}
            <div className="button-container" style={{ gap: "10px" }}>
                {isEditing ? (
                    <>
                        <button className="add-client-button"
                            style={styles.updateButton}
                            onClick={() => {
                                setNarration(tempNarration);
                                setNotes(tempNotes);
                                setIsEditing(false);
                            }}>
                            Update
                        </button>
                        <button style={styles.cancelButton} onClick={() => {
                            setTempNarration(narration);
                            setTempNotes(notes);
                            setIsEditing(false);
                        }}>
                            Cancel
                        </button>
                    </>
                ) : (
                    <button className="add-client-button" onClick={() => setIsEditing(true)}>
                        Edit
                    </button>
                )}
            </div>
        </div>
    );

}

const styles = {
    // container: {
    //   width: "600px",
    //   margin: "0 auto",
    //   fontFamily: "Arial, sans-serif",
    // },
    title: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: '500',
        marginBottom: '5px'
    },
    field: {
        display: "flex",
        alignItems: "flex-start",
        marginBottom: "15px",
        gap: "10px",
        marginTop: "25px",
    },
    label: {
        flex: "0 0 200px",
        color: 'black',
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: '400',
    },
    textValue: {
        flex: "1",
        fontSize: "12px",
        color: "#333",
        lineHeight: "1.5",
        color: '#192A3E',
    },
    textarea: {
        flex: "1",
        border: "1px solid #d1e3ed",
        borderRadius: "5px",
        fontSize: "14px",
        padding: "10px",
        width: "100%",
        resize: "none",
    },
    updateButton: {
        backgroundColor: "#40513B",
        color: "white",
        border: "none",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    },
    cancelButton: {
        backgroundColor: "white",
        color: "#40513B",
        border: "1px solid #858585",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    }
};

export default InvoiceReceiptNotesScreen;
