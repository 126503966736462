
import React, { useState } from 'react';
import Input from '../../../../../Components/Input';
import uploadIcon from '../../../../../Assets/Icons/upload.png'
import InvoiceOtherSettingsController from '../../../../Controllers/AdminScreens/Invoices/Settings/InvoiceOtherSettingsController';


const InvoiceOtherSettingsScreen = () => {
    const {
        booksOpeningBalance,
        setBooksOpeningBalance,
        overdueSetting,
        setOverdueSetting
    } = InvoiceOtherSettingsController();

    const [isEditingBooksOpening, setIsEditingBooksOpening] = useState(false);
    const [isEditingOverdue, setIsEditingOverdue] = useState(false);
    const [tempBooksOpening, setTempBooksOpening] = useState(booksOpeningBalance);
    const [tempOverdue, setTempOverdue] = useState(overdueSetting);

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Other Setting</h2>

            <div style={styles.field}>
                <label style={styles.label}>Books Opening Balance</label>
                {isEditingBooksOpening ? (
                    <Input
                        value={tempBooksOpening}
                        onChange={(e) => setTempBooksOpening(e.target.value)}
                        searchBoxWidth={220}
                    />
                ) : (
                    <div>
                        <span style={styles.value}>{booksOpeningBalance}</span>
                    </div>
                )}
            </div>
            {isEditingBooksOpening ? (
                <div className="button-container" style={{ gap: "10px" }}>
                    <button className='add-client-button' onClick={() => {
                        setBooksOpeningBalance(tempBooksOpening);
                        setIsEditingBooksOpening(false);
                    }}>
                        Update
                    </button>
                    <button style={styles.cancelButton} onClick={() => {
                        setTempBooksOpening(booksOpeningBalance);
                        setIsEditingBooksOpening(false);
                    }}>
                        Cancel
                    </button>
                </div>
            ) : (
                <button className='add-client-button' onClick={() => setIsEditingBooksOpening(true)} style={{ marginTop: '50px' }}>
                    Edit
                </button>
            )}

            {/* Overdue Setting */}
            <div style={{ ...styles.field, marginTop: '50px' }}>
                <label style={styles.label}>Overdue Setting</label>
                {isEditingOverdue ? (
                    <Input
                        value={tempOverdue}
                        onChange={(e) => setTempOverdue(e.target.value)}
                        searchBoxWidth={220}
                    />
                ) : (
                    <div>
                        <span style={styles.value}>{overdueSetting}</span>
                    </div>
                )}
            </div>
            {isEditingOverdue ? (
                <div className="button-container" style={{ gap: "10px" }}>
                    <button className='add-client-button' onClick={() => {
                        setOverdueSetting(tempOverdue);
                        setIsEditingOverdue(false);
                    }}>
                        Update
                    </button>
                    <button style={styles.cancelButton} onClick={() => {
                        setTempOverdue(overdueSetting);
                        setIsEditingOverdue(false);
                    }}>
                        Cancel
                    </button>
                </div>
            ) : (
                <button className='add-client-button' onClick={() => setIsEditingOverdue(true)} style={{ marginTop: '50px' }}>
                    Edit
                </button>
            )}
        </div>
    );

}

const styles = {
    // container: {
    //   width: "600px",
    //   margin: "0 auto",
    //   fontFamily: "Arial, sans-serif",
    // },
    title: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: '500',
    },
    field: {
        // display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        marginTop: "15px",
    },
    label: {
        flex: "0 0 200px", // Ensures all labels are the same width
        color: 'black',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: '400',
    },
    value: {
        flex: "1",
        fontSize: "14px",
        color: "#333",
        lineHeight: "1.5",
        color: '#192A3E',
    },
    // input: {
    //   flex: "1",
    //   height: "30px",
    //   padding: "5px 10px",
    //   fontSize: "14px",
    //   border: "1px solid #d1d5db",
    //   borderRadius: "4px",
    // },

    addServiceButton: {
        backgroundColor: "transparent",
        border: "1px #858585 solid",
        borderRadius: "5px",
        padding: "8px 12px",
        color: "#40513B",
        cursor: "pointer",
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: '400',
        borderRadius: '28px',
        // marginBottom:'16px'
        display: "flex", // Use flexbox for alignment
        alignItems: "center", // Vertically align icon and text
        justifyContent: "center",
    },
    icon: {
        width: "12px",
        height: "12px",
        marginRight: "10px",
    },
    cancelButton: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        color: "#40513B",
        border: "1px solid #858585",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    }
};

export default InvoiceOtherSettingsScreen