import React, { useState, useEffect, useRef } from "react";
import { getData, postData } from "../../../../ApiServices/ApiService";
import { ORG_GET_ROLES_LIST, ORG_GET_TEAM_LIST, ORG_ADD_EMPLOYEE } from "../../../../ApiServices/BaseURL";
import { validateEmail, validateMobileNumber } from "../../../../Utils/Validation";
import { useNavigate } from "react-router-dom";

const AddUserPrimaryInformationController = () => {

    const navigate = useNavigate();

    const [isRoleLoading, setIsRoleLoading] = useState(false)
    const [roleError, setRoleError] = useState('');
    const rolesListRef = useRef([]);
    const [teamLoading, setTeamLoading] = useState(false)
    const [teamError, setTeamError] = useState('');
    const teamListRef = useRef([]);
    const [primaryData, setPrimaryData] = useState({
        "emp_id": null,
        "emp_name": "",
        "emp_email": "",
        "emp_mobile_no": "",
        "emp_mobile_code": "+91",
        "role_id": 0,
        "team_id": 0,
        "emp_status": 0,
        "emp_code": "",
        "emp_task_assign_flag": 0,
        "team_name": "",
        "emp_id_team_manager": null,
        "emp_id_team_lead": null,
        "emp_id_team_manager_name": "",
        "emp_id_team_lead_name": "",
        "role_id_name": ""
    });
    const [addError, setAddError] = useState('');
    const [addLoading, setAddLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [fieldConfigs, setFieldConfigs] = useState([
        { label: "User Name", key: "emp_name", type: "text", error: "" },
        { label: "Email ID", key: "emp_email", type: "text", error: "" },
        { label: "Phone No.", key: "emp_mobile_no", type: "mobile", error: "" },
        { label: "Role", key: "role_id_name", type: "dropdown", options: rolesListRef.current, loading: isRoleLoading, error: "" },
        { label: "Date Of Joining", key: "employee_join_at", type: "date", error: "" },
        { label: "User ID/Employee Code", key: "emp_code", type: "text", error: "" },
        { label: "Team Name", key: "team_name", type: "dropdown", options: teamListRef.current, loading: teamLoading, error: "" },
        { label: "Team Leader", key: "emp_id_team_lead_name", type: "text" },
        { label: "Manager", key: "emp_id_team_manager_name", type: "text" },
    ]);

    useEffect(() => {
            fetchRoleList();
            fetchTeamList();

    }, [])

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const fetchRoleList = async () => {
        try {
            setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                    field.key === "role_id_name"
                        ? { ...field, loading: true }
                        : field
                )
            );
            setIsRoleLoading(true);
            setRoleError('');
            rolesListRef.current = [];
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_ROLES_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            // rolesListRef.current = responseData?.dataJ?.map(doc => {
                            //     const [key, value] = Object.entries(doc)[0]; // Extract the first key-value pair
                            //     return { label: value, value: key };
                            //   });
                            rolesListRef.current = await responseData?.dataJ.map(service => ({
                                label: service.role_name,
                                value: service.role_id
                            }));
                            setFieldConfigs((prevConfigs) =>
                                prevConfigs.map((field) =>
                                    field.key === "role_id_name"
                                        ? { ...field, options: rolesListRef.current }
                                        : field
                                )
                            );
                        } else {
                            setRoleError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setRoleError(responseData?.info || 'Failed to fetch Roles data. Please try again.');
                    }
                } else {
                    setRoleError(responseData?.info || 'Failed to fetch Roles data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setRoleError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setRoleError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setRoleError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setRoleError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setRoleError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Roles data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setRoleError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setRoleError(`Failed to fetch Roles List. Please check your network connection and try again.`);
            }
        } finally {
            setIsRoleLoading(false);
            setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                    field.key === "role_id_name"
                        ? { ...field, loading: false }
                        : field
                )
            );
        }
    }

    const fetchTeamList = async () => {
        try {
            setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                    field.key === "team_name"
                        ? { ...field, loading: true }
                        : field
                )
            );
            setTeamLoading(true);
            setTeamError('');
            teamListRef.current = [];
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {

                            teamListRef.current = await responseData?.dataJ.map(service => ({
                                label: service.team_name,
                                value: service.team_id,
                                team_manager: service.emp_id_team_manager_name,
                                team_leader: service.emp_id_team_lead_name
                            }));
                            setFieldConfigs((prevConfigs) =>
                                prevConfigs.map((field) =>
                                    field.key === "team_name"
                                        ? { ...field, options: teamListRef.current }
                                        : field
                                )
                            );
                        } else {
                            setTeamError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setTeamError(responseData?.info || 'Failed to fetch team data. Please try again.');
                    }
                } else {
                    setTeamError(responseData?.info || 'Failed to fetch team data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setTeamError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setTeamError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setTeamError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setTeamError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setTeamError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch team data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setTeamError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setTeamError(`Failed to fetch team data. Please check your network connection and try again.`);
            }
        } finally {
            setTeamLoading(false);
            setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                    field.key === "team_name"
                        ? { ...field, loading: false }
                        : field
                )
            );
        }
    }

    const handleInputChange = (key, fieldData) => {
        // setPrimaryData((prevState) => ({
        //     ...prevState,
        //     [key]: value,
        // }));
        setPrimaryData((prev) => {
            // If the key is "emp_id_team_manager_name", update label and related ID
            if (key === "team_name") {
                return {
                    ...prev,
                    [key]: fieldData.label, // Set the label in emp_id_team_manager_name
                    team_id: fieldData.value, // Set the value in emp_id_team_manager
                    emp_id_team_lead_name: fieldData.team_leader,
                    emp_id_team_manager_name: fieldData.team_manager

                };
            }
            if (key === "role_id_name") {
                return {
                    ...prev,
                    [key]: fieldData.label, // Set the label in emp_id_team_lead_name
                    role_id: fieldData.value, // Set the value in emp_id_team_lead
                };
            }
            // For other keys, update normally
            return {
                ...prev,
                [key]: fieldData, // Update with the provided value
            };
        });
    };

    const clearFeilds = () => {
        setPrimaryData({
            "emp_id": null,
            "emp_name": "",
            "emp_email": "",
            "emp_mobile_no": "",
            "emp_mobile_code": "+91",
            "role_id": 0,
            "team_id": 0,
            "emp_status": 0,
            "emp_code": "",
            "emp_task_assign_flag": 0,
            "team_name": "",
            "emp_id_team_manager": null,
            "emp_id_team_lead": null,
            "emp_id_team_manager_name": "",
            "emp_id_team_lead_name": "",
            "role_id_name": ""
        });

    }


    const handleAddUser = async () => {

        try {
            setAddError('');

            const updatedFieldConfigs = fieldConfigs.map((field) => ({
                ...field,
                error: "", // Reset errors initially for all fields
            }));

            let hasError = false;

            // Validate each field based on its key
            const validatedFields = updatedFieldConfigs.map((field) => {
                let error = "";
                const value = primaryData?.[field.key]; // Retrieve the value for this field using its key

                // Validation logic based on the key
                switch (field.key) {

                    case "emp_name":
                    case "emp_code":
                        if (!value?.trim()) {
                            error = `${field.label} is required.`;
                            hasError = true;
                        }
                        break;
                    case "emp_email":
                        if (!validateEmail(value)) {
                            error = `${field.label} is required.`;
                            hasError = true;
                        }
                        break;
                    case "emp_mobile_no":
                        if (!value || value.length !== 10 || !/^\d+$/.test(value)) {
                            error = `${field.label} must be a valid 10-digit number.`;
                            hasError = true;
                        }
                        break;

                    case "employee_join_at":
                        if (!value) {
                            error = `${field.label} is required.`;
                            //console.log('value date----if',value)
                            hasError = true;
                        } else {
                            //console.log('value date----else',value)
                        }
                        break;

                    case "role_id_name":
                        if (!value) {
                            error = `${field.label} must be selected.`;
                            hasError = true;
                        }
                        break;

                    default:
                        break;
                }

                return { ...field, error }; // Attach the error message to the field
            });

            // Update fieldConfigs with errors
            setFieldConfigs(validatedFields);

            if (hasError) {
                return; // Stop submission if there are validation errors
            }

            setAddLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "emp_name": primaryData?.emp_name,
                "emp_email": primaryData?.emp_email,
                "emp_mobile_no": primaryData?.emp_mobile_no,
                "emp_mobile_code": primaryData?.emp_mobile_code,
                "role_id": primaryData?.role_id,
                "emp_code": primaryData?.emp_code,
                "team_id": primaryData?.team_id,
                "employee_join_at": primaryData?.employee_join_at
            }

            console.log('catagory Data', payLoad);
            // Make the API call
            const response = await postData(ORG_ADD_EMPLOYEE, payLoad);
            const responseData = await response.json();
            //console.log('response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setSuccess(responseData?.info);
                        clearFeilds();
                        setTimeout(() => {
                            handleBack();
                        }, 3000);
                    } else {
                        setAddError(responseData?.info || 'Failed to add employee. Please try again.');
                    }
                } else {
                    setAddError(responseData?.info || 'Failed to add employee.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setAddError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setAddError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setAddError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setAddError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setAddError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add employee. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setAddError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setAddError(`Failed to add employee. Please check your network connection and try again.`);
            }
        } finally {
            setAddLoading(false);
        }
    };

    const handleCancel = () => {
        handleBack();

    }

    return {
        handleCancel,
        fieldConfigs,
        primaryData,
        handleInputChange,
        handleAddUser,
        addLoading,
        success,
        addError,
    };

}

export default AddUserPrimaryInformationController;