import React, { useState, useEffect, useRef } from "react";
import InvoiceServiceEstimateSeriesController from "../../../../Controllers/AdminScreens/Invoices/Settings/InvoiceServiceEstimateSeriesController";
import editImg from "../../../../../Assets/Icons/edit.png";
import deleteImg from "../../../../../Assets/Icons/delete.png";
import Loader from "../../../../../Components/Loader";

const InvoiceServiceEstimateSeriesScreen = () => {
    const {
        seriesHistory,
        loading,
        error,
        handleEdit,
        handleDelete,
    } = InvoiceServiceEstimateSeriesController();

    return (
        <div>
            {/* Title */}
            <div className="tasksScreen-tasks-header" style={{ paddingBottom: 10 }}>
                <h3 className="tasksScreen-tasks-title">History</h3>
            </div>

            {/* Table */}
            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>
                        <tr>
                            <th style={{ width: "20%" }}>Series Type</th>
                            <th style={{ width: "20%" }}>Start Date</th>
                            <th style={{ width: "20%" }}>End Date</th>
                            <th style={{ width: "30%" }}>Added By</th>
                            <th></th>
                        </tr>
                    </thead>
                    {seriesHistory.length > 0 && (
                        <tbody style={{ backgroundColor: "white" }}>
                            {seriesHistory.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ textDecoration: 'underline' }}>{item.seriesType || "--"}</td>
                                    <td>{item.startDate || "--"}</td>
                                    <td>{item.endDate || "--"}</td>
                                    <td>{item.addedBy || "--"}</td>
                                    <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                        <button className="tasksScreen-edit-button" onClick={() => handleEdit(item)}>
                                            <img src={editImg} alt="Edit" />
                                        </button>
                                        <button className="tasksScreen-delete-button" onClick={() => handleDelete(item)}>
                                            <img src={deleteImg} alt="Delete" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>

                {/* Loader & Error Message */}
                {loading && <Loader loading={loading} color="var(--primary-color)" />}
                {error && <div className="list-error-msg">{error} </div>}
            </div>
        </div>
    );
};

export default InvoiceServiceEstimateSeriesScreen;
