import { useState, useRef, useEffect } from "react";
import { postData } from "../../../../ApiServices/ApiService";
import { ORG_EMP_ACCESS_PERMISSION_UPDATE } from '../../../../ApiServices/BaseURL';

const UserAccessController = (userInfo, onRefresh) => {

    const [isManageAccessModalOpen, setIsManageAccessModalOpen] = useState(false);
    const [selUserAccessService, setSelUserAccessService] = useState(null);
    const [userAccessToggleUpdateError, setUserAccessToggleUpdateError] = useState({});
    const [userAccessToggleUpdateSuccess, setUserAccessToggleUpdateSuccess] = useState({});
    const [userAccessToggleUpdateLoading, setUserAccessToggleUpdateLoading] = useState({});

    const handleEditUserAccess = (userAccess) => {
        //console.log('handleEditUserAccess userAccess @', userAccess)
        setSelUserAccessService(userAccess);
        setIsManageAccessModalOpen(true);
    }

    const handleAccessToggleChange = async (service, status) => {
        console.log('Toggle is now:', service, 'status:', status);
        const updatedService = {
            ...service,
            per_name_sub_list: service?.per_name_sub_list?.map(subService => ({
                ...subService,
                is_active: subService.per_origin?.toUpperCase() === "ROOT" ? 1 : (status ? 1 : 0)
            }))
        };

        console.log("Updated Service Data access:", updatedService);
        await handleUpdateAccessToggle(updatedService);
    }

    const handleCloseManageAccessModal = () => {
        setSelUserAccessService(null);
        setIsManageAccessModalOpen(false);
    }

    const getActiveSubPerNamesOld = (list) => {
        const activeSubPerNames = list
            ?.filter((perNameSub) => perNameSub.is_active === 1)
            ?.map((perNameSub) => perNameSub.per_name_sub);

        // return activeSubPerNames?.length > 0 ? activeSubPerNames.join(", ") : "--";
        return {
            names: activeSubPerNames?.length > 0 ? activeSubPerNames.join(", ") : "--",
            isActive: activeSubPerNames?.length > 0
        }
    };

    const getActiveSubPerNames = (list) => {
        if (!list || list.length === 0) {
            return { names: "--", isActive: false };
        }
        const result = list.reduce(
            (acc, perNameSub) => {
                if (perNameSub.is_active === 1) {
                    acc.names.push(perNameSub.per_name_sub);
                    if (perNameSub.per_origin?.toUpperCase() !== "ROOT") {
                        acc.isActive = true; 
                    }
                }
                return acc;
            },
            { names: [], isActive: false }
        );

        return {
            names: result.names.length > 0 ? result.names.join(", ") : "--",
            isActive: result.isActive,
        };
    };

    const handleUpdateAccessToggle = async (updatedService) => {
        console.log('updatedService :', updatedService);

        try {
            setUserAccessToggleUpdateError((prev) => ({ ...prev, [updatedService?.per_name_code]: '' }));
            setUserAccessToggleUpdateLoading((prev) => ({ ...prev, [updatedService?.per_name_code]: true }));
            setUserAccessToggleUpdateSuccess((prev) => ({ ...prev, [updatedService?.per_name_code]: '' }));

            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                emp_id_auth: storedUserInfo?.emp_id,
                emp_id: userInfo?.emp_id,
                per_name_code: updatedService?.per_name_code,
                per_name_sub_list: updatedService?.per_name_sub_list
            }

            console.log('handleUpdateAccessToggle service payload', payLoad);
            const response = await postData(ORG_EMP_ACCESS_PERMISSION_UPDATE, payLoad);
            const responseData = await response.json();
            //console.log('handleUpdate service Data', responseData);
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        // setToggleUpdatedMessages((prevDeleted) => [...prevDeleted, updatedService?.per_name_code]);
                        setUserAccessToggleUpdateSuccess((prev) => ({ ...prev, [updatedService?.per_name_code]: responseData?.info }));
                        setTimeout(() => {
                            setUserAccessToggleUpdateSuccess((prev) => ({ ...prev, [updatedService?.per_name_code]: '' }));
                            onRefresh();
                        }, 2000);
                    } else {
                        setUserAccessToggleUpdateError((prev) => ({ ...prev, [updatedService?.per_name_code]: responseData?.info || 'Failed to update user access toggle.' }));
                    }
                } else {
                    setUserAccessToggleUpdateError((prev) => ({ ...prev, [updatedService?.per_name_code]: 'Failed to update user access toggle. Please try again.' }));
                }
            } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setUserAccessToggleUpdateError((prev) => ({ ...prev, [updatedService?.per_name_code]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
                } else {
                    setUserAccessToggleUpdateError((prev) => ({ ...prev, [updatedService?.per_name_code]: responseData?.info || 'Bad request. Please check the request parameters.' }));
                }
            } else {
                setUserAccessToggleUpdateError((prev) => ({ ...prev, [updatedService?.per_name_code]: 'Unable to update user access toggle.' }));
            }
        } catch (error) {
            if (error.response) {
                setUserAccessToggleUpdateError((prev) => ({ ...prev, [updatedService?.per_name_code]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update user access toggle. Please check your network connection.'}` }));
            } else if (error.request) {
                setUserAccessToggleUpdateError((prev) => ({ ...prev, [updatedService?.per_name_code]: 'No response received from the server. Please check your network connection and try again.' }));
            } else {
                setUserAccessToggleUpdateError((prev) => ({ ...prev, [updatedService?.per_name_code]: `Failed to update user access toggle. Please check your network connection and try again.` }));
            }
        } finally {
            setUserAccessToggleUpdateLoading((prev) => ({ ...prev, [updatedService?.per_name_code]: false }));
        }
    };

    return {
        handleEditUserAccess,
        handleAccessToggleChange,
        isManageAccessModalOpen,
        handleCloseManageAccessModal,
        selUserAccessService,
        getActiveSubPerNames,
        userAccessToggleUpdateLoading, userAccessToggleUpdateError, userAccessToggleUpdateSuccess
    };

}

export default UserAccessController;