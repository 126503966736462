// export const API_URL = "https://k0s8ewhycd.execute-api.us-east-1.amazonaws.com/dev" // DEV
export const API_URL = 'https://mae405i773.execute-api.us-east-1.amazonaws.com/qa'; //QA

export const IS_DATE_MANUAL = true; // only QA Manual Entry True

export const REFRESH_TOKEN =""
export const SIGNUP_API = "/org/auth/employee-login" // POST METHOD
export const FORGOT_API = "/org/auth/forgot-password" // POST METHOD
export const CHANGE_PASSWORD_API = "/org/auth/forgot-change-password" // POST METHOD
export const ORG_MFA_LOGIN = "/org/auth/verify-mfa-code" // POST METHOD

export const ORG_ADD_SERVICE = '/org/app/services/add-service' // POST METHOD 
export const ORG_GET_SERVICE_LIST = '/org/app/services/get-service-list' // GET METHOD
export const ORG_UPDATE_SERVICE = '/org/app/services/update-service-info' // POST METHOD
export const ORG_DELETE_SERVICE = '/org/app/services/delete-service' // POST METHOD

export const ORG_GET_SERVICE_LIST_SEARCH = '/org/app/services/service-dd' // POST METHOD
export const ORG_GET_SUB_SERVICE_LIST_SEARCH = '/org/app/services-sub/service-sub-dd' // POST METHOD

export const ORG_ADD_SUB_SERVICE = '/org/app/services-sub/add-service-sub' // POST METHOD 
export const ORG_GET_SUB_SERVICE_LIST = '/org/app/services-sub/get-service-sub-list' // GET METHOD
export const ORG_UPDATE_SUB_SERVICE = '/org/app/services-sub/update-service-sub' // POST METHOD
export const ORG_DELETE_SUB_SERVICE = '/org/app/services-sub/delete-service-sub' // POST METHOD

export const ORG_ADD_SUB_SERVICE_CHECK = '/org/app/services-checks/add-service-checks' // POST METHOD 
export const ORG_GET_SUB_SERVICE_CHECK_LIST = '/org/app/services-checks/get-service-checks-list' // GET METHOD 
export const ORG_UPDATE_SUB_SERVICE_CHECK = '/org/app/services-checks/update-service-checks' // POST METHOD
export const ORG_DELETE_SUB_SERVICE_CHECK = '/org/app/services-checks/delete-service-checks' // POST METHOD

export const ORG_ADD_GROUP = '/org/app/group/add-group' // POST METHOD 
export const ORG_GET_GROUP_LIST = '/org/app/group/groups-list' // GET METHOD
export const ORG_GET_GROUP_INFO = '/org/app/group/get-group-info' // GET METHOD
export const ORG_GET_GROUP_MEMBERS = '/org/app/group/get-group-clients-list' //GET METHOD
export const ORG_ADD_CLIENT_TO_GROUP = '/org/app/group/add-client-to-group' //POST METHOD
export const ORG_UPDATE_GROUP_STATUS = '/org/app/group/group-activate-inactivate' //POST METHOD
export const ORG_UPDATE_GROUP = '/org/app/group/edit-group' // POST METHOD
export const ORG_DELETE_GROUP = '/org/app/group/delete-group' // POST METHOD
export const ORG_REMOVE_CLIENT_TO_GROUP = '/org/app/group/group-remove-client' //POST METHOD
export const ORG_SEARCH_GROUP = '/org/app/group/group-search' //POST METHOD

export const ORG_ADD_CLIENT = '/org/app/clientile/clients/add-client' // GET METHOD 
export const ORG_GET_ORG_CODE = '/org/app/utils/org-dd-codes' // POST METHOD 
export const ORG_GET_CLIENT_TYPE_LIST = '/org/app/clientile/client-types/get-type-list' // GET METHOD

export const ORG_GET_CLIENT_LIST = '/org/app/clientile/clients/get-client-list' // GET METHOD
export const ORG_GET_CLIENT_INFO = '/org/app/clientile/clients/get-client-info' // GET METHOD
export const ORG_UPDATE_CLIENT_INFO = '/org/app/clientile/clients/update-client' // POST METHOD
export const ORG_GET_CLIENT_CONTACT_LIST = '/org/app/clientile/client-contacts/get-contact-list' // GET METHOD
export const ORG_ADD_CLIENT_CONTACT = '/org/app/clientile/client-contacts/add-contacts' // POST METHOD
export const ORG_UPDATE_CLIENT_CONTACT = '/org/app/clientile/client-contacts/update-contact' // POST METHOD
export const ORG_DELETE_CLIENT_CONTACT = '/org/app/clientile/client-contacts/delete-contacts' // POST METHOD
export const ORG_CLIENT_LIST_SEARCH = '/org/app/clientile/clients/client-search' //POST

export const ORG_GET_CLIENT_TASK_LIST = '/org/app/tasks/task-list-by-client' //GET METHOD
export const ORG_GET_CLIENT_TASK_INFO = '/org/app/tasks/task-get-info' //GET METHOD
export const ORG_UPDATE_CLIENT_TASK_INFO = '/org/app/tasks/update-task' // POST METHOD
export const ORG_GET_CLIENT_TASK_CHECK_LIST = '/org/app/tasks/task-checks-get-list' //GET METHOD 
export const ORG_UPDATE_CLIENT_TASK_CHECK_STATUS = '/org/app/tasks/task-check-update-status' // POST METHOD
export const ORG_DELETE_CLIENT_TASK = '/org/app/tasks/task-delete' // POST METHOD 
export const ORG_UPDATE_CLIENT_TASK_STATUS = '/org/app/tasks/task-update-status' // POST METHOD
export const ORG_UPDATE_CLIENT_TASK_PRIORITY = '/org/app/tasks/update-priority-status' // POST METHOD 


export const ORG_GET_ALL_CODES_LIST = '/org/app/utils/org-dd-codes' // POST METHOD

export const ORG_ADD_TASK = '/org/app/tasks/add-task' // POST METHOD 
export const ORG_GET_TASK_LIST = '/org/app/tasks/task-list-search' // POST METHOD

export const ORG_ADD_TEAM = '/org/app/emp-teams/add-team' // POST METHOD 
export const ORG_GET_TEAM_LIST = '/org/app/emp-teams/get-team-list' // GET METHOD
export const ORG_GET_TEAM_INFO = '/org/app/emp-teams/get-team-info' // GET METHOD
export const ORG_GET_TEAM_MEMBERS = '/org/app/emp-teams/get-team-emp-list' //GET METHOD 
export const ORG_ADD_EMP_TEAM = '/org/app/emp-teams/add-emp-to-team' //POST METHOD
export const ORG_UPDATE_TEAM_STATUS = '/org/app/emp-teams/team-activate-inactivate' //POST METHOD
export const ORG_UPDATE_EMP_INFO = '/org/app/emp-teams/update-team' //POST METHOD
export const ORG_DELETE_TEAM = '/org/app/emp-teams/delete-team' //POST METHOD
export const ORG_REMOVE_EMP_TEAM = '/org/app/emp-teams/teams-emp-remove' //POST METHOD
export const ORG_SEARCH_TEAM = '/org/app/emp-teams/search-team' //POST METHOD 
export const ORG_GET_TEAM_LEADER_MANAGER_LIST = '/org/app/emp-teams/team-leader-manager-dd'//GET METHOD emp_role=TEAM_LEAD&emp_id_auth=10001&emp_role2=TEAM_MANAGER

export const ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER = '/org/app/clientile/clients/client-search-dd' // POST METHOD
export const ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER = '/org/app/emp/emp-search-dd-full' // POST METHOD

export const ORG_ADD_EMPLOYEE = '/org/app/emp/emp-add' // POST METHOD 
export const ORG_UPDATE_EMPLOYEE = '/org/app/emp/emp-update' // POST METHOD
export const ORG_GET_EMPLOYEE_LIST = '/org/app/emp/emp-list' // GET METHOD
export const ORG_GET_EMPLOYEE_INFO = '/org/app/emp/get-emp-info' // GET METHOD
export const ORG_EMP_SPECIALIZATION_LIST = '/org/app/emp/speciliazation/speciliazation-list' // GET METHOD
export const ORG_EMP_SPECIALIZATION_UPDATE = '/org/app/emp/speciliazation/speciliazation-update' // POST METHOD
export const ORG_EMP_DELETE = '/org/app/emp/emp-delete' //POST  
export const ORG_EMP_TOGGLE_ON_OF = '/org/app/emp/emp-activate-deactivate' //POST
export const ORG_EMP_WORK_STATUS = '/org/app/emp/emp-auto-task-assign' //POST
export const ORG_EMP_ACCESS_PERMISSION_LIST = '/org/app/emp/permissions/permissions-list' // GET METHOD
export const ORG_EMP_ACCESS_PERMISSION_UPDATE = '/org/app/emp/permissions/permission-update' // POST METHOD
export const ORG_EMP_SEARCH = '/org/app/emp/emp-search' //POST METHOD
export const ORG_EMP_ASSIGNMENTS = '/org/app/emp/emp-assignments-list' //GET

export const ORG_GET_ROLES_LIST = '/org/app/roles/roles/roles-list' // GET METHOD

// export const ORG_ADD_KYC_DOCUMENT = '/org/app/clientile/client-kyc-doc/add-kyc-doc' //POST
export const ORG_GET_KYC_DOCUMENTS_LIST = '/org/app/clientile/client-kyc-doc/list-kyc-doc' //GET 
// export const ORG_DOWNLOAD_KYC_DOCUMENTS ='/org/app/clientile/client-kyc-doc/download-kyc-doc' //GET
// export const ORG_UPDATE_KYC_DOCUMENT ='/org/app/clientile/client-kyc-doc/update-kyc-doc' //POST
// export const ORG_DELETE_KYC_DOCUMENT = '/org/app/clientile/client-kyc-doc/delete-kyc-doc' //POST

export const ORG_GET_USER_CREDENTIAL_LIST = '/org/app/clientile/client-accounts/get-account-list' // GET METHOD
export const ORG_ADD_USER_CREDENTIAL = '/org/app/clientile/client-accounts/add-account' // POST METHOD 
export const ORG_DELETE_USER_CREDENTIAL = '/org/app/clientile/client-accounts/delete-account' //POST METHOD
export const ORG_UPDATE_USER_CREDENTIAL = '/org/app/clientile/client-accounts/update-account' // POST METHOD 

export const ORG_ADD_DOCUMENT_RACK = '/org/app/doc-rack/doc-rack-add' // POST METHOD 
export const ORG_GET_DOCUMENTS_LIST_RACK = '/org/app/doc-rack/doc-rack-list' //GET
export const ORG_UPDATE_DOCUMENT_RACK = '/org/app/doc-rack/doc-rack-update' // POST METHOD 
export const ORG_DELETE_DOCUMENT_RACK = '/org/app/doc-rack/doc-rack-delete' // POST METHOD 
export const ORG_DOWNLOAD_DOCUMENT_RACK = '/org/app/doc-rack/doc-rack-download'
export const ORG_DOCUMENT_SEARCH_RACK = '/org/app/doc-rack/doc-rack-search' //POST 

// export const ORG_TASK_ADD_DOCUMENT = '/org/app/tasks/tasks-document-upload' //POST
export const ORG_TASK_DOCUMENT_LIST = '/org/app/tasks/task-documents-list' //GET
// export const ORG_TASK_DOCUMENT_DOWNLOAD = '/org/app/tasks/task-doc-download' //GET

export const ORG_CLIENT_AVAILED_SERVICES = '/org/app/clientile/availed-services/availed-services-list'

export const ORG_NATURE_OF_CALLS_ADD = '/org/app/schedules/schedule-master-add' //pOST
export const ORG_NATURE_OF_CALLS_LIST = '/org/app/schedules/schedules-master-list' //GET
export const ORG_NATURE_OF_CALLS_UPDATE = '/org/app/schedules/schedules-master-update' //POST
export const ORG_NATURE_OF_CALLS_DELETE = '/org/app/schedules/schedule-master-delete' //POST

export const ORG_SCHEDULE_ADD_DASHBOARD = '/org/app/schedules/schedules-add' //POST
export const ORG_SCHEDULE_TYPE_LIST = '/org/app/schedules/schedules-master-dd' //GET
export const ORG_SCHEDULE_PRIORITY_LIST_DASHBOARD = '/org/app/dashboard/schedule-prior-list' //GET
export const ORG_GET_PENDING_TASK_LIST = '/org/app/dashboard/pending-tasks-list' // GET
export const ORG_GET_CALENDER_LIST = '/org/app/schedules/calender-list' // GET
export const ORG_GET_NOTIFICATIONS_LIST_DASHBOARD = '/org/app/add-ons/notice-board/nb-notification-list' //GET
export const ORG_GET_NOTIFICATIONS_LIST_HEADER='/org/app/dashboard/ntfn-icon-list' //GET
export const ORG_CHANGE_NOTIFICATION_READ_STATUS_HEADER='/org/app/ntfn-read-status-check/sts-check' //POST

export const ORG_TODO_SCHEDULE_ADD_DASHBOARD = '/org/app/schedules/schedule-to-do-add' //POST
export const ORG_TODO_SCHEDULE_LIST_DASHBOARD = '/org/app/dashboard/to-do-list' //GET
export const ORG_TODO_SCHEDULE_UPDATE_DASHBOARD = '/org/app/schedules/schedule-to-do-update' //POST
export const ORG_UPCOMING_APPOINTMENT_DASHBOARD = '/org/app/dashboard/schedule-upcoming' //GET
export const ORG_UPDATE_TO_DO_STATUS = '/org/app/schedules/schedules-to-do-update-status' //POST
export const ORG_GET_CLIENT_TO_DO_LIST_DD = '/org/app/tasks/client-task-to-do-list-dd' // GET METHOD
export const ORG_GET_CLIENT_TO_DO_LIST = '/org/app/clientile/clients/task-to-do-list' // GET METHOD
export const ORG_GET_TASKS_OVERVIEW_DASHBOARD = '/org/app/dashboard/task-overview-report' // GET METHOD
export const ORG_DELETE_TO_DO_ITEM = '/org/app/schedules/schedule-to-do-delete' // GET METHOD

export const ORG_UPLOAD_FORM16 = '/org/app/clientile/clients/client-extract-doc' //POST
export const ORG_OVERVIEW_REPORT = '/org/app/service-dashboard/overview-report' //POST
export const ORG_TASK_STATUS = '/org/app/service-dashboard/task-with-status' //POST
export const ORG_DATE_STATUS = '/org/app/service-dashboard/dates-report' //POST
export const ORG_CHECKWISE_STATUS = '/org/app/service-dashboard/checkwise-report' //POST
export const ORG_GET_SCHEDULE_REPORT = '/org/app/service-dashboard/schedule-report' //POST

export const ORG_GET_COMMENTS_LIST = '/org/app/tasks/client-task-comments-list' // GET METHOD

export const ORG_GET_ESTIMATE_LIST = '/org/app/invoices/bill-estimate/bill-estimate-list' // GET METHOD
export const ORG_ESTIMATE_SEARCH = '/org/app/invoices/bill-estimate/bill-estimate-search' // POST METHOD 
export const ORG_GET_ESTIMATE_TASK_LIST = '/org/app/invoices/bill-estimate/get-estimate-tasks' // GET METHOD 
export const ORG_CREATE_ESTIMATE = '/org/app/invoices/bill-estimate/bill-estimate-add' // POST
export const ORG_DOWNLOAD_ESTIMATE_TASK = '/org/app/invoices/bill-estimate/bill-estimate-download' // GET METHOD 
export const ORG_GET_GROUP_ESTIMATE_TASK_LIST = '/org/app/invoices/bill-estimate/bill-estimate-tasks-list' // GET
export const ORG_GET_ESTIMATE_INFO = '/org/app/invoices/bill-estimate/bill-estimates-get-info' // GET METHOD
export const ORG_UPDATE_ESTIMATE = '/org/app/invoices/bill-estimate/bill-estimate-edit' // POST
export const ORG_DELETE_ESTIMATE = '/org/app/invoices/bill-estimate/bill-estimate-delete' // POST 

export const ORG_CREATE_INVOICE = '/org/app/invoices/invoices/bills-invoices-add' // POST 
export const ORG_GET_INVOICE_LIST = '/org/app/invoices/invoices/bill-invoices-list' // GET METHOD
export const ORG_GET_INVOICE_INFO = '/org/app/invoices/invoices/bill-invoice-get-info' // GET METHOD
export const ORG_DOWNLOAD_INVOICE_DOC = '/org/app/invoices/invoices/bill-invoice-download' // GET METHOD  
export const ORG_UPDATE_INVOICE = '/org/app/invoices/invoices/invoice-update' // POST 
export const ORG_INVOICE_FILTER_SEARCH = '/org/app/invoices/invoices/bill-invoices-filter' // POST METHOD 
export const ORG_DELETE_INVOICE = '/org/app/invoices/invoices/bill-invoice-delete' // POST METHOD 

export const ORG_PREP_RECEIPT = '/org/app/invoices/bill-receipts/prep-receipt' // POST METHOD
export const ORG_GEN_RECEIPT = '/org/app/invoices/bill-receipts/gen-receipt' // POST METHOD
export const ORG_PRINT_RECEIPT = '/org/app/invoices/bill-receipts/receipt-download' // GET METHOD

export const ORG_NOTICE_BOARD_MSG_ADD ='/org/app/add-ons/notice-board/notice-board-add' //POST
export const ORG_NOTICE_BOARD_MSG_LIST = "/org/app/add-ons/notice-board/nb-list" //GET
export const ORG_NOTICE_BOARD_MSG_UPDATE = '/org/app/add-ons/notice-board/nb-update' //POST
export const ORG_NOTICE_BOARD_MSG_DELETE = '/org/app/add-ons/notice-board/nb-delete' //POST
export const ORG_NOTICE_BOARD_MSG_FILTER_LIST = '/org/app/add-ons/notice-board/nb-filter' //POST
export const ORG_DSC_ADD = '/org/app/add-ons/dsc-center/dsc-add' //POST
export const ORG_DSC_CERTIFICATES_LIST ='/org/app/add-ons/dsc-center/dsc-list' //GET
export const ORG_DSC_CERTIFICATE_EXPIRED_LIST = '/org/app/add-ons/dsc-center/dsc-list-exp' //GET
export const ORG_DSC_CERTIFICATE_UPDATE = '/org/app/add-ons/dsc-center/dsc-update' //POST
export const ORG_DSC_CERTIFICATE_DELETE = '/org/app/add-ons/dsc-center/dsc-delete' //POST
export const ORG_DSC_CERTIFICATES_FLITER_LIST ='/org/app/add-ons/dsc-center/dsc-filter' //POST


export const ORG_SCHEDULE_UPDATE_DASHBOARD = '/org/app/schedules/schedules-update' //POST