import { useState } from "react";

const InvoiceTaxationController = () => {
  const [cgst, setCgst] = useState("");
  const [sgst, setSgst] = useState("");
  const [igst, setIgst] = useState("");
  const [conveyanceFee, setConveyanceFee] = useState("");

  return {
    cgst,
    setCgst,
    sgst,
    setSgst,
    igst,
    setIgst,
    conveyanceFee,
    setConveyanceFee,
  };
};

export default InvoiceTaxationController;
