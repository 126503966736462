import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    ORG_GET_GROUP_INFO, ORG_GET_GROUP_MEMBERS, ORG_UPDATE_GROUP_STATUS,
    ORG_ADD_GROUP, ORG_UPDATE_GROUP, ORG_DELETE_GROUP, ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_REMOVE_CLIENT_TO_GROUP
} from '../../../../ApiServices/BaseURL';
import { getData, postData } from "../../../../ApiServices/ApiService";


const ManageGroupDetailsController = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const isGroupInfoEdit = useRef(location.state?.isEdit);
    const isGroupAdd = useRef(location.state?.isAddGroup);
    const [groupID, setGroupID] = useState(location.state?.selGroupInfo?.group_id || null);
    const [userToggleState, setUserToggleState] = useState(true);
    const [groupMemberModalOpen, setGroupMemberModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [groupMembers, setGroupMembers] = useState([]);
    const [groupMembersError, setGroupMembersError] = useState('');
    const [groupMembersLoading, setGroupMembersLoading] = useState(false);
    const [groupStatusLoading, setGroupStatusLoading] = useState(false);
    const [groupStatusError, setGroupStatusError] = useState('');
    const [groupStatusSuccess, setGroupStatusSuccess] = useState('');
    const [searchClient, setSearchClient] = useState('');
    const [clientList, setClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientListLoading, setClientListLoading] = useState(false);
    const [updateGroupLoading, setUpdateGroupLoading] = useState(false);
    const [deleteGroupLoading, setDeleteGroupLoading] = useState(false);
    const [updateDeleteGroupErrorMsg, setUpdateDeleteGroupErrorMsg] = useState('');
    const [updateDeleteGroupSuccessMsg, setUpdateDeleteGroupSuccessMsg] = useState('');
    const [groupNameError, setGroupNameError] = useState('');
    const [groupMasterError, setGroupMasterError] = useState('');
    const [isGroupMemberDeleteModalOpen, SetIsGroupMemberDeleteModalOpen] = useState(false);
    const selGroupMemberRef = useRef(null);
    const [groupMemDeleteLoading, setGroupMemDeleteLoading] = useState({});
    const [groupMemDeleteError, setGroupMemDeleteError] = useState({});
    const [groupMemDeleteSuccess, setGroupMemDeleteSuccess] = useState({});

    const [groupName, setGroupName] = useState('');
    const [description, setDescription] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [addLoading, setAddLoading] = useState(false)
    const [addError, setAddError] = useState('');
    const [addSuccessMsg, setAddSuccessMsg] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [groupClientError, setGroupClientError] = useState('');
    const [groupClient, setGroupClient] = useState('');
    const groupClientOptions = ["Active", "Disabled"];
    const [isGroupDeleteModalOpen, setIsGroupDeleteModalOpen] = useState(false);


    //console.log('groupdetails selGroupInfo', location.state?.selGroupInfo);

    const [primaryData, setPrimaryData] = useState({
        group_id: null,
        group_name: "",
        group_clients_count: 0,
        group_status: 0,
        group_desc: "",
        emp_id_manager_name: "",
        emp_id_manager: "",
        client_id_primary: null,
        client_id_primary_email: "",
        client_id_primary_mobile_no: "",
        client_id_primary_name: "",
        client_id_primary_mobile_code: ""
    });

    const [editPrimaryData, setEditPrimaryData] = useState({
        group_id: null,
        group_name: "",
        group_clients_count: 0,
        group_status: 0,
        group_desc: "",
        emp_id_manager_name: "",
        emp_id_manager: "",
        client_id_primary: null,
        client_id_primary_email: "",
        client_id_primary_mobile_no: "",
        client_id_primary_name: "",
        client_id_primary_mobile_code: ""
    });

    const fieldConfigs = [
        { label: "Group Name", key: "group_name", type: "text", error: groupNameError },
        { label: "Group Master", key: "client_id_primary_name", type: "search", error: groupMasterError },
        { label: "Primary Contact", key: "client_id_primary_mobile_no", type: "inputtext" }
    ];

    useEffect(() => {
        if (location.state?.isAddGroup) {
            isGroupAdd.current = location.state?.isAddGroup;
        } else {
            isGroupInfoEdit.current = location.state?.isEdit
            fetchGroupInfo(location.state?.selGroupInfo?.group_id)
            fetchGroupMembers(location.state?.selGroupInfo?.group_id)
        }

    }, [location.state?.isEdit])

    useEffect(() => {
        setGroupID(location.state?.selGroupInfo?.group_id)

    }, [location.state?.selGroupInfo?.group_id])

    const fetchGroupInfo = async (groupIdValue) => {
        try {
            setLoading(true);
            setError('');
            // setTeamInfo({});


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_GROUP_INFO}?group_id=${groupIdValue}&emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setPrimaryData(responseData?.dataJ[0]);
                            setEditPrimaryData(responseData?.dataJ[0]);
                            // selGroupInfo.current = responseData?.dataJ[0]
                        } else {
                            setError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Group Data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setLoading(false);
        }
    }

    const fetchGroupMembers = async (groupIdValue) => {
        //console.log("__Fetch Member call__")
        try {
            setGroupMembersLoading(true);
            setGroupMembersError('');
            setGroupMembers([]);

            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_GROUP_MEMBERS}?emp_id_auth=${storedUserInfo?.emp_id}&group_id=${groupIdValue}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        //console.log("Fetch Member success")
                        if (responseData?.dataJ?.length > 0) {
                            setGroupMembers(responseData?.dataJ);
                            // selGroupInfo.current = responseData?.dataJ[0]
                        } else {
                            setGroupMembersError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setGroupMembersError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setGroupMembersError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGroupMembersError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setGroupMembersError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setGroupMembersError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setGroupMembersError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setGroupMembersError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Group Info. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setGroupMembersError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setGroupMembersError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setGroupMembersLoading(false);
        }
    }

    const handleBack = () => {
        //console.log('handleBack group')
        navigate('/services/manage-group');
    }

    const handleUserToggleChange = async (newState, group_id) => {
        //console.log('newState', newState)
        try {
            setGroupStatusLoading(true);
            setGroupStatusError('');

            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "group_status": newState === true ? 1 : 0,
                "group_id": group_id
            }
            //console.log('group updatestatus payload:---', payLoad);
            const response = await postData(ORG_UPDATE_GROUP_STATUS, payLoad);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        //console.log("Group Status success")
                        setGroupStatusSuccess(responseData?.info);
                        setTimeout(() => {
                            setGroupStatusSuccess('');
                        }, 3000);
                    } else {
                        setGroupStatusError(responseData?.info || 'Failed to update group status. Please try again.');
                    }
                } else {
                    setGroupStatusError(responseData?.info || 'Failed to update group status.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGroupStatusError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setGroupStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setGroupStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setGroupStatusError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setGroupStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update group status. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setGroupStatusError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setGroupStatusError(`Failed to update group status. Please check your network connection and try again.`);
            }
        } finally {
            setGroupStatusLoading(false);
        }
    }

    const handleEditGroupMember = (groupMember) => {
        //console.log('handleEditGroupMember @@', groupMember)
        navigate('/manage-clients', { state: { task: 'PrimaryInformation', clientId: groupMember?.client_id } })
    }

    const handleDeleteGroupMember = (groupMember) => {
        //console.log('handleDeleteGroupMember @@', groupMember)
        selGroupMemberRef.current = groupMember;
        SetIsGroupMemberDeleteModalOpen(true);
    }

    const handleUpdateGroup = async () => {
        try {
            //console.log('handleUpdateGroup selectedClient : ', selectedClient?.client_id)
            setUpdateDeleteGroupErrorMsg('');
            setGroupNameError('');
            setUpdateDeleteGroupSuccessMsg('');
            if (!editPrimaryData?.group_name.trim()) {
                setGroupNameError('Group Name is required.'); return
            }
          
            if (!editPrimaryData?.client_id_primary) {
                setGroupMasterError('Select Group Master after search.'); return
            }
            setUpdateGroupLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "group_id": primaryData?.group_id,
                "group_name": editPrimaryData?.group_name,
                "client_id_primary": editPrimaryData?.client_id_primary,
                "group_desc": editPrimaryData?.group_desc
            }

            //console.log('handleUpdateGroup payload', payLoad);
            // Make the API call
            const response = await postData(ORG_UPDATE_GROUP, payLoad);
            const responseData = await response.json();
            //console.log('handleUpdateGroup Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setUpdateDeleteGroupSuccessMsg(responseData?.info);
                        setTimeout(() => {
                            setUpdateDeleteGroupSuccessMsg('')
                            // isGroupInfoEdit.current = false
                            fetchGroupInfo(primaryData?.group_id);
                        }, 3000);
                    } else {
                        setUpdateDeleteGroupErrorMsg(responseData?.info || 'Failed to update group. Please try again.');
                    }
                } else {
                    setUpdateDeleteGroupErrorMsg(responseData?.info || 'Failed to update group.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setUpdateDeleteGroupErrorMsg('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setUpdateDeleteGroupErrorMsg(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setUpdateDeleteGroupErrorMsg(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setUpdateDeleteGroupErrorMsg('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setUpdateDeleteGroupErrorMsg(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update contact. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setUpdateDeleteGroupErrorMsg('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setUpdateDeleteGroupErrorMsg(`Failed to update group. Please check your network connection and try again.`);
            }
        } finally {
            setUpdateGroupLoading(false);
        }
    }

    const handleDeleteGroup = () => {
        // //console.log('handleDeleteGroup @@')
        // const confirmed = window.confirm("Are you sure you want to delete?");
        // if (confirmed) {
        //     callDeleteGroup();
        // } else {
        //     //console.log("Delete canceled");
        // }
        setIsGroupDeleteModalOpen(true);
    }

    const handleCloseDeleteGroup = () => {
        setIsGroupDeleteModalOpen(false);
    }

    const handleCancelGroup = () => {
        //console.log('console:');
        if (isGroupAdd.current) {
            resetForm();
        } else {
            isGroupInfoEdit.current = false;
            fetchGroupInfo(groupID)
        }
    }

    const handleAddGroupMembers = () => {
        //console.log('handleAddGroupMembers')
        setGroupMemberModalOpen(true);
    }

    const handleCloseGroupMemberModal = () => {
        setGroupMemberModalOpen(false);
    }

    const onValueChangeGroupMember = () => {
        //console.log('onValueChangeGroupMember');
        fetchGroupMembers(groupID);
    }

    const handleInputChange = (key, value) => {
        // setPrimaryData((prevState) => ({
        //     ...prevState,
        //     [key]: value,
        // }));

        setEditPrimaryData((prev) => {
            
            if (key === "client_id_primary_name") {
              return {
                ...prev,
                [key]: value, 
                client_id_primary:null,
                client_id_primary_mobile_no: "",
                client_id_primary_mobile_code: ""
              };
            }
            // For other keys, update normally
            return {
              ...prev,
              [key]: value, // Update with the provided value
            };
          });
        setClientList([]);
    };

    const handleSearchClient = async (searchWord) => {
        try {
            setGroupMasterError('');
            //console.log('handleSearchClient search text', searchWord)
            setError('');
            setClientListLoading(true);
            setClientList([]);
            if (!searchWord) {
                setError('Please enter valid text'); return;
            }
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchWord }

            //console.log('handleSearchClient payload', payLoad);
            // Make the API call
            const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
            const responseData = await response.json();
            //console.log('response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setClientList(responseData?.dataJ || []);
                    } else {
                        setError(responseData?.info || 'Failed to fetch Client. Please try again.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch Client.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Client. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setClientListLoading(false);
        }
    }

    const handleSelectClient = (client) => {
        //console.log('handleSelectClient client ', client)
        setSelectedClient(client); // Set selected client data
        setGroupMasterError('');
        handleInputChange('client_id_primary_name', client?.client_name);
        handleInputChange('client_id_primary', client?.client_id);
        handleInputChange('client_id_primary_mobile_no', client?.client_mobile_no);
        handleInputChange('client_id_primary_mobile_code', client?.client_mobile_code);
        setSearchClient(client?.client_name); // Update TextInput with the selected client's name
        const mobileInfo = client?.client_mobile_code + ' ' + client?.client_mobile_no
        setMobileNo(mobileInfo)
        setClientList([]); // Clear the dropdown     
    };

    const showConfirmation = (groupMember) => {
        const confirmed = window.confirm("Are you sure you want to delete?");
        if (confirmed) {
            callDeleteGroupMember(groupMember);
        } else {
            //console.log("Delete canceled");
        }
    }

    const callDeleteGroup = async () => {
        try {
            //console.log('callDeleteGroup group_id',groupID)
            setUpdateDeleteGroupErrorMsg('');
            setDeleteGroupLoading(true);
            setUpdateDeleteGroupSuccessMsg('');
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                emp_id_auth: storedUserInfo?.emp_id,
                group_id: groupID || location.state?.selGroupInfo?.group_id
            }

            //console.log('callDeleteGroup payload', payLoad);
            // Make the API call
            const response = await postData(ORG_DELETE_GROUP, payLoad);
            const responseData = await response.json();
            //console.log('callDeleteGroup response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setUpdateDeleteGroupSuccessMsg(responseData?.info);
                        setTimeout(() => {
                            handleBack();
                        }, 2000);
                    } else {
                        setUpdateDeleteGroupErrorMsg(responseData?.info || 'Failed to delete group. Please try again.');
                    }
                } else {
                    setUpdateDeleteGroupErrorMsg(responseData?.info || 'Failed to delete group.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setUpdateDeleteGroupErrorMsg('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setUpdateDeleteGroupErrorMsg(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setUpdateDeleteGroupErrorMsg(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setUpdateDeleteGroupErrorMsg('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setUpdateDeleteGroupErrorMsg(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete group. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setUpdateDeleteGroupErrorMsg('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setUpdateDeleteGroupErrorMsg(`Failed to delete group. Please check your network connection and try again.`);
            }
        } finally {
            setDeleteGroupLoading(false);
        }
    }

    const callDeleteGroupMember = async (groupMember) => {
        //console.log('callDeleteGroupMember groupMember @@@', groupMember)
        try {
            setGroupMemDeleteSuccess((prev) => ({ ...prev, [groupMember?.client_id]: '' }));
            setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: '' }));
            setGroupMemDeleteLoading((prev) => ({ ...prev, [groupMember?.client_id]: true }));
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "client_id": groupMember?.client_id,
                "group_id": groupID || location.state?.selGroupInfo?.group_id
            }

            //console.log('callDeleteGroupMember payload', payLoad);

            // Make the API call
            const response = await postData(ORG_REMOVE_CLIENT_TO_GROUP, payLoad);
            const responseData = await response.json();
            //console.log('callDeleteGroupMember response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setGroupMemDeleteSuccess((prev) => ({ ...prev, [groupMember?.client_id]: responseData?.info }));
                        setTimeout(() => {
                            onValueChangeGroupMember();
                            setGroupMemDeleteSuccess((prev) => ({ ...prev, [groupMember?.client_id]: '' }));
                        }, 3000);
                    } else {
                        setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: responseData?.info || 'Failed to delete group member. Please try again.' }));
                    }
                } else {
                    setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: responseData?.info || 'Failed to delete group member. Please try again...' }));
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
                } else {
                    setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
                }
            }
            else if (response?.status === 500) {
                setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
            }
            else {
                setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: 'Unexpected error occurred. Please try again later.' }));
            }
        } catch (error) {
            if (error.response) {
                setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete service. Please try again.'}` }));
            } else if (error.request) {
                setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: 'No response received from the server. Please check your network connection and try again.' }));
            } else {
                setGroupMemDeleteError((prev) => ({ ...prev, [groupMember?.client_id]: `Failed to delete group member. Please check your network connection and try again.` }));
            }
        } finally {
            setGroupMemDeleteLoading((prev) => ({ ...prev, [groupMember?.client_id]: false }));
        }
    }

    const handleCloseGroupMemberDeleteModal = () => {
        SetIsGroupMemberDeleteModalOpen(false);
    }

    const clearFeilds = () => {
        setGroupName('');
        setDescription('');
        setSelectedClient(null);
        setSearchClient('');
    }

    const handleAddGroup = async () => {

        try {
            setAddError('');
            setGroupNameError('');
            setDescriptionError('');
            setGroupClientError('');
            setGroupMasterError('')
            if (!groupName) {
                setGroupNameError("Please enter group name");
                return;
            }
            if (!selectedClient?.client_id) {
                setGroupMasterError('Group Master is required.');
                return;
            }
            setAddLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                // "emp_id_manager": storedUserInfo?.emp_id,
                "group_name": groupName,
                "group_status": 1,
                "group_desc": description,
                "client_id_primary": selectedClient?.client_id
            }

            //console.log('catagory Data', payLoad);
            // Make the API call
            const response = await postData(ORG_ADD_GROUP, payLoad);
            const responseData = await response.json();
            //console.log('response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setAddSuccessMsg(responseData?.info);
                        clearFeilds();
                        setTimeout(() => {
                            handleClose(responseData?.dataJ[0]?.group_id);
                        }, 3000);
                    } else {
                        setAddError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setAddError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setAddError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setAddError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setAddError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setAddError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setAddError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setAddError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setAddError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setAddLoading(false);
        }

        // onValueChange(clientData); // Send data to Dashboard
        // onClose(); // Close modal
    };

    const handleClose = (groupId) => {
        setAddError('');
        setAddSuccessMsg('');
        resetForm();
        isGroupAdd.current = null
        isGroupInfoEdit.current = false
        fetchGroupInfo(groupId);
        fetchGroupMembers(groupId);
        setGroupID(groupId);
    }

    // Reset form fields to initial values
    const resetForm = () => {
        setGroupName('');
        setDescription('');
        setGroupClient('');
        setGroupNameError('');
        setDescriptionError('');
        setGroupClientError('');
        setSearchClient('');
        setMobileNo('');
    };

    const handleSearchInputText = (value) => {
        // console.log('handleSearchInputText value : ',value);
        setSearchClient(value);
        setGroupMasterError('');
        setSelectedClient(null);
        setMobileNo('');
        setClientList([]);
    }

    return {
        handleBack,
        userToggleState,
        handleUserToggleChange,
        isGroupInfoEdit,
        loading,
        error,
        primaryData,
        editPrimaryData,
        fieldConfigs,
        handleEditGroupMember,
        handleDeleteGroupMember,
        handleUpdateGroup,
        handleDeleteGroup,
        handleCancelGroup,
        handleAddGroupMembers,
        groupMemberModalOpen,
        handleCloseGroupMemberModal,
        onValueChangeGroupMember,
        handleInputChange,
        groupMembers,
        groupMembersError,
        groupMembersLoading,
        groupStatusLoading,
        groupStatusError,
        searchClient,
        setSearchClient,
        handleSearchClient,
        clientList,
        selectedClient,
        handleSelectClient,
        clientListLoading,
        updateGroupLoading,
        deleteGroupLoading,
        isGroupMemberDeleteModalOpen,
        selGroupMemberRef, groupMemDeleteLoading,
        groupMemDeleteSuccess, groupMemDeleteError,
        callDeleteGroupMember,
        handleCloseGroupMemberDeleteModal,
        updateDeleteGroupErrorMsg,
        updateDeleteGroupSuccessMsg,
        isGroupAdd,
        groupName, setGroupName,
        description, setDescription,
        addLoading,
        addError,
        addSuccessMsg,
        descriptionError,
        groupClientError,
        groupNameError,
        groupClient, setGroupClient,
        groupClientOptions,
        handleAddGroup,
        mobileNo,
        isGroupDeleteModalOpen,
        handleCloseDeleteGroup,
        callDeleteGroup, groupMasterError,
        groupStatusSuccess,
        handleSearchInputText
    };

}

export default ManageGroupDetailsController;