import { useState, useRef, useEffect } from 'react';
import { getData, postData } from "../../../../../ApiServices/ApiService";
import { ORG_DSC_CERTIFICATES_LIST,ORG_DSC_CERTIFICATE_EXPIRED_LIST } from '../../../../../ApiServices/BaseURL';

const DSCCenterController = () => {
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    useEffect(() => {
        // Fetch or load the data from an API or local file
        const data = [
            {
                clientName: 'Client Name',
                panNo: 'DNDJS1234A',
                physicalToken: '1287283712883',
                organizatioName: 'Company Name',
                status: 'Downloaded',
                validFrom: '11/11/2024',
                validTo: '11/11/2024',
                addedBy: 'John Doe',
                addedOn: '11/11/2024',
            },
            {
                clientName: 'Client Name',
                panNo: 'DNDJS1234A',
                physicalToken: '1287283712883',
                organizatioName: 'Company Name',
                status: 'Downloaded',
                validFrom: '11/11/2024',
                validTo: '11/11/2024',
                addedBy: 'John Doe',
                addedOn: '11/11/2024',
            },
            {
                clientName: 'Client Name',
                panNo: 'DNDJS1234A',
                physicalToken: '1287283712883',
                organizatioName: 'Company Name',
                status: 'Downloaded',
                validFrom: '11/11/2024',
                validTo: '11/11/2024',
                addedBy: 'John Doe',
                addedOn: '11/11/2024',
            },
            {
                clientName: 'Client Name',
                panNo: 'DNDJS1234A',
                physicalToken: '1287283712883',
                organizatioName: 'Company Name',
                status: 'Downloaded',
                validFrom: '11/11/2024',
                validTo: '11/11/2024',
                addedBy: 'John Doe',
                addedOn: '11/11/2024',
            },
            {
                clientName: 'Client Name',
                panNo: 'DNDJS1234A',
                physicalToken: '1287283712883',
                organizatioName: 'Company Name',
                status: 'Downloaded',
                validFrom: '11/11/2024',
                validTo: '11/11/2024',
                addedBy: 'John Doe',
                addedOn: '11/11/2024',
            },
            {
                clientName: 'Client Name',
                panNo: 'DNDJS1234A',
                physicalToken: '1287283712883',
                organizatioName: 'Company Name',
                status: 'Downloaded',
                validFrom: '11/11/2024',
                validTo: '11/11/2024',
                addedBy: 'John Doe',
                addedOn: '11/11/2024',
            },
            {
                clientName: 'Client Name',
                panNo: 'DNDJS1234A',
                physicalToken: '1287283712883',
                organizatioName: 'Company Name',
                status: 'Downloaded',
                validFrom: '11/11/2024',
                validTo: '11/11/2024',
                addedBy: 'John Doe',
                addedOn: '11/11/2024',
            },
            {
                clientName: 'Client Name',
                panNo: 'DNDJS1234A',
                physicalToken: '1287283712883',
                organizatioName: 'Company Name',
                status: 'Downloaded',
                validFrom: '11/11/2024',
                validTo: '11/11/2024',
                addedBy: 'John Doe',
                addedOn: '11/11/2024',
            },
            // Add more sample data as needed
        ];
        setTasks(data);
        setFilteredTasks(data); // Initially, all tasks are displayed
    }, []);

    // Handle filtering tasks
    const filterTasks = (filters) => {
        const { panNo, message } = filters;
        const filtered = tasks.filter((task) => {
            const matchesSource = panNo ? task.panNo === panNo : true;
            const matchesDeliveryType = message ? task.message === message : true;
            return matchesSource && matchesDeliveryType;
        });
        setFilteredTasks(filtered);
        setCurrentPage(1); // Reset to the first page after filtering
    };

    // Handle pagination
    const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    useEffect(() => {
        fetchDSCActiveCertificatesList();
        fetchDSCExpiredCertificatesList();
    }, [])

    const [activeCertificatesLoading, setActiveCertificatesLoading] = useState(false);
    const [activeCertificatesError, setActiveCertificatesError] = useState('');
    const [activeCertificatesList, setActiveCertificatesList] = useState([]);
    const fetchDSCActiveCertificatesList = async () => {

        try {
            setActiveCertificatesLoading(true);
            setActiveCertificatesError('')
            setActiveCertificatesList([])
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_DSC_CERTIFICATES_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&dsc_type=${'active'}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setActiveCertificatesList(responseData?.dataJ);
                        } else {
                            setActiveCertificatesError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setActiveCertificatesError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                    }
                } else {
                    setActiveCertificatesError(responseData?.info || 'Failed to fetch Team Information.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setActiveCertificatesError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setActiveCertificatesError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setActiveCertificatesError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setActiveCertificatesError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setActiveCertificatesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setActiveCertificatesError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setActiveCertificatesError(`Failed to fetch Team Information. Please check your network connection and try again.`);
            }
        } finally {
            setActiveCertificatesLoading(false);
        }
    }

    const [expiredCertificatesLoading, setExpiredCertificatesLoading] = useState(false);
    const [expiredCertificatesError, setExpiredCertificatesError] = useState('');
    const [expiredCertificatesList, setExpiredCertificatesList] = useState([]);
    const fetchDSCExpiredCertificatesList = async () => {

        try {
            setExpiredCertificatesLoading(true);
            setExpiredCertificatesError('')
            setExpiredCertificatesList([])
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_DSC_CERTIFICATE_EXPIRED_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&dsc_type=${'expired'}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setExpiredCertificatesList(responseData?.dataJ);
                        } else {
                            setExpiredCertificatesError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setExpiredCertificatesError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                    }
                } else {
                    setExpiredCertificatesError(responseData?.info || 'Failed to fetch Team Information.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setExpiredCertificatesError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setExpiredCertificatesError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setExpiredCertificatesError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setExpiredCertificatesError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setExpiredCertificatesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setExpiredCertificatesError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setExpiredCertificatesError(`Failed to fetch Team Information. Please check your network connection and try again.`);
            }
        } finally {
            setExpiredCertificatesLoading(false);
        }
    }

    return {
        tasks: currentTasks, // Only return tasks for the current page
        currentPage,
        totalPages,
        handlePageChange,
        filterTasks, // Expose the filter function
        activeCertificatesList,
        activeCertificatesError,
        activeCertificatesLoading,
        fetchDSCActiveCertificatesList,
        setActiveCertificatesList,
        setActiveCertificatesError,
        setActiveCertificatesLoading,

        expiredCertificatesList,
        expiredCertificatesError,
        expiredCertificatesLoading,
        fetchDSCExpiredCertificatesList,
        setExpiredCertificatesList,
        setExpiredCertificatesError,
        setExpiredCertificatesLoading
    };
};

export default DSCCenterController;
