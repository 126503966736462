
import React, { useState, useRef, useEffect } from 'react';
import { getData, postData } from '../../../../ApiServices/ApiService';
import { ORG_GET_SERVICE_LIST_SEARCH, ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_GET_EMPLOYEE_LIST } from '../../../../ApiServices/BaseURL';
const InvoiceReceiptController = () => { 

  const [filterError, setFilterError] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [filterListLoading, setFilterListLoading] = useState(false);
  const [filterListError, setFilterListError] = useState('');
  const [clientList, setClientList] = useState([]);
  const [searchUser, setSearchUser] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [serviceOptions, setServiceOptions] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(false);
  const [servicesError, setServicesError] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  const [userError, setUserError] = useState('');
  const [userOptions, setUserOptions] = useState([]);

  const invoiceReceipt = [
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "10,000.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "500.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "7,000.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "9,000.00"
    },
    {
      receiptNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "22,000.00"
    }
  ];

  useEffect(() => {
    fetchServices();
    fetchUserList();
  }, [])

  const ItemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(invoiceReceipt.length / ItemsPerPage);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * ItemsPerPage;
  const indexOfFirstItem = indexOfLastItem - ItemsPerPage;

  // Slice the data for the current page
  const currentTasks = invoiceReceipt.slice(indexOfFirstItem, indexOfLastItem);

  const [filterData, setFilterData] = useState({
    receiptNO: "",
    clientName: "",
    taskType: "",
    generatedDate: "",
  });

  const handleFilterChange = (field, value) => {
    setFilterData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const toggleFilters = () => {
    setShowFilters((prev) => !prev); // Toggle the filter visibility
  };

  const applyFilters = () => {
    fetchReceiptFilterList();
  }

  const resetFilters = () => {
  }

  const cancelFilters = () => {
    resetFilters();
    setShowFilters(!showFilters);
  }

  const handleSearchInputText = (value) => {
    setSearchUser(value);
    setSearchError('');
    setClientList([]);
    if (!value) {
      setFilterData((prevData) => ({
        ...prevData,
        clientName: null,
      }));
    }
  };

  const handleSelectClient = (client) => {
    handleFilterChange('clientName', client);
    setSearchUser(client?.client_name);
    setClientList([]);
  };

  const handleSearchClient = async () => {
    try {
      setSearchError('');
      setSearchLoading(true);
      setClientList([]);
      if (!searchUser) {
        setSearchError('Please enter valid text'); return;
      }
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchUser }

      //console.log('team Data', payLoad);
      // Make the API call
      const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setClientList(responseData?.dataJ || []);
          } else {
            setSearchError(responseData?.info || 'Failed to fetch client. Please try again.');
          }
        } else {
          setSearchError(responseData?.info || 'Failed to fetch client. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setSearchError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setSearchError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setSearchError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setSearchError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setSearchError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch client. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setSearchError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setSearchError(`Failed to fetch client. Please check your network connection and try again.`);
      }
    } finally {
      setSearchLoading(false);
    }
  };

  const fetchReceiptFilterList = async () => {

  }

  const fetchServices = async () => {
    setServicesLoading(true);
    setServiceOptions([]);
    setServicesError('');
    try {
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_')); // Get user info from localStorage
      const payload = {
        emp_id_auth: storedUserInfo?.emp_id,
      };

      // API call to fetch GST services
      const response = await postData(ORG_GET_SERVICE_LIST_SEARCH, payload);
      const responseData = await response.json();

      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message?.toLowerCase() === "success") {
            const services = responseData?.dataJ.map((service) => ({
              label: service.service_name,
              value: service.service_id,
            }));
            setServiceOptions(services);
          } else {
            setServicesError(responseData?.info || 'Service search failed.');
          }
        } else {
          setServicesError(responseData?.info || 'Unexpected response code from the server.');
        }
      }
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setServicesError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setServicesError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setServicesError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setServicesError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setServicesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to get service options. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setServicesError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setServicesError(`Failed to search service options. Please check your network connection and try again.`);
      }
    } finally {
      setServicesLoading(false);
    }
  };
  const fetchUserList = async () => {
    try {
      setUserLoading(true);
      setUserError('');

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const formattedTeams = responseData.dataJ.map(service => ({
                label: service.emp_name,
                value: service.emp_id
              }));
              setUserOptions(formattedTeams);
            } else {
              setUserError(responseData?.info || 'Data Not Found');
            }
          } else {
            setUserError(responseData?.info || 'Failed to fetch User data. Please try again.');
          }
        } else {
          setUserError(responseData?.info || 'Failed to fetch User data. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setUserError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setUserError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setUserError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setUserError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setUserError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setUserError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setUserError(`Failed to fetch User data. Please check your network connection and try again.`);
      }
    } finally {
      setUserLoading(false);
    }
  }

  return {
    invoiceReceipt,
    handlePageChange,
    currentTasks,
    totalPages,
    currentPage,
    filterData,
    setFilterData,
    handleFilterChange,
    applyFilters, cancelFilters, resetFilters, toggleFilters,
    filterError, showFilters, filterListLoading,
    searchUser, searchLoading, searchError, clientList,
    serviceOptions, servicesLoading, servicesError,
    userOptions, userLoading, userError,
    handleSearchInputText, handleSearchClient, handleSelectClient,

  }
}

export default InvoiceReceiptController