
import React,{useState,useEffect} from "react";
import { getData, postData } from "../../../../../ApiServices/ApiService";
import {ORG_NOTICE_BOARD_MSG_DELETE,ORG_GET_TEAM_LIST,ORG_GET_EMPLOYEE_LIST,ORG_NOTICE_BOARD_MSG_FILTER_LIST } from '../../../../../ApiServices/BaseURL';

const NoticeBoardActiveMsgListController = (noticeBoardMessagesList, noticeBoardMessagesError, noticeBoardMessagesLoading, onRefresh,noticeBoardActiveMessagesError,setNoticeBoardMessagesLoading,setNoticeBoardActiveMessagesError,setNoticeBoardMessagesList,showFilter)=>{
    const [currentPage, setCurrentPage] = useState(1);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
      const itemsPerPage = 10;
      const[createdBy,setCreatedBy] = useState('')
      const[ publishedData, setPublishedDate] = useState('')
      const[ expiredData, setExpiredDate] = useState('')

    const totalPages = Math.ceil(noticeBoardMessagesList?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNoticeMsg = noticeBoardMessagesList?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleDelete = (noticeMsg, index) => {
    console.log("Notice msg",noticeMsg )
    setSelectedDocument({ noticeMsg, index });
    setIsDeleteModalOpen(true);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true); // Open the modal
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
  };

  const [loading,setLoading] = useState(false);
  const [noticeBoardMsgDeleteError, setNoticeBoardMsgDeleteError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentNoticeDeleteIndex, setCurrentNoticeDeleteIndex] = useState(null);
  const [deletedMessages, setDeletedMessages] = useState([]);

  const handleNoticeBoardMessageDelete = async () => {
   
    try {
      const { noticeMsg, index } = selectedDocument;
      setCurrentNoticeDeleteIndex(null)
        setNoticeBoardMsgDeleteError('');  
        setSuccess('')      
        setLoading(true);
        setCurrentNoticeDeleteIndex(index)
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, nb_id:noticeMsg.nb_id}
    
        //console.log('handleSearchEmployee payload',payLoad);
        // Make the API call
        const response = await postData(ORG_NOTICE_BOARD_MSG_DELETE,payLoad);
        const responseData = await response.json();
        //console.log('response Data',responseData);
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              setNoticeBoardMsgDeleteError('');
              setDeletedMessages((prevDeleted) => [...prevDeleted, noticeMsg.nb_id]);
                setSuccess(responseData?.info);
                setTimeout(()=>{
                  setSuccess('');
                  onRefresh();
                },3000);
                // setEmployeeList(responseData?.dataJ || []);
            } else {
              setNoticeBoardMsgDeleteError(responseData?.info || 'Failed to delete notice board message. Please try again.');
            }
          } else {
            setNoticeBoardMsgDeleteError(responseData?.info || 'Failed to delete notice board message. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setNoticeBoardMsgDeleteError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setNoticeBoardMsgDeleteError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setNoticeBoardMsgDeleteError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setNoticeBoardMsgDeleteError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setNoticeBoardMsgDeleteError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete notice board message. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setNoticeBoardMsgDeleteError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setNoticeBoardMsgDeleteError(`Failed to delete notice board message. Please check your network connection and try again.`);
        }
      } finally {
        setLoading(false);
        // setCurrentNoticeDeleteIndex(null)
      }

    // onValueChange(teamData);
    // onClose();
};

const maxVisiblePages = 3;

    const getVisiblePages = () => {
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
    };

    const visiblePages = getVisiblePages();

const [teamLoading, setTeamLoading] = useState(false)
const [error, setError] = useState('');
const [recipientOptions, setRecipientOptions] = useState([]);
const [noticeBoardRecipientError, setNoticeBoardRecipientError] = useState('')
const recipientTypeOptions = [
  { label: "Everyone", value: 1 },
  { label: "Teamwise", value: 2 },
  { label: "Userwise", value: 3 },
  { label: "Others", value: 4 },
];
const [recipientType, setRecipientType] = useState("");
      const fetchTeamList = async () => {
        try {
          setTeamLoading(true);
          setError('');
          setRecipientOptions([])
          setRecipients([])
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
          // Make the API call
          const response = await getData(`${ORG_GET_TEAM_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
          const responseData = await response.json();
    
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                if (responseData?.dataJ?.length > 0) {
    
                  const formattedTeams = responseData.dataJ.map(service => ({
                    label: service.team_name,
                    value: service.team_id
                  }));

                  setRecipientOptions(formattedTeams);
                  console.log("recipientOptions", recipientOptions)
                } else {
                  setError(responseData?.info || 'Data Not Found');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch team data. Please try again.');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch team data.');
            }
          }
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
              setError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
              setError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          }
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setError(responseData?.info || 'Internal Server Error. Please try again later.');
          }
          // Unexpected or network-related errors
          else {
            setError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch team data. Please try again.'}`);
          } else if (error.request) {
            // Request was made but no response was received
            setError('No response received from the server. Please check your network connection and try again.');
          } else {
            // An error occurred in setting up the request
            setError(`Failed to fetch team data. Please check your network connection and try again.`);
          }
        } finally {
          setTeamLoading(false);
        }
      }

    const [userLoading, setUserLoading] = useState(false);
    const [userError, setUserError] = useState('');  
    const [recipients, setRecipients] = useState([]);
      const fetchUserList =async ()=>{
              try {
                setUserLoading(true);
                setUserError('');
                setRecipientOptions([])
                setRecipients([]);
                  const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
              
                  // Make the API call
                  const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
                  const responseData = await response.json();
              
                  // Check for success (200) and internal status code
                  if (response?.status === 200) {
                    if (responseData?.statusCode === 200) {
                      if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                          const formattedTeams = responseData.dataJ.map(service => ({
                            label: service.emp_name,
                            value: service.emp_id
                          }));
                          setRecipientOptions(formattedTeams);
                        } else {
                          setUserError(responseData?.info || 'Data Not Found');
                        }
                      } else {
                        setUserError(responseData?.info || 'Failed to fetch User data. Please try again.');
                      }
                    } else {
                      setUserError(responseData?.info || 'Failed to fetch User data.');
                    }
                  } 
                  // Token-related errors: 400, 401, 403
                  else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                    if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                      setUserError('Unauthorized access. Your session may have expired. Please log in again.');
                      // Optionally, redirect to the login page or refresh the token
                      // Example: redirectToLogin();
                    } else {
                      setUserError(responseData?.info || 'Bad request. Please check the request parameters.');
                    }
                  } 
                  // Internal Server Error: 500
                  else if (response?.status === 500) {
                    setUserError(responseData?.info || 'Internal Server Error. Please try again later.');
                  } 
                  // Unexpected or network-related errors
                  else {
                    setUserError('Unexpected error occurred. Please try again later.');
                  }
                } catch (error) {
                  // Handle various error scenarios
                  if (error.response) {
                    // Server responded with a status outside the 2xx range
                    setUserError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
                  } else if (error.request) {
                    // Request was made but no response was received
                    setUserError('No response received from the server. Please check your network connection and try again.');
                  } else {
                    // An error occurred in setting up the request
                    setUserError(`Failed to fetch User data. Please check your network connection and try again.`);
                  }
                } finally {
                  setUserLoading(false);
                }
            }

            useEffect(()=>{
              fetchEmployeeList();
                  },[])
            const[employeeList,setEmployeeList] = useState([]);
            const[employeeListError, setEmployeeListError] = useState('');
            const[employeeListLoading,setEmployeeListLoading] = useState(false)
      
            const fetchEmployeeList = async () => {
                    try {
                      setEmployeeListLoading(true);
                      setEmployeeListError('');
                      setEmployeeList([]);
                        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            
                        // Make the API call
                        const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
                        const responseData = await response.json();
            
                        // Check for success (200) and internal status code
                        if (response?.status === 200) {
                            if (responseData?.statusCode === 200) {
                                if (responseData?.message.toLowerCase() === 'success') {
                                    if (responseData?.dataJ?.length > 0) {
                                        const filterData = responseData?.dataJ.map(service => ({
                                            label: service?.emp_name,
                                            value: service?.emp_id
                                        }));
                                        setEmployeeList(filterData);
                                    } else {
                                      setEmployeeListError(responseData?.info || 'Data Not Found');
                                    }
                                } else {
                                  setEmployeeListError(responseData?.info || 'Failed to fetch User data. Please try again.');
                                }
                            } else {
                              setEmployeeListError(responseData?.info || 'Failed to fetch User data.');
                            }
                        }
                        // Token-related errors: 400, 401, 403
                        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                              setEmployeeListError('Unauthorized access. Your session may have expired. Please log in again.');
                                // Optionally, redirect to the login page or refresh the token
                                // Example: redirectToLogin();
                            } else {
                              setEmployeeListError(responseData?.info || 'Bad request. Please check the request parameters.');
                            }
                        }
                        // Internal Server Error: 500
                        else if (response?.status === 500) {
                          setEmployeeListError(responseData?.info || 'Internal Server Error. Please try again later.');
                        }
                        // Unexpected or network-related errors
                        else {
                          setEmployeeListError('Unexpected error occurred. Please try again later.');
                        }
                    } catch (error) {
                        // Handle various error scenarios
                        if (error.response) {
                            // Server responded with a status outside the 2xx range
                            setEmployeeListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
                        } else if (error.request) {
                            // Request was made but no response was received
                            setEmployeeListError('No response received from the server. Please check your network connection and try again.');
                        } else {
                            // An error occurred in setting up the request
                            setEmployeeListError(`Failed to fetch User data. Please check your network connection and try again.`);
                        }
                    } finally {
                      setEmployeeListLoading(false);
                    }
                }

                const[noticeBoardFilterError, setNoticeBoardFilterError] = useState('');
                const[noticeBoardActiveFilterListLoading,setNoticeBoardActiveFilterListLoading] = useState(false)
                const fetchNoticeBoardMessagesFilterList = async () => {
                            try {
                              if(!recipientType && recipients.length===0 && !publishedData && !expiredData &&createdBy.length===0){
                                setNoticeBoardFilterError('Please fill at least one field to search');
                                return
                              }
                              setNoticeBoardActiveMessagesError('');
                              setNoticeBoardActiveFilterListLoading(true);
                                setNoticeBoardFilterError('')
                                setNoticeBoardMessagesList([]);
                                setEmployeeListError('');
                                setUserError('');
                                setError('')
                                setCurrentPage(1);
                                // setTeamInfo({});
                                const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
                                const payLoad = {
                                  emp_id_auth: storedUserInfo?.emp_id,
                                  create_by: createdBy?.value || 0,
                                  recipient_type: recipientType ||0, 
                                  recipient_members: recipients,
                                  publish_at:publishedData ||'',
                                  expire_at:expiredData||'',
                                  notice_type:'active'
                                }
                                console.log("payLoad in Notice Filter", payLoad)
                                // return
                                // Make the API call
                                const response = await postData(ORG_NOTICE_BOARD_MSG_FILTER_LIST, payLoad);
                                const responseData = await response.json();
                    
                                // Check for success (200) and internal status code
                                if (response?.status === 200) {
                                    if (responseData?.statusCode === 200) {
                                        if (responseData?.message.toLowerCase() === 'success') {
                                            if (responseData?.dataJ?.length > 0) {
                                              setNoticeBoardMessagesList(responseData?.dataJ);
                                              // showFilter();
                                            } else {
                                              setNoticeBoardActiveMessagesError(responseData?.info || 'Data Not Found');
                                            }
                                        } else {
                                          setNoticeBoardActiveMessagesError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                                        }
                                    } else {
                                      setNoticeBoardActiveMessagesError(responseData?.info || 'Failed to fetch Team Information.');
                                    }
                                }
                                // Token-related errors: 400, 401, 403
                                else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                                    if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                                      setNoticeBoardActiveMessagesError('Unauthorized access. Your session may have expired. Please log in again.');
                                        // Optionally, redirect to the login page or refresh the token
                                        // Example: redirectToLogin();
                                    } else {
                                      setNoticeBoardActiveMessagesError(responseData?.info || 'Bad request. Please check the request parameters.');
                                    }
                                }
                                // Internal Server Error: 500
                                else if (response?.status === 500) {
                                  setNoticeBoardActiveMessagesError(responseData?.info || 'Internal Server Error. Please try again later.');
                                }
                                // Unexpected or network-related errors
                                else {
                                  setNoticeBoardActiveMessagesError('Unexpected error occurred. Please try again later.');
                                }
                            } catch (error) {
                                // Handle various error scenarios
                                if (error.response) {
                                    // Server responded with a status outside the 2xx range
                                    setNoticeBoardActiveMessagesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
                                } else if (error.request) {
                                    // Request was made but no response was received
                                    setNoticeBoardActiveMessagesError('No response received from the server. Please check your network connection and try again.');
                                } else {
                                    // An error occurred in setting up the request
                                    setNoticeBoardActiveMessagesError(`Failed to fetch Team Information. Please check your network connection and try again.`);
                                }
                            } finally {
                              setNoticeBoardActiveFilterListLoading(false);
                              showFilter();
                            }
                        }

                const resetFilter = ()=>{
                  setPublishedDate('');
                  setExpiredDate('');
                  // setEmployeeList([]);
                  setRecipientType('');
                  setRecipients([]);
                  setCreatedBy('');
                  setRecipientOptions([]);
                  setNoticeBoardFilterError('')
                }

  return{
    handlePageChange,
    currentNoticeMsg,
    currentPage,
    totalPages,

    loading,
    success,
    handleCloseDeleteModal,
    handleOpenDeleteModal,
    handleDelete,
    isDeleteModalOpen,
    selectedDocument,
    setSelectedDocument,
    noticeBoardMsgDeleteError,
    handleNoticeBoardMessageDelete,
    currentNoticeDeleteIndex,
    deletedMessages,
    visiblePages,

    recipientOptions,
    error,
    teamLoading,
    recipients,
    userError,
    userLoading,
    fetchUserList,
    fetchTeamList,
    recipientTypeOptions,
    noticeBoardRecipientError,
    setRecipients,
    recipientType,
    setRecipientType,
    setRecipientOptions,
    employeeListLoading,
    employeeListError,
    employeeList,
    createdBy,
    setCreatedBy,
    setExpiredDate,
    setPublishedDate,
    expiredData,
    publishedData,
    resetFilter,
    fetchNoticeBoardMessagesFilterList,
    noticeBoardFilterError,
    noticeBoardActiveFilterListLoading
  }

}

export default NoticeBoardActiveMsgListController