import React, { useState, useRef, useEffect} from 'react';
import TextInput from '../../../../../Components/TextInput';
import DateInput from '../../../../../Components/DateInput';
import search from '../../../../../Assets/Icons/search.png';
import Dropdown from '../../../../../Components/Dropdown';
import InvoiceReportsController from '../../../../Controllers/AdminScreens/Invoices/Reports/InvoiceReportsController';

const InvoiceReportsScreen = () => {

    const {
        reportName,
        setReportName,
        clientName,
        setClientName,
        groupName,
        setGroupName,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        deliveryTypeOptions,
        statusOptions
    } = InvoiceReportsController()

    const handleStartDateChange = (value) => {
        setStartDate(value);
    };

    const handleEndtDateChange = (value) => {
        setEndDate(value);
    };
    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div style={{ width: '93%', margin: 'auto' }}>
                <h3 style={styles.headerTop}>
                    <div style={styles.headerContainer} >
                        {/* <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleBack}
            /> */}
                        <span>Invoicing Reports</span>
                    </div>
                </h3>
            </div>
            <div className="tasksScreen-main-content" style={{ width: 'unset' }}>
                <div className="tasksScreen-tasks-container" >
                    {/* <div className="tasksScreen-tasks-header"> */}
                    <h3 style={styles.categoryHeader}>Generate Reports</h3>
                    {/* </div> */}
                    <hr className="footer-divider" />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["Report Name"] = el)}
                        isOpen={openDropdown === "Report Name"}
                        toggleDropdown={() => toggleDropdown("Report Name")}
                        label="Report Name"
                        options={deliveryTypeOptions}
                        value={reportName}
                        onChange={setReportName}
                    />
                    <div className="filter-field" style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <label style={{ ...styles.labaleStyle, display: "block" }}>Client</label>
                        <TextInput
                            type="text"
                            placeholder="Client Name"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            right={<img src={search} alt="Search" className="search-icon" />}
                        />
                    </div>
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["Group Name"] = el)}
                        isOpen={openDropdown === "Group Name"}
                        toggleDropdown={() => toggleDropdown("Group Name")}
                        label="Group Name"
                        options={statusOptions}
                        value={groupName}
                        onChange={setGroupName}
                        isMulti
                    />
                    <div style={{ marginTop: '10px' }}>
                        <div className="filter-field">
                            <label style={{ ...styles.labaleStyle, display: "block" }}>Period</label>
                            <DateInput
                                value={startDate || ""}
                                onChange={(e) => handleStartDateChange('startDate', e.target.value)}
                            />
                        </div>
                        <span style={{ margin: '0 10px' }}>-</span>
                        <div className="filter-field">
                            {/* <label>Period</label> */}
                            <DateInput
                                value={endDate || ""}
                                onChange={(e) => handleEndtDateChange('endDate', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="button-container" style={{ gap: "10px" }}>
                        <button className="add-client-button" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                            Generate
                        </button>
                        <button style={{ ...styles.cancelButton, paddingLeft: '30px', paddingRight: '30px' }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    cancelButton: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        color: "#40513B",
        border: "1px solid #858585",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    },
    labaleStyle: {
        fontSize: '12px',
        // marginBottom: '5px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        color: 'rgb(52, 60, 68)'
    },
    categoryHeader: {
        color: '#1F384C',
        fontSize: 28,
        fontFamily: 'Roboto Slab',
        fontWeight: 500,
        // paddingBottom: 20
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    headerTop: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
};

export default InvoiceReportsScreen;
