import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getData,postData } from "../../../../ApiServices/ApiService";
import { ORG_GET_EMPLOYEE_LIST,ORG_EMP_DELETE, ORG_EMP_SEARCH, ORG_GET_TEAM_LEADER_MANAGER_LIST } from '../../../../ApiServices/BaseURL';

const ManageUsersController = (onRefresh,userList,error,refreshTeamList) => {

    const navigate = useNavigate();
    const [userListLoading, setUserListLoading] = useState(false);
    const [userListError, setUserListError] = useState(error || '');
    const [manageUserList, setManageUserList] = useState([]);
    const [isUserEditable, SetIsUserEditable] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [username, setUsername] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [countryCode, setCountryCode] = useState('+91');
    const [email, setEmail] = useState("");
    const [teamLead, setTeamLead] = useState(null);
    const [teamManager, setTeamManager] = useState(null);
    const [employeeList, setEmployeeList] = useState([]);
    const [teamLeaderLoading, setTeamLeaderLoading] = useState(false);
    const [empError, setEmpError] = useState('');
    const [userNameError, setUserNameError] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [teamLeadError, setTeamLeadError] = useState('');
    const [teamManagerError, setTeamManagerError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [showFilters, setShowFilters] = useState(true);
    const [filterError, setFilterError] = useState('');
    const categoryInfoRef = useRef(null);

    useEffect(() => {
        setManageUserList(userList);
    }, [userList])

    const handleDelete = (doc) => {
        // setSelectedEmployee({ doc, index }); // Store the credential to delete
        categoryInfoRef.current = doc;
        setIsDeleteModalOpen(true); // Open the confirmation modal
    };
  
    const handleOpenDeleteModal = () => {
        setIsDeleteModalOpen(true); // Open the modal
    };
  
    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false); // Close the modal
    };
    const itemsPerPage = 10;

    useEffect(() => {
        fetchTeamLeaderList()
        fetchManagersList()
    }, [])

    const fetchTeamLeaderList = async () => {
        try {
          setTeamLeaderLoading(true);
            setEmpError('');
            setEmployeeList([]);
            setTeamLeadError('');


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_LEADER_MANAGER_LIST}?emp_role=${'TEAM_LEAD'}&emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service?.emp_name,
                                value: service?.emp_id
                            }));
                            setEmployeeList(filterData);
                        } else {
                          setTeamLeadError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                      setTeamLeadError(responseData?.info || 'Failed to fetch User data. Please try again.');
                    }
                } else {
                  setTeamLeadError(responseData?.info || 'Failed to fetch User data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                  setTeamLeadError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                  setTeamLeadError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setTeamLeadError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
              setTeamLeadError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setTeamLeadError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setTeamLeadError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setTeamLeadError(`Failed to fetch User data. Please check your network connection and try again.`);
            }
        } finally {
          setTeamLeaderLoading(false);
        }
    }

    const[managerLoading, setManagerLoading]=  useState(false);
    const [managerList, setManagerList] = useState([]);
    const [managerFiletrError,setManagerFilterError] = useState('');
    const fetchManagersList = async () => {
      try {
          setManagerLoading(true);
          setEmpError('');
          setManagerList([]);
          setManagerFilterError('');


          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

          // Make the API call
          const response = await getData(`${ORG_GET_TEAM_LEADER_MANAGER_LIST}?emp_role=${'TEAM_MANAGER'}&emp_id_auth=${storedUserInfo?.emp_id}`);
          const responseData = await response.json();

          // Check for success (200) and internal status code
          if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                  if (responseData?.message.toLowerCase() === 'success') {
                      if (responseData?.dataJ?.length > 0) {
                          const filterData = responseData?.dataJ.map(service => ({
                              label: service?.emp_name,
                              value: service?.emp_id
                          }));
                          setManagerList(filterData);
                      } else {
                        setManagerFilterError(responseData?.info || 'Data Not Found');
                      }
                  } else {
                    setManagerFilterError(responseData?.info || 'Failed to fetch User data. Please try again.');
                  }
              } else {
                setManagerFilterError(responseData?.info || 'Failed to fetch User data. Status code error.');
              }
          }
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setManagerFilterError('Unauthorized access. Your session may have expired. Please log in again.');
                  // Optionally, redirect to the login page or refresh the token
                  // Example: redirectToLogin();
              } else {
                setManagerFilterError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
          }
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setManagerFilterError(responseData?.info || 'Internal Server Error. Please try again later.');
          }
          // Unexpected or network-related errors
          else {
            setManagerFilterError('Unexpected error occurred. Please try again later.');
          }
      } catch (error) {
          // Handle various error scenarios
          if (error.response) {
              // Server responded with a status outside the 2xx range
              setManagerFilterError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
          } else if (error.request) {
              // Request was made but no response was received
              setManagerFilterError('No response received from the server. Please check your network connection and try again.');
          } else {
              // An error occurred in setting up the request
              setManagerFilterError(`Failed to fetch User data. Please check your network connection and try again.`);
          }
      } finally {
        setManagerLoading(false);
      }
  }

    const handleAddDocument = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const getStatusStyle = (status) => {
        switch (status) {
            // case "Incomplete":
            //     return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
            case 0:
                return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
            case 1:
                return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
            default:
                return {};
        }
    };

    const handleEditUser = (value, userInfo) => {
        const editable = value === 'editUser';
        SetIsUserEditable(editable);
        //console.log('editable controller : ', editable)
        navigate('/manage-user', { state: { isUserEditable: editable, userInfo: userInfo } });
    }
    const handleAddUser = () => {
      navigate('/add-user');
  }
    const onRefreshList = () => {
        onRefresh();
        setFilterError('');
        setMobileNumber('');
        setCountryCode('+91');
        setFilters({
          user_name: '',
          team_leader: null,
          team_manager: null,
          email_id: ''
        });
        setUserListError('');
    }

    const totalPages = Math.ceil(manageUserList?.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentPageUsers = manageUserList?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
    }
    };

    const [employeedDeleteError, setEmployeeDeleteError] = useState({});
    const [employeeDeleteLoading, setEmployeeDeleteLoading] = useState({});
    const [currentDocDeleteIndex, setCurrentDocDeleteIndex] = useState(null);
    const [success, setSuccess] = useState({});
    const [deletedMessages, setDeletedMessages] = useState([]);
    const handleEmployeeDelete = async (user) => {
   
        try {
          // const { doc, index } = selectedEmployee;
          // //console.log("index", index)
          // setEmployeeDeleteError('');  
            // setSuccess('')
            // if (!searchUser) {
            //   setError('Please enter valid text'); return;
            // }         
            // setEmployeeDeleteLoading(true);
            setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]: ''}));
            setEmployeeDeleteLoading((prev) => ({ ...prev, [user?.emp_id]: true }));
            // setCurrentDocDeleteIndex(index)
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, emp_id:user.emp_id}
        
            //console.log('handleSearchEmployee payload',payLoad);
            // Make the API call
            const response = await postData(ORG_EMP_DELETE,payLoad);
            const responseData = await response.json();
            //console.log('response Data',responseData);
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  setDeletedMessages((prevDeleted) => [...prevDeleted, user?.emp_id]);
                  setSuccess((prev) => ({ ...prev, [user?.emp_id]:responseData?.info}));
                    setTimeout(()=>{
                      setSuccess((prev) => ({ ...prev, [user?.emp_id]:''}));
                      onRefreshList();
                      setCurrentPage(1);
                      refreshTeamList();
                    },3000);
                    // setEmployeeList(responseData?.dataJ || []);
                } else {
                  setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]:responseData?.info || 'Failed to delete kyc document. Please try again.'}));
                }
              } else {
                setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]:responseData?.info || 'Failed to delete kyc document.'}));
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]:'Unauthorized access. Your session may have expired. Please log in again.'}));
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]:responseData?.info || 'Bad request. Please check the request parameters.'}));
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]:responseData?.info || 'Internal Server Error. Please try again later.'}));
            } 
            // Unexpected or network-related errors
            else {
                setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]:'Unexpected error occurred. Please try again later.'}));
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]:`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete kyc document. Please try again.'}`}));
            } else if (error.request) {
              // Request was made but no response was received
              setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]:'No response received from the server. Please check your network connection and try again.'}));
            } else {
              // An error occurred in setting up the request
              setEmployeeDeleteError((prev) => ({ ...prev, [user?.emp_id]:`Failed to delete kyc document. Please check your network connection and try again.`}));
            }
          } finally {
            setEmployeeDeleteLoading((prev) => ({ ...prev, [user?.emp_id]: false }));
            // setCurrentDocDeleteIndex(null)
          }
    
        // onValueChange(teamData);
        // onClose();
    };

      const [filters, setFilters] = useState({
        user_name: '',
        team_leader: null,
        team_manager: null,
        email_id: '',
      });
    
      const handleFilterChange = (name, value) => {
        //console.log('name : ', name, 'value : ', value)
        setFilterError('');
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: value,
        }));
      };
    
      const applyFilters = () => {
        //console.log('Applying filters:', filters);
        // setTeamNameError('');
        // setTeamLeadError('');
        // setTeamManagerError('');
        // if(!filters?.team_name){
        //   setTeamNameError('Please enter team name');return
        // }
        // if(!filters?.team_leader){
        //   setTeamLeadError('Please select team leader');return
        // }
        // if(!filters?.team_manager){
        //   setTeamManagerError('Please select team manager');return
        // }
        fetchSearchEmployeeList();
      };
    
      const resetFilters = () => {
        if (filters?.user_name.trim() || mobileNumber.trim() || filters?.team_leader || filters?.team_manager || filters?.email_id.trim()) {
          onRefresh();
          setCurrentPage(1);
        }
        setFilterError('');
        setMobileNumber('');
        setCountryCode('+91');
        setFilters({
           user_name: '',
           team_leader: null,
           team_manager: null,
           email_id: ''
        });
        setUserListError('');
        setManagerFilterError('');
        setTeamLeadError('')
        setEmployeeDeleteError({});
      };

      const cancelFilters = () => {
        setShowFilters(!showFilters);
        resetFilters();
      }
    
      const toggleFilters = () => {
        setShowFilters(!showFilters);
      };
    
      const fetchSearchEmployeeList = async () => {
        try {
          setEmployeeDeleteError({});
          setFilterError('');
          if (!(filters?.user_name.trim() || mobileNumber.trim() || filters?.team_leader || filters?.team_manager || filters?.email_id.trim())) {
            setFilterError('Please enter/select any one of the above'); return
          }
          setUserListLoading(true);
          setUserListError('');
    
          // teamListRef.current = [];
          setManageUserList([]);
          setCurrentPage(1);
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
          const payLoad={
            "emp_id_auth": storedUserInfo?.emp_id,
            "emp_name": filters?.user_name,
            "emp_mobile_no": mobileNumber,
            "emp_id_team_lead": filters?.team_leader?.value || 0,
            "emp_id_team_manager": filters?.team_manager?.value || 0,
            "emp_email": filters?.email_id
          }

          //console.log('---team status:---',payLoad);
    
          const response = await postData(ORG_EMP_SEARCH,payLoad);
          const responseData = await response.json();
          //console.log('---team responseData:---',responseData);
    
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                if (responseData?.dataJ?.length > 0) {
                  // teamListRef.current = responseData?.dataJ;
                  setManageUserList(responseData?.dataJ);
                } else {
                    setUserListError(responseData?.info || 'No Teams Available');
                }
              } else {
                setUserListError(responseData?.info || 'Failed to fetch team list. Please try again.');
              }
            } else {
                setUserListError(responseData?.info || 'Failed to fetch team list.');
            }
          }
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setUserListError('Unauthorized access. Your session may have expired. Please log in again.');
            } else {
                setUserListError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          }
          else if (response?.status === 500) {
            setUserListError(responseData?.info || 'Internal Server Error. Please try again later.');
          }
          else {
            setUserListError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          if (error.response) {
            setUserListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
          } else if (error.request) {
            setUserListError('No response received from the server. Please check your network connection and try again.');
          } else {
            setUserListError(`Failed to fetch team list. Please check your network connection and try again.`);
          }
        } finally {
            setUserListLoading(false);  
        }
      }

      const maxVisiblePages = 3;

    const getVisiblePages = () => {
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
    };

    const visiblePages = getVisiblePages();

    return {
        userListLoading, userListError,
        username, setUsername, userNameError,
        mobileNumber, setMobileNumber, mobileNumberError,
        countryCode, setCountryCode,
        email, setEmail, emailError,
        teamManager, setTeamManager, teamManagerError,
        teamLead, setTeamLead, teamLeadError,
        employeeList, teamLeaderLoading,
        manageUserList,
        isModalOpen,
        handleAddDocument,
        handleCloseModal,
        getStatusStyle,
        handleEditUser, isUserEditable,
        onRefreshList,
        currentPage,
        totalPages,
        handlePageChange,
        currentPageUsers,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        handleDelete,
        setSelectedEmployee,
        selectedEmployee,
        isDeleteModalOpen,
        handleEmployeeDelete,
        success,
        currentDocDeleteIndex,
        employeeDeleteLoading,
        employeedDeleteError,
        showFilters,
        filters, filterError,
        handleFilterChange, toggleFilters,
        applyFilters, resetFilters, cancelFilters,
        categoryInfoRef,
        handleAddUser,

        managerFiletrError,
        managerList,
        managerLoading,
        deletedMessages,
        visiblePages,
    };
}

export default ManageUsersController;