import React, { useState, useRef, useEffect } from 'react';
import UserSpecializationController from '../../../Controllers/AdminScreens/Users/UserSpecializationController';
import editIcon from '../../../../Assets/Icons/edit.png';
import Toggle from '../../../../Components/Toggle';
import UserSpecializationModal from '../../../../Components/Modals/UserSpecializationModal';
import Loader from '../../../../Components/Loader';

const UserSpecializationScreen = ({ userInfo, userSpecializationList, userSpecializationLoading, userSpecializationError, onRefresh }) => {

    const { handleEditSpecialization,
        handleToggleChange,
        selUserSpecializationService,
        isUserSpecializationModalOpen,
        handleCloseUserSpecializationModal,
        getActiveSubServiceNames,
        userSpecToggleUpdateLoading, userSpecToggleUpdateError, userSpecToggleUpdateSuccess,
        toggleUpdatedMessages
    } = UserSpecializationController(userInfo, userSpecializationList, userSpecializationLoading, userSpecializationError, onRefresh);

    return (
        <div className="basic-info-container">
            <h3 className='basic-info-header'>Specialization</h3>
            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>
                        <tr>
                            <th style={{ width: '25%' }}>Service</th>
                            <th style={{ width: '65%' }}>Service Category</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                    </thead>
                    {userSpecializationList?.length > 0 &&
                        <tbody style={{ backgroundColor: 'white' }}>
                            {userSpecializationList?.map((service, index) => {
                                const { names, isActive } = getActiveSubServiceNames(service?.sub_service_list);
                                return (
                                    <tr
                                        key={index}
                                        className="tasksScreen-main-task-row"
                                        style={{ borderBottom: "1px solid #dee2e6" }}
                                    >
                                        <td style={{ cursor: 'pointer', textDecoration: 'underline', color: '#5a55d2' }}>{service?.service_name}</td>
                                        <td>{names}
                                            {userSpecToggleUpdateSuccess[service?.service_id] &&
                                                <div style={{ color: 'green', paddingTop: 10 }} >{userSpecToggleUpdateSuccess[service?.service_id]}</div>}
                                            {userSpecToggleUpdateError[service?.service_id] &&
                                                <div style={{ color: 'red', paddingTop: 10 }} >{userSpecToggleUpdateError[service?.service_id]}</div>}
                                        </td>
                                        <td style={{ display: 'flex', gap: 14 }}>
                                            <button
                                                className="tasksScreen-edit-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: userSpecToggleUpdateLoading[service?.service_id],
                                                    pointerEvents: userSpecToggleUpdateLoading[service?.service_id],
                                                    opacity: userSpecToggleUpdateLoading[service?.service_id] ? 0.3 : 1
                                                }}
                                                onClick={() => handleEditSpecialization(service)}
                                            >
                                                <img
                                                    src={editIcon}
                                                    alt="Edit"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>
                                            {/* <Toggle initialStatus={false} onToggle={handleToggleChange} size='small' /> */}
                                            <Toggle initialStatus={isActive} onToggle={(status) => handleToggleChange(service, status)} size='small' disabled={userSpecToggleUpdateLoading[service?.service_id]} />
                                            {userSpecToggleUpdateLoading[service?.service_id] &&
                                                <span style={{ paddingTop: 2 }}><Loader loading={userSpecToggleUpdateLoading[service?.service_id]} color={'var(--primary-color)'} size='small' /></span>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}

                        </tbody>}
                </table>
                {userSpecializationLoading && <Loader loading={userSpecializationLoading} color={'var(--primary-color)'} />}
                {userSpecializationError && <div className="list-error-msg">{userSpecializationError} </div>}
            </div>
            {/* Manage Specialization Modal */}
            {isUserSpecializationModalOpen && (
                <UserSpecializationModal
                    isOpen={isUserSpecializationModalOpen}
                    onClose={handleCloseUserSpecializationModal} userInfo={userInfo}
                    selService={selUserSpecializationService} onValueChange={onRefresh}
                />
            )}
        </div>
    )

};

export default UserSpecializationScreen;