import React, { useEffect, useRef, useState } from "react";
import { ORG_GET_GROUP_LIST, ORG_DELETE_GROUP, ORG_SEARCH_GROUP, ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER } from "../../../../ApiServices/BaseURL";
import { postData, getData } from "../../../../ApiServices/ApiService";
import { useNavigate } from "react-router-dom";
import { validateMobileNumber } from "../../../../Utils/Validation";

const useManageGroupsController = () => {
  const [username, setUsername] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState('+91');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [groupsList, setgroupsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(groupsList.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentGroupsList = groupsList.slice(indexOfFirstItem, indexOfLastItem);
  const [deleteGroupLoading, setDeleteGroupLoading] = useState({});
  const [deleteGroupError, setDeleteGroupError] = useState({});
  const [deleteGroupSuccess, setDeleteGroupSuccess] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const groupInfoRef = useRef(null);
  const [showFilters, setShowFilters] = useState(true);
  const [filterError, setFilterError] = useState('');
  const [clientList, setClientList] = useState([]);
  const [clientListLoading, setClientListLoading] = useState(false);
  const [searchClient, setSearchClient] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [groupMasterError, setGroupMasterError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [applyLoading, setApplyLoading] = useState(false);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
  }
  };

  const navigate = useNavigate()

  useEffect(() => {
    fetchGroupList();
  }, [])

  const fetchGroupList = async () => {
    try {
      setLoading(true);
      setError('');
      setgroupsList([]);
      setCurrentPage(1);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              setgroupsList(responseData?.dataJ);
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleAddGroup = () => {
    navigate('/manage-group-details', { state: { selGroupInfo: null, isEdit: null, isAddGroup: true } });
    // setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const OnAddUpdateSucess = () => {
    fetchGroupList();
    setClientList([]);
    setFilterError('');
    setMobileNumber('');
    setCountryCode('+91');
    setSelectedClient(null);
    setSearchClient('');
    setFilters({
      client_name: '',
    });
  }

  const getStatusStyle = (status) => {
    switch (status) {
      //   case "Incomplete":
      //     return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
      case 0:
        return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
      case 1:
        return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
      default:
        return {};
    }
  };

  const handleEditGroup = (group, isEdit) => {
    //console.log('group:-', group);
    navigate('/manage-group-details', { state: { selGroupInfo: group, isEdit: isEdit } });
  }

  const handleDeleteGroup = (group) => {
    // //console.log('handleDeleteGroup group @', group);
    // showConfirmation(group);
    groupInfoRef.current = group;
    setIsDeleteModalOpen(true);
  }

  const showConfirmation = (group) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      callDeleteGroup(group);
    } else {
      //console.log("Delete canceled");
    }
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
  };

  const [deletedMessages, setDeletedMessages] = useState([]);
  const callDeleteGroup = async (group) => {
    try {
      //console.log('callDeleteGroup group', group)
      setDeleteGroupError({});
      setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: '' }));
      setDeleteGroupLoading((prev) => ({ ...prev, [group?.group_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "group_id": group?.group_id
      }

      //console.log('callDeleteGroup payload', payLoad);

      // Make the API call
      const response = await postData(ORG_DELETE_GROUP, payLoad);
      const responseData = await response.json();
      //console.log('callDeleteGroup response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setDeletedMessages((prevDeleted) => [...prevDeleted, group?.group_id]);
            setDeleteGroupSuccess((prev) => ({ ...prev, [group?.group_id]: responseData?.info }));
            setTimeout(() => {
              setDeleteGroupSuccess((prev) => ({ ...prev, [group?.group_id]: '' }));
              OnAddUpdateSucess();
              setCurrentPage(1);
            }, 3000);
          } else {
            setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: responseData?.info || 'Failed to delete group. Please try again.' }));
          }
        } else {
          setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: responseData?.info || 'Failed to delete group. Please try again...' }));
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      // Unexpected or network-related errors
      else {
        setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete group. Please try again.'}` }));
      } else if (error.request) {
        // Request was made but no response was received
        setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        // An error occurred in setting up the request
        setDeleteGroupError((prev) => ({ ...prev, [group?.group_id]: `Failed to delete group. Please check your network connection and try again.` }));
      }
    } finally {
      setDeleteGroupLoading((prev) => ({ ...prev, [group?.group_id]: false }));
    }
  }

  const handleSearchClient = async (searchWord) => {
    try {
      //console.log('handleSearchClient search text', searchWord)
      setGroupMasterError('');
      setClientListLoading(true);
      setClientList([]);
      if (!searchWord) {
        setGroupMasterError('Please enter valid text'); return;
      }
      setFilterError('');
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchWord }

      //console.log('handleSearchClient payload', payLoad);

      const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setClientList(responseData?.dataJ || []);
          } else {
            setGroupMasterError(responseData?.info || 'No Client Available');
          }
        } else {
          setGroupMasterError(responseData?.info || 'Failed to fetch Client.');
        }
      } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setGroupMasterError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setGroupMasterError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else if (response?.status === 500) {
        setGroupMasterError(responseData?.info || 'Internal Server Error. Please try again later.');
      } else {
        setGroupMasterError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setGroupMasterError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Client. Please try again.'}`);
      } else if (error.request) {
        setGroupMasterError('No response received from the server. Please check your network connection and try again.');
      } else {
        setGroupMasterError(`Failed to fetch client. Please check your network connection and try again.`);
      }
    } finally {
      setClientListLoading(false);
    }
  }

  const handleSelectClient = (client) => {
    //console.log('handleSelectClient client ', client)
    setSelectedClient(client); // Set selected client data
    // handleInputChange('client_id_primary_mobile_no', client?.client_mobile_no);
    // handleInputChange('client_id_primary_mobile_code', client?.client_mobile_code);
    // handleInputChange('client_id_primary_name', client?.client_name);
    setSearchClient(client?.client_name); // Update TextInput with the selected client's name
    setClientList([]); // Clear the dropdown     
  };

  //   const handleInputChange = (key, value) => {
  //     setEditPrimaryData((prevState) => ({
  //         ...prevState,
  //         [key]: value,
  //     }));
  // };

  const [filters, setFilters] = useState({
    client_name: ''
  });

  const handleFilterChange = (name, value) => {
    //console.log('name : ', name, 'value : ', value)
    setFilterError('');
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSearchInputText = (value) => {
    //console.log('handleSearchInputText value : ',value);
    setSearchClient(value);
    setGroupMasterError('');
    setSelectedClient(null);
    setClientList([]);
  }

  const applyFilters = () => {
    //console.log('Applying filters:', filters);
    // setTeamNameError('');
    // setTeamLeadError('');
    // setTeamManagerError('');
    // if(!filters?.team_name){
    //   setTeamNameError('Please enter team name');return
    // }
    // if(!filters?.team_leader){
    //   setTeamLeadError('Please select team leader');return
    // }
    // if(!filters?.team_manager){
    //   setTeamManagerError('Please select team manager');return
    // }
    fetchSearchGroupList();
  };

  const resetFilters = () => {
    //console.log('resetFilters')
    if (filters?.client_name.trim() || selectedClient?.client_id || mobileNumber.trim()) {
      fetchGroupList();
    }
    setClientList([]);
    setFilterError('');
    setMobileNumber('');
    setCountryCode('+91');
    setSelectedClient(null);
    setSearchClient('');
    setFilters({
      client_name: '',
    });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const cancelFilters = () => {
    setShowFilters(!showFilters);
    resetFilters();
  }

  const fetchSearchGroupList = async () => {
    try {
      setGroupMasterError('');
      setFilterError('');
      setMobileNumberError('');
      if (!(filters?.client_name.trim() || selectedClient?.client_id || mobileNumber.trim())) {
        setFilterError('Please enter/select any one of the above'); return
      }
      // if (!validateMobileNumber(mobileNumber)) {
      //   setMobileNumberError('Mobile number must be 10 digits.');
      //   return;
      // }
      setApplyLoading(true);
      setError('');

      setgroupsList([]);
      setCurrentPage(1);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "client_name": filters?.client_name,
        "client_id_primary": selectedClient?.client_id || 0,
        // "client_id_primary": filters?.client_id_primary?.value || 0,
        "client_mobile_no": mobileNumber,
      }
      //console.log('fetchSearchGroupList payload:---', payLoad);

      const response = await postData(ORG_SEARCH_GROUP, payLoad);
      const responseData = await response.json();

      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              setgroupsList(responseData?.dataJ);
            } else {
              setError(responseData?.info || 'No Teams Available');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch group list. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch group list.');
        }
      }
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        setError(`Failed to fetch group list. Please check your network connection and try again.`);
      }
    } finally {
      setApplyLoading(false);
    }
  }

  const maxVisiblePages = 3;

    const getVisiblePages = () => {
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
    };

    const visiblePages = getVisiblePages();


  return {
    groupsList,
    isModalOpen,
    username,
    mobileNumber,
    countryCode,
    handleAddGroup,
    handleCloseModal,
    getStatusStyle,
    setUsername,
    setMobileNumber,
    setCountryCode,
    OnAddUpdateSucess,
    loading,
    error,
    handleEditGroup,
    handleDeleteGroup,
    deleteGroupLoading,
    deleteGroupError,
    currentGroupsList,
    currentPage,
    totalPages,
    handlePageChange,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    callDeleteGroup,
    groupInfoRef,
    applyLoading,
    groupMasterError, mobileNumberError,
    clientList, clientListLoading,
    handleSearchClient, selectedClient, handleSearchInputText,
    searchClient, setSearchClient, handleSelectClient,
    filterError, filters, showFilters,
    handleFilterChange,
    applyFilters, resetFilters, cancelFilters,
    toggleFilters,
    deleteGroupSuccess,
    deletedMessages,
    visiblePages,
  };

};
export default useManageGroupsController;
