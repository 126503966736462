import React, { useState, useRef, useEffect } from 'react';
import CheckBox from '../../../../Components/CheckBox';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import ClientTaskListController from '../../../Controllers/AdminScreens/Clients/ClientTaskListController';
import Loader from '../../../../Components/Loader';
import { formatToDMY } from '../../../../Utils/Validation';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';
import downArrow from '../../../../Assets/Icons/chevron-down.png';

const ClientTaskListScreen = ({clientId, taskList, responseError, responseLoading, onTaskEdit, fetchTaskList }) => {
  // //console.log("task taskList", taskList)
  const [selectAll, setSelectAll] = useState(false);
  const [checkedStates, setCheckedStates] = useState([]);
  const [showArchived, setShowArchived] = useState(false); // State to toggle archived tasks

  const {
    tasks,
    currentPage,
    totalPages,
    handlePageChange,
    toggleDetails,
    getStatusStyle,
    loading,
    error,
    handleGenerateInvoive,
    onTaskDelete,
    deleteTaskError,deleteTaskLoading,deleteTaskSuccess,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    callDeleteTask,
    taskInfoRef,
    visiblePages,
  } = ClientTaskListController(taskList,responseError,responseLoading,fetchTaskList);

 
  const handleSelectAll = (isChecked) => {
    setSelectAll(isChecked);
    setCheckedStates(tasks.map(() => isChecked));
  };

  const handleCheckboxChange = (index, isChecked) => {
    const updatedStates = [...checkedStates];
    updatedStates[index] = isChecked;
    setCheckedStates(updatedStates);
  };

 // Filter tasks based on the showArchived state
 const filteredTasks = showArchived ? tasks : tasks?.filter(task => task?.task_status !== 3);

  return (
    <>
      <div className="tasksScreen-task-table-wrapper basic-info-outer-card">
        <div className='flexdisplaybetween' style={{ paddingBottom: 10 }}>
          <h3 className='basic-info-header' style={{ marginBottom: 0 }}>Task Lists</h3>
           <div 
            style={styles.showTasktyle} 
            onClick={() => setShowArchived(prev => !prev)} // Toggle showArchived state
          >
            {showArchived ? "Hide Archived Tasks" : "Show Archived Tasks"}
          </div>
        </div>

        <table className="tasksScreen-task-table" >
          <thead>
            <tr>
              <th style={{ width: "3%" }}>
                {/* <input type="checkbox" /> */}
                <CheckBox
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th style={{ width: "10%", }}>Task ID</th>
              <th style={{ width: "18%", }}>Service/Category</th>
              <th style={{ width: "10%", }}>Financial Year</th>
              <th style={{ width: "10%", }}>Assigned to</th>
              <th style={{ width: "10%", }}>Task Leader</th>
              <th style={{ width: "8%", }}>Start Date</th>
              <th style={{ width: "8%", }}>ETD</th>
              <th style={{ width: "16%", }}>Status</th>
              <th style={{ width: "8%", }}>
                
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: 'white' }}>
            {filteredTasks?.map((task, index) => (
              <tr
                key={index}
                style={{
                  borderBottom: "3px solid #f8f9fa",
                }}
              >
                <td>
                  {/* <input type="checkbox" /> */}
                  <CheckBox
                    checked={checkedStates[index] || false}
                    onChange={(isChecked) => handleCheckboxChange(index, isChecked)}
                  />
                </td>
                <td onClick={() => onTaskEdit(task)} style={{ ...styles.tableValue, color: '#5A55D2',cursor:'pointer', textDecoration: 'underline' }}>{task?.task_id}</td>
                <td style={styles.tableValue}>{task?.service_name}/{task?.service_sub_name}</td>
                <td style={styles.tableValue}>{task?.financial_year}</td>
                <td style={styles.tableValue}>{task?.emp_id_assigned_name}</td>
                <td style={styles.tableValue}>{task?.emp_id_tl_name}</td>
                <td style={styles.tableValue}>{formatToDMY(task?.task_start_date)}</td>
                <td style={styles.tableValue}>{formatToDMY(task?.task_due_date)}</td>
                <td style={styles.tableValue}>
                  <div
                    style={{
                      display: "inline-block", // Makes the div wrap content and have its own width
                      padding: "5px 10px", // Padding for the button-like appearance
                      borderRadius: "15px",
                      ...getStatusStyle(task), // Dynamic styles based on the status
                      width: "auto", // Width adjusts automatically to content
                    }}
                  >
                    {task?.task_priority ? 'Priority / ':''}{task?.task_status_name}
                  </div>
                  {deleteTaskSuccess[task?.task_id] &&
                        <div style={{ color: 'green', paddingTop: 10 }} >{deleteTaskSuccess[task?.task_id]}</div>}
                      {deleteTaskError[task?.task_id] &&
                        <div style={{ color: 'red', paddingTop: 10 }} >{deleteTaskError[task?.task_id]}</div>}
                </td>
                <td
                >
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <button className="tasksScreen-edit-button" style={{ marginRight: "10px" }} onClick={() => onTaskEdit(task)}>
                    <img src={editIcon} alt="Edit" style={{ width: "16px", height: "16px" }} />
                  </button>
                  <button className="tasksScreen-delete-button" style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            opacity: deleteTaskLoading[task?.task_id] ? 0.3 : 1
                          }} onClick={() => onTaskDelete(task)}>
                    <img src={deleteIcon} alt="Delete" style={{ width: "16px", height: "16px" }} />
                  </button>
                  {deleteTaskLoading[task?.task_id] &&
                          <span><Loader loading={deleteTaskLoading[task?.task_id]} color={'var(--primary-color)'} size='small' /></span>
                        }
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
        {error && <div className="list-error-msg">{error} </div>}
        <div className="tasksScreen-table-footer" style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="tasksScreen-pagination-info"
                                style={{
                                    whiteSpace: "nowrap",
                                    // overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}>
                                {`${currentPage}-${totalPages} of items`}
                            </span>
                            <div className="tasksScreen-pagination" style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', maxWidth: '100%' }}>
                                <button
                                    className="tasksScreen-pagination-button"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1 || totalPages === 0}
                                >
                                    <img
                                        src={downArrow}
                                        alt="Previous"
                                        className="tasksScreen-arrow-left tasksScreen-icon-image"
                                    />
                                </button>
                                {visiblePages.map((page) => (
                                    <button
                                        key={page}
                                        className={`tasksScreen-pagination-button ${currentPage === page ? "active" : ""}`}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </button>
                                ))}
                                <button
                                    className="tasksScreen-pagination-button"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                >
                                    <img
                                        src={downArrow}
                                        alt="Next"
                                        className="tasksScreen-arrow-right tasksScreen-icon-image"
                                    />
                                </button>
                            </div>
                        </div>
      </div>
      <div style={{ paddingTop: 15 }}>
        <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleGenerateInvoive}>
          Generate Invoices
        </button>
      </div>
      <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          message={`Are you sure you want to delete ${taskInfoRef.current?.task_id} Task?`}
          onClose={handleCloseDeleteModal}
          onConfirm={() => {
            handleCloseDeleteModal(); // Close modal
            callDeleteTask(taskInfoRef.current); // Perform delete operation
          }}
        />
    </>
  );

};

const styles = {
  header: {
    fontSize: "18px",
    color: "#609966",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Roboto Slab'
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2'
  },
  showTasktyle: {
    color: '#5A55D2',
    fontSize: '12px',
    fontWeight: '400',
    textDecoration: 'underline',
    wordWrap: 'break-word',
    cursor:'pointer'
  }
}

export default ClientTaskListScreen;