import React, { useState, useRef, useEffect } from 'react';
import ClientListController from '../../../Controllers/AdminScreens/Clients/ClientListController';
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import filter from '../../../../Assets/Icons/filter.png';
import Dropdown from '../../../../Components/Dropdown';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../Components/Loader';
import leftBtuIcon from '../../../../Assets/Icons/back.png';
import TextInput from '../../../../Components/TextInput';
import search from '../../../../Assets/Icons/search.png';
import Input from '../../../../Components/Input';

const ClientListScreen = () => {
    const navigate = useNavigate();
    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
        loading,
        error, setError,
        clientTypeLoading,
        clientType,
        clientTypeError,
        groupName,
        groupNameError,
        groupNameLoading,
        seriveError,
        servicesSearchList,
        serviceLoading,
        employeeLoading,
        employeeError,
        employeeSearchList,
        setEmployeeSearchList,
        handleSearchEmployee,
        setSelectedEmployee,
        selectedEmployee,
        searchUser,
        setSearchUser,
        selectedGroupName,
        setSelectedGroupName,
        selectedClientType,
        setSelectedClientType,
        handleSelectedEmployee,
        setService,
        service,
        setGstin,
        gstin,
        setCity,
        city,
        clientName,
        setClientName,
        pendingTask,
        setPendingTask,
        handleSubmitClientSearch,
        setClientTypeError,
        setServiceError,
        setGroupNameError,
        setEmployeeError,
        fetchClientList,
        searchError, setSearchError,
        applyLoading,
        handleSearchInputText,
        showFilters,
        filters,
        resetFilters, cancelFilters,
        toggleFilters,
        visiblePages,
    } = ClientListController();
    //console.log('get client screen')

    // const [showFilters, setShowFilters] = useState(true); // Control the visibility of filters
    // const [filters, setFilters] = useState({
    //     clientName: '',
    //     clientType: '',
    //     gstin: '',
    //     city: '',
    //     assignedTo: '',
    //     groupName: '',
    //     pendingTask: '',
    // });

    // const handleFilterChange = (name, value) => {
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         [name]: value,
    //     }));
    // };


    // const resetFilters = () => {
    //     setCity('');
    //     setClientName('');
    //     setSelectedClientType(null);
    //     setSelectedEmployee(null);
    //     setSelectedGroupName(null);
    //     setGstin('');
    //     setCity('');
    //     setService(null);
    //     setSearchUser('')
    //     setPendingTask(null);
    //     // fetchClientList();
    //     // setClientTypeError('');
    //     // setServiceError('');
    //     // setGroupNameError('');
    //     setEmployeeError('')
    //     setError('');
    //     setSearchError('');
    // };


    // const toggleFilters = () => {
    //     // resetFilters();
    //     // fetchClientList();
    //     setShowFilters(!showFilters);
    //     toggleDropdown('null')
    //     setError('');
    //     setSearchError('');
    // };
    // const showFilter = () => {
    //     setShowFilters(!showFilters);
    //     toggleDropdown('null')
    // }

    const handleNav = (pageName, componentName, clintId) => {
        //console.log('page', pageName);
        navigate(`/${pageName}`, { state: { task: componentName, clientId: clintId } })

    }
    //   const [selectedClientType, setSelectedClientType] = useState(null);
    //   const [selectedGroupName, setSelectedGroupName] = useState(null);
    //   const [searchUser, setSearchUser] = useState('');
    //   const [selectedEmployee, setSelectedEmployee] = useState(null);

    //   const handleSelectedEmployee = (employee) => {
    //     setSelectedEmployee(employee); // Set selected client data
    //     setSearchUser(employee?.emp_name); // Update TextInput with the selected client's name
    //     setEmployeeSearchList([]); // Clear the dropdown
    //   };
    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div style={{ width: '93%', margin: 'auto' }}>
                <h3 style={styles.header}>
                    <div style={styles.headerContainer}>
                        <span>Clients</span>
                    </div>
                </h3>
            </div>
            <div className="tasksScreen-main-content">
                {/* Filters Section */}
                <div className="tasksScreen-tasks-container">
                    <div className="tasksScreen-tasks-header" style={{ paddingBottom: 10 }}>
                        <h2 className="tasksScreen-tasks-title">Search Clients</h2>
                    </div>
                    <div className="tasksScreen-filters-row" style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0px' }}>
                        <div className="tasksScreen-filters-button-wrapper">
                            <button style={styles.filterButton} onClick={toggleFilters}> <img src={filter} alt="Filter" style={{ marginRight: '8px' }} />  <span>
                                {showFilters ? 'Filters' : 'Filters'}
                            </span></button>
                        </div>
                        <div style={{ paddingRight: '12px' }}>
                            <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
                            <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
                        </div>
                    </div>

                    {showFilters && (
                        <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
                            <div className="tasksScreen-filters">
                                {/* Replace each filter select with Dropdown component */}

                                <div className="filter-field">
                                    <label>Client Name</label>
                                    <Input
                                        type="text"
                                        placeholder="Client Name"
                                        value={clientName}
                                        onChange={(e) => setClientName(e.target.value)}
                                    // right={<img src={search} alt="Search" className="search-icon" />}
                                    />
                                </div>

                                <Dropdown
                                    ref={(el) => (dropdownRefs.current["clientType"] = el)}
                                    isOpen={openDropdown === "clientType"}
                                    toggleDropdown={() => toggleDropdown("clientType")}
                                    label="Client Type"
                                    options={clientType} // Use the transformed dropdown options
                                    value={selectedClientType} // State for selected value
                                    onChange={setSelectedClientType} // Handler for value change
                                    errorMessage={clientTypeError}
                                    width={260}
                                    loading={clientTypeLoading}
                                />


                                <div className="filter-field">
                                    <label>GSTIN No</label>
                                    <Input
                                        type="text"
                                        placeholder="GSTIN No"
                                        value={gstin}
                                        onChange={(e) => setGstin(e.target.value)}
                                    // right={<img src={search} alt="Search" className="search-icon" />}
                                    />
                                </div>

                                <div className="filter-field">
                                    <label>City</label>
                                    <Input
                                        type="text"
                                        placeholder="City"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    // right={<img src={search} alt="Search" className="search-icon" />}
                                    />
                                </div>

                                <Dropdown
                                    ref={(el) => (dropdownRefs.current["pendingTask"] = el)}
                                    isOpen={openDropdown === "pendingTask"}
                                    toggleDropdown={() => toggleDropdown("pendingTask")}
                                    label="Pending Task"
                                    options={[
                                        { "value": 1, "label": "Not Started" },
                                        { "value": 2, "label": "In Progress" },
                                        { "value": 3, "label": "Completed" },
                                        { "value": 11, "label": "Reopen" },
                                        { "value": 12, "label": "Issue" },
                                        { "value": 13, "label": "Hold" }
                                    ]}
                                    value={pendingTask}
                                    onChange={setPendingTask}
                                />

                                <Dropdown
                                    ref={(el) => (dropdownRefs.current["groupName"] = el)}
                                    isOpen={openDropdown === "groupName"}
                                    toggleDropdown={() => toggleDropdown("groupName")}
                                    label="Group Name"
                                    options={groupName} // Use the transformed dropdown options
                                    value={selectedGroupName} // State for selected value
                                    onChange={setSelectedGroupName} // Handler for value change
                                    errorMessage={groupNameError}
                                    width={260}
                                    loading={groupNameLoading}
                                />

                                {/* <div className="filter-field">
                            <label>Assigned To</label>
                            <TextInput
                                type="text"
                                placeholder="Assigned To"
                                value={filters.assignedTo}
                                onChange={(e) => handleFilterChange('assignedTo',e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                            </div> */}
                                <div className="filter-field" >
                                    <label
                                        style={{
                                            ...styles.labaleStyle,
                                            // marginBottom: '8px',
                                            display: 'block',
                                        }}
                                    >
                                        Assigned To
                                    </label>
                                    <TextInput
                                        type="text"
                                        placeholder="Assigned To"
                                        value={searchUser}
                                        onChange={(e) => handleSearchInputText(e.target.value)}
                                        onRightClick={handleSearchEmployee}
                                        right={
                                            employeeLoading ? (
                                                <Loader loading={employeeLoading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                                            ) : (
                                                <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                                            )
                                        }
                                        style={{ padding: '20px 10px' }}
                                        errorMessage={employeeError}
                                    />
                                    {employeeSearchList?.length > 0 && (
                                        <ul style={styles.dropdownList}>
                                            {employeeSearchList?.map((employee, index) => (
                                                <li
                                                    key={employee?.emp_id}
                                                    onClick={() => handleSelectedEmployee(employee)}
                                                    style={{
                                                        ...styles.dropdownItem,
                                                        borderBottom: index !== employeeSearchList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                                    }}
                                                >
                                                    {employee?.emp_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                </div>

                                {/* <Dropdown
            ref={(el) => (dropdownRefs.current[""] = el)}
isOpen={openDropdown === " "}
toggleDropdown={() => toggleDropdown(" ")}
                                label="Services"
                                options={['Type 1', 'Type 2']}
                                value={filters.taskType}
                                onChange={(value) => handleFilterChange('taskType', value)}
                            /> */}
                                <Dropdown
                                    ref={(el) => (dropdownRefs.current["service"] = el)}
                                    isOpen={openDropdown === "service"}
                                    toggleDropdown={() => toggleDropdown("service")}
                                    label="Service"
                                    options={servicesSearchList || []}
                                    value={service}
                                    onChange={setService}
                                    width={260}
                                    loading={serviceLoading}
                                    errorMessage={seriveError}
                                />
                            </div>
                            <div>
                                {searchError && <div className="list-error-msg" style={{ textAlign: 'left', paddingBottom: 5 }}> {searchError}</div>}
                                <div className="tasksScreen-filter-buttons">
                                    <button onClick={handleSubmitClientSearch} className="tasksScreen-apply-button"
                                        disabled={applyLoading}
                                        style={{
                                            cursor: applyLoading ? "not-allowed" : "pointer",
                                            opacity: applyLoading ? 0.6 : 1, display: 'flex'
                                        }}> {applyLoading && <span style={{ paddingRight: 5 }}><Loader loading={applyLoading} color={'var(--background-color)'} size='small' /> </span>} Apply</button>
                                    <button onClick={cancelFilters} className="tasksScreen-cancel-button">Cancel</button>
                                    <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Table Section */}
                {tasks?.length > 0 &&
                    <div className="tasksScreen-tasks-container" style={{ marginTop: 20 }}>
                        <div className="tasksScreen-task-table-wrapper" >
                            <table className="tasksScreen-task-table">
                                <thead>
                                    <tr>
                                        <th>Client Name</th>
                                        <th>Client Type</th>
                                        <th>Services</th>
                                        <th>GSTIN No.</th>
                                        <th>City</th>
                                        <th>Group Name</th>
                                        <th>Assigned To</th>
                                        <th>Task Leader</th>
                                        <th>Pending Task</th>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: '#fff' }}>
                                    {tasks?.map((task, index) => (
                                        <React.Fragment key={index}>
                                            <tr
                                                className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}
                                            >
                                                <td style={{ color: '#5A55D2', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { handleNav('manage-clients', 'PrimaryInformation', task?.client_id) }}>{task?.client_name}</td>
                                                <td>{task?.client_type_name}</td>
                                                <td>{task?.combined_service_name || '--'}</td>
                                                <td>{task?.client_gstin || '--'}</td>
                                                <td>{task?.client_city || '--'}</td>
                                                <td>{task?.group_name || '--'}</td>
                                                <td>{task?.employee_id_assigned_name || '--'}</td>
                                                <td>{task?.emp_id_team_lead_name || '--'}</td>
                                                <td>
                                                    <span
                                                        className={`tasksScreen-status-badge ${task?.task_status_name?.toLowerCase().replace(' ', '-')}`}
                                                    >
                                                        {task?.task_status_name || '--'}
                                                    </span>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <div className="tasksScreen-table-footer" style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                            <span className="tasksScreen-pagination-info"
                                style={{
                                    whiteSpace: "nowrap",
                                    // overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}>
                                {`${currentPage}-${totalPages} of items`}
                            </span>
                            <div className="tasksScreen-pagination" style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', maxWidth: '100%' }}>
                                <button
                                    className="tasksScreen-pagination-button"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1 || totalPages === 0}
                                >
                                    <img
                                        src={downArrow}
                                        alt="Previous"
                                        className="tasksScreen-arrow-left tasksScreen-icon-image"
                                    />
                                </button>
                                {visiblePages.map((page) => (
                                    <button
                                        key={page}
                                        className={`tasksScreen-pagination-button ${currentPage === page ? "active" : ""}`}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </button>
                                ))}
                                <button
                                    className="tasksScreen-pagination-button"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages || totalPages === 0}
                                >
                                    <img
                                        src={downArrow}
                                        alt="Next"
                                        className="tasksScreen-arrow-right tasksScreen-icon-image"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {(loading || applyLoading) && <Loader loading={true} color={'var(--primary-color)'} />}
                {error && <div className="list-error-msg" style={{ marginTop: 20 }}>{error} </div>}
            </div>
        </>
    );
};
const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        //   fontFamily:'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    },
    dropdownList: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
        padding: 0, // Remove default padding
        margin: 0, // Remove default margin
        listStyleType: 'none', // Remove the dots
    },
    dropdownItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee', // Default for all items
        backgroundColor: '#fff',
        hover: {
            backgroundColor: '#f0f0f0',
        },
    },
    filterButton: {
        backgroundColor: "white",
        color: "#40513B",
        border: "none",
        borderRadius: "5px",
        padding: "7px 13px",
        cursor: "pointer",
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        border: '1px #40513B solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    },
    filterIcon: {
        width: '10px',
        height: '10px',
        paddingRight: '5px',
    },
};

export default ClientListScreen;