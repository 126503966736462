import React, { useState, useEffect, useRef } from 'react';
import CreateTaskController from '../../Controllers/AdminScreens/CreateTaskController';
import Input from '../../../Components/Input';
import Dropdown from '../../../Components/Dropdown';
import CheckBox from '../../../Components/CheckBox';
import uploadIcon from '../../../Assets/Icons/upload.png';
import search from '../../../Assets/Icons/search.png';
import TextInput from '../../../Components/TextInput';
import DateInput from '../../../Components/DateInput';
import Loader from '../../../Components/Loader';
import Textarea from '../../../Components/Textaera';
import PlusIcon from '../../../Assets/Icons/Pluse.png';
import CreateTaskDocumentModal from '../../../Components/Modals/CreateTaskDocumentModal';
// import { isDocument } from '@testing-library/user-event/dist/utils';

const CreateTaskScreen = () => {
  const { formData, fileName, handleFileChange, handleSubmit, handleChange,
    serviceList, categoryList, groupList, yearList, periodList, error, loading, billError, agreedBillError, clientNameError,
    serviceError, taskAssignedError,
    taskCategoryError,
    financialYearError,
    startDateError,
    dueDateError, financialPeriodError, handleSearchClient, searchUser,
    setSearchUser, setClientList, clientList1, setClientList1, handleSelectClient, selectedClient,
    setSelectedClient, clientLoading, categoryLoading,
    employeeList,
    empLoading,
    empError,
    groupLoading,
    yearLoading,
    serviceLoading, yearError, groupError, success,
    toDoList, toDoError, toDoLoading,
    infoLoading,
    isDocumentModalOpen,
    handleCloseDocumentModal,
    handleOpenDocumentModal,
    handleDocumentSubmit,
    clintInfo,
    documentData,
  } = CreateTaskController();
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };


  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className='title-container' style={{ paddingBottom: 10, maxWidth: '96%', marginLeft: 'auto' }}>
        <h2 className='title'>Tasks</h2>
      </div>
      <div className="create-task-container">
        <h2>Create Task</h2>
        <form onSubmit={handleSubmit}>
          {/* Task Information Section */}
          <div className="task-information">
            <h3 style={styles.heading}>Task Information</h3>
            <div className="basic-info-outer-card">
              <div className="task-fields">
                <div className="filter-field">
                  <label>Client Name</label>
                  <TextInput
                    type="text"
                    placeholder="Client Name"
                    value={searchUser}
                    onChange={(e) => setSearchUser(e.target.value)}
                    onRightClick={handleSearchClient}
                    right={
                      clientLoading ? (
                        <Loader loading={clientLoading} color={'var(--primary-color)'} size="small" /> // Show loader when loading
                      ) : (
                        <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                      )
                    }
                    style={{ padding: '20px 10px' }}
                    errorMessage={clientNameError}
                  />
                  {clientList1.length > 0 && (
                    <ul style={styles.dropdownList}>
                      {clientList1.map((client, index) => (
                        <li
                          key={client?.client_id}
                          onClick={() => handleSelectClient(client)}
                          style={{
                            ...styles.dropdownItem,
                            borderBottom: index !== clientList1.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                          }}
                        >
                          {client?.client_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>



                <Input
                  label="File No."
                  placeholder="Auto"
                  value={formData.fileNo}
                  onChange={(e) => handleChange('fileNo', e.target.value)}
                  isEdit={false}
                />



                {/* <Dropdown
isOpen={openDropdown === " "}
toggleDropdown={() => toggleDropdown(" ")}
              label="Group"
              options={groupList || []}
              value={formData?.group?.label}
              onChange={(value) => handleChange('group', value)}
              errorMessage={groupError}
              loading={groupLoading}
            /> */}



                <Dropdown
                  ref={(el) => (dropdownRefs.current["Services"] = el)}
                  isOpen={openDropdown === "Services"}
                  toggleDropdown={() => toggleDropdown("Services")}
                  label="Services"
                  options={serviceList || []}
                  value={formData.services?.label}
                  onChange={(value) => handleChange('services', value)}
                  errorMessage={serviceError}
                  loading={serviceLoading}
                />

                <Dropdown
                  ref={(el) => (dropdownRefs.current["Service Category"] = el)}
                  isOpen={openDropdown === "Service Category"}
                  toggleDropdown={() => toggleDropdown("Service Category")}
                  label="Service Category"
                  options={categoryList || []}
                  value={formData?.taskCategory?.label}
                  onChange={(value) => handleChange('taskCategory', value)}
                  errorMessage={taskCategoryError}
                  loading={categoryLoading}
                />
                <Input
                  label="Group"
                  placeholder="Auto"
                  value={formData?.group}
                  onChange={(e) => handleChange('group', e.target.value)}
                  isEdit={false}
                />

                <Dropdown
                  ref={(el) => (dropdownRefs.current["Financial Year"] = el)}
                  isOpen={openDropdown === "Financial Year"}
                  toggleDropdown={() => toggleDropdown("Financial Year")}
                  label="Financial Year"
                  options={yearList || []}
                  value={formData?.financialYear?.label}
                  onChange={(value) => handleChange('financialYear', value)}
                  errorMessage={financialYearError || yearError}
                  loading={yearLoading}
                />

                <Dropdown
                  ref={(el) => (dropdownRefs.current["Period"] = el)}
                  isOpen={openDropdown === "Period"}
                  toggleDropdown={() => toggleDropdown("Period")}
                  label="Period"
                  options={periodList || []}
                  value={formData?.period?.label}
                  onChange={(value) => handleChange('period', value)}
                  errorMessage={financialPeriodError || yearError}
                  loading={yearLoading}
                />

                {/* <Dropdown
isOpen={openDropdown === " "}
toggleDropdown={() => toggleDropdown(" ")}
                label="Task Assigned User"
                options={employeeList || []}
                value={formData?.primaryAssignedUser}
                onChange={(value) => handleChange('primaryAssignedUser', value)}
                errorMessage={taskAssignedError}
                loading={empLoading}

              />

              <Dropdown
isOpen={openDropdown === " "}
toggleDropdown={() => toggleDropdown(" ")}
                label="Task Leader"
                options={employeeList || []}
                value={formData?.taskLeader}
                onChange={(value) => handleChange('taskLeader', value)}
                loading={empLoading}
              />

              <Dropdown
isOpen={openDropdown === " "}
toggleDropdown={() => toggleDropdown(" ")}
                label="Task Manager"
                options={employeeList || []}
                value={formData?.taskManager}
                onChange={(value) => handleChange('taskManager', value)}
                loading={empLoading}
              /> */}



                {/* <Input
              label="Period"
              placeholder="Enter Period"
              value={formData.period}
              onChange={(e) => handleChange('period', e.target.value)}
            /> */}
                <div className="filter-field">
                  <label>Start Date</label>
                  <DateInput
                    value={formData.startDate || ""}
                    onChange={(e) => handleChange('startDate', e.target.value)}
                    errorMessage={startDateError}
                  />
                </div>
                <div className="filter-field">
                  <label>Due Date</label>
                  <DateInput
                    value={formData.dueDate || ""}
                    onChange={(e) => handleChange('dueDate', e.target.value)}
                    errorMessage={dueDateError}
                  />
                </div>



                <Dropdown
                  ref={(el) => (dropdownRefs.current["Task Priority"] = el)}
                  isOpen={openDropdown === "Task Priority"}
                  toggleDropdown={() => toggleDropdown("Task Priority")}
                  label="Task Priority"
                  options={[{ label: 'Not Priority', value: 0 }, { label: 'Priority', value: 1 }]}
                  value={formData.taskPriority.label}
                  onChange={(value) => handleChange('taskPriority', value)}
                />

                <Input
                  label="Estimated Invoice Amount"
                  inputType="number"
                  placeholder="Enter Bill Amount"
                  value={formData.billAmount}
                  onChange={(e) => handleChange('billAmount', e.target.value)}
                  errorMessage={billError}
                />

                <Input
                  label="Agreed Invoice Amount"
                  inputType="number"
                  placeholder="Enter Agreed Bill Amount"
                  value={formData.agreedBillAmount}
                  onChange={(e) => handleChange('agreedBillAmount', e.target.value)}
                  errorMessage={agreedBillError}
                />





                {/* <Dropdown
isOpen={openDropdown === " "}
toggleDropdown={() => toggleDropdown(" ")}
              label="Completed By"
              options={['User A', 'User B', 'User C']}
              value={formData.completedBy}
              onChange={(value) => handleChange('completedBy', value)}
            /> */}

                <Dropdown
                  ref={(el) => (dropdownRefs.current["To-Do List"] = el)}
                  isOpen={openDropdown === "To-Do List"}
                  toggleDropdown={() => toggleDropdown("To-Do List")}
                  label="To-Do List"
                  options={toDoList || []}
                  value={formData.toDoList}
                  onChange={(value) => handleChange('toDoList', value)}
                  errorMessage={toDoError}
                  loading={toDoLoading}
                  isMulti={true}
                />
                <Textarea
                  label="Description"
                  placeholder="Enter Task Description"
                  value={formData.taskDescription}
                  onChange={(e) => handleChange('taskDescription', e.target.value)}
                  // errorMessage={errorMessage}
                  maxLength={200}
                  width={520}
                  height={19}
                />


                <div>
                  {/* <label style={styles.checboxFlexSty}>
                <input
                  type="checkbox"
                  checked={formData.allowTimePosting || false}
                  onChange={(e) => handleChange('allowTimePosting', e.target.checked)}
                />
               <span style={{color:'#343C44',fontSize:12}}>Allow Time Posting even after Invoice Generation? </span> 
               
              </label> */}
                  {/* <CheckBox
                        label="Allow Time Posting even after Invoice Generation?"
                        checked={formData.allowTimePosting || false}
                        onChange={(isChecked) => handleChange('allowTimePosting',isChecked)}
                    /> */}
                </div>
              </div>
            </div>
          </div>

          {/* Upload Documents Section */}
          <div style={styles.uploadDocumentsContainer}>
            <h3 style={styles.heading}>Upload Documents</h3>
            <div style={styles.uploadBox} onClick={clintInfo.current?.client_id || selectedClient?.client_id ? handleOpenDocumentModal : null}>
              <div style={styles.uploadIconWrapper}>
                <span>
                  <img src={PlusIcon} alt="Plus" style={styles.tickIcon} />
                </span>
              </div>
              <p style={styles.uploadText}>
                {documentData?.finalDocumentName
                  ? documentData.finalDocumentName
                  : 'Add Document (Max size : 2MB, Document format supported: PDF, Excel, JPEGs, PNG, Docx)'}
              </p>

              <img src={uploadIcon} alt="Upload" style={styles.uploadIcon} />
              <input
                style={{ ...styles.hiddenFileInput, cursor: clintInfo.current?.client_id || selectedClient?.client_id ? 'pointer' : 'not-allowed' }}
                onChange={handleFileChange}
                accept=".pdf, .doc, .docx, .xlsx, .png, .jpg"
              />
              {fileName && <p style={styles.fileName}>{fileName}</p>}
            </div>
          </div>

          {error && <div className="list-error-msg">{error}</div>}
          {success && <div className="list-success-msg">{success}</div>}

          {/* Buttons */}
          <div className="button-container">
            <button type="submit" className="add-client-button" style={{ opacity: loading || infoLoading ? 0.5 : 1, cursor: loading || infoLoading ? 'not-allowed' : 'pointer', }} disabled={loading || infoLoading}>
              <span style={{ paddingRight: loading || infoLoading ? 10 : 0 }}><Loader loading={loading || infoLoading} color={'#FFFFFF'} size='small' /></span>
              Create Task
            </button>
            {/* <button className="add-client-button" onClick={handleAddClient} disabled={loading}><span style={{paddingRight:loading?10:0}}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Add Client</button> */}
            <button type="button" style={styles.cancelButton}>
              Cancel
            </button>
          </div>
        </form>
      </div>
      {isDocumentModalOpen &&
        <CreateTaskDocumentModal isOpen={isDocumentModalOpen} onClose={handleCloseDocumentModal} info={searchUser} onSubmit={handleDocumentSubmit} />}
    </div>

  );
};

const styles = {
  uploadDocumentsContainer: {
    width: "97%",
    // padding: "20px",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Roboto Slab",
    color: "#609966",
    marginBottom: "10px",
  },
  uploadBox: {
    width: "100%",
    height: "150px",
    border: "1px dashed #609966",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    backgroundColor: "#ffffff",
  },
  uploadIconWrapper: {
    width: "80px",
    height: "80px",
    backgroundColor: "#f0f0f0",
    borderRadius: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  plusIcon: {
    fontSize: "20px",
    color: "#343C44",
    fontWeight: "500",
  },
  uploadText: {
    fontSize: "14px",
    color: "#343C44",
    fontFamily: "Roboto",
    fontWeight: "400",
    marginTop: "10px",
  },
  uploadIcon: {
    position: "absolute",
    top: "15px",
    right: "15px",
    width: "20px",
    height: "20px",
  },
  hiddenFileInput: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#2b542c",
    border: "1px solid #2b542c",
    borderRadius: "25px",
    padding: "8px 25px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Roboto",
    marginLeft: '10px'
  },
  checboxFlexSty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee',
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  tickIcon: {
    width: "20px",
    height: "20px",
    filter: 'brightness(0)',
  },
};

export default CreateTaskScreen;


