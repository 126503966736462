import { useState } from 'react';

const InvoiceOtherSettingsController = () => {
    const [booksOpeningBalance, setBooksOpeningBalance] = useState("0.00");
    const [overdueSetting, setOverdueSetting] = useState("10 Days");

    return {
        booksOpeningBalance,
        setBooksOpeningBalance,
        overdueSetting,
        setOverdueSetting,
    };
};

export default InvoiceOtherSettingsController;
