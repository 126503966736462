import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ORG_PREP_RECEIPT, ORG_GEN_RECEIPT, ORG_GET_EMPLOYEE_LIST, ORG_PRINT_RECEIPT } from '../../../../ApiServices/BaseURL';
import { postData, getData } from "../../../../ApiServices/ApiService";
const GenerateEditReceiptController = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [sendUpdateToClient, setSendUpdateToClient] = useState(false);
    const [sendUpdateToTeam, setSendUpdateToTeam] = useState(false);
    const [generateReceiptLoading, setGenerateReceiptLoading] = useState(false);
    const [generateReceiptError, setGenerateReceiptError] = useState('');
    const [generateReceiptSuccess, setGenerateReceiptSuccess] = useState('');
    const [printReceiptLoading, setPrintReceiptLoading] = useState(false);
    const [printReceiptError, setPrintReceiptError] = useState('');
    const [printReceiptSuccess, setPrintReceiptSuccess] = useState('');
    const [paymentDetails, setPaymentDetails] = useState({
        paymentMode: null,
        collectedBy: null,
        followUpDate: "11/11/2024",
        receiptBook: "Username",
    });

    const [prepReceiptData, setPrepReceiptData] = useState(location?.state?.prepReceiptData || {});
    const [clientInvoiceInfo, setClientInvoiceInfo] = useState({
        client_id: null,
        client_name: '',
        client_type: '',
        client_type_id: null,
        invoice_date: '',
        invoice_id: 0,
        invoice_id_gen: '',
        combo_inv: '',
        is_group_invoice: '',
        tast_status: '',
        inv_gen_by: '',
        client_id_primary: 0,
        group_id: 0,
        group_master: '',
        invoice_amount: 0,
        amount_received: 0,
        advance_paid_amount: 0,
        balance_due_amount: 0,
        receipt_id: 0

    })

    const [fieldConfigs, setFieldConfigs] = useState([
        { label: "Client Name", key: "client_name", type: "text", error: "" },
        { label: "Client Type", key: "client_type", type: "text", error: "" },
        { label: "Date of Invoice", key: "invoice_date", type: "text", error: "" },
        { label: "Invoice Number", key: "invoice_id_gen", type: "text", error: "" },
        { label: "Combined Invoice", key: "combo_inv", type: "text", error: "" },
        { label: "Group Invoice", key: "is_group_invoice", type: "text", error: "" },
        { label: "Task Status", key: "tast_status", type: "text", error: "" }, ,
        { label: "Invoice Generated By", key: "inv_gen_by", type: "text", error: "" },
    ])

    const [employeeList, setEmployeeList] = useState([]);
    const [empLoading, setEmpLoading] = useState(false);
    const [empError, setEmpError] = useState('');

    const [paymentList, setPaymentList] = useState([
        { label: "Cash", value: 1 },
        { label: "UPI", value: 2 },
        { label: "Card", value: 3 }
    ])
    const [paymentModeError, setPaymentModeError] = useState('');
    const [amountReceivedErrMsg, setAmountReceivedErrMsg] = useState('');
    const [advancePaymentErrMsg, setAdvancePaymentErrMsg] = useState('');
    const [isGenReceiptModalOpen, setIsGenReceiptModalOpen] = useState(false);

    useEffect(() => {
        if (location?.state?.prepReceiptData) {
            console.log('prepReceiptData', location?.state?.prepReceiptData)
            setPrepReceiptData(location.state.prepReceiptData);
            fetchClientInvoiceInfo();
        }
        fetchUserList();
    }, [location?.state?.prepReceiptData])

    const handlePaymentDetailsChange = (field, value) => {
        console.log('handlePaymentDetailsChange :', field, 'value', value)
        setPaymentModeError('');
        setEmpError('');
        setPaymentDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };

    const handleInputChange = (field, value) => {
        console.log('handleInputChange field', field, 'value', value)
        // setClientInvoiceInfo((prevDetails) => ({
        //     ...prevDetails,
        //     [field]: value,
        // }));
        setClientInvoiceInfo((prevDetails) => {

            const updatedDetails = {
                ...prevDetails,
                [field]: value,
            };

            if (field === "amount_received" && (Number(value) <= Number(prevDetails?.invoice_amount))) {
                updatedDetails.balance_due_amount = Number(prevDetails?.invoice_amount) - Number(value);
            }
            if (field === "amount_received" && (Number(value) > Number(prevDetails?.invoice_amount))) {
                updatedDetails.balance_due_amount = '--';
            }
    
            return updatedDetails;
        });
        // console.log('clientInvoiceinfo :', clientInvoiceInfo)
        setAmountReceivedErrMsg('');
        setAdvancePaymentErrMsg('');
    };

    const fetchClientInvoiceInfo = async () => {
        try {
            setError('');
            setLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "inv_number": prepReceiptData?.searchInvoice || '',
                "adv_payment": prepReceiptData?.advancePayment ? 1 : 0,
                "client_id": prepReceiptData?.clientId || 0
            }
            console.log('handleSearchClient generate receipt', payLoad);
            const response = await postData(ORG_PREP_RECEIPT, payLoad);
            const responseData = await response.json();
            console.log('response Data', responseData);
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            const invoiceData = responseData.dataJ[0];
                            setClientInvoiceInfo({
                                ...invoiceData, // Spread all the properties from the response data
                                combo_inv: invoiceData?.combo_inv === 1 ? "Yes" : invoiceData?.combo_inv === 0 ? "No" : invoiceData?.combo_inv, // Conditional check
                                is_group_invoice: invoiceData?.is_group_invoice === 1 ? "Yes" : invoiceData?.is_group_invoice === 0 ? "No" : invoiceData?.is_group_invoice
                            });
                        } else {
                            setError(responseData?.info);
                        }
                    } else {
                        setError(responseData?.info || 'Failed to fetch invoice info. Please try again.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch invoice info.');
                }
            } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setError('Unauthorized access. Your session may have expired. Please log in again.');
                } else {
                    setError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            } else {
                setError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            if (error.response) {
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch invoice info. Please try again.'}`);
            } else if (error.request) {
                setError('No response received from the server. Please check your network connection and try again.');
            } else {
                setError(`Failed to fetch invoice info. Please check your network connection and try again.`);
            }
        } finally {
            setLoading(false);
        }
    }

    const fetchUserList = async () => {
        try {
            setEmpLoading(true);
            setEmpError('');
            setEmployeeList([]);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service?.emp_name,
                                value: service?.emp_id
                            }));
                            setEmployeeList(filterData);
                        } else {
                            setEmpError(responseData?.info || 'No user available.');
                        }
                    } else {
                        setEmpError(responseData?.info || 'Failed to fetch user data. Please try again.');
                    }
                } else {
                    setEmpError(responseData?.info || 'Failed to fetch User data.');
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setEmpError('Unauthorized access. Your session may have expired. Please log in again.');
                } else {
                    setEmpError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            } else {
                setEmpError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            if (error.response) {
                setEmpError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch user data. Please try again.'}`);
            } else if (error.request) {
                setEmpError('No response received from the server. Please check your network connection and try again.');
            } else {
                setEmpError(`Failed to fetch user data. Please check your network connection and try again.`);
            }
        } finally {
            setEmpLoading(false);
        }
    }

    const handleGenReceiptConfirmModal = () => {
        setIsGenReceiptModalOpen(true);
    }

    const handleCloseGenReceiptModal = () => {
        setIsGenReceiptModalOpen(false);
    };

    const handleValidateFieldsReceipt = async () => {
        console.log('amount_received ', clientInvoiceInfo?.amount_received, 'advance_paid_amount :', clientInvoiceInfo?.advance_paid_amount)
        setGenerateReceiptError('');
        setGenerateReceiptSuccess('');
        setPaymentModeError('');
        setEmpError('');
        if (!paymentDetails?.paymentMode?.value) {
            setPaymentModeError('Mode of payment is required.'); return;
        }
        if (!paymentDetails?.collectedBy?.value) {
            setEmpError('Collected by is required.'); return;
        }
        if (prepReceiptData?.advancePayment) {
            if (!clientInvoiceInfo?.advance_paid_amount) {
                setAdvancePaymentErrMsg('Advance payment is required.'); return; 
            }
            if (Number(clientInvoiceInfo?.advance_paid_amount) <= 0) {
                setAdvancePaymentErrMsg('Enter valid advance payment.'); return;
            }
            handleGenReceiptConfirmModal();
        } else {
            if (!clientInvoiceInfo?.amount_received) {
                setAmountReceivedErrMsg('Amount received is required.'); return;
            }
            if (Number(clientInvoiceInfo?.amount_received) <= 0) {
                setAmountReceivedErrMsg('Enter valid amount received.'); return;
            }
            if (Number(clientInvoiceInfo?.amount_received) > Number(clientInvoiceInfo?.invoice_amount)) {
                setAmountReceivedErrMsg('Amount entered exceeds invoice; Add excess amount to advance.'); return;
            }
            handleGenReceiptConfirmModal();
        }
        // if (prepReceiptData?.advancePayment && !clientInvoiceInfo?.advance_paid_amount) {
        //     setAdvancePaymentErrMsg('Advance payment is required.'); return;
        // }
        // if (prepReceiptData?.advancePayment && Number(clientInvoiceInfo?.advance_paid_amount) <= 0) {
        //     setAdvancePaymentErrMsg('Enter valid advance payment.'); return;
        // }
        // if (!prepReceiptData?.advancePayment && !clientInvoiceInfo?.amount_received) {
        //     setAmountReceivedErrMsg('Amount received is required.'); return;
        // }
        // if (!prepReceiptData?.advancePayment && Number(clientInvoiceInfo?.amount_received) <= 0) {
        //     setAmountReceivedErrMsg('Enter valid amount received.'); return;
        // }
        // handleGenReceiptConfirmModal();
    }

    const handleGenerateReceipt = async () => {
        try {
            setGenerateReceiptLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "invoice_id": clientInvoiceInfo?.invoice_id || 0,
                "client_id": clientInvoiceInfo?.client_id || 0,
                // "client_type_id": clientInvoiceInfo?.client_type_id || 0,
                "mode_of_payment": paymentDetails?.paymentMode?.value || 0,
                "receipt_gen_emp_id": paymentDetails?.collectedBy?.value || 0,
                "send_update_client": sendUpdateToClient ? 1 : 0,
                "send_update_team": sendUpdateToTeam ? 1 : 0,
                "client_id_primary": clientInvoiceInfo?.client_id_primary || 0,
                "group_id": clientInvoiceInfo?.group_id || 0,
                "invoice_amount": clientInvoiceInfo?.invoice_amount || 0,
                "received_amount": Number(clientInvoiceInfo?.amount_received) || 0,
                // "amount_received_date": '',
                "advance_paid_amount": Number(clientInvoiceInfo?.advance_paid_amount) || 0,
                "advance_pay_flag": prepReceiptData?.advancePayment ? 1 : 0,
                "balance_due_amount": clientInvoiceInfo?.balance_due_amount || 0
            }
            console.log('handleGenerateReceipt payload', payLoad);
            const response = await postData(ORG_GEN_RECEIPT, payLoad);
            const responseData = await response.json();
            console.log('handleGenerateReceipt response Data', responseData);
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ.length > 0) {
                            setGenerateReceiptSuccess(responseData?.info);
                            handleInputChange('receipt_id', responseData?.dataJ[0]?.receipt_id);
                        } else {
                            setGenerateReceiptError(responseData?.info || 'No receipt generated.');
                        }
                    } else {
                        setGenerateReceiptError(responseData?.info || 'Failed to generate receipt. Please try again.');
                    }
                } else {
                    setGenerateReceiptError(responseData?.info || 'Failed to generate receipt');
                }
            } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGenerateReceiptError('Unauthorized access. Your session may have expired. Please log in again.');
                } else {
                    setGenerateReceiptError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            } else {
                setGenerateReceiptError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            if (error.response) {
                setGenerateReceiptError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to generate receipt. Please try again.'}`);
            } else if (error.request) {
                setGenerateReceiptError('No response received from the server. Please check your network connection and try again.');
            } else {
                setGenerateReceiptError(`Failed to generate receipt. Please check your network connection and try again.`);
            }
        } finally {
            setGenerateReceiptLoading(false);
        }
    }

    const handlePrintReceipt = async () => {
        try {
            console.log('handlePrintReceipt clientInvoiceinfo :', clientInvoiceInfo)
            setGenerateReceiptSuccess('');
            setGenerateReceiptError('');
            if (!clientInvoiceInfo?.receipt_id) {
                setGenerateReceiptError('No receipt available to print.'); return;
            }
            setPrintReceiptLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_PRINT_RECEIPT}?emp_id_auth=${storedUserInfo?.emp_id}&receipt_id=${clientInvoiceInfo?.receipt_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            const downloadUrl = responseData?.dataJ?.[0]?.download_url;
                            if (downloadUrl) {
                                try {
                                    // Fetch the file as a blob
                                    const fileResponse = await fetch(downloadUrl);
                                    const blob = await fileResponse.blob();

                                    const url = URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));

                                    let iframe = document.createElement("iframe");
                                    iframe.style.position = "absolute";
                                    iframe.style.width = "0px";
                                    iframe.style.height = "0px";
                                    iframe.style.border = "none";
                                    document.body.appendChild(iframe);

                                    // Load the PDF into the iframe and trigger print
                                    iframe.src = url;
                                    iframe.onload = () => {
                                        iframe.contentWindow.focus();
                                        iframe.contentWindow.print();
                                    };

                                } catch (error) {
                                    console.log('Error downloading file:', error);
                                    setGenerateReceiptError('An error occurred while attempting to download the receipt. Please try again.');
                                }
                            } else {
                                setGenerateReceiptError('Download URL not available.');
                            }
                        } else {
                            setGenerateReceiptError(responseData?.info || 'No receipt available to print.');
                        }
                    } else {
                        console.log(responseData?.info || 'Failed to fetch document. Please try again.');
                        setGenerateReceiptError(responseData?.info || 'Failed to fetch receipt. Please try again.');
                    }
                } else {
                    setGenerateReceiptError(responseData?.info || 'Failed to fetch receipt. Status code error.');
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGenerateReceiptError('Unauthorized access. Your session may have expired. Please log in again.');
                } else {
                    setGenerateReceiptError('Bad request. Please check the request parameters.');
                }
            } else {
                setGenerateReceiptError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            if (error.response) {
                setGenerateReceiptError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch document. Please try again.'}`);
            } else if (error.request) {
                setGenerateReceiptError('No response received from the server. Please check your network connection and try again.');
            } else {
                setGenerateReceiptError('Failed to fetch receipt. Please check your network connection and try again.');
            }
        } finally {
            setPrintReceiptLoading(false);
        }
    }

    const handleCancel = () => {
        // navigate(-1);
        navigate('/bills-receipts', { state: { selectedTab: 2 } });
    }

    return {
        loading, error,
        clientInvoiceInfo, prepReceiptData,
        fieldConfigs,
        handleValidateFieldsReceipt, handleGenerateReceipt, handlePrintReceipt,
        generateReceiptLoading, generateReceiptError, generateReceiptSuccess,
        printReceiptLoading, printReceiptError, printReceiptSuccess,
        employeeList, empLoading, empError,
        paymentList, paymentModeError,
        paymentDetails,
        handlePaymentDetailsChange,
        handleInputChange,
        sendUpdateToClient, setSendUpdateToClient,
        sendUpdateToTeam, setSendUpdateToTeam,
        amountReceivedErrMsg, advancePaymentErrMsg,
        handleCancel,
        isGenReceiptModalOpen, handleCloseGenReceiptModal, handleGenReceiptConfirmModal
    }

}

export default GenerateEditReceiptController