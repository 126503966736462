import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import profileImg from '../Assets/Images/temp-img.png';
import dashboardIcon from '../Assets/Icons/dashboard.png';
import tasksIcon from '../Assets/Icons/check-list.png';
import billingIcon from '../Assets/Icons/layer.png';
import settingIcon from '../Assets/Icons/setting-2.png';
import logoutIcon from '../Assets/Icons/user.png';
import userIcon from '../Assets/Icons/user-s.png';
import logo from '../Assets/Images/sideMenuLogo.png';
import menuIcon from '../Assets/Icons/chevron-down-arrow.png';
import downArrowIcon from '../Assets/Icons/chevron-down.png';
import pluseIcon from '../Assets/Icons/pluse-s.png';
import cardIcon from '../Assets/Icons/qns-mark.png';
import DocumentIcon from '../Assets/Icons/crose-arrow.png';
import setting2Icon from '../Assets/Icons/setting-s.png';
import searchIcon from '../Assets/Icons/search.png';
import { postData,postDataLogin } from '../ApiServices/ApiService';
import { REFRESH_TOKEN } from '../ApiServices/BaseURL';
import sideMenu from '../Assets/Icons/side-menu.png';
import { forwardRef } from 'react';

const Sidebar = forwardRef(({ onValueChange, isOverlay, toggleOverlay }, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuShow,setIsMenuShow] = useState(false)
  const [userInfo, setUserInfo] = useState({});
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [activeSubDropdown, setActiveSubDropdown] = useState(null);
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  
  useEffect(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    setUserInfo(storedUserInfo);
  }, []);

  useEffect(()=>{
    if(screenSize.width <= 958){
      //console.log('window.innerWidth:-----',screenSize)

      setIsMenuShow(true)
    }

  },[screenSize])

  const handleNav = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    localStorage.removeItem('userRole');
    localStorage.removeItem('_userInfo_');
    // localStorage.clear();
    navigate('/login');
  };

  const isActive = (path) =>{
    // //console.log('location.pathname',location.pathname,'path:-',path)
    return location.pathname === path
  } ;



  const navigationItems = [
    {
      title: "Dashboard",
      icon: dashboardIcon,
      path: '/dashboard',
      items:[]
    },
    {
      title: "Services",
      icon: tasksIcon,
      items: [
        { subtitle: "Service Dashboard", path: "/services/gst-services" },
      
        { subtitle: "Settings", path: "/settings" , subItems:[
          { subtitle: "Client List", path: "/clientlist" },
          { subtitle: "Group Master", path: "/services/manage-group" },
          { subtitle: "Manage Services", path: "/task-management-list" },
          { subtitle: "Others", path: "/services/other-settings" },
        ]},
      ]
    },
    {
      title: "Invoices",
      icon: billingIcon,
      items: [
        { subtitle: "Bills/Receipt/Estimate", path: "/bills-receipts" },
        { subtitle: "Accounts", path: "/invoice/accounts" },
        { subtitle: "Reports", path: "/invoice/reports" },
        { subtitle: "Settings", path: "/invoice/settings" }
      ]
    },
    {
      title: "Progress Card",
      icon: cardIcon,
      items: [
        { subtitle: "Reports", path: "/progress-reports" },
        // { subtitle: "Settings", path: "/settings" }
      ]
    },
    {
      title: "Employee Mgmt.",
      icon: userIcon,
      items: [
        { subtitle: "Add/Edit Employee", path: "/teams-userlist" },
        { subtitle: "Settings", path: "/settings" }
      ]
    },
    {
      title: "Add On’s",
      icon: pluseIcon,
      items: [
        { subtitle: "Notice Board", path: "/notice-board" },
        { subtitle: "DSC Center", path: "/dsc-center" },
        { subtitle: "Income Tax Portal", path: "/income-tax-portal" },
        // { subtitle: "Settings", path: "/settings" }
      ]
    },
    {
      title: "Configuration",
      icon: settingIcon,
      items: [
        { subtitle: "Configuration Center", path: "/configuration/config-center" },
        { subtitle: "Activity Register", path: "/configuration/activity-register" },
        { subtitle: "Reminder Register", path: "/configuration/reminder-register" },
        { subtitle: "Template Center", path: "/configuration/template-center" },
        { subtitle: "Contact Center", path: "/configuration/contact-center" },
        // { subtitle: "Settings", path: "/settings" }
      ]
    },
    {
      title: "Document Rack",
      icon: DocumentIcon,
      items: [
        { subtitle: "Documents", path: "/documents" },
        // { subtitle: "Settings", path: "/settings" }
      ]
    },
    // {
    //   title: "Settings *",
    //   icon: setting2Icon,
    //   path: '/admin-settings',
    //   items:[]
    // },
    {
      title: "Billing & Payments *",
      icon: billingIcon,
      path: '/billing-payment-list',
      items:[]
    },
  ];
  


  const handleMenuShowHide =()=>{
    //console.log('sgokhh')
    setIsMenuShow(!isMenuShow);
    onValueChange(!isMenuShow)
  }
  const [activeDropdown, setActiveDropdown] = useState(null);
  const handleDropdownToggle = (title) => {
    setActiveDropdown(activeDropdown === title ? null : title);
  };
  const handleNavigation = (path) => {
    // setActiveDropdown(null)
    navigate(path); // Navigate to the given path
    if(isMenuShow){
      setActiveDropdown(null)
    }
    if (isOverlay) {
      toggleOverlay();
    }
    handleToCheckSessionExpired()
  };

  const handleToCheckSessionExpired =()=>{
    const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    // console.log('storedUserInfo:-',storedUserInfo)
    isTokenExpired(storedUserInfo?.emp_tkn_id,storedUserInfo?.emp_role)

  }

  const handleSubDropdownToggle = (title) => {
    setActiveSubDropdown(activeSubDropdown === title ? null : title);
  };

  const isTokenExpired = async (token, user_role) => {
    // console.log("Token and User Role:", token, user_role);
  
    try {
      if (!token) {
        console.error("No token provided.");
        return false; // No token means it's effectively expired
      }
  
      console.log("Decoding token...");
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      const decodedToken = JSON.parse(jsonPayload);
  
      // console.log("Decoded Token:", decodedToken);
  
      // Get current time in seconds
      const currentTime = Math.floor(Date.now() / 1000);
      const expTime = decodedToken.exp;
  
      if (!expTime) {
        console.error("Token does not contain an 'exp' field.");
        return false; // Invalid token structure
      }
  
      console.log(`Current Time: ${new Date(currentTime * 1000).toString()}`);
      console.log(`Token Expiration Time: ${new Date(expTime * 1000).toString()}`);
  
      if (currentTime >= expTime) {
        console.log("Token has expired.");
        await callRefreshToken(); // Attempt to refresh the token
        return true;
      } else {
        console.log("Token is still valid.");
        const refreshTime = expTime - 60 * 60; // 1 hour before expiration
        const delay = (refreshTime - currentTime) * 1000;
  
        if (delay > 0) {
          console.log(`Token refresh scheduled in ${delay / 1000} seconds.`);
          // You can add a timer to refresh the token proactively
          setTimeout(() => callRefreshToken(), delay);
        }
        return false;
      }
    } catch (error) {
      console.error("Error while checking token expiration:", error);
      return false; // Assume expired if an error occurs
    }
  };
  
  

  const callRefreshToken = async () => {
    try {
      console.log('callRefreshToken : ');
      const userDetails = JSON.parse(localStorage.getItem('_userInfo_'));
      console.log('userDetails : ', userDetails)
      const payload = {
        "user_id": userDetails?.emp_id,
        "rt": userDetails?.emp_tkn_rf
      }
      console.log('payload', payload);
      // handleLogout();
      return

      const responseData = await postDataLogin(REFRESH_TOKEN, payload);
      if (!responseData) {
        throw new Error('Login failed');
      }
      const response = await responseData?.data;
      console.log('responseData', response);
      // setLoading(false)
      if (response?.statusCode === 200) {
        if (response?.message.toLowerCase() === 'success') {
          if (response?.dataJ.length > 0) {
            userDetails[0].emp_tkn_id = response?.dataJ[0].emp_tkn_id;
            console.log("userDetails[0].it", userDetails[0].emp_tkn_id)
            localStorage.setItem('_userInfo_', userDetails);
            const parsedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            console.log("parsedUserInfo call refresh", parsedUserInfo)
          
            
          } else {
            console.log('callRefreshToken tokenlen ', response?.info);
          }
        } else {
          console.log('callRefreshToken fail ', response?.info);
        }
      } else {
        console.log('callRefreshToken catch ', response);
      }
    } catch (e) {
      console.log('callRefreshToken catch ', e);
    }
  }


  return (
      <div className={`sidebar ${isOverlay ? 'overlay' : ''}`}
      ref={ref}
      style={{
        width: isOverlay ? '250px' : isMenuShow ? '50px' : '18%',
        position: isOverlay ? 'fixed' : 'static',
        zIndex: isOverlay ? 9999 : 'auto',
        transition: 'transform 0.3s ease',
        transform: isOverlay ? 'translateX(0)' : '',
      }}>
      
          {/* Company Info */}
      <div style={{display:'flex',alignItems:'center',width:'100%',paddingTop:40}}>
      <div className="companyInfo-sty">
        <img className="smallLogo-Sty" src={userInfo?.logo_url || logo} alt="Company Logo"/>
        <h3 className="companyname-Sty" style={{display:isMenuShow ? 'none':'block'}}>{userInfo?.emp_company_name}</h3>
      </div>
      <img onClick={handleMenuShowHide} className="menu-icon-sty" src={menuIcon} alt="Company Logo" style={{ display: screenSize.width <= 958 ? 'none' : 'block', transform: isMenuShow ? 'rotate(0deg)' : 'rotate(180deg)', cursor: 'pointer' }} />
     
      </div>
      <div className='display-full-height'>

      {/* Profile Section */}
      <div className="sidebar-profile" style={{paddingTop:30}}>
        <img src={profileImg} alt="Profile" className={isMenuShow ? "profile-image-min":"profile-image"} />
        <h3 className='user-name-sidebar' style={{ display: isMenuShow ? 'none' : 'block' }}>{userInfo?.emp_name}</h3>
          <p style={{ color: '#9DC08B', fontSize: '14px', marginTop: '6px', display: isMenuShow ? 'none' : 'block' }}>{userInfo?.emp_role}</p>
      </div>

      {/* Dropdown section*/}
      <div className={isMenuShow && activeDropdown ? "sidebar-menu-active sidebar-menu":"sidebar-menu"} style={{paddingTop:20}}>
      {!isMenuShow &&(
      <div style={{...styles.searchBox}}>
        <img src={searchIcon} alt="Search Icon" style={styles.searchIcon} />
        <input
          type="text"
          style={styles.searchInput}
          placeholder="Search Items..."
        />
      </div>
      )}
      {navigationItems.map((navItem) => (
        <div key={navItem.title} className="dropdown">
          <button
            className={`sidebar-menu-li ${isActive(navItem.path) && navItem?.items?.length > 0  ? 'active' : ''}`}            
            onClick={() =>
              navItem?.items?.length
                ? handleDropdownToggle(navItem?.title)
                : (handleNavigation(navItem?.path),setActiveDropdown(null))
            }
          >            
            <img
              src={navItem?.icon}
              alt={navItem?.title}
              className={`sidebar-icon ${isActive(navItem?.path) ? 'sidebar-icon-white' : 'icon-color-sidebar'}`}
            />
            <span className={`dropdown-title ${isActive(navItem?.path) ? 'sidebar-icon-white' : ''}`} style={{ display: isMenuShow && !activeDropdown && !isOverlay ? 'none' : 'block' }}>{navItem?.title}</span>
            <span className="dropdown-arrow">  
            {navItem?.items?.length > 0 && (            
              <img className="down-icon-sty" src={downArrowIcon} alt="Company Logo" style={{transform: activeDropdown === navItem.title ? 'rotate(180deg)':'rotate(0deg)',display:isMenuShow && !activeDropdown ? 'none':'block'}}/>
            )}
            </span>
          </button>
          {/* {activeDropdown === navItem.title && navItem.items.length > 0 && (
            <div className="dropdown-content" style={{position: isMenuShow ?'absolute':'',left:35}}>
              {navItem.items.map((item, index) => (
                <div
                  key={index}
                  className={`dropdown-link ${isActive(item?.path) ? 'sub-active' : 'sub-inactive'}`}
                  style={{paddingLeft: isMenuShow ? 10: 30,cursor:'pointer'}}
                  onClick={() => handleNavigation(item.path)}
                >
                  {item.subtitle}
                </div>
              ))}
            </div>
          )} */}
           {/* Sub-Items */}
           {activeDropdown === navItem.title &&
              navItem.items.map((item, idx) => (
                <div key={idx}>
                  <div
                    className={`dropdown-link ${
                      isActive(item.path) ? "sub-active" : "sub-inactive"
                    }`}
                    style={{ paddingLeft: 30, cursor: "pointer",alignItems:'center',display:'flex',justifyContent:'space-between',lineBreak:'anywhere' }}
                    onClick={() =>
                      item.subItems
                        ? handleSubDropdownToggle(item.subtitle)
                        : handleNavigation(item.path)
                    }
                  >
                   <span>{item.subtitle}</span>
                    {item?.subItems?.length > 0 && (            
                      <img className="down-icon-sty" src={downArrowIcon} alt="Company Logo" style={{transform: activeSubDropdown === item.subtitle ? 'rotate(180deg)':'rotate(0deg)'}}/>
                    )}
                  </div>

                  {activeSubDropdown === item.subtitle &&
                    item.subItems?.map((subItem, subIdx) => (
                      <div
                        key={subIdx}
                        className={`dropdown-link-sub ${
                          isActive(subItem.path) ? "sub-active" : "sub-inactive"
                        }`}
                        style={{
                          paddingLeft: 30,
                          color: isActive(subItem.path) ? "white" : "",
                          cursor: "pointer",
                        }}
                        onClick={() => handleNavigation(subItem.path)}
                      >
                        {subItem.subtitle}
                      </div>
                    ))}
                </div>
              ))}

        </div>
      ))}

         {/* Logout Section */}
         <div className="sidebar-logout" style={{padding:isMenuShow ? '20px 0px':'15px'}}>
        <button onClick={handleLogout} className="center-alin-sty" style={{justifyContent:isMenuShow ? 'center':''}}>
          <img src={logoutIcon} alt="Logout" className="logout-icon" />
          <span style={{ display: isMenuShow && !isOverlay ? 'none' : 'block', paddingLeft: 10 }}>Log out</span>
        </button>
      </div>
    </div>   
      </div>

    </div>
  );
});

export default Sidebar;

const styles = {
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#2C3829',
    borderRadius: '30px',
    padding: '8px 20px',
    // width: '70%',
    margin:'auto'
  },
  searchIcon: {
    width: '15px', 
    height: '15px',
    marginRight: '10px', // Space between the icon and input field
    filter: 'invert(63%) sepia(11%) saturate(384%) hue-rotate(163deg) brightness(90%) contrast(85%)',
  },
  searchInput: {
    border: 'none',
    background: 'none',
    outline: 'none',
    color: '#627B87',
    fontSize: '10px',
    width: '80%',
    fontStyle: 'italic',
  },
};
