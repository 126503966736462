import React, { useState, useRef, useEffect } from 'react';
import TextInput from '../TextInput';
import CheckBox from '../CheckBox';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import closeIcon from '../../Assets/Icons/close.png';
import search from '../../Assets/Icons/search.png';
import { postData } from '../../ApiServices/ApiService';
import { ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_PREP_RECEIPT } from '../../ApiServices/BaseURL';
import Loader from '../Loader';
import Input from '../Input';

const GenerateReceiptModal = ({ isOpen, onClose }) => {
  const [searchInvoice, setSearchInvoice] = useState('');
  const [searchInvoiceError, setSearchInvoiceError] = useState('');
  const [advancePayment, setAdvancePayment] = useState(false);
  const navigate = useNavigate(); // Initialize navigate for routing
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchClientLoading, setSearchClientLoading] = useState(false);
  const [searchClientError, setSearchClientError] = useState('');

  const handleInvoiceInputText = (value) => {
    if (!value.trim()) {
      setSearchInvoiceError('please enter valid invoice number');
      setSearchInvoice(value);
      setError('');
      setSearchClient('');
      setSelectedClient(null);
      setAdvancePayment(false);
      setSearchClientError('');
      setClientList([]);
      return;
    }
    setSearchInvoice(value);
    setError('');
    setSearchInvoiceError('');
    setSearchClient('');
    setSelectedClient(null);
    setAdvancePayment(false);
    setSearchClientError('');
    setClientList([]);
  }

  const handleCheckboxChange = (isChecked) => {
    console.log('handleCheckboxChange isChecked :', isChecked)
    if (!isChecked) {
      setSelectedClient(null);
      setSearchClient('');
    }
    setAdvancePayment(isChecked);
    setError('');
    setSearchInvoice('');
    setSearchInvoiceError('');
  }

  const handleSearchClient = async () => {
    try {
      setError('');
      setSearchClientError('');
      if (!searchClient.trim()) {
        setSearchClientError('Please enter valid text to search client'); return;
      }
      setSearchClientLoading(true);
      setClientList([]);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchClient }

      //console.log('handleSearchClient generate receipt', payLoad);
      const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setClientList(responseData?.dataJ || []);
            //   setError(responseData?.info);
          } else {
            setSearchClientError(responseData?.info || 'Failed to fetch Client. Please try again.');
          }
        } else {
          setSearchClientError(responseData?.info || 'Failed to fetch Client.');
        }
      } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setSearchClientError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setSearchClientError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else {
        setSearchClientError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setSearchClientError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Client. Please try again.'}`);
      } else if (error.request) {
        setSearchClientError('No response received from the server. Please check your network connection and try again.');
      } else {
        setSearchClientError(`Failed to fetch client. Please check your network connection and try again.`);
      }
    } finally {
      setSearchClientLoading(false);
    }
  };

  const handleSelectClient = (client) => {
    setSelectedClient(client); // Set selected client data
    setSearchClient(client?.client_name); // Update TextInput with the selected client's name
    setClientList([]); // Clear the dropdown
    setSearchClientError('');
  };

  const handleSearchInputText = (value) => {
    //console.log('handleSearchInputText value : ',value);
    setSearchClient(value);
    setSearchClientError('');
    setClientList([]);
    setError('');
    setSearchInvoiceError('');
    setSelectedClient(null);
    setSearchInvoice('');
    setSearchInvoiceError('');
  }

  // Handler for Add button
  const handleAdd = async () => {
    try {
      console.log('handleAdd searchInvoice', searchInvoice, 'advancePayment:', advancePayment, 'searchClient', searchClient);
      setError('');
      setSearchClientError('');
      setSearchInvoiceError('');
      if (!searchInvoice.trim() && !advancePayment) {
        setError('Please select either invoice number or advance payment receipt'); return;
      }

      if (advancePayment && !selectedClient?.client_id) {
        setSearchClientError('Please enter client name, search, and select.'); return;
      }

      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "inv_number": searchInvoice || '',
        "adv_payment": advancePayment ? 1 : 0,
        "client_id": selectedClient?.client_id || 0
      }

      console.log('handleSearchClient generate receipt', payLoad);
      const response = await postData(ORG_PREP_RECEIPT, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const prepReceiptData = {
                searchInvoice: searchInvoice || '',
                advancePayment: advancePayment ? 1 : 0,
                clientId: selectedClient?.client_id || 0
              }
              navigate('/generate-edit-receipt', { state: { pageType: 'receiptmodal', clientInvoiceInfo: responseData?.dataJ[0], prepReceiptData: prepReceiptData } });
              resetForm();
              onClose();
            } else {
              setError(responseData?.info);
            }
          } else {
            setError(responseData?.info || 'Failed to fetch invoice info. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch invoice info.');
        }
      } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch invoice info. Please try again.'}`);
      } else if (error.request) {
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        setError(`Failed to fetch invoice info. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setSearchInvoice('');
    setSearchInvoiceError('');
    setAdvancePayment(false);
    setSearchClient('');
    setSearchClientError('');
    setClientList([]);
    setSelectedClient(null);
  };

  const handleModalClose = () => {
    resetForm();
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={handleModalClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>

        <h2 className="modal-title">Generate Receipt</h2>
        <hr className="title-divider" />

        {/* Search Invoice Input */}
        {/* <div className="filter-field" style={{ marginBottom: '15px' }}>
          <label
            style={{
              ...styles.labaleStyle,
              display: 'block',
            }}
          >
            Search Invoice
          </label>
          <TextInput
            type="text"
            placeholder="Search Invoice"
            value={searchInvoice}
            onChange={(e) => setSearchInvoice(e.target.value)}
            right={<img src={search} alt="Search" className="search-icon" />}
            style={{ padding: '20px 10px' }}
          />
        </div> */}

        {!advancePayment && <div >
          <Input
            label="Invoice Number"
            placeholder="Enter Invoice Number"
            value={searchInvoice || ''}
            onChange={(e) => { handleInvoiceInputText(e.target.value); }}
            errorMessage={searchInvoiceError}
            searchBoxWidth={220}
          />
        </div>}

        {advancePayment && <div className="filter-field">
          <label
            style={{
              ...styles.labaleStyle,
              display: 'block',
            }}
          >
            Search Client
          </label>
          <TextInput
            type="text"
            placeholder="Search Client"
            value={searchClient}
            onChange={(e) => handleSearchInputText(e.target.value)}
            onRightClick={handleSearchClient}
            right={
              searchClientLoading ? (
                <Loader loading={searchClientLoading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
              ) : (
                <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
              )
            }
            style={{ padding: '20px 10px' }}
            searchBoxWidth={240}
            errorMessage={searchClientError}
          />
          {clientList?.length > 0 && (
            <ul style={styles.dropdownList}>
              {clientList.map((client, index) => (
                <li
                  key={client?.client_id}
                  onClick={() => handleSelectClient(client)}
                  style={{
                    ...styles.dropdownItem,
                    borderBottom: index !== clientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                  }}
                >
                  {client?.client_name}
                </li>
              ))}
            </ul>
          )}

        </div>}

        {/* Advance Payment Checkbox */}
        <div className="existing-client-checkbox" style={{ marginTop: '20px', marginBottom: '20px' }}>
          <CheckBox
            label="Advance Payment Receipt"
            checked={advancePayment}
            onChange={(isChecked) => handleCheckboxChange(isChecked)}
            style={styles.checkboxContainer}
            labelStyle={styles.checkboxLabel}
          />
        </div>
        <hr className="footer-divider" />

        {/* Action Buttons */}
        <div className="button-container" style={{ alignItems: 'center' }}>
          <button className="add-client-button" onClick={handleAdd} style={{
            paddingLeft: '30px', paddingRight: '30px', display: 'flex', justifyContent: 'center',
            alignItems: 'center', opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer'
          }}
            disabled={loading}>
            <span style={{ paddingRight: loading ? 10 : 0 }}>
              <Loader loading={loading} color={'var(--background-color)'} size="small" />
            </span>
            Add
          </button>
          <button className="cancel-button" onClick={handleModalClose}>
            Cancel
          </button>
          {error && <div className="list-error-msg" style={{ marginBottom: '3px' }}>{error} </div>}
          {/* {success && <div className="list-success-msg" style={{marginBottom:'3px'}}>{success} </div>} */}
        </div>
      </div>
    </div>
  );
};

const styles = {
  labaleStyle: {
    fontSize: '12px',
    // marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)',
  },
  iconImage: {
    width: '12px',
    height: '12px',
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Roboto'

  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  fullWidth: {
    gridColumn: "span 2",
    // width: "97%", // Full width
  },
};

export default GenerateReceiptModal;
