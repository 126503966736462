import React, { useState, useRef, useEffect } from 'react';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import AddNewContactModal from '../../../../Components/Modals/AddNewContactModal';
import Input from '../../../../Components/Input';
import Dropdown from '../../../../Components/Dropdown';
import DateInput from '../../../../Components/DateInput';
import MobileInput from '../../../../Components/MobileInput';
import PrimaryInformationController from '../../../Controllers/AdminScreens/Clients/PrimaryInformationController';
import ClientAvailedServicesScreen from './ClientAvailedServicesScreen';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import Loader from '../../../../Components/Loader';
import TextInput from '../../../../Components/TextInput';
import search from '../../../../Assets/Icons/search.png';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';
import pluseIcon from '../../../../Assets/Icons/Pluse.png';
import CheckBox from '../../../../Components/CheckBox';
import { formatToDMY } from '../../../../Utils/Validation';

const PrimaryInformationScreen = ({ isEditable, onUpdate, onCancel, clientInfo }) => {

  const {
    basicInformation,
    isModalOpen,
    clientContactList,
    dropdownFields,
    clientInformation,
    fieldConfigs,
    handleFieldChange,
    handleCloseModal,
    handleAddContactClick,
    loading,
    error,
    clientContactListLoading,
    clientContactListError,
    handleEditClientContact,
    handleDeleteClientContact,
    contactInfoRef,
    onAddUpdateSuccess,
    clientId,
    deleteContactLoading,
    deleteContactError,
    onUpdateClientInfo,
    updateLoading,
    updateStatusError,
    basicInformationDisplay,
    clientAssignedUserError,
    addEmployeeError,
    employeeList,
    searchUser,
    handleSearchEmployee,
    setSearchUser,
    handleSelectedEmployee,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    callDeleteClientContact,

    availedServiceError,
    availedServiceLoading,
    availedServices,
    toggleService,
    infoLoading,
    deleteContactSuccess,
    updateStatusSuccess,
    deletedMessages
  } = PrimaryInformationController(clientInfo);
  // //console.log("availedServices", availedServices)
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='basic-info-container'>
      {/* Basic Information Section */}
      <h3 className='basic-info-header'>Basic Information</h3>
      <div className='basic-info-card'>
        <div className='basic-info-row' style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
          {fieldConfigs.map((field) => (
            <div className="basic-info-item" key={field.key}>
              {isEditable ? (
                field.type === "dropdown" ? (
                  <Dropdown
                    ref={(el) => (dropdownRefs.current[`${field.label}`] = el)}
                    isOpen={openDropdown === `${field.label}`}
                    toggleDropdown={() => toggleDropdown(`${field.label}`)}
                    label={field.label} // Dropdown already renders the label internally
                    options={field.options}
                    value={basicInformation[field.key]}
                    onChange={(selectedValue) => handleFieldChange(field.key, selectedValue)}
                    errorMessage={field?.error}

                  />
                ) : field.type === "date" ? (
                  <div className="basic-info-label">
                    <label>{field.label}</label>
                    <DateInput
                      value={basicInformation[field.key] || ""}
                      onChange={(e) => handleFieldChange(field.key, e.target.value)}
                    />
                  </div>
                ) : field.type === "mobile" ? (
                  <MobileInput
                    label={field.label}
                    countryCode={basicInformation?.client_mobile_code || ""}
                    mobileNumber={basicInformation[field.key] || ""}
                    onCountryChange={(value) => handleFieldChange("client_mobile_code", value)}
                    onMobileChange={(value) => handleFieldChange(field.key, value)}
                    placeholder={`Enter ${field.label}`}
                    countries={[
                      { code: "IN", dialCode: "+91" },
                      { code: "US", dialCode: "+1" },
                      { code: "UK", dialCode: "+44" },
                    ]}
                    errorMessage={field?.error}
                  />
                ) : field.type === "search" ? (
                  <div className="filter-field" style={{ marginBottom: '15px', width: '100%' }}>
                    <label
                      style={{
                        ...styles.labaleStyle,
                        // marginBottom: '8px',
                        display: 'block',
                      }}
                    >
                      {field.label}
                    </label>
                    <TextInput
                      type="text"
                      placeholder="Search User"
                      value={basicInformation[field.key]}
                      onChange={(e) => handleFieldChange(field.key, e.target.value)}
                      onRightClick={handleSearchEmployee}
                      right={
                        loading ? (
                          <Loader loading={loading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                        ) : (
                          <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                        )
                      }
                      style={{ padding: '20px 10px' }}
                      errorMessage={addEmployeeError}
                    />
                    {employeeList.length > 0 && (
                      <ul style={styles.dropdownList}>
                        {employeeList.map((employee, index) => (
                          <li
                            key={employee?.emp_id}
                            onClick={() => handleSelectedEmployee(employee)}
                            style={{
                              ...styles.dropdownItem,
                              borderBottom: index !== employeeList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                            }}
                          >
                            {employee?.emp_name}
                          </li>
                        ))}
                      </ul>
                    )}

                  </div>
                ) : field.type === "inputTypeNone" ? (
                  <Input
                    label={field.label}
                    value={basicInformation[field.key]}
                    inputType="text"
                    placeholder={`Enter ${field.label}`}
                    isEdit={false} // Ensure the field is read-only
                    onChange={() => { }}
                    errorMessage={field?.error}
                  />
                ) : (
                  <Input
                    label={field.label} // Input already renders the label internally
                    inputType={field?.inputType}
                    placeholder={`Enter ${field.label} ${field?.key === 'client_pin' ? '(optional)' : ''}`}
                    value={basicInformation[field.key]}
                    onChange={(e) => handleFieldChange(field.key, e.target.value)}
                    errorMessage={field?.error}
                    maxLength={field?.maxLength}
                  />
                )
              ) : (
                <><span className="basic-info-label">{field.label}</span>


                  {infoLoading ? <LoadingSkeleton width="40%" height="10px" /> : <span className="basic-info-value">
                    {field.key === 'is_india_client' ? (<>{basicInformationDisplay[field.key] === 1 ? 'Yes' : 'No'}</>) :
                      field.key === "client_mobile_no" ? (
                        `${basicInformationDisplay?.client_mobile_code || ""} ${basicInformationDisplay[field.key] || ""
                        }`
                      ) : field.key === "client_enrolled_on" ? (formatToDMY(basicInformationDisplay[field.key])) :
                        (<div style={{ color: field?.style === 'textDecoration' ? '#5A55D2' : '#060606', textDecoration: field?.style === 'textDecoration' ? 'underline' : 'none' }}>{basicInformationDisplay[field.key]?.trim() || "--"}</div>)}
                  </span>}</>
              )}
            </div>
          ))}

        </div>
        {error && <div className="list-error-msg">{error} </div>}

      </div>

      {/* Client Contacts Section */}
      <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 17 }}>
        <h3 className='basic-info-header'>Client Contacts</h3>
        <div className="basic-info-outer-card">
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Name</th>
                <th style={{ width: "20%", }}>Phone No.</th>
                <th style={{ width: "30%", }}>Email ID</th>
                <th style={{ width: "15%" }}>Role</th>
                <th style={{ width: "15%", cursor: 'pointer', textAlign: 'end', color: '#40513B', fontWeight: '400' }} onClick={handleAddContactClick}>
                  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={pluseIcon} alt="Plus Icon" style={{ ...styles.iconImage, filter: 'brightness(0) invert(0)' }} /></span>
                    <span>New Contact</span></span></th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: 'white' }}>
              {clientContactList?.map((contact, index) => (
                <tr
                  key={index}
                  className="tasksScreen-main-task-row"
                  style={{
                    borderBottom: "1px solid #dee2e6", // Horizontal line between rows
                  }}
                >
                  <td>
                    {contact?.contact_name}{" "}
                    {contact?.is_primary_contact === 1 && (
                      <span style={{ color: "#609966", marginLeft: "5px" }}>★</span>
                    )}
                  </td>
                  <td>{contact?.contact_phone_code ? contact?.contact_phone_code : ''} {contact?.contact_phone_no}</td>
                  <td style={styles.tableValue}>{contact?.contact_email}
                    {deleteContactSuccess[contact?.contact_id] &&
                      <div style={{ color: 'green', textDecoration: 'none', paddingTop: 10 }} >{deleteContactSuccess[contact?.contact_id]}</div>}
                    {deleteContactError[contact?.contact_id] &&
                      <div style={{ ...styles.deleteErrorMsg, paddingTop: 10 }} >{deleteContactError[contact?.contact_id]}</div>}
                  </td>
                  <td>{contact?.contact_role}</td>
                  <td>
                    <div style={{ display: "flex", gap: "2px", justifyContent: 'flex-end' }}>
                      <button className="tasksScreen-edit-button" onClick={() => { handleEditClientContact(contact) }}
                        style={{
                          cursor: deleteContactLoading[contact?.contact_id] || deletedMessages.includes(contact?.contact_id) ? 'not-allowed' : 'pointer',
                          pointerEvents: deleteContactLoading[contact?.contact_id] || deletedMessages.includes(contact?.contact_id) ? 'none' : 'auto',
                            opacity: deleteContactLoading[contact?.contact_id] ? 0.3 : 1
                        }}
                        >
                        <img
                          src={editIcon}
                          alt="Edit"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </button>
                      <button className="tasksScreen-delete-button" style={{ opacity: deleteContactLoading[contact?.contact_id] ? 0.3 : 1, pointerEvents: deleteContactLoading[contact?.contact_id] || deletedMessages.includes(contact?.contact_id) ? 'none' : 'auto', }}
                        onClick={() => { handleDeleteClientContact(contact) }} disabled={deleteContactLoading[contact?.contact_id]}>
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </button>
                      {deleteContactLoading[contact?.contact_id] &&
                        <span><Loader loading={deleteContactLoading[contact?.contact_id]} color={'var(--primary-color)'} size='small' /></span>
                      }
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {clientContactListLoading && <Loader loading={clientContactListLoading} color={'var(--primary-color)'} />}
          {clientContactListError && <div className="list-error-msg">{clientContactListError} </div>}
        </div>
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          message={`Are you sure you want to delete the contact of ${contactInfoRef.current?.contact_name}?`}
          onClose={handleCloseDeleteModal}
          onConfirm={() => {
            handleCloseDeleteModal(); // Close modal
            callDeleteClientContact(contactInfoRef.current); // Perform delete operation
          }}
        />
        <AddNewContactModal isOpen={isModalOpen} onClose={handleCloseModal} clientId={clientId} editData={contactInfoRef.current} onValueChange={onAddUpdateSuccess} />
      </div>
      <h3 className='basic-info-header' style={{ paddingTop: 20 }}>Availed Services</h3>
      <div className="basic-info-card" style={{ paddingBottom: "20px" }}>
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          rowGap: "20px",
          columnGap: '75px',
          justifyContent: "flex-start" // Align items to the start
        }}>
          {availedServices.map((service) => (
            <div key={service.service_id} >
              <CheckBox
                label={service.service_name}
                checked={service.is_active === 1}
                onChange={() => { }}
                style={{ gap: "0px" }}
              // disabled={true}
              />
            </div>
          ))}
        </div>
        {availedServiceLoading && <Loader loading={availedServiceLoading} color={'var(--primary-color)'} />}
        {availedServiceError && <div className="list-error-msg">{availedServiceError} </div>}
      </div>
      <div style={{ paddingTop: 10 }}></div>
      {/* <ClientAvailedServicesScreen /> */}
      {isEditable && (<>
        {updateStatusError && <div className="list-error-msg">{updateStatusError}</div>}
        {updateStatusSuccess && <div className="list-success-msg">{updateStatusSuccess}</div>}
        <div className="button-container">
          <button className="add-client-button" style={{ padding: '0px 30px', opacity: updateLoading ? 0.5 : 1, cursor: updateLoading ? 'not-allowed' : 'pointer', }} onClick={onUpdateClientInfo} disabled={updateLoading}>
            {updateLoading && <span style={{ paddingRight: 10 }}><Loader loading={updateLoading} color='#FFFFFF' size='small' /></span>} Update Client
          </button>
          <button style={styles.cancelButton} onClick={onCancel}>
            Cancel
          </button>
        </div>
      </>

      )}
    </div>
  );
};

const styles = {
  container: {
    margin: "20px",
    //   fontFamily: "Arial, sans-serif",
    backgroundColor: '#fff',
    marginTop: '-18px'
  },
  header: {
    fontSize: "18px",
    color: "#609966",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Roboto Slab'
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid black",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  item: {
    flex: "1 1 calc(25% - 20px)",
    minWidth: "200px",
  },
  label: {
    fontWeight: 400,
    color: "#767676",
    display: "block",
    marginBottom: "5px",
    fontSize: '12px',
    fontFamily: 'Roboto'
  },
  value: {
    fontSize: "14px",
    color: '#060606',
    fontWeight: 400,
    fontFamily: 'Roboto'
  },
  tableHeaderRow: {
    display: "flex",
    borderBottom: "2px solid #ddd",
    padding: "15px 0",
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#f5f5f5",
  },
  tableHeaderCell: {
    padding: "0 10px",
    fontSize: "14px",
  },
  tableRow: {
    display: "flex",
    borderBottom: "1px solid #ddd",
    padding: "10px 0",
    alignItems: "center",
  },
  tableCell: {
    padding: "0 10px",
    fontSize: "14px",
    color: "#555",
  },
  primaryIcon: {
    color: "#2b542c",
    marginLeft: "5px",
  },
  actionButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "#2b542c",
    fontSize: "16px",
    marginLeft: "10px",
  },
  newContactButton: {
    backgroundColor: "#2b542c",
    color: "#fff",
    border: "none",
    borderRadius: "3px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  servicesGrid: {
    display: "flex",
    flexWrap: "wrap",
    // Reduced gap between items for better alignment
    rowGap: "10px", // Adds consistent spacing between rows
  },
  serviceItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: "#343C44",
    gap: "5px",
    paddingBottom: "5px",
    marginRight: '80px',// Adds spacing at the bottom of each service item
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  checkbox: {
    marginRight: "3px",
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#2b542c",
    border: "1px solid #2b542c",
    borderRadius: "25px",
    padding: "8px 25px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Roboto",
    marginLeft: '10px'
  },
  tableValue: {
    color: "#192A3E",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
  },
  deleteErrorMsg: {
    color: 'red',
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  iconImage: {
    width: '12px',
    height: '12px',
    paddingRight: '10px'
  },
};

export default PrimaryInformationScreen;