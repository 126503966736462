
import React, {useState,useEffect,useRef} from "react";
import { ORG_GET_CLIENT_TASK_INFO, ORG_TASK_DOCUMENT_LIST, ORG_GET_ALL_CODES_LIST,
  ORG_UPDATE_CLIENT_TASK_INFO, ORG_GET_EMPLOYEE_LIST,ORG_GET_CLIENT_TASK_CHECK_LIST,ORG_UPDATE_CLIENT_TASK_STATUS,
  ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER, ORG_DOWNLOAD_DOCUMENT_RACK, ORG_GET_SERVICE_LIST_SEARCH, ORG_GET_SUB_SERVICE_LIST_SEARCH} from '../../../../ApiServices/BaseURL';
import { getData,postData } from "../../../../ApiServices/ApiService";

const ClientTaskDetailsController = (clientId,task)=>{

    const [kycListLoading, setKycListLoading] = useState(false);
    const [kycListError, setKYCListError] = useState('');
    const [success, setSuccess] = useState('');
    const [kycDocumentList, setKycDocumentList] = useState([]);
    const [internalRecords, setInternalRecords] = useState([]);
    const [externalRecords, setExternalRecords] = useState([]);
    const [kycDownloadError, setKYCDownloadError] = useState('');
    const [downloadKycLoading, setDownloadKycLoading] = useState(false);
    const [currentDocIndex, setCurrentDocIndex] = useState(null);

    const [internalDownloadError, setInternalDownloadError] = useState('');
    const [externalDownloadError, setExternalDownloadError] = useState('');
    const [internalLoading, setInternalLoading] = useState(false);
    const [externalLoading, setExternalLoading] = useState(false);
    const [isEditable,setIsEditable] = useState(false);
    const [loading,setLoading]=useState(false);
    const [addEmployeeError,setAddEmployeeError]=useState('');
    const [error,setError]=useState('');
    const [employeeList,setEmployeeList]=useState([]);
    const [yearLoading, setYearLoading] = useState(false);
    const [yearError, setYearError] = useState('');
    const [yearList, setYearList] = useState([]);
    const [periodList, setPeriodList] = useState([]);
    const [updateLoading,setUpdateLoading] = useState(false);
    const [updateStatusError,setUpdateStatusError]=useState('');
    const [updateStatusSuccess, setUpdateStatusSuccess] = useState('')
    const [taskCheckList,setTaskCheckList] = useState([]);
    const [taskCheckLoading,setTaskCheckLoading]=useState(false);
    const [taskCheckError,setTaskCheckError]=useState('');
    const [saveLoading,setSaveLoading] = useState(false);
    const [saveError,setSaveError]=useState('');
    const [checklistError ,setChecklistError ]=useState('');
    const [statusError ,setStatusError]=useState('');
    const [commentError,setCommentError]=useState('');
    const [saveSuccess,setSaveSucess]=useState('');

    const [status, setStatus] = useState(null);
    const [assignedTo, setAssignedTo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isIssueModalOpen, setIsIssueModalOpen] = useState(false);
    const [isHoldTask,setIsHoldTask] = useState(null);
    const [isIssueTask,setIsIssueTask] = useState(null);
    const [complitedBy,setComplitedBy] = useState('');
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalSource, setModalSource] = useState('');
    const [taskComment, setTaskComment] = useState("");
    const [selectedTaskCheck, setSelectedTaskCheck] = useState(null);
    const [isPriorityModalOpen, setIsPriorityModalOpen] = useState(false);
    const [isPriority,setIsPriority] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);
    const [isTaskCheckModalOpen, setIsTaskCheckModalOpen] = useState(false);
    const [isTaskCheck,setIsTaskCheck] = useState(null);
    const [assignEmployeeList,setAssignEmployeeList] = useState([]);
    const [empLoading,setEmpLoading]=useState(false);
    const [empResError,setEmpResError]=useState('');
    const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
const [taskInfo, setTaskInfo] = useState({
  task_id: null, // Initialize as null for numbers
  task_priority: null,
  financial_period: "", // Empty string for text
  financial_year: "",
  task_description: "",
  task_status: null,
  task_assign_status: null,
  employee_id_assigned: null,
  employee_id_tl: null,
  employee_id_tm: null,
  service_id: null,
  service_sub_id: null,
  start_date: "", // Dates as strings
  due_date: "",
  agreed_invoice_amount: null, // Amounts as null
  estimated_invoice_amount: null,
  service_name: "",
  service_sub_name: "",
  group_id: null,
  group_name: "",
  employee_id_assigned_name: "",
  employee_id_tl_name: "",
  employee_id_tm_name: "",
  client_file_id:"",
  client_id:null,
  task_priority_name: '',
});
const [taskInfoLoading,setTaskInfoLoading]=useState(false);
const [taskInfoError,setTaskInfoError]=useState('');
const [servicesList, setServicesList] = useState([]);
const [serviceLoading, setServiceLoading] = useState(false);
const [serviceError, setServiceError] = useState('');

const [subCategoryList, setSubCategoryList] = useState([]);
const [subCategoryLoading, setSubCategoryLoading] = useState(false)
const [subCategoryError, setSubCategoryError] = useState('');
const [serviceCategoryErrorMessage, setServiceCategoryErrorMessage] = useState('');
const categoryListRef = useRef([]);
const subCategoryListRef = useRef([]);

const [fieldConfigs, setFieldConfigs] = useState([
  { label: "Task ID", key: "task_id", type: "inputTypeNone",readOnly:false, error: "" },
  { label: "Services", key: "service_name", type: task?.task_status === 1 ? "dropdown" : "inputTypeNone", options:[] , loading: serviceLoading, readOnly:task?.task_status === 1 ? true : false, error: "" },
  { label: "Service Category", key: "service_sub_name", type: task?.task_status === 1 ? "dropdown" : "inputTypeNone", options:[], loading: subCategoryLoading, readOnly:task?.task_status === 1 ? true : false, error: "" },
  // { label: "Services", key: "service_name", type: "inputTypeNone", options:[] , loading: serviceLoading, readOnly:false, error: "" },
  // { label: "Service Category", key: "service_sub_name", type: "inputTypeNone", options:[], loading: subCategoryLoading, readOnly:false, error: "" },
  { label: "Group Name", key: "group_name", type: "inputTypeNone",readOnly:false, error: "" },
  { label: "Task Assigned User", key: "employee_id_assigned_name", type: "search", error: ""},
  { label: "Team Leader", key: "employee_id_tl_name", type: "text", readOnly:false, error: ""},
  { label: "Team Manager", key: "employee_id_tm_name", type: "text", readOnly:false, error: ""},
  { label: "Financial Year", key: "financial_year", type: "dropdown", options:[], error: "" },
  { label: "Period", key: "financial_period", type: "dropdown", options:[], error: "" },
  { label: "Start Date", key: "start_date", type: "date", error: "" },
  { label: "ETD", key: "due_date", type: "date", error: ""},
  { label: "Task Priority", key: "task_priority_name", type: "dropdown",options:[{ label: 'No', value: 0 }, { label: 'Yes', value: 1 }],error: ""},
  { label: "Estimated Invoice Amount", key: "estimated_invoice_amount", type: "text", error: "" },
  { label: "Agreed Invoice Amount", key: "agreed_invoice_amount", type: "text", error: "" },
  { label: "File No.", key: "client_file_id", type: "inputTypeNone",readOnly:false, error: "" },
  { label: "Description", key: "task_description", type: "text", error: "" } 
]);

// const fieldConfigs = [
//   { label: "Task ID", key: "task_id", type: "inputTypeNone",readOnly:false },
//   { label: "Services", key: "service_name", type: task?.task_status === 1 ? "dropdown" : "inputTypeNone", options:servicesList || [] ,readOnly:task?.task_status === 1 ? true : false },
//   { label: "Service Category", key: "service_sub_name", type: "inputTypeNone",readOnly:false },
//   { label: "Group Name", key: "group_name", type: "inputTypeNone",readOnly:false },
//   { label: "Task Assigned User", key: "employee_id_assigned_name", type: "search"},
//   { label: "Team Leader", key: "employee_id_tl_name", type: "text", readOnly:false},
//   { label: "Team Manager", key: "employee_id_tm_name", type: "text", readOnly:false},
//   { label: "Financial Year", key: "financial_year", type: "dropdown", options:yearList || [] },
//   { label: "Period", key: "financial_period", type: "dropdown", options: periodList || [] },
//   { label: "Start Date", key: "start_date", type: "date" },
//   { label: "ETD", key: "due_date", type: "date"},
//   { label: "Task Priority", key: "task_priority_name", type: "dropdown",options:[{ label: 'No', value: 0 }, { label: 'Yes', value: 1 }]},
//   { label: "Estimated Invoice Amount", key: "estimated_invoice_amount", type: "text" },
//   { label: "Agreed Invoice Amount", key: "agreed_invoice_amount", type: "text" },
//   { label: "File No.", key: "client_file_id", type: "inputTypeNone",readOnly:false },
//   { label: "Description", key: "task_description", type: "text" } 
// ];



    useEffect(()=>{
        fetchYearList();
        fetchEmpList();
    },[])
    useEffect(()=>{
      if(task?.task_id){
        console.log('task_status : ',task)
        if (task?.task_status === 1) {
          fetchTaskServicesSearchList();
        }
        fetchKYCDocumentsList();
        fetchClientTaskList();
        fetchClientTaskCheckList();
      }
  },[task])

  const fetchTaskServicesSearchList =async ()=>{
    try {
        setServiceError('')
        //console.log('fetchTaskServicesSearchList')
        setServiceLoading(true);
        setFieldConfigs((prevConfigs) =>
          prevConfigs.map((field) =>
            field.key === "service_name"
              ? { ...field, loading: true, options: [], error: ""  }
              : field
          )
        );
        setServicesList([]);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad = {
          "emp_id_auth": storedUserInfo?.emp_id
        }
          
        //console.log('fetchTaskServicesSearchList payload',payLoad);
    
        // Make the API call
        const response = await postData(ORG_GET_SERVICE_LIST_SEARCH,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                categoryListRef.current = await  responseData?.dataJ.map(service => ({
                  label: service.service_name,
                  value: service.service_id
                }));
                setServicesList(categoryListRef.current)
                // Update the fieldConfigs dynamically
                setFieldConfigs((prevConfigs) =>
                  prevConfigs.map((field) =>
                    field.key === "service_name"
                      ? { ...field, options: categoryListRef.current }
                      : field
                  )
                );
              
              } else {
                setFieldConfigs((prevConfigs) =>
                  prevConfigs.map((field) =>
                    field.key === "service_name"
                      ? { ...field, error: responseData?.info || 'No service available.' }
                      : field
                  )
                );
                setServiceError(responseData?.info || 'No service available.');
              }
            } else {
              setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                  field.key === "service_name"
                    ? { ...field, error: responseData?.info || 'Failed to fetch services. Please try again.' }
                    : field
                )
              );
              setServiceError(responseData?.info || 'Failed to fetch services. Please try again.');
            }
          } else {
            setFieldConfigs((prevConfigs) =>
              prevConfigs.map((field) =>
                field.key === "service_name"
                  ? { ...field, error: responseData?.info || 'Failed to fetch services.' }
                  : field
              )
            );
            setServiceError(responseData?.info || 'Failed to fetch services.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setServiceError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setServiceError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        else {
          setFieldConfigs((prevConfigs) =>
            prevConfigs.map((field) =>
              field.key === "service_name"
                ? { ...field, error: 'Unexpected error occurred. Please try again later.' }
                : field
            )
          );
          setServiceError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setServiceError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch services. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setServiceError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setFieldConfigs((prevConfigs) =>
            prevConfigs.map((field) =>
              field.key === "service_name"
                ? { ...field, error: 'Failed to fetch services. Please check your network connection and try again.`' }
                : field
            )
          );
          setServiceError(`Failed to fetch services. Please check your network connection and try again.`);
        }
      } finally {
        setServiceLoading(false);
        setFieldConfigs((prevConfigs) =>
          prevConfigs.map((field) =>
            field.key === "service_name"
              ? { ...field, loading: false }
              : field
          )
        );
      }
  }

  const fetchTaskSubCategorieList =async (serviceId)=>{
    try {
      //console.log('fetchTaskSubCategorieList serviceId @@',serviceId)
      setTaskInfo((prev) =>{
        return {
        ...prev,
          service_sub_id: null,
          service_sub_name: "",
        }
      });
      setSubCategoryLoading(true);
      setFieldConfigs((prevConfigs) =>
        prevConfigs.map((field) =>
          field.key === "service_sub_name"
            ? { ...field, loading: true, options: [], error: "" }
            : field
        )
      );
      setSubCategoryError('');
      setServiceCategoryErrorMessage('')

      subCategoryListRef.current = [];
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_id": serviceId 
      }
        
      //console.log('fetchTaskSubCategorieList payload',payLoad);
    
        // Make the API call service_id=10006&
        const response = await postData(ORG_GET_SUB_SERVICE_LIST_SEARCH,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                subCategoryListRef.current = await  responseData?.dataJ.map(service => ({
                  label: service?.service_sub_name,
                  value: service?.service_sub_id
                }));
                setSubCategoryList(subCategoryListRef.current)
                // Update the fieldConfigs dynamically
                setFieldConfigs((prevConfigs) =>
                  prevConfigs.map((field) =>
                    field.key === "service_sub_name"
                      ? { ...field, options: subCategoryListRef.current }
                      : field
                  )
                );

              } else {
                setFieldConfigs((prevConfigs) =>
                  prevConfigs.map((field) =>
                    field.key === "service_sub_name"
                      ? { ...field, error: responseData?.info || 'No service category available.' }
                      : field
                  )
                );
                setServiceCategoryErrorMessage(responseData?.info || 'No service category available.');
              }
            } else {
              setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                  field.key === "service_sub_name"
                    ? { ...field, error: responseData?.info || 'Failed to fetch service category data. Please try again.' }
                    : field
                )
              );
              setServiceCategoryErrorMessage(responseData?.info || 'Failed to fetch service category data. Please try again.');
            }
          } else {
            setFieldConfigs((prevConfigs) =>
              prevConfigs.map((field) =>
                field.key === "service_sub_name"
                  ? { ...field, error: responseData?.info || 'Failed to fetch service category data.' }
                  : field
              )
            );
            setServiceCategoryErrorMessage(responseData?.info || 'Failed to fetch service category data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setServiceCategoryErrorMessage('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setServiceCategoryErrorMessage(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        else {
          setFieldConfigs((prevConfigs) =>
            prevConfigs.map((field) =>
              field.key === "service_sub_name"
                ? { ...field, error: 'Unexpected error occurred. Please try again later.' }
                : field
            )
          );
          setServiceCategoryErrorMessage('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setServiceCategoryErrorMessage(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setServiceCategoryErrorMessage('No response received from the server. Please check your network connection and try again.');
        } else {
          setFieldConfigs((prevConfigs) =>
            prevConfigs.map((field) =>
              field.key === "service_sub_name"
                ? { ...field, error: 'Failed to fetch service category data. Please check your network connection and try again.' }
                : field
            )
          );
          // An error occurred in setting up the request
          setServiceCategoryErrorMessage(`Failed to fetch service category data. Please check your network connection and try again.`);
        }
      } finally {
        setSubCategoryLoading(false);
        setFieldConfigs((prevConfigs) =>
          prevConfigs.map((field) =>
            field.key === "service_sub_name"
              ? { ...field, loading: false }
              : field
          )
        );
      }
  }

  const [kycDocInternalError, setKYCDocInternalError] = useState('');
  const [kycDocClientError, setKYCDocClientError] = useState('');
    const fetchKYCDocumentsList =async ()=>{
        try {
            setKycListLoading(true);
            setKYCListError('');
            setKYCDocInternalError('');
            setKYCDocClientError('')
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_TASK_DOCUMENT_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}&task_id=${task.task_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    const records = responseData?.dataJ || [];
      
                        // Separate internal and external records
                        const internal = records.filter(record => record.doc_is_internal === 1);
                        const external = records.filter(record => record.doc_is_internal === 2);
                        
                        // Update state
                        setInternalRecords(internal);
                        setExternalRecords(external);
                    // selTeamInfo.current = responseData?.dataJ[0];
                    if (internal.length === 0 ) {
                      setKYCDocInternalError("No internal documents availabe");
                  }
                  if ( external.length === 0) {
                    setKYCDocClientError("No client documents available.");
                }
                  } else {
                    setKYCListError(responseData?.info || 'Data Not Found');
                  }
                } else {
                    setKYCListError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                }
              } else {
                setKYCListError(responseData?.info || 'Failed to fetch Team Information.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setKYCListError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setKYCListError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setKYCListError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
                setKYCListError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setKYCListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setKYCListError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setKYCListError(`Failed to fetch Team Information. Please check your network connection and try again.`);
            }
          } finally {
            setKycListLoading(false);
          }
      }

       const fetchClientTaskList = async () => {
              //console.log('Fetching user credentials');
              try {
               setTaskInfoLoading(true); // Start loading
                setTaskInfoError(''); // Clear any existing error
        
                  const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
                  // Make the API call
                  const response = await getData(`${ORG_GET_CLIENT_TASK_INFO}?emp_id_auth=${storedUserInfo?.emp_id}&task_id=${task?.task_id}&client_id=${clientId}`);
                  const responseData = await response.json();
        
                  // Check for success (200) and internal status code
                  if (response?.status === 200) {
                      if (responseData?.statusCode === 200) {
                          if (responseData?.message.toLowerCase() === 'success') {
                              if (responseData?.dataJ?.length > 0) {                            
                                const taskData = responseData.dataJ[0];

                                setTaskInfo({
                                  ...taskData, // Spread all the properties from the response data
                                  task_priority_name: taskData.task_priority === 1 ? "Yes" : "No" // Conditional check
                                });
                                const taskStatus = {label:taskData?.task_status_name || '',value:taskData?.task_status || 0}
                                const assignedTo = {label:taskData?.employee_id_assigned_name || '',value:taskData?.employee_id_assigned || 0}
                                setAssignedTo(assignedTo)

                                setTaskStatus(taskStatus);
                                setStatus(taskStatus)
                              } else {
                                setTaskInfoError(responseData?.info || 'No credentials found.');
                              }
                          } else {
                            setTaskInfoError(responseData?.info || 'Failed to fetch credentials.');
                          }
                      } else {
                        setTaskInfoError(responseData?.info || 'Failed to fetch credentials.');
                      }
                  }
                  // Handle specific token-related errors
                  else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                      if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                        setTaskInfoError('Unauthorized access. Your session may have expired. Please log in again.');
                      } else {
                        setTaskInfoError(responseData?.info || 'Bad request. Please check the request parameters.');
                      }
                  }
                  // Internal Server Error
                  else if (response?.status === 500) {
                    setTaskInfoError(responseData?.info || 'Internal Server Error. Please try again later.');
                  }
                  // Handle unexpected errors
                  else {
                    setTaskInfoError('Unexpected error occurred. Please try again later.');
                  }
              } catch (error) {
                  // Handle various error scenarios
                  if (error.response) {
                    setTaskInfoError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch credentials. Please try again.'}`);
                  } else if (error.request) {
                    setTaskInfoError('No response received from the server. Please check your network connection and try again.');
                  } else {
                    setTaskInfoError(`Failed to fetch credentials. Please check your network connection and try again.`);
                  }
              } finally {
                setTaskInfoLoading(false)
              }
          };


          const fetchClientTaskCheckList = async () => {
            //console.log('Fetching user credentials');
            try {
             setTaskCheckLoading(true); // Start loading
              setTaskCheckError(''); // Clear any existing error
              setTaskCheckList([])
      
                const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      
                // Make the API call
                const response = await getData(`${ORG_GET_CLIENT_TASK_CHECK_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}&task_id=${task?.task_id}`);
                const responseData = await response.json();
      
                // Check for success (200) and internal status code
                if (response?.status === 200) {
                    if (responseData?.statusCode === 200) {
                        if (responseData?.message.toLowerCase() === 'success') {
                            if (responseData?.dataJ?.length > 0) {                            
                              setTaskCheckList(responseData?.dataJ);
                            } else {
                              setTaskCheckError(responseData?.info || 'No check list found.');
                            }
                        } else {
                          setTaskCheckError(responseData?.info || 'Failed to fetch check list.');
                        }
                    } else {
                      setTaskCheckError(responseData?.info || 'Failed to fetch check list.');
                    }
                }
                // Handle specific token-related errors
                else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                    if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                      setTaskCheckError('Unauthorized access. Your session may have expired. Please log in again.');
                    } else {
                      setTaskCheckError(responseData?.info || 'Bad request. Please check the request parameters.');
                    }
                }
                // Internal Server Error
                else if (response?.status === 500) {
                  setTaskCheckError(responseData?.info || 'Internal Server Error. Please try again later.');
                }
                // Handle unexpected errors
                else {
                  setTaskCheckError('Unexpected error occurred. Please try again later.');
                }
            } catch (error) {
                // Handle various error scenarios
                if (error.response) {
                  setTaskCheckError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch check list. Please try again.'}`);
                } else if (error.request) {
                  setTaskCheckError('No response received from the server. Please check your network connection and try again.');
                } else {
                  setTaskCheckError(`Failed to fetch check list. Please check your network connection and try again.`);
                }
            } finally {
              setTaskCheckLoading(false)
            }
        };

      const downloadDocuments =async (docId,index,isInternal)=>{
        try {
          setCurrentDocIndex(index);
          if (isInternal) {
            setInternalLoading(true);
            setInternalDownloadError('');
        } else {
            setExternalLoading(true);
            setExternalDownloadError('');
        }
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_DOWNLOAD_DOCUMENT_RACK}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}&doc_id=${docId}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    const downloadUrl = responseData?.dataJ?.[0]?.download_url;
                    if (downloadUrl) {
                        try {
                          // Fetch the file as a blob
                          const fileResponse = await fetch(downloadUrl);
                          const blob = await fileResponse.blob();
              
                          // Create a temporary object URL for the blob
                          const url = window.URL.createObjectURL(blob);
              
                          // Create a link and trigger download
                          const link = document.createElement('a');
                          link.href = url;
              
                          // Extract the file extension from the download URL or set a default
                          const fileExtension = downloadUrl.includes('.')
                            ? downloadUrl.split('.').pop().split('?')[0]
                            : ''; // Default to 'pdf' if no extension found
              
                          const filename = `Document_${docId}.${fileExtension}`;
                          link.setAttribute('download', filename);
              
                          document.body.appendChild(link);
                          link.click();
              
                          // Clean up
                          document.body.removeChild(link);
                          window.URL.revokeObjectURL(url);
                        } catch (error) {
                          console.error('Error downloading file:', error);
                          if(isInternal){
                            setInternalDownloadError('An error occurred while attempting to download the file. Please try again.')
                          }else{
                            setExternalDownloadError('An error occurred while attempting to download the file. Please try again.')
                          }
                        }
                      } else {
                        // setKYCDownloadError('Download URL not available.');
                        if(isInternal){
                          setInternalDownloadError('Download URL not available.')
                        }else{
                          setExternalDownloadError('Download URL not available.')
                        }
                      }
                  } else {
                    // setKYCDownloadError(responseData?.info || 'Data Not Found');
                    if(isInternal){
                      setInternalDownloadError(responseData?.info || 'Data Not Found');
                    }else{
                      setExternalDownloadError(responseData?.info || 'Data Not Found');
                    }
                  }
                } else {
                    // setKYCDownloadError(responseData?.info || 'Failed to fetch KYC document. Please try again.');
                    if(isInternal){
                      setInternalDownloadError(responseData?.info || 'Failed to fetch KYC document. Please try again.');
                    }else{
                      setExternalDownloadError(responseData?.info || 'Failed to fetch KYC document. Please try again.');
                    }
                }
              } else {
                // setKYCDownloadError(responseData?.info || 'Failed to fetch KYC document.');
                if(isInternal){
                  setInternalDownloadError(responseData?.info || 'Failed to fetch KYC document.');
                }else{
                  setExternalDownloadError(responseData?.info || 'Failed to fetch KYC document.');
                }
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                // setKYCDownloadError('Unauthorized access. Your session may have expired. Please log in again.');
                if(isInternal){
                  setInternalDownloadError('Unauthorized access. Your session may have expired. Please log in again.');
                }else{
                  setExternalDownloadError('Unauthorized access. Your session may have expired. Please log in again.');
                }
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                // setKYCDownloadError(responseData?.info || 'Bad request. Please check the request parameters.');
                if(isInternal){
                  setInternalDownloadError(responseData?.info || 'Bad request. Please check the request parameters.');
                }else{
                  setExternalDownloadError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
                // setKYCDownloadError(responseData?.info || 'Internal Server Error. Please try again later.');
                if(isInternal){
                  setInternalDownloadError(responseData?.info || 'Internal Server Error. Please try again later.');
                }else{
                  setExternalDownloadError(responseData?.info || 'Internal Server Error. Please try again later.');
                }
            } 
            // Unexpected or network-related errors
            else {
                // setKYCDownloadError('Unexpected error occurred. Please try again later.');
                if(isInternal){
                  setInternalDownloadError('Unexpected error occurred. Please try again later.');
                }else{
                  setExternalDownloadError('Unexpected error occurred. Please try again later.');
                }
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              // setKYCDownloadError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch KYC document. Please try again.'}`);
              if(isInternal){
                setInternalDownloadError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch KYC document. Please try again.'}`);
              }else{
                setExternalDownloadError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch KYC document. Please try again.'}`);
              }
            } else if (error.request) {
              // Request was made but no response was received
              // setKYCDownloadError('No response received from the server. Please check your network connection and try again.');
              if(isInternal){
                setInternalDownloadError('No response received from the server. Please check your network connection and try again.');
              }else{
                setExternalDownloadError('No response received from the server. Please check your network connection and try again.');
              }
            } else {
              // An error occurred in setting up the request
              // setKYCDownloadError(`Failed to fetch KYC document. Please check your network connection and try again.`);
              if(isInternal){
                setInternalDownloadError(`Failed to fetch KYC document. Please check your network connection and try again.`);;
              }else{
                setExternalDownloadError(`Failed to fetch KYC document. Please check your network connection and try again.`);;
              }
            }
          } finally {
            setCurrentDocIndex(null);
            if (isInternal) {
              setInternalLoading(false);
          } else {
              setExternalLoading(false);
          }
          }
      }

      const handleFieldChange = (key, fieldData) => {
        // console.log('key ',key, 'fieldData:',fieldData)
        setTaskInfo((prev) => {
          // If the key is "client_type_name", update label and related ID
          if (key === "financial_period") {
            return {
              ...prev,
              [key]: fieldData.value, 
              // financial_period: fieldData.value, 
            };
          }
          if (key === "financial_year") {
            return {
              ...prev,
              [key]: fieldData.value, 
              // financial_year: fieldData.value, 
            };
          }

          if(key === "task_priority_name"){
            return {
              ...prev,
              [key]: fieldData?.label, 
              task_priority: fieldData.value, 
            };
          }
          if(key === "service_name"){
            return {
              ...prev,
              [key]: fieldData?.label, 
              service_id: fieldData.value, 
            };
          }
          if(key === "service_sub_name"){
            return {
              ...prev,
              [key]: fieldData?.label, 
              service_sub_id: fieldData.value, 
            };
          }
          
          // For other keys, update normally
          return {
            ...prev,
            [key]: fieldData, // Update with the provided value
          };
        });
        if(key === "service_name"){
          fetchTaskSubCategorieList(fieldData?.value);
        }
      };

      const onEdit = ()=>{
        setIsEditable(!isEditable);
        const selServiceCategory = { 
          label: taskInfo?.service_sub_name,
          value: taskInfo?.service_sub_id
        }
        fetchTaskSubCategorieList(taskInfo?.service_id);
        handleFieldChange('service_sub_name',selServiceCategory)

        // setFieldConfigs((prevConfigs) =>
        //   prevConfigs.map((field) =>
        //     field.key === "service_sub_name"
        //       ? { ...field, error: '' }
        //       : field
        //   )
        // );
      }

      const handleSearchEmployee = async () => {
   
        try {
            setError('');    
            if (!taskInfo?.employee_id_assigned_name) {
              setError('Please enter valid text'); return;
          }       
            setLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {  emp_id_auth: storedUserInfo?.emp_id,  search_type: "GENERIC", search_word: taskInfo?.employee_id_assigned_name}
        
            //console.log('handleSearchEmployee payload',payLoad);
            // Make the API call
            const response = await postData(ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER,payLoad);
            const responseData = await response.json();
            //console.log('response Data',responseData);
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                    setAddEmployeeError('')
                    setEmployeeList(responseData?.dataJ || []);
                //   setError(responseData?.info);
                  setTimeout(() => {
                    // handleClose();
                    // onValueChange();
                  }, 3000);
                } else {
                  setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Task Categorie data.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
          } finally {
            setLoading(false);
          }
    
        // onValueChange(teamData);
        // onClose();
    };

      const handleSelectedEmployee = (employee) => {
        //console.log('selected employe',employee)
        handleFieldChange('employee_id_assigned',employee?.emp_id);
        handleFieldChange('employee_id_assigned_name',employee?.emp_name);
        handleFieldChange('employee_id_tl_name',employee?.employee_id_tl_name || '');
        handleFieldChange('employee_id_tm_name',employee?.employee_id_tm_name || '');
        setEmployeeList([]); // Clear the dropdown 
      };

   const fetchEmpList = async () => {
      try {
        setEmpLoading(true);
        setEmpResError('');
        setAssignEmployeeList([]);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
  
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                const filterData = responseData?.dataJ.map(service => ({
                  label: service?.emp_name,
                  value: service?.emp_id
                }));
                setAssignEmployeeList(filterData);
              } else {
                setEmpResError(responseData?.info || 'Data Not Found');
              }
            } else {
              setEmpResError(responseData?.info || 'Failed to fetch Employee data. Please try again.');
            }
          } else {
            setEmpResError(responseData?.info || 'Failed to fetch Employee data.');
          }
        } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setEmpResError('Unauthorized access. Your session may have expired. Please log in again.');
          } else {
            setEmpResError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } else if (response?.status === 500) {
          setEmpResError(responseData?.info || 'Internal Server Error. Please try again later.');
        } else {
          setEmpResError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        if (error.response) {
          setEmpResError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Employee data. Please try again.'}`);
        } else if (error.request) {
          setEmpResError('No response received from the server. Please check your network connection and try again.');
        } else {
          setEmpResError(`Failed to fetch Employee data. Please check your network connection and try again.`);
        }
      } finally {
        setEmpLoading(false);
      }
    }

       const fetchYearList = async () => {
          try {
            setYearLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      
            // Make the API call
            const payLoad = { "code_id": "org_all_codes" }
            const response = await postData(ORG_GET_ALL_CODES_LIST, payLoad);
            const responseData = await response.json();
      
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ) {
                    // Transforming the data
                    const yerarListOption = await responseData?.dataJ?.financial_year_codes_list.map(service => ({
                      label: service.year_name,
                      value: service.year_id
                    }));
                    setYearList(yerarListOption);
                    setFieldConfigs((prevConfigs) =>
                      prevConfigs.map((field) =>
                        field.key === "financial_year"
                          ? { ...field, options: yerarListOption }
                          : field
                      )
                    );
      
                    // Transforming the data
                    const periodOption = await responseData?.dataJ?.financial_period_codes_list.map(service => ({
                      label: service.pc_name,
                      value: service.pc_id
                    }));
                    setPeriodList(periodOption)
                    setFieldConfigs((prevConfigs) =>
                      prevConfigs.map((field) =>
                        field.key === "financial_period"
                          ? { ...field, options: periodOption }
                          : field
                      )
                    );
                  } else {
                    setYearError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setYearError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
              } else {
                setYearError(responseData?.info || 'Failed to fetch Task Categorie data.');
              }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setYearError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setYearError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setYearError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
              setYearError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setYearError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setYearError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setYearError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
          } finally {
            setYearLoading(false);
          }
        }

         const onUpdateTaskInfo=async ()=>{
          console.log('taskInfo?.service_sub_id :',taskInfo?.service_sub_id)
            try {
              setUpdateStatusError('');
              if (task?.task_status === 1) {
              const updatedFieldConfigs = fieldConfigs.map((field) => ({
                ...field,
                error: "", // Reset errors initially for all fields
              }));
            
              let hasError = false;
              const validatedFields = updatedFieldConfigs.map((field) => {
                let error = "";
                const value = taskInfo?.[field.key]; // Retrieve the value for this field using its key
            
                // Validation logic based on the key
                switch (field.key) {
                  case "service_sub_name":
                    if (!value) {
                      error = `${field.label} must be selected.`;
                      hasError = true;
                    }
                    break;
                  default:
                    break;
                }
            
                return { ...field, error }; // Attach the error message to the field
              });
              setFieldConfigs(validatedFields);
    
              if (hasError) { return; }
            }
              // if (task?.task_status === 1 && !taskInfo?.service_sub_id) {
              //   setUpdateStatusError("Please select Service Category")
              //   return;
              // }
              if (!taskInfo.start_date) {
                setUpdateStatusError("Please select the start date")
                return;
              }
        
              if (!taskInfo.due_date) {
                setUpdateStatusError("Please select the end date")
                return;
              }
              const startDateObj = new Date(taskInfo?.start_date);
              const endDateObj = new Date(taskInfo?.due_date);
                 // Validation: Start date should not be greater than the end date
            if (taskInfo?.start_date && taskInfo?.due_date && startDateObj > endDateObj) {
              setUpdateStatusError("Due date cannot be earlier than the start date.");return
            }
              const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
              const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "client_id": taskInfo?.client_id,
                "task_id":taskInfo?.task_id,
                "task_description": taskInfo?.task_description,
                "task_start_date": taskInfo?.start_date,
                "task_due_date": taskInfo?.due_date,
                "task_priority": taskInfo?.task_priority,
                "task_bill_amount": taskInfo?.agreed_invoice_amount || 0,
                "task_bill_amount_estimated":taskInfo?.estimated_invoice_amount || 0,
                "financial_year": taskInfo?.financial_year,
                "financial_period": taskInfo?.financial_period,
                "employee_id_assigned": taskInfo?.employee_id_assigned,
                "service_id": taskInfo?.service_id,
                "service_sub_id": taskInfo?.service_sub_id
              }
              console.log('onUpdateTaskInfo payload:---',payLoad);
              
              setUpdateLoading(true);
              const response = await postData(ORG_UPDATE_CLIENT_TASK_INFO,payLoad);
              const responseData = await response.json();
          
              // Check for success (200) and internal status code
              if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                  if (responseData?.message.toLowerCase() === 'success') {
                    //console.log("Team update success");
                    setUpdateStatusSuccess(responseData?.info || 'Task update success.');
                    setTimeout(() => {
                      setIsEditable(false)
                      fetchClientTaskList();
                      setUpdateStatusSuccess('')
                    }, 3000);
                  } else {
                    setUpdateStatusError(responseData?.info || 'Failed to update Task Info. Please try again.');
                  }
                } else {
                  setUpdateStatusError(responseData?.info || 'Failed to update Task Info.');
                }
              } 
              // Token-related errors: 400, 401, 403
              else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                  setUpdateStatusError('Unauthorized access. Your session may have expired. Please log in again.');
                } else {
                  setUpdateStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
              } 
              // Internal Server Error: 500
              else if (response?.status === 500) {
                setUpdateStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
              } 
              // Unexpected or network-related errors
              else {
                setUpdateStatusError('Unexpected error occurred. Please try again later.');
              }
            } catch (error) {
              // Handle various error scenarios
              if (error.response) {
                // Server responded with a status outside the 2xx range
                setUpdateStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update Task Info. Please try again.'}`);
              } else if (error.request) {
                // Request was made but no response was received
                setUpdateStatusError('No response received from the server. Please check your network connection and try again.');
              } else {
                // An error occurred in setting up the request
                setUpdateStatusError(`Failed to update Task Info. Please check your network connection and try again.`);
              }
            } finally {
              setUpdateLoading(false);
            }
          }

          const handleSaveTaskStatus=async ()=>{
            try {
              setSaveError('');
              setEmpResError('');
              // if(!selectedTaskCheck?.task_check_id){
              //   setSaveError ('Please select one checklist.');return
              // }

              // if(!status?.value){
              //   // setStatusError ('Please select status');
              //   setSaveError ('Please select a status.');return
              // }

              // if(!taskComment){
              //   // setCommentError ('Please enter a comment.');
              //   setSaveError ('Please enter a comment.');return
              // }

              if(!assignedTo?.value){
                setEmpResError ('Please select assigner.');return
              }

              const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
              const payLoad = {
                  "emp_id_auth": storedUserInfo?.emp_id,
                  "task_id": task?.task_id,
                  "employee_id_assigned": assignedTo?.value,
                  "event_action": "TASK_ASSIGN_EMP",
                  "comment_text": taskComment
                }
                // {
                //   "emp_id_auth": storedUserInfo?.emp_id,
                //   "task_id": task?.task_id,
                //   "task_status": status?.value,
                //   "comment_text": taskComment || '',
                //   "employee_id_assigned_update": assignedTo?.value || 0
                //   }
              //console.log('task status:---',payLoad);
              
              setSaveLoading(true);
              const response = await postData(ORG_UPDATE_CLIENT_TASK_STATUS,payLoad);
              const responseData = await response.json();
          
              // Check for success (200) and internal status code
              if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                  if (responseData?.message.toLowerCase() === 'success') {
                    setSaveSucess(responseData?.info || 'Task Status success.');
                    setTimeout(() => {
                      fetchClientTaskList();
                      setSaveSucess('');
                      setTaskComment('')
                    }, 3000);
                  } else {
                    setSaveError(responseData?.info || 'Failed to update Task Status. Please try again.');
                  }
                } else {
                  setSaveError(responseData?.info || 'Failed to update Task Status.');
                }
              } 
              // Token-related errors: 400, 401, 403
              else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                  setSaveError('Unauthorized access. Your session may have expired. Please log in again.');
                } else {
                  setSaveError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
              } 
              // Internal Server Error: 500
              else if (response?.status === 500) {
                setSaveError(responseData?.info || 'Internal Server Error. Please try again later.');
              } 
              // Unexpected or network-related errors
              else {
                setSaveError('Unexpected error occurred. Please try again later.');
              }
            } catch (error) {
              // Handle various error scenarios
              if (error.response) {
                // Server responded with a status outside the 2xx range
                setSaveError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update Task Status. Please try again.'}`);
              } else if (error.request) {
                // Request was made but no response was received
                setSaveError('No response received from the server. Please check your network connection and try again.');
              } else {
                // An error occurred in setting up the request
                setSaveError(`Failed to update Task Status. Please check your network connection and try again.`);
              }
            } finally {
              setSaveLoading(false);
            }
          }

          
    const handleHoldTask = (taskInfo,isHold,type) => {
      const taskStatus = {taskInfo:taskInfo,isHold:isHold,type:type}
      setIsHoldTask(taskStatus);
      setIsModalOpen(true); // Open the modal
      
  };

  const handleTaskIssue = (taskInfo,isHold,type) => {
    const taskStatus = {taskInfo:taskInfo,isHold:isHold,type:type}
    setIsIssueTask(taskStatus);
    setIsIssueModalOpen(true); // Open the modal
    
};
const handleCloseIssueModal = () => {
  setIsIssueModalOpen(false);
};


  const handleCloseModal = () => {
      setIsModalOpen(false);
  };


  const handleStatusChange = (value) => {
      setStatus(value);
  };

  const handleAssignedToChange = (value) => {
      setAssignedTo(value);
  };
  const handleComplitedBy =(value) =>{
      setComplitedBy(value)
  }

  const handleRowClick = (item,index) => {
    //   setTaskCheckList((prev) =>
    //     prev.map((item, idx) =>
    //         idx === index ? { ...item, selected: true } : { ...item, selected: false }
    //     )
    // );
    // setSelectedTaskCheck(item); // Update the selected item
    const taskStatus = {taskInfo:item}
    setIsTaskCheck(taskStatus);
    setIsTaskCheckModalOpen(true); // Open the modal
  };



  const handleAddDocument = (source) => {
      setSelectedDocument(null);
      setModalSource(source); // Set modal source: "external" or "internal"
      setModalVisible(true);
  };

  const handleAddDocumentCloseModal = () => {
      setModalVisible(false);
  };

  const handleCommentChange = (e) => {
    setTaskComment(e.target.value); // Update the state with the new value
  };

  const handleUpdatePriority = (priority)=>{
    //console.log('Priority:-',priority);
    const taskStatus = {priority:priority}
      setIsPriority(taskStatus);
      setIsPriorityModalOpen(true); // Open the modal
    
  }
  const handleClosePriorityModal = ()=>{
    setIsPriorityModalOpen(false);
  }

  const onStatusChangeSuccess = ()=>{
    fetchClientTaskList();
  }

  const handleCloseTaskCheckModal=()=>{
    setIsTaskCheckModalOpen(false)
  }
  const onTaskCheckStatusSuccess=()=>{
    fetchClientTaskCheckList();
  }
  const handleCloseScheduleDoModal = () =>{
    setIsScheduleModalOpen(false)
  }

  const handleScheduleAppointment = ()=>{
    setIsScheduleModalOpen(true)
  }

      return{
        kycListError,
        kycDocumentList,
        kycListLoading,
        internalRecords,
        externalRecords,
        fetchKYCDocumentsList,
        downloadDocuments,
        downloadKycLoading,
        kycDownloadError,
        externalDownloadError,
        internalDownloadError,
        externalLoading,
        internalLoading,
        currentDocIndex,
        taskInfo,isEditable,
        fieldConfigs,
        taskInfoError,
        taskInfoLoading,
        handleFieldChange,
        onEdit,
        handleSelectedEmployee,
        handleSearchEmployee,
        addEmployeeError,
        employeeList,
        loading,
        updateLoading,
        updateStatusError,
        updateStatusSuccess,
        onUpdateTaskInfo,
        setTaskCheckList,
        taskCheckList,
        taskCheckError,
        taskCheckLoading,
        handleSaveTaskStatus,
        saveLoading,
        saveError,
        saveSuccess,
        handleHoldTask,
        handleCloseModal,
        handleStatusChange,
        handleAssignedToChange,
        handleComplitedBy,
        handleRowClick,
        handleAddDocument,
        handleAddDocumentCloseModal,
        status,
        assignedTo,
        isModalOpen,
        complitedBy,
        selectedDocument,
        modalVisible,
        modalSource,
        setSelectedDocument,
        handleCommentChange,
        taskComment,commentError,statusError,checklistError,
        isHoldTask,
        handleUpdatePriority,
        isPriorityModalOpen,handleClosePriorityModal,isPriority,onStatusChangeSuccess,
        taskStatus,setTaskStatus,assignEmployeeList,empLoading,empResError,
        isTaskCheckModalOpen,handleCloseTaskCheckModal,isTaskCheck,onTaskCheckStatusSuccess,
        isScheduleModalOpen,handleCloseScheduleDoModal,
        handleScheduleAppointment,
        kycDocClientError,
        kycDocInternalError,handleTaskIssue,isIssueTask,isIssueModalOpen,handleCloseIssueModal
      }

}

export default ClientTaskDetailsController