
import React from 'react';
import Input from '../../../../../Components/Input';
import uploadIcon from '../../../../../Assets/Icons/upload.png'
import InvoiceReceiptBooksController from '../../../../Controllers/AdminScreens/Invoices/Settings/InvoiceReceiptBooksController';
import deleteIcon from '../../../../../Assets/Icons/delete.png'
import editIcon from '../../../../../Assets/Icons/edit.png';
import { useNavigate } from 'react-router-dom';


const InvoiceReceiptBooksScreen = () => {
    const {
        invoiceReceiptBookData,
        getStatusStyle
    } = InvoiceReceiptBooksController();

    const navigator = useNavigate()
    const handleNavigateToUser = ()=>{
        navigator('/manage-user')
    }

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Receipt Books</h2>
            <div className='basic-info-card' style={{ border: 'none', padding: '0px' }}>
        <div style={styles.tableWrapper}>
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
              <th style={{ width: "15%" }}>Receipt Books</th>
                <th style={{ width: "15%" }}>Organization Name</th>
                <th style={{ width: "15%" }}>Seq No.</th>
                <th style={{ width: "15%" }}>GST No.</th>
                <th style={{ width: "15%" }}>Phone No.</th>
                <th style={{ width: "10%" }}>Signatory</th>
                <th style={{ width: "10%" }}>Status</th>
                <th style={{ width: "5%" }}></th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {invoiceReceiptBookData?.map((category, index) => (
                <tr key={index}>
                    <td style={styles.tableLink}>{category.receiptBook}</td>
                  <td style={styles.tableValue}>{category.organization}</td>
                  <td style={styles.tableValue}>{category.seqNo}</td>
                  <td style={styles.tableValue}>{category.gstNo}</td>
                  <td style={styles.tableValue}>{category.phone}</td>
                  <td style={styles.tableValue}>{category.signatory}</td>
                  <td ><div
                        style={{
                          display: "inline-block", // Makes the div wrap content and have its own width
                          padding: "5px 5px", // Padding for the button-like appearance
                          borderRadius: "15px",
                          ...getStatusStyle(category.status), // Dynamic styles based on the status
                          width: "auto", // Width adjusts automatically to content
                        }}
                      >
                        {category.status}
                      </div></td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "2px", // Space between edit and delete buttons
                      }}
                    >
                      {/* Edit Button */}
                      <button
                        className="tasksScreen-edit-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          style={{ width: "16px", height: "16px" }}
                          onClick={handleNavigateToUser}
                        />
                      </button>

                      {/* Delete Button */}
                      <button
                                    className="tasksScreen-delete-button"
                                    style={{
                                    border: "none",
                                    background: "transparent",
                                    cursor: "pointer",
                                    }}
                                >
                                    <img
                                    src={deleteIcon}
                                    alt="Delete"
                                    style={{ width: "16px", height: "16px" }}
                                    />
                                </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
          {/* <AddUserModal isOpen={isModalOpen} onClose={handleCloseModal}/> */}
        </div>

      </div>

            <hr className="footer-divider" />
            <div className="button-container" style={{ gap: "10px" }}>
                <button className="add-client-button" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                    Update
                </button>
                <button style={{ ...styles.cancelButton, paddingLeft: '30px', paddingRight: '30px' }}>
                    Cancel
                </button>
            </div>
        </div>
    );

}

const styles = {
    // container: {
    //   width: "600px",
    //   margin: "0 auto",
    //   fontFamily: "Arial, sans-serif",
    // },
    title: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: '500',
        marginBottom: '5px'
    },
    field: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
    },
    label: {
        flex: "0 0 200px", // Ensures all labels are the same width
        color: 'black',
        fontSize: 14,
        fontFamily: 'Roboto',
        fontWeight: '400',
    },
    // input: {
    //   flex: "1",
    //   height: "30px",
    //   padding: "5px 10px",
    //   fontSize: "14px",
    //   border: "1px solid #d1d5db",
    //   borderRadius: "4px",
    // },

    addServiceButton: {
        backgroundColor: "transparent",
        border: "1px #858585 solid",
        borderRadius: "5px",
        padding: "8px 12px",
        color: "#40513B",
        cursor: "pointer",
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: '400',
        borderRadius: '28px',
        // marginBottom:'16px'
        display: "flex", // Use flexbox for alignment
        alignItems: "center", // Vertically align icon and text
        justifyContent: "center",
    },
    icon: {
        width: "12px",
        height: "12px",
        marginRight: "10px",
    },
    cancelButton: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        color: "#40513B",
        border: "1px solid #858585",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    },
    textarea: {
        border: "1px solid #d1e3ed",
        borderRadius: "5px",
        fontSize: "14px",
        padding: "10px",
        width: "100%",
        resize: "none", // Prevents resizing for consistent appearance
    },
    tableWrapper: {
        overflowX: "auto", // Horizontal scroll
        whiteSpace: "nowrap", // Prevent table from wrapping on small devices
        // marginBottom:50
      },
      tableValue: {
        colour: '#192A3E',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px'
      },
      tableLink: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        textDecoration: 'underline',
        color: '#5A55D2'
      },
};

export default InvoiceReceiptBooksScreen