import React from 'react';
// import './App.css';
import notifIcon from '../Assets/Icons/notificationIcon.png'
import AddClientModal from './AddClientModal';
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import searchIcon from '../Assets/Icons/search-icon.png';
import quickActionIcon from '../Assets/Icons/bitcoin-convert.png';
import plusIcon from '../Assets/Icons/Pluse.png'
import CreateEntryModal from './Modals/CreateEntryModal';
import Loader from './Loader';
import error from '../Assets/Icons/error_message.png';
import { postData, getData } from '../ApiServices/ApiService';
import { ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_GET_NOTIFICATIONS_LIST_HEADER,ORG_CHANGE_NOTIFICATION_READ_STATUS_HEADER } from '../ApiServices/BaseURL';
import LoadingSkeleton from './LoadingSkeleton';
import AddToDoModal from './Modals/AddToDoModal';
import downArrowIcon from '../Assets/Icons/chevron-down.png';

const Header = ({ title, onValueChange, onDashboardRefresh }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  const [searchText, setSearchText] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [searchClientList, setSearchClientList] = useState([]);

  const [selectedDocument, setSelectedDocument] = useState(null);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 420)
  const [isOpen, setIsOpen] = useState(false);

  const quickActions = [
    { label: 'Add Client', modal: 'addClient' },
    // { label: 'Post Time Sheet', modal: 'postTimeSheet' },
    // { label: 'Leave/Attendance', modal: 'leaveAttendance' },
    // { label: 'Add Expense', modal: 'addExpense' },
    { label: 'Document In/Out', modal: 'documentInOut' },
    // { label: 'Add Notes', modal: 'addNotes' },
    // { label: 'Create Task', isActive: true },
    { label: 'Add To-Do', modal: 'addToDoModal' },
    { label: 'Leave/ Attendance', modal: 'leaveAttendance' },
    { label: 'Add Expense', modal: 'addExpense' },
    { label: 'Approvals', modal: 'approvals' },
  ];
  const location = useLocation();
  const navigator = useNavigate();

  useEffect(() => {
    console.log('useEffect header');
    setSearchText('');
    setSearchError('');
    setSearchClientList([]);
    setNofificationsList([])
    setNotifcationError('');
    setUnreadCount(0)
  }, [location])

  useEffect(() => {
    handleNotificationsList();
  }, [location])

  const handleAddClientClick = () => {
    setIsModalOpen(true);
    setIsOpen(false);
  };
  const handleAddTaskClick = () => {
    navigator('/create-task')
    setIsOpen(false);
  };
  const handleShowQuickActionClick = () => {
    setIsActionModalOpen(true);
  };

  const handleCloseActionModal = () => {
    setIsActionModalOpen(false);
  }

  const handleQuickActionClick = (modalType) => {
    setActiveModal(modalType); // Open specific modal
    setIsActionModalOpen(false); // Close Quick Actions modal
  };


  const handleCloseModal = () => {
    setActiveModal(null);
    setIsModalOpen(false);
  };

  const hadleNotification = (notifications) => {
    navigator('/resourceslist');
    setDropdownOpen((prev) => !prev);
  }
  const handleSearch = () => {
    navigator('/clientlist')
  }

  const handleInputChange = (value) => {
    setSearchText(value);
    setSearchError('');
    setSearchClientList([]);
  }

  const [NotificationsList, setNofificationsList] = useState([]);
  const [notificationError, setNotifcationError] = useState('');
  const [notificationLoading, setNofificationLoading] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0);

  const handleNotificationsList = async () => {
    try {
      setNotifcationError('');
      setNofificationLoading(true);
      setNofificationsList([]);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      //console.log('handleSearchClient header payload', payLoad);
      const response = await getData(`${ORG_GET_NOTIFICATIONS_LIST_HEADER}?emp_id_auth=${storedUserInfo?.emp_id}&icon_type=${'bell'}`);
      const responseData = await response.json();
      //console.log('response Data', responseData);
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {

            setNofificationsList(responseData?.dataJ);
            setUnreadCount(responseData?.dataJ?.filter((item) => item?.is_read === 0 || item?.is_read === null).length);
          } else {
            setNotifcationError(responseData?.info || 'Failed to fetch Client. Please try again.');
          }
        } else {
          setNotifcationError(responseData?.info || 'Failed to fetch Client.');
        }
      } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setNotifcationError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setNotifcationError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else {
        setNotifcationError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      setNotifcationError(`Failed to fetch client. Please check your network connection and try again.`);
    } finally {
      setNofificationLoading(false);
    }
  };

  const[notificationReadSuccess, setNotificationReadSuccess] = useState({});
  const[notificationReadError, setNotificationReadError] = useState({});
  const[notificationReadLoading, setNotificationReadLoading] = useState({});

  const handleNotificationRead = async (nftId) => {
     
          try {
            setNotificationReadSuccess((prev) => ({ ...prev, [nftId]: '' }));
            setNotificationReadError((prev) => ({ ...prev, [nftId]: '' }))
              // if (!searchUser) {
              //   setError('Please enter valid text'); return;
              // }         
              setNotificationReadLoading((prev) => ({ ...prev, [nftId]: true }));
              const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
              const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, ntfn_id:nftId, is_read:1}
              console.log("notification read payLoad", payLoad)
              // return
              // Make the API call
              const response = await postData(ORG_CHANGE_NOTIFICATION_READ_STATUS_HEADER,payLoad);
              const responseData = await response.json();
              //console.log('response Data',responseData);
          
              // Check for success (200) and internal status code
              if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                  if (responseData?.message.toLowerCase() === 'success') {
                    setNotificationReadError((prev) => ({ ...prev, [nftId]: '' }))
                    setNotificationReadSuccess((prev) => ({ ...prev, [nftId]: responseData?.info }));
                      setUnreadCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
                      setTimeout(()=>{
                        setNotificationReadSuccess((prev) => ({ ...prev, [nftId]: '' }));
                        handleNotificationsList();
                        // onRefresh();
                      },2000);
                      // setEmployeeList(responseData?.dataJ || []);
                  } else {
                    // setNotificationReadError(responseData?.info || 'Failed to delete kyc document. Please try again.');
                    setNotificationReadError((prev) => ({ ...prev, [nftId]: responseData?.info || 'Failed to update notification status.Please try again.' }));
                  }
                } else {
                  setNotificationReadError((prev) => ({ ...prev, [nftId]: responseData?.info || 'Failed to update notification status' }));
                }
              } 
              // Token-related errors: 400, 401, 403
              else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                  setNotificationReadError((prev) => ({ ...prev, [nftId]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
                  // Optionally, redirect to the login page or refresh the token
                  // Example: redirectToLogin();
                } else {
                  setNotificationReadError((prev) => ({ ...prev, [nftId]: responseData?.info || 'Bad request. Please check the request parameters.' }));
                }
              } 
              // Internal Server Error: 500
              else if (response?.status === 500) {
                setNotificationReadError((prev) => ({ ...prev, [nftId]: responseData?.info || 'Internal Server Error. Please try again later.' }));
              } 
              // Unexpected or network-related errors
              else {
                setNotificationReadError((prev) => ({ ...prev, [nftId]: 'Unexpected error occurred. Please try again later.' }));
              }
            } catch (error) {
              // Handle various error scenarios
              if (error.response) {
                // Server responded with a status outside the 2xx range
                const errorMessage = error.response
                ? `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update notification status. Please try again.'}`
                : 'Unexpected error occurred. Please try again later.';

                setNotificationReadError((prev) => ({ ...prev, [nftId]: errorMessage }));
              } else if (error.request) {
                // Request was made but no response was received
                setNotificationReadError((prev) => ({ ...prev, [nftId]: 'No response received from the server. Please check your network connection and try again.' }));
              } else {
                // An error occurred in setting up the request
                setNotificationReadError((prev) => ({ ...prev, [nftId]: 'Failed to update notification status. Please check your network connection and try again.' }));
              }
            } finally {
              setNotificationReadLoading((prev) => ({ ...prev, [nftId]: false }));
            }
      
          // onValueChange(teamData);
          // onClose();
      };

  const handleSearchClient = async () => {
    try {
      setSearchError('');
      setSearchLoading(true);
      setSearchClientList([]);
      if (!searchText) {
        setSearchError('Please enter client name to search'); return;
      }
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "NAME-FILEID", search_word: searchText }

      //console.log('handleSearchClient header payload', payLoad);
      const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSearchClientList(responseData?.dataJ || []);
          } else {
            setSearchError(responseData?.info || 'Failed to fetch Client. Please try again.');
          }
        } else {
          setSearchError(responseData?.info || 'Failed to fetch Client.');
        }
      } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setSearchError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setSearchError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else {
        setSearchError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      setSearchError(`Failed to fetch client. Please check your network connection and try again.`);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSelectClient = (selClient) => {
    console.log('handleSelectClient', selClient);
    setSearchText(selClient?.client_name);
    setSearchClientList([]);
    navigator('/manage-clients', { state: { task: 'PrimaryInformation', clientId: selClient?.client_id } })
  }

  const sampleNotifications = [
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
    'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...',
  ];
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!isDropdownOpen) {
      setNotificationReadError({});
    }
  }, [isDropdownOpen]);

  const handleNotification = () => {
    setDropdownOpen((prev) => !prev);
    setIsOpen(false);
  };
  // //console.log('titte', title)
  const handleNotificationClick = (index) => {
    setNofificationsList((prevList) => {
      const updatedList = [...prevList];
      if (!updatedList[index].is_read) {
        updatedList[index].is_read = true;
      }
      return updatedList;
    });
  };

  // Update screen width on resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 440);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="header-div">
      <div className="header" style={window.innerWidth < 610 ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '10px' } : {}}>
        <div className="header-controls" style={{ gap: isSmallScreen ? '10px' : '' }}>
          <div className="header-greeting">
            {title}
          </div>
          <div style={{ position: 'relative', width: '100%', maxWidth: 300, }}>
            <div style={{ ...styles.searchBar, border: searchError ? '1px solid red' : 'none' }}>
              <img src={searchIcon} alt="Search" style={styles.searchIcon} onClick={handleSearchClient} />
              {/* <span onClick={handleSearch} className='search-input' style={styles.searchInput}>Search Something...</span> */}
              <input type="text" placeholder="Search Something..."
                style={styles.searchInput}
                className='search-input'
                value={searchText || ''}
                onChange={(e) => handleInputChange(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSearchClient()}
              />
              <span style={{ paddingLeft: 10 }}> <Loader loading={searchLoading} color={'var(--primary-color)'} size='small' /> </span>
            </div>
            {searchError && (
              <div style={{ ...styles.errorMessage }}>
                <span>
                  <img
                    src={error}
                    alt="Error"
                    style={{ width: "12px", height: "12px" }}
                  />
                </span>{" "}
                {searchError}
              </div>
            )}
            {searchClientList.length > 0 && (
              <ul style={styles.dropdownList}>
                {searchClientList.map((client, index) => (
                  <li
                    key={client?.client_id}
                    onClick={() => handleSelectClient(client)}
                    style={{
                      ...styles.dropdownItem,
                      borderBottom: index !== searchClientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                    }}
                  >
                    {client?.client_name} ({client?.client_file_id})
                  </li>
                ))}
              </ul>
            )}
          </div>
          {/* Notification Button */}
          <div style={{ position: 'relative', paddingRight: isSmallScreen ? '10px' : '' }}>
            <button style={styles.notificationButton} onClick={handleNotification}>
              <img src={notifIcon} alt="Notification" style={styles.notificationIcon} />
              {/* {notificationLoading && (
            <div style={styles.iconLoader}>
              <Loader loading={true} size="small" color="red" />
            </div>
          )} */}
              {unreadCount > 0 && (
                <div style={styles.notificationDot}>{unreadCount}</div>
              )}
            </button>

            {isDropdownOpen && (
              <div style={styles.dropdownWrapper}>
                <div style={styles.arrow}></div>
                <div ref={dropdownRef} style={{ ...styles.dropdownContainer, width: isSmallScreen ? '260px' : '350px' }}>
                  <div style={styles.header}>
                    <h3 style={{ margin: 0, fontWeight: 400 }}>Notifications</h3>
                    <span style={styles.viewAll} onClick={() => hadleNotification(NotificationsList)}>
                      View All
                    </span>
                  </div>

                  {notificationLoading ? (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <LoadingSkeleton
                          key={index}
                          width="100%"
                          height="30px"
                        />
                      ))}
                    </div>
                  ) : (
                    NotificationsList?.map((notification, index) => (
                      <div
                        key={index}
                        // onClick={() => handleNotificationRead(notification?.ntfn_id)}
                        onClick={notification.is_read !== 1 ? () => handleNotificationRead(notification?.ntfn_id) : null}
                        style={{
                          ...styles.notificationItem,
                          backgroundColor: notification.is_read===1 ? '#ffffff' : '#f9f9f9',
                          marginBottom: '10px',
                          cursor: 'pointer'
                        }}
                      >
                        {notificationReadLoading[notification?.ntfn_id] ? (
                          <LoadingSkeleton width="100%" height="30px" />
                        ) : (
                          notification?.ntfn_text
                        )}
                        {notificationReadError[notification?.ntfn_id] && (
                          <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                            {notificationReadError[notification?.ntfn_id]}
                          </p>
                        )}
                        {notificationReadSuccess[notification?.ntfn_id] && (
                      <p style={{ color: 'green', fontSize: '12px', marginTop: '5px' }}>
                        {notificationReadSuccess[notification?.ntfn_id]}
                      </p>
                    )}
                      </div>
                    ))
                  )}


                  {notificationError && <div style={{ fontSize: '12px', color: 'red', marginTop: '5px', textAlign: 'center' }}>{notificationError} </div>}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* )} */}

        <div style={{ display: 'flex', gap: '10px', paddingLeft: '10px', alignItems: 'center' }}>
          {isSmallScreen ? (
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <button className="add-client-button-header" onClick={() => { setIsActionModalOpen(false); setIsOpen((prev) => !prev); }}>
                +&emsp; Add
                <img className="down-icon-sty" src={downArrowIcon} alt="Company Logo" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', filter: 'none', paddingLeft: '5px' }} />
              </button>
              {isOpen && (
                <div ref={dropdownRef} style={styles.dropdownList}>
                  <div
                    style={styles.dropdownItem}
                    onClick={handleAddClientClick}
                  >
                    Add Client
                  </div>
                  <div
                    style={styles.dropdownItem}
                    onClick={handleAddTaskClick}
                  >
                    Add Task
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <button className="add-client-button-header" onClick={handleAddTaskClick}>
                +&emsp; Add Task
              </button>

              <button className="add-client-button-header" onClick={handleAddClientClick}>
                +&emsp; Add Client
              </button>
            </>
          )}

          <div style={styles.quickActiondiv} onClick={handleShowQuickActionClick}>
            <img src={quickActionIcon} alt="Search" style={styles.quickActionIcon} />
          </div>

          {isActionModalOpen && (
            <div
              style={styles.modalOverlay}
              // className='modal-overlay-m'
              onClick={handleCloseActionModal}>
              <div
                style={{
                  ...styles.modalContent,
                }}
                className='modal-content-m'
              >
                <h3 style={styles.quickActionsTitle}>
                  <img src={quickActionIcon} alt="Quick Actions" style={styles.quickActionIcon} />
                  Quick Links
                </h3>

                <div style={styles.quickActionsContainer}>
                  {quickActions?.map((action, index) => (
                    <div
                      key={index}
                      style={{
                        ...styles.quickActionCard,
                        ...(action.isActive ? styles.activeCard : {}),
                      }}
                      onClick={() => handleQuickActionClick(action.modal)}
                    >
                      <div style={styles.quickActionContent}>
                        <div style={styles.quickActionLabel}>{action.label}</div>

                      </div>
                      <div style={styles.quickActionIconContainer}>
                        <img src={plusIcon} alt="Plus" style={styles.plusIcon} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {activeModal === 'addClient' && (
          <AddClientModal isOpen={!!activeModal} onClose={handleCloseModal} onValueChange={onValueChange} />
        )}
        {/* {activeModal === 'postTimeSheet' && <div>Post Time Sheet Modal</div>}
          {activeModal === 'leaveAttendance' && <div>Leave/Attendance Modal</div>}
          {activeModal === 'addExpense' && <div>Add Expense Modal</div>}
          {activeModal === 'documentInOut' && <div>Document In/Out Modal</div>}
          {activeModal === 'addNotes' && <div>Add Notes Modal</div>} */}

        {activeModal === 'documentInOut' && (
          <CreateEntryModal isOpen={!!activeModal} onClose={handleCloseModal} onValueChange={onDashboardRefresh} documentData={selectedDocument} setSelectedDocument={setSelectedDocument} />
        )}

        {activeModal === 'addToDoModal' && (
          <AddToDoModal isOpen={!!activeModal} onClose={handleCloseModal} onValueChange={onValueChange} fetchToDoList={() => { console.log("Refreshing") }} fetchPriorityList={onDashboardRefresh} />
        )}

        {isModalOpen &&
          <AddClientModal isOpen={isModalOpen} onClose={handleCloseModal} onValueChange={onValueChange} />
        }
      </div>
    </div>

  );
};


const styles = {
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: 'none',
    borderRadius: '6px',
    padding: '8px 10px',
    // width: '100%',
    // maxWidth: 300,
    cursor: 'pointer'
  },
  searchIcon: {
    width: '15px',
    height: '15px',
    marginRight: '10px',
  },
  searchInput: {
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    fontSize: '12px',
    // color: '#A0A0A0',
    width: '100%',
  },
  notificationButton: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #858585',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  notificationIcon: {
    width: '20px',
    height: '20px',
  },
  quickActionIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  quickActiondiv: {
    backgroundColor: 'var(--secondary-color)',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    cursor: 'pointer'
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'flex-end', // Align modal to the right
    alignItems: 'flex-start', // Align modal to the top
    zIndex: 1003,
    padding: '10px'
  },
  modalContent: {
    backgroundColor: '#609966',
    borderRadius: '35px',
    padding: '10px',
    maxWidth: '1200px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    gap: '20px',
    justifyContent: 'flex-end'
  },
  quickActionsTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '400',
    color: '#F2F2F2',
    // marginBottom: '10px',
    fontFamily: 'Roboto',
  },
  quickActionIcon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
  quickActionsContainer: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    backgroundColor: '#EDF1D6',
    padding: '15px',
    borderRadius: '25px',
  },
  quickActionCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '74px',
    height: '74px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease',
    padding: '5px',
    cursor: 'pointer',
  },
  activeCard: {
    border: '2px solid #62C462',
    transform: 'translateY(-5px)',
  },
  quickActionContent: {
    // textAlign: 'center',
    // padding:'5px',
  },
  quickActionLabel: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#060606',
    marginBottom: '8px',
    wordWrap: 'anywhere',
    fontFamily: 'Roboto',
  },
  quickActionIconContainer: {
    width: '21px',
    height: '21px',
    backgroundColor: '#40513B',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 16px rgba(90, 85, 210, 0.4)',
    // marginTop:'15px'
    cursor: 'pointer',
    marginLeft: '2px',
    marginBottom: '2px',
  },
  plusIcon: {
    width: '12px',
    height: '12px',
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  errorMessage: {
    color: "#EE201C",
    fontSize: "10px",
    marginTop: "4px",
    display: "flex",
    gap: "4px",
    fontWeight: '400',
    position: 'absolute',
  },
  notificationDot: {
    position: 'absolute',
    top: '2px',
    right: '-4px',
    fontSize: '14px',
    color: 'red',
    borderRadius: '50%',
    // border: '1px solid white',
    zIndex: 1,
    fontWeight: 'bold',
    backgroundColor: 'rgb(239 237 237)',
  },

  dropdownWrapper: {
    position: 'absolute', // Fixed position for sticky behavior
    top: '50px',
    right: '275px',
    zIndex: 1000,
  },

  dropdownContainer: {
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    // borderRadius: '8px',
    padding: '10px',
    maxHeight: '400px',
    overflowY: 'auto',
    width: '350px',
    position: 'absolute',
  },

  arrow: {
    position: 'absolute',
    top: '-10px',
    left: '250px',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #fff',
    zIndex: 1001,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },

  viewAll: {
    color: '#5A55D2',
    fontSize: '12px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  notificationItem: {
    backgroundColor: '#f9f9f9',
    padding: '15px',
    borderRadius: '5px',
    marginBottom: '10px',
    fontSize: '14px',
    color: '#333',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
  },
  iconLoader: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 1002,
  },

}

export default Header;
