import { useState, useRef, useEffect } from 'react';
import { getData, postData } from '../../../../ApiServices/ApiService';
import { ORG_GET_CLIENT_LIST, ORG_GET_CLIENT_TYPE_LIST, ORG_GET_GROUP_LIST, ORG_GET_SERVICE_LIST_SEARCH, ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER, ORG_CLIENT_LIST_SEARCH } from '../../../../ApiServices/BaseURL';

const ClientListController = () => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [clientList, setClientList] = useState([]);
  const [searchError, setSearchError] = useState('');
  const [applyLoading, setApplyLoading] = useState(false);

  const [selectedClientType, setSelectedClientType] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [searchUser, setSearchUser] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [clientName, setClientName] = useState('')
  const [city, setCity] = useState('');
  const [gstin, setGstin] = useState('');
  const [service, setService] = useState(null);
  const [pendingTask, setPendingTask] = useState(null);

  const [showFilters, setShowFilters] = useState(true);

  const handleSelectedEmployee = (employee) => {
    setSelectedEmployee(employee); // Set selected client data
    setSearchUser(employee?.emp_name); // Update TextInput with the selected client's name
    setEmployeeSearchList([]); // Clear the dropdown
  };

  const handleSearchInputText = (value) => {
    setSearchUser(value);
    setEmployeeSearchList([]);
    setEmployeeError('');
    if (!value.trim()) {
      setSelectedEmployee(null);
    }
  }

  useEffect(() => {

    // setTasks(data);
    // setFilteredTasks(data);
    // fetchClientList();
    fetchClientTypesList();
    fetchGroupList();
    fetchTaskServicesSearchList();
    //console.log('get client')
  }, []);

  const fetchClientList = async (value) => {
    console.log('get client')
    try {
      setError('')
      setTasks([]);
      setFilteredTasks([])
      if (value === 'apply') { setApplyLoading(true); }
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_CLIENT_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              setTasks(responseData?.dataJ);
              setFilteredTasks(responseData?.dataJ);
              setCurrentPage(1);
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch clients. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch clients.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch clients. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch clients. Please check your network connection and try again.`);
      }
    } finally {
      if (value === 'apply') { setApplyLoading(false); }
      setLoading(false);
    }
  }

  const [clientTypeError, setClientTypeError] = useState('')
  const [clientType, setClientType] = useState([]);
  const [clientTypeLoading, setClientTypeLoading] = useState(false)
  const fetchClientTypesList = async () => {
    setClientTypeError('')
    try {
      setClientTypeLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_CLIENT_TYPE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              const dropdownOptions = responseData?.dataJ.map((type) => ({
                label: type.client_type_name,
                value: type.client_type_id,
              }));

              setClientType(dropdownOptions)
              //console.log('type list',responseData?.dataJ)
            } else {
              setClientTypeError(responseData?.info || 'Data Not Found');
            }
          } else {
            setClientTypeError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setClientTypeError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setClientTypeError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setClientTypeError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setClientTypeError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setClientTypeError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setClientTypeError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setClientTypeError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setClientTypeError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setClientTypeLoading(false);
    }
  }

  const [groupNameError, setGroupNameError] = useState('')
  const [groupName, setGroupName] = useState([]);
  const [groupNameLoading, setGroupNameLoading] = useState(false)
  const fetchGroupList = async () => {
    try {
      setGroupNameError('')
      setGroupNameLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data

              const dropdownOptions = responseData?.dataJ.map((type) => ({
                label: type.group_name,
                value: type.group_id,
              }));
              setGroupName(dropdownOptions)
            } else {
              setGroupNameError(responseData?.info || 'Data Not Found');
            }
          } else {
            setGroupNameError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setGroupNameError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setGroupNameError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setGroupNameError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setGroupNameError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setGroupNameError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setGroupNameError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setGroupNameError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setGroupNameError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setGroupNameLoading(false);
    }
  }

  const [servicesSearchList, setServicesSearchList] = useState([]);
  const [seriveError, setServiceError] = useState('')
  const [serviceLoading, setServiceLoading] = useState(false)
  const fetchTaskServicesSearchList = async () => {
    try {
      //console.log('fetchTaskServicesSearchList')
      setServiceError('')
      setServiceLoading(true);
      setServicesSearchList([]);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id
      }

      //console.log('fetchTaskServicesSearchList payload',payLoad);

      // Make the API call
      const response = await postData(ORG_GET_SERVICE_LIST_SEARCH, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const filterData = await responseData?.dataJ.map(service => ({
                label: service.service_name,
                value: service.service_id
              }));
              setServicesSearchList(filterData);
            } else {
              setServiceError(responseData?.info || 'Data Not Found');
            }
          } else {
            setServiceError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setServiceError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setServiceError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setServiceError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setServiceError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setServiceError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setServiceError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setServiceError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setServiceError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setServiceLoading(false);
    }
  }

  const [employeeSearchList, setEmployeeSearchList] = useState([]);
  const [employeeError, setEmployeeError] = useState('')
  const [employeeLoading, setEmployeeLoading] = useState(false)
  const handleSearchEmployee = async () => {

    try {
      setEmployeeError('');
      setEmployeeSearchList([]);
      //   if (!taskInfo?.employee_id_assigned_name) {
      //     setError('Please enter valid text'); return;
      // }       
      setEmployeeLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchUser }

      //console.log('handleSearchEmployee payload',payLoad);
      // Make the API call
      const response = await postData(ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('response Data',responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            // setEmployeeSearchList('')
            setEmployeeSearchList(responseData?.dataJ || []);
            //   setError(responseData?.info);
            setTimeout(() => {
              // handleClose();
              // onValueChange();
            }, 3000);
          } else {
            setEmployeeError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setEmployeeError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setEmployeeError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setEmployeeError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setEmployeeError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setEmployeeError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setEmployeeError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setEmployeeError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setEmployeeError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setEmployeeLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  const handleSubmitClientSearch = async () => {
    setSearchError('');
    setError('');
    if (!selectedClientType && !gstin && !city && !selectedGroupName && !selectedEmployee && !service && !pendingTask && !clientName) {
      // setSearchError('Please fill at least one field to search.');
      fetchClientList('apply');
      return; // Stop execution if all fields are empty
    }
    try {
      setApplyLoading(true);
      setTasks([]);
      setFilteredTasks([])
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        emp_id_auth: storedUserInfo?.emp_id,
        client_type_name: selectedClientType?.label || '', // Default to empty string
        client_type_id: selectedClientType?.value || 0, // Default to null
        client_gstin: gstin || '',
        client_city: city || '',
        group_id: selectedGroupName?.value || 0,
        emp_id: selectedEmployee?.emp_id || 0,
        service_id: service?.value || 0,
        task_status: pendingTask?.value || 0,
        client_name: clientName || '',
      };

      console.log('handleSearchEmployee payload',payLoad);
      // Make the API call
      const response = await postData(ORG_CLIENT_LIST_SEARCH, payLoad);
      const responseData = await response.json();
      console.log('response Data',responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            // setEmployeeSearchList('')
            setTasks(responseData?.dataJ || []);
            setFilteredTasks(responseData?.dataJ || [])
            setCurrentPage(1);
            //   setError(responseData?.info);
            // setTimeout(() => {
            //   // handleClose();
            //   // onValueChange();
            // }, 3000);
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setApplyLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  const toggleDetails = (index) => {
    setFilteredTasks((prevTasks) =>
      prevTasks.map((task, i) =>
        i === index ? { ...task, showDetails: !task.showDetails } : task
      )
    );
  };

  const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const [filters, setFilters] = useState({
    clientName: '',
    clientType: '',
    gstin: '',
    city: '',
    assignedTo: '',
    groupName: '',
    pendingTask: '',
});

const handleFilterChange = (name, value) => {
  setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
  }));
};

    const maxVisiblePages = 3;

    const getVisiblePages = () => {
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
      };

    const visiblePages = getVisiblePages();

const resetFilters = () => {
  if (selectedClientType || gstin || city || selectedGroupName || selectedEmployee || service || pendingTask || clientName) {
    fetchClientList('reset');
  }
  setCity('');
  setClientName('');
  setSelectedClientType(null);
  setSelectedEmployee(null);
  setSelectedGroupName(null);
  setGstin('');
  setCity('');
  setService(null);
  setSearchUser('')
  setPendingTask(null);
  // fetchClientList();
  // setClientTypeError('');
  // setServiceError('');
  // setGroupNameError('');
  setEmployeeError('')
  setError('');
  setSearchError('');
};

const toggleFilters = () => {
  setShowFilters(!showFilters);
};

const cancelFilters = () => {
  setShowFilters(!showFilters);
  resetFilters();
  // toggleDropdown('null')
}



  return {
    tasks: currentTasks,
    currentPage,
    totalPages,
    handlePageChange,
    toggleDetails,
    loading,
    error, setError,
    clientTypeLoading,
    clientType,
    clientTypeError,
    groupName,
    groupNameError,
    groupNameLoading,
    seriveError,
    servicesSearchList,
    serviceLoading,
    employeeLoading,
    employeeError,
    employeeSearchList,
    setEmployeeSearchList,
    handleSearchEmployee,

    setSelectedEmployee,
    selectedEmployee,
    searchUser,
    setSearchUser,
    selectedGroupName,
    setSelectedGroupName,
    selectedClientType,
    setSelectedClientType,
    handleSelectedEmployee,
    setService,
    service,
    setGstin,
    gstin,
    setCity,
    city,
    clientName,
    setClientName,
    pendingTask,
    setPendingTask,
    handleSubmitClientSearch,
    setClientTypeError,
    setServiceError,
    setGroupNameError,
    setEmployeeError,
    fetchClientList,
    searchError, setSearchError,
    handleSearchInputText,
    applyLoading,
    showFilters,
    filters,
    resetFilters, cancelFilters,
    toggleFilters,
    visiblePages,
  };
};

export default ClientListController;
