// ThemeContext.js
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [theme, setTheme] = useState(null);
    const companyIduseRef = useRef(''); // Replace with actual dynamic company ID

    useEffect(() => {
        // Load theme when component mounts
        loadTheme();
    }, [companyIduseRef.current]);

    const applyTheme = (theme) => {
        if (!theme) return;
        document.documentElement.style.setProperty('--primary-color', theme.primaryColor || '#40513B');
        document.documentElement.style.setProperty('--primary-color-hover', theme.primaryColorHover || '#3e7b61');
        document.documentElement.style.setProperty('--secondary-color', theme.secondaryColor || '#609966');
        document.documentElement.style.setProperty('--background-color', theme.backgroundColor || '#F2F2F2');
        document.documentElement.style.setProperty('--font-color', theme.fontColor || '#060606');
        document.documentElement.style.setProperty('--Grays-White', theme.graysWhite || '#fff');
        document.documentElement.style.setProperty('--font-color-sidebar', theme.fontColorSidebar || '#9DC08B');
        document.documentElement.style.setProperty('--read-input-box-bg-color', theme.readInputBoxBgColor || '#f2f2f2');
        document.documentElement.style.setProperty('--sidebar-icon-filter', theme.iconWhiteColor || 'none');


    };

    const loadTheme = async () => {
        const themeId = localStorage.getItem(`themeId`);
        // const storedTheme = await localStorage.getItem(`appTheme_${compId}`);
        // //console.log('companyIduseRef:-----',companyIduseRef.current);
        var selectedTheme = companyIduseRef.current || themeId || 0;
        if (selectedTheme == 1) {
            const theme = {
                "primaryColor": "#609966",
                "primaryColorHover": "#3e7b61",
                "secondaryColor": "#609966",
                "backgroundColor": "#F2F2F2",
                "fontColor": "#060606",
                "graysWhite": "#fff",
                "fontColorSidebar": "#fff",
                "readInputBoxBgColor": "#f2f2f2",
                "iconWhiteColor":"brightness(0) invert(1)"
            }
            setTheme(theme);
            applyTheme(theme);
            //console.log('local theme')

        } else if (selectedTheme == 2) {
            //  //console.log('selectedTheme',selectedTheme)
            const theme = {
                "primaryColor": "#9DC08B",
                "primaryColorHover": "#3e7b61",
                "secondaryColor": "#609966",
                "backgroundColor": "#F2F2F2",
                "fontColor": "#060606",
                "graysWhite": "#fff",
                "fontColorSidebar": "#fff",
                "readInputBoxBgColor": "#f2f2f2",
                "iconWhiteColor":"brightness(0) invert(1)"
            }

            setTheme(theme);
            applyTheme(theme);
            // localStorage.setItem(`appTheme_${companyId}`, JSON.stringify(theme));
        } else if (selectedTheme == 3) {
            // //console.log('selectedTheme',selectedTheme)
            const theme = {
                "primaryColor": "#EDF1D6",
                "primaryColorHover": "#3e7b61",
                "secondaryColor": "#609966",
                "backgroundColor": "#F2F2F2",
                "fontColor": "#060606",
                "graysWhite": "#fff",
                "fontColorSidebar": "#000",
                "readInputBoxBgColor": "#f2f2f2",
                "iconWhiteColor":"brightness(0) invert(0)"
            }

            setTheme(theme);
            applyTheme(theme);
            // localStorage.setItem(`appTheme_${companyId}`, JSON.stringify(theme));
        } else {
            const theme = {
                "primaryColor": "#40513B",
                "primaryColorHover": "#3e7b61",
                "secondaryColor": "#609966",
                "backgroundColor": "#F2F2F2",
                "fontColor": "#060606",
                "graysWhite": "#fff",
                "fontColorSidebar": "#9DC08B",
                "readInputBoxBgColor": "#f2f2f2",
                "iconWhiteColor":"none"
            };

            setTheme(theme);
            applyTheme(theme);
            // localStorage.setItem(`appTheme_${companyId}`, JSON.stringify(theme));
        }
    };

    // Function to update the company ID
    const setCompanyId = (newCompanyId) => {
        companyIduseRef.current = newCompanyId;
        loadTheme()
    };



    return (
        <GlobalContext.Provider value={{ theme, setCompanyId }}>
            {children}
        </GlobalContext.Provider>
    );
};

export const useTheme = () => useContext(GlobalContext);
