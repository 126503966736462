import React,{useState} from "react";
import {ORG_DSC_CERTIFICATE_DELETE,ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER,ORG_DSC_CERTIFICATES_FLITER_LIST} from '../../../../../ApiServices/BaseURL';
import { getData, postData } from "../../../../../ApiServices/ApiService";

const DSCExpiredCertificateController = ( expiredCertificatesList, expiredCertificatesError, expiredCertificatesLoading, onRefresh,setExpiredCertificatesList,setExpiredCertificatesLoading,setExpiredCertificatesError,showFilter)=>{
    const [currentPage, setCurrentPage] = useState(1);
            const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
            const [selectedDocument, setSelectedDocument] = useState(null);
              const itemsPerPage = 10;
              const [status, setStatus] = useState("");
              const [validFrom, setValidFrom] = useState("");
              const [addedOn, setAddedOn] = useState("");
        
            const totalPages = Math.ceil(expiredCertificatesList?.length / itemsPerPage);
          const indexOfLastItem = currentPage * itemsPerPage;
          const indexOfFirstItem = indexOfLastItem - itemsPerPage;
          const currentDSCExpiredCertificates = expiredCertificatesList?.slice(indexOfFirstItem, indexOfLastItem);
        
          const handlePageChange = (pageNumber) => {
            if (pageNumber > 0 && pageNumber <= totalPages) {
              setCurrentPage(pageNumber);
            }
          };

          const handleDelete = (expiredCertificate, index) => {
            console.log("expiredCertificate Certificate",expiredCertificate )
            setSelectedDocument({ expiredCertificate, index });
            setIsDeleteModalOpen(true);
          };
        
          const handleOpenDeleteModal = () => {
            setIsDeleteModalOpen(true); // Open the modal
          };
        
          const handleCloseDeleteModal = () => {
            setIsDeleteModalOpen(false); // Close the modal
          };

          const [loading,setLoading] = useState(false);
  const [expiredCertificateDeleteError, setExpiredCertificateDeleteError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentExpiredCertificateDeleteIndex, setCurrentExpiredCertificateDeleteIndex] = useState(null);
  const [deletedMessages, setDeletedMessages] = useState([]);

  const handleDSCExpiredCertificateDelete = async () => {
   
    try {
      const { expiredCertificate, index } = selectedDocument;
      setCurrentExpiredCertificateDeleteIndex(null)
      setExpiredCertificateDeleteError('');  
        setSuccess('')      
        setLoading(true);
        setCurrentExpiredCertificateDeleteIndex(index)
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, dsc_id:expiredCertificate.dsc_id}
    
        //console.log('handleSearchEmployee payload',payLoad);
        // Make the API call
        const response = await postData(ORG_DSC_CERTIFICATE_DELETE,payLoad);
        const responseData = await response.json();
        //console.log('response Data',responseData);
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              setExpiredCertificateDeleteError('');
              setDeletedMessages((prevDeleted) => [...prevDeleted, expiredCertificate.dsc_id]);
                setSuccess(responseData?.info);
                setTimeout(()=>{
                  setSuccess('');
                  onRefresh();
                },3000);
                // setEmployeeList(responseData?.dataJ || []);
            } else {
              setExpiredCertificateDeleteError(responseData?.info || 'Failed to delete notice board message. Please try again.');
            }
          } else {
            setExpiredCertificateDeleteError(responseData?.info || 'Failed to delete notice board message. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setExpiredCertificateDeleteError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setExpiredCertificateDeleteError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setExpiredCertificateDeleteError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setExpiredCertificateDeleteError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setExpiredCertificateDeleteError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete notice board message. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setExpiredCertificateDeleteError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setExpiredCertificateDeleteError(`Failed to delete notice board message. Please check your network connection and try again.`);
        }
      } finally {
        setLoading(false);
        // setCurrentNoticeDeleteIndex(null)
      }

    // onValueChange(teamData);
    // onClose();
};

const [searchUser, setSearchUser] = useState('');
const [error, setError] = useState('');
const [employeeList, setEmployeeList] = useState([]);
const [clientSearchError, setClientSearchError] = useState('');
const [employeesListLoading, setEmployeeListLoading] = useState(false)
    const handleSearchEmployee = async () => {

        try {
            setError('');
            setClientSearchError('');
            setDscFliterError('')
            if (!searchUser) {
                setClientSearchError('Please enter valid text'); return;
            }
            setEmployeeListLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchUser }

            //console.log('handleSearchEmployee payload', payLoad);
            // Make the API call
            const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
            const responseData = await response.json();
            //console.log('response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        // setAddEmployeeError('')
                        setEmployeeList(responseData?.dataJ || []);
                        //   setError(responseData?.info);
                        setTimeout(() => {
                            // handleClose();
                            // onValueChange();
                        }, 2000);
                    } else {
                        setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setEmployeeListLoading(false);
        }

        // onValueChange(teamData);
        // onClose();
    };

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const handleSearchInputChange = (value) => {
        setSearchUser(value);
        if (!value) {
            setSelectedEmployee(null)
        }
    }
    const handleSelectedEmployee = (employee) => {
        setSelectedEmployee(employee);
        setSearchUser(employee?.client_name);

        setEmployeeList([]); // Clear the dropdown
    };

    const[dscFilterError, setDscFliterError] = useState('')
        const fetchDSCExpiredCertificatesFliterList = async () => {
        
                try {
                  if(!selectedEmployee?.client_id && !status && !validFrom && !addedOn){
                    setDscFliterError('Please fill at least one field to search');
                    return
                  }
                  setDscFliterError('');
                    setExpiredCertificatesLoading(true);
                    setExpiredCertificatesError('')
                    setExpiredCertificatesList([])
                    setCurrentPage(1);
                    setClientSearchError('')
                    // setTeamInfo({});
                    const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
                const payLoad = {
                  emp_id_auth: storedUserInfo?.emp_id,
                  client_id: selectedEmployee?.client_id,
                  client_name: searchUser ||'',
                  dsc_status:status ||0,
                  valid_from: validFrom ||'',
                  create_at:addedOn||'',
                  dsc_type:'expired'
                }
                console.log("payLoad in DSC Filter", payLoad)
                // return
                const response = await postData(ORG_DSC_CERTIFICATES_FLITER_LIST, payLoad);
                const responseData = await response.json();
        
                    // Check for success (200) and internal status code
                    if (response?.status === 200) {
                        if (responseData?.statusCode === 200) {
                            if (responseData?.message.toLowerCase() === 'success') {
                                if (responseData?.dataJ?.length > 0) {
                                    setExpiredCertificatesList(responseData?.dataJ);
                                } else {
                                    setExpiredCertificatesError(responseData?.info || 'Data Not Found');
                                }
                            } else {
                                setExpiredCertificatesError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                            }
                        } else {
                            setExpiredCertificatesError(responseData?.info || 'Failed to fetch Team Information.');
                        }
                    }
                    // Token-related errors: 400, 401, 403
                    else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                            setExpiredCertificatesError('Unauthorized access. Your session may have expired. Please log in again.');
                            // Optionally, redirect to the login page or refresh the token
                            // Example: redirectToLogin();
                        } else {
                            setExpiredCertificatesError(responseData?.info || 'Bad request. Please check the request parameters.');
                        }
                    }
                    // Internal Server Error: 500
                    else if (response?.status === 500) {
                        setExpiredCertificatesError(responseData?.info || 'Internal Server Error. Please try again later.');
                    }
                    // Unexpected or network-related errors
                    else {
                        setExpiredCertificatesError('Unexpected error occurred. Please try again later.');
                    }
                } catch (error) {
                    // Handle various error scenarios
                    if (error.response) {
                        // Server responded with a status outside the 2xx range
                        setExpiredCertificatesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
                    } else if (error.request) {
                        // Request was made but no response was received
                        setExpiredCertificatesError('No response received from the server. Please check your network connection and try again.');
                    } else {
                        // An error occurred in setting up the request
                        setExpiredCertificatesError(`Failed to fetch Team Information. Please check your network connection and try again.`);
                    }
                } finally {
                    setExpiredCertificatesLoading(false);
                    showFilter();
                }
            }
    
            const resetFilter = ()=>{
              setValidFrom('');
              setAddedOn('');
              setSearchUser('');
              setSelectedEmployee(null);
              setStatus('')
              setDscFliterError('');
              setClientSearchError('');
              setError('');
            }
    
          return{
            handlePageChange,
            currentDSCExpiredCertificates,
            currentPage,
            totalPages,

            handleCloseDeleteModal,
            handleOpenDeleteModal,
            handleDelete,
            isDeleteModalOpen,
            selectedDocument,
            setSelectedDocument,

            deletedMessages,
            currentExpiredCertificateDeleteIndex,
            success,
            expiredCertificateDeleteError,
            loading,
            handleDSCExpiredCertificateDelete,

            clientSearchError,
        employeeList,
        error,
        employeesListLoading,
        handleSelectedEmployee,
        handleSearchInputChange,
        searchUser,
        handleSearchEmployee,
        setAddedOn,
        addedOn,
        setValidFrom,
        validFrom,
        setStatus,
        status,
        fetchDSCExpiredCertificatesFliterList,
        dscFilterError,
        resetFilter
    }

}

export default DSCExpiredCertificateController