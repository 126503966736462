import React, { useState, useRef, useEffect } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import closeIcon from "../../Assets/Icons/close.png";
import Textarea from "../Textaera";
import { ORG_UPDATE_CLIENT_TASK_STATUS } from "../../ApiServices/BaseURL";
import { postData } from "../../ApiServices/ApiService";
import Loader from "../Loader";

const TaskIssueModal = ({ isOpen, onClose, isTaskInfo, onSuccess }) => {
  console.log('isTaskInfo issue',isTaskInfo)
  const [comments, setComments] = useState("");
  const [task, setTask] = useState({ "value": 1, "label": "Mark the Task as Issue" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('')

  //console.log('isHold:-',isTaskInfo);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const resetForm = () => {
    setComments("");
    setTask("");
  };

  const clearFeilds = () => {
    setComments("");
  }

  const handleAddCallType = async () => {

    try {
      setError('');
      if (!comments) {
        setError('Comments is required'); return
      }
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      // const locationValue = location === 'Online' ? 2 : 1;
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "task_id": isTaskInfo?.taskInfo?.task_id,
        "task_issue_flag": task?.value,
        "event_action": "TASK_ISSUE_STATUS",
        "comment_text": comments
      }
      //console.log('ToDo status payload', payLoad);
      // Make the API call
      const response = await postData(ORG_UPDATE_CLIENT_TASK_STATUS, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            clearFeilds();
            setTimeout(() => {
              onClose();
              onSuccess();
              setSuccess('');
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to change status. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to change status.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to change status. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to change status. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  };
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" style={styles.closeIcon} />
        </button>
        <h2 className="modal-title">{isTaskInfo?.isHold === 'hold' ? 'Hold Task' : isTaskInfo?.isHold === 'issue' ? 'Task Issue' : 'Update Task Status'}</h2>
        <hr className="title-divider" />
        <div className="form-grid" style={{ gridTemplateColumns: '1fr' }}>
         
            <div style={{ width: 'fit-content' }}>
              <Dropdown
                ref={(el) => (dropdownRefs.current["Update Task Status"] = el)}
                isOpen={openDropdown === "Update Task Status"}
                toggleDropdown={() => toggleDropdown("Update Task Status")}
                label="Update Issue Status"
                options={[
                  { "value": 1, "label": "Mark the Task as Issue" },
                  { "value": 0, "label": "Remove Issue" },
                ]}
                value={task}
                onChange={(value) => setTask(value)}
                searchBoxWidth={230}
              /> </div>
              

          <div style={{ ...styles.actionField }}>
            {/* <textarea placeholder="Enter comments here..." style={styles.textarea}></textarea> */}
            <Textarea
              label="Comments"
              placeholder="Enter your comments here..."
              value={comments}
              onChange={(e) => { setComments(e.target.value) }}
              // errorMessage={errorMessage}
              maxLength={200}
              width={null}
            />
          </div>
        </div>
        <hr className="footer-divider" />
        {error && <div className="list-error-msg" style={{ padding: 0 }}> {error}</div>}
        {success && <div className="list-success-msg" style={{ padding: 0 }}> {success}</div>}
        <div className="button-container">
          <button className="add-client-button" onClick={handleAddCallType} style={{ opacity: loading ? 0.5 : 1 }} disabled={loading}> {loading &&
            <span style={{ paddingRight: 5 }} ><Loader loading={loading} color={'var(--background-color)'} size="small" /> </span>
          } Add Comment</button>
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  closeIcon: {
    width: "16px",
    height: "16px",
  },
  actionField: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: "12px",
    fontWeight: "500",
    marginBottom: "8px",
    color: "#343C44",
  },
  textarea: {
    padding: "10px",
    border: "1px solid #D6E4EC",
    borderRadius: "5px",
    resize: "none",
    height: "87px",
    fontSize: "14px",
    color: "#333",
    width: '575px'
  },
};

export default TaskIssueModal;
