import React, { useState, useEffect, useRef } from "react";
import Input from "../Input";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import DateInput from "../DateInput";
import MobileInput from "../MobileInput";
import closeIcon from "../../Assets/Icons/close.png";
import search from "../../Assets/Icons/search.png";
import uploadIcon from "../../Assets/Icons/upload.png";
import Loader from "../Loader";
import { ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_DSC_ADD, ORG_DSC_CERTIFICATE_UPDATE } from '../../ApiServices/BaseURL';
import { postData } from '../../ApiServices/ApiService';
import errorIcon from '../../Assets/Icons/error_message.png';
import { validateEmail } from "../../Utils/Validation";

const AddDigitalSignatureModal = ({ isOpen, onClose, onValueChange, documentData }) => {
    useEffect(() => {
        if (isOpen && documentData) {
            console.log("documentData in DSC", documentData);
            setNameOfClient(documentData?.client_name)
            setOrganisationName(documentData?.org_name);
            setPanNo(documentData?.pan_no);
            setEmailId(documentData?.email_id)
            setValidFrom(documentData?.valid_from);
            setValidTo(documentData?.valid_to);
            setPhysicalTokenNo(documentData?.physical_token_no);
            setPhoneNo(documentData?.mobile_no);
            setCountryCode(documentData?.client_mobile_code_);
            setTokenPassword(documentData?.token_password);
            setAddRemark(documentData?.dsc_remarks);
            setSearchUser(documentData?.client_name);
            setFileUpload(documentData?.e_device_type)
            setStatus(documentData?.dsc_status)
        }
    }, [isOpen, documentData]);

    const [clientFileNo, setClientFileNo] = useState("");
    const [physicalTokenNo, setPhysicalTokenNo] = useState("");
    const [nameOfClient, setNameOfClient] = useState("");
    const [organisationName, setOrganisationName] = useState("");
    const [panNo, setPanNo] = useState("");
    const [emailId, setEmailId] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [countryCode, setCountryCode] = useState('+91');
    const [tokenPassword, setTokenPassword] = useState("");
    const [status, setStatus] = useState("");
    const [fileUpload, setFileUpload] = useState("");
    const [validFrom, setValidFrom] = useState("");
    const [validTo, setValidTo] = useState("");
    const fileInputRef = useRef(null);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [base64File, setBase64File] = useState('');
    const [fileExtension, setFileExtension] = useState('');
    const [documentUploadError, setDocumentUploadError] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [employeeList, setEmployeeList] = useState([]);
    const [clientSearchError, setClientSearchError] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [addRemark, setAddRemark] = useState('');
    const [digitalSignatureLoading, setDigitalSignatureLoading] = useState(false);
    const [digitalSignatureError, setDigitalSignatureError] = useState('')
    const [digitalSignatureSuccess, setDigitalSignatureSuccess] = useState('')
    const [addRemarkError, setAddRemarkError] = useState('');
    const [validFromError, setValidFromError] = useState('');
    const [validToError, setValidToError] = useState('');
    const [addPhysicalTokenError, setAddPhysicalTokenError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [panError,setPanError] = useState('')

    const statusOptions = [
        { label: "Downloaded", value: 1 },
        { label: "Pending", value: 2 },
        // { label: "Rejected", value: "rejected" },
    ];

    const fileUploadFrom = [
        { label: "USB", value: 1 },
        { label: "Mail", value: 2 },
        { label: "WhatsApp", value: 3 },
        { label: "Drive", value: 4 },
    ];

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);

    const resetForm = () => {
        setClientFileNo("");
        setPhysicalTokenNo("");
        setNameOfClient("");
        setOrganisationName("");
        setPanNo("");
        setEmailId("");
        setPhoneNo("");
        setTokenPassword("");
        setStatus("");
        setValidFrom("");
    };

    const handleClose = () => {
        onClose();
        setError('');
        setClientFileNo("");
        setPhysicalTokenNo("");
        setNameOfClient("");
        setOrganisationName("");
        setPanNo("");
        setEmailId("");
        setPhoneNo("");
        setTokenPassword("");
        setStatus("");
        setValidFrom("");
        setClientSearchError('');
        setAddRemarkError('');
        setValidToError('');
        setValidFromError('');
        setAddPhysicalTokenError('');
        setDigitalSignatureSuccess('');
        setSearchUser('');
        setValidTo('');
        setAddRemark('')
        setDocumentUploadError('');
        setUploadedFileName('');
        setBase64File('')
        setEmailError('')
        setDigitalSignatureError('');
        setFileUpload('');
        setPanError('');
        setSelectedEmployee(null)
    };

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        setDocumentUploadError('');
        const files = event.target.files;

        if (files && files.length > 0) {
            const file = files[0];
            const fileName = file.name;
            const fileSize = file.size; // File size in bytes
            const maxFileSize = 2 * 1024 * 1024;
            const fileExtension = fileName.split('.').pop(); // Extract the file extension
            console.log("fileName", fileName);

            //console.log("Uploaded File Name:", fileName);
            //console.log("File Extension:", fileExtension);
            setUploadedFileName(fileName);

            if (fileSize > maxFileSize) {
                setDocumentUploadError("File size exceeds the maximum limit of 2MB.");
                return;
            }

            const reader = new FileReader();

            try {
                reader.onload = () => {
                    try {
                        const base64String = reader.result.split(',')[1]; // Extract Base64 string from result
                        //console.log("Base64 Encoded File:", base64String);

                        setBase64File(base64String); // Add the Base64 string to the payload
                        // Optionally, save the file extension in state or payload
                        setFileExtension(fileExtension);
                    } catch (parseError) {
                        console.error("Error parsing file to Base64:", parseError);
                        setDocumentUploadError("Failed to process the file. Please try again.");
                    }
                };

                reader.onerror = (error) => {
                    console.error("Error reading file:", error);
                    setDocumentUploadError("Failed to read the file. Please try again.");
                };

                reader.readAsDataURL(file); // Convert file to Base64 string
            } catch (readError) {
                console.error("File upload error:", readError);
                setDocumentUploadError("An unexpected error occurred during the file upload. Please try again.");
            }
        } else {
            //   setDocumentUploadError("No file selected. Please choose a file to upload.");
        }
    };
    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const handleSearchInputChange = (value) => {
        setSearchUser(value);
        if (!value) {
            setSelectedEmployee(null)
        }
    }

    const handleSelectedEmployee = (employee) => {
        setSelectedEmployee(employee);
        setSearchUser(employee?.client_name);
        setNameOfClient(employee?.client_name);
        // setPanNo(employee?.pan_id);
        setPanNo(employee?.client_pan_no);
        setEmailId(employee?.client_email);
        setPhoneNo(employee?.client_mobile_no);

        setEmployeeList([]); // Clear the dropdown
    };

    const [searchUser, setSearchUser] = useState('');
    const handleSearchEmployee = async () => {

        try {
            setError('');
            setClientSearchError('')
            if (!searchUser) {
                setClientSearchError('Please enter valid text'); return;
            }
            setLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "NAME-FILEID", search_word: searchUser }

            //console.log('handleSearchEmployee payload', payLoad);
            // Make the API call
            const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
            const responseData = await response.json();
            //console.log('response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        // setAddEmployeeError('')
                        setEmployeeList(responseData?.dataJ || []);
                        //   setError(responseData?.info);
                        setTimeout(() => {
                            // handleClose();
                            // onValueChange();
                        }, 2000);
                    } else {
                        setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setLoading(false);
        }

        // onValueChange(teamData);
        // onClose();
    };

    const clearFeilds = () => {
        setUploadedFileName("");
        setBase64File("");
        setNameOfClient("");
        setPhoneNo("");
        setEmailId("");
        setValidFrom("");
        setValidTo("");
        setTokenPassword("");
        setAddRemark("");
        setPhysicalTokenNo("");
        setOrganisationName("");
        setCountryCode("+91");
        setPanNo("");
        setStatus("");
        setFileUpload("");
        setSelectedEmployee(null);
        setSearchUser("");
    }

    const handleAddDigitalSignature = async () => {

        try {

            setError('');
            setClientSearchError('');
            setAddRemarkError('');
            setValidToError('');
            setValidFromError('');
            setAddPhysicalTokenError('');
            setEmailError('');
            setDigitalSignatureError('');
            setPanError('');
            setDocumentUploadError('')
            const MAX_REMARK_MESSAGE_LENGTH = 500;

            if(!base64File){
                setDocumentUploadError('Please upload document');
                return
            }
            if (!selectedEmployee?.client_id) {
                setClientSearchError('Please enter valid client'); return;
            }
            
            if (physicalTokenNo && physicalTokenNo.length > 10) {
                setAddPhysicalTokenError(`Please enter valid pan card`);
                return;

            }
            if (panNo && panNo.length<10) {
                setPanError(`Please enter valid pan card`);
                return
            }
            if (emailId && !validateEmail(emailId)) {
                setEmailError('Please enter a valid email address.');
                return;
            }
            
            if (!validFrom) {
                setValidFromError("Please select valid from date");
                return
            }
            if (!validTo) {
                setValidToError("Please select valid to date");
                return
            }
            if (validTo < validFrom) {
                setValidToError("Validto date can't be before validFrom date");
                return;
            }
            if (addRemark.length > MAX_REMARK_MESSAGE_LENGTH) {
                setAddRemarkError(`Remarks cannot exceed ${MAX_REMARK_MESSAGE_LENGTH} characters.`);
                return
            }

            setDigitalSignatureLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                emp_id_auth: storedUserInfo?.emp_id,
                // doc_name:uploadedFileName?.split('.')[0],
                doc_base_64: base64File,
                doc_name_with_ext: uploadedFileName,
                client_id: selectedEmployee?.client_id,
                client_name: nameOfClient,
                physical_token_no: physicalTokenNo,
                e_device_type: fileUpload ? fileUpload : 0,
                dsc_status: status ? status : 0,
                org_name: organisationName,
                pan_no: panNo,
                mobile_no: phoneNo,
                client_mobile_code_: countryCode,
                email_id: emailId,
                valid_from: validFrom,
                valid_to: validTo,
                token_password: tokenPassword,
                dsc_remarks: addRemark
            }

            console.log('handlea Add DSC payload', payLoad);
            // return
            // Make the API call
            const response = await postData(ORG_DSC_ADD, payLoad);
            const responseData = await response.json();
            //console.log('response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setDigitalSignatureError('')
                        setDigitalSignatureSuccess(responseData?.info);
                        clearFeilds();
                        setTimeout(() => {
                            handleClose();
                            onValueChange();
                        }, 3000);
                    } else {
                        setDigitalSignatureError(responseData?.info || 'Failed to add notice board message. Please try again.');
                    }
                } else {
                    setDigitalSignatureError(responseData?.info || 'Failed to add notice board message. Status code error.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setDigitalSignatureError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setDigitalSignatureError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setDigitalSignatureError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setDigitalSignatureError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setDigitalSignatureError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add notice board message. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setDigitalSignatureError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setDigitalSignatureError(`Failed to add notice board message. Please check your network connection and try again.`);
            }
        } finally {
            setDigitalSignatureLoading(false);
        }

        // onValueChange(teamData);
        // onClose();
    };

    const handleUpdateDigitalSignature = async () => {
  
        try {
            
            setError('');
            setClientSearchError('');
            setAddRemarkError('');
            setValidToError('');
            setValidFromError('');
            setAddPhysicalTokenError('');
            setEmailError('');
            setDigitalSignatureError('');
            setPanError('');
            const MAX_REMARK_MESSAGE_LENGTH = 500;
            if(!searchUser){
              setClientSearchError('Please enter valid text'); return;
            }
            if(physicalTokenNo.length > 10){
                setAddPhysicalTokenError(`Physical tokenNo cannot exceed ${10} characters.`);
                return;
                  
            }
            if (panNo.length<10) {
              setPanError(`Please enter valid pan card`);
              return
          }
            if (!validateEmail(emailId)) {
                setEmailError('Invalid email address.');
                return;
            }
            if (!validFrom) {
              setValidFromError("Please select valid from date");
              return
            } 
            if (!validTo) {
              setValidToError("Please select valid to date");
              return
            }   
            if (validTo < validFrom) {
              setValidToError("Validto date can't be before validFrom date");
              return;
          }   
          if(addRemark.length > MAX_REMARK_MESSAGE_LENGTH){
              setAddRemarkError(`Remarks cannot exceed ${MAX_REMARK_MESSAGE_LENGTH} characters.`);
              return
            }

          setDigitalSignatureLoading(true);
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
          const payLoad = { emp_id_auth: storedUserInfo?.emp_id,
              // doc_name:uploadedFileName?.split('.')[0],
            //   doc_base_64:base64File,
            //   doc_name_with_ext: uploadedFileName,
              client_id: documentData?.client_id,
              client_name: nameOfClient,
              physical_token_no: physicalTokenNo,
              e_device_type: fileUpload,
              dsc_status: status,
              org_name:organisationName,
              pan_no:panNo,
              mobile_no:phoneNo,
              client_mobile_code_:countryCode,
              email_id:emailId,
              valid_from:validFrom,
              valid_to:validTo,
              token_password:tokenPassword,
              dsc_remarks:addRemark,
              dsc_id: documentData?.dsc_id
           }
    
          console.log('handlea Update DSC payload', payLoad);
        //   return
          // Make the API call
          const response = await postData(ORG_DSC_CERTIFICATE_UPDATE, payLoad);
          const responseData = await response.json();
          //console.log('response Data', responseData);
    
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                  setDigitalSignatureError('')
                  setDigitalSignatureSuccess(responseData?.info);
                setTimeout(() => {
                  handleClose();
                  onValueChange();
                }, 3000);
              } else {
                  setDigitalSignatureError(responseData?.info || 'Failed to add notice board message. Please try again.');
              }
            } else {
              setDigitalSignatureError(responseData?.info || 'Failed to add notice board message. Status code error.');
            }
          }
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
              setDigitalSignatureError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
              setDigitalSignatureError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          }
          // Internal Server Error: 500
          else if (response?.status === 500) {
              setDigitalSignatureError(responseData?.info || 'Internal Server Error. Please try again later.');
          }
          // Unexpected or network-related errors
          else {
              setDigitalSignatureError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setDigitalSignatureError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add notice board message. Please try again.'}`);
          } else if (error.request) {
            // Request was made but no response was received
            setDigitalSignatureError('No response received from the server. Please check your network connection and try again.');
          } else {
            // An error occurred in setting up the request
            setDigitalSignatureError(`Failed to add notice board message. Please check your network connection and try again.`);
          }
        } finally {
          setDigitalSignatureLoading(false);
        }
    
        // onValueChange(teamData);
        // onClose();
      };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{ width: '750px' }}>
                <button className="close-button" onClick={() => { handleClose(); }}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">{documentData ? 'Update' : 'Add'} New Digital Signature</h2>
                <hr className="footer-divider" />
                <div className="form-grid">
                    {!documentData && (
                        <div>
                            <label style={{ fontSize: '12px', color: '#343C44' }}>Upload Certificate</label>
                            <div style={styles.uploadContainer}>
                                <div style={styles.uploadBox} onClick={handleFileUploadClick}>
                                    <span style={styles.uploadText}><img src={uploadIcon} alt="Upload" style={styles.uploadIcon} />{uploadedFileName ? uploadedFileName : 'Upload (Valid file format Only)'}</span>
                                </div>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={styles.fileInput}
                                    onChange={handleFileChange}
                                />
                            </div>
                            {documentUploadError && <div className="list-error-msg" style={{ display: 'flex', padding: '5px' }}><img
                                src={errorIcon}
                                alt="Error"
                                style={{ width: "12px", height: "12px", marginRight: '4px' }}
                            />{documentUploadError} </div>}
                        </div>
                    )}
                    <div className="filter-field">
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Select Client or File No.</label>

                        <TextInput
                            type="text"
                            placeholder="Enter File No."
                            value={searchUser}
                            onChange={(e) => handleSearchInputChange(e.target.value)}
                            onRightClick={!!documentData ? null : handleSearchEmployee}
                            width={270}
                            right={
                                loading ? (
                                    <Loader loading={loading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                                ) : (
                                    <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                                )
                            }
                            style={{ padding: '20px 10px' }}
                            errorMessage={error || clientSearchError}
                            disabled={!!documentData}
                        // disabled={!!documentData}
                        />
                        {employeeList?.length > 0 && (
                            <ul style={styles.dropdownList}>
                                {employeeList?.map((employee, index) => (
                                    <li
                                        key={employee?.client_id}
                                        onClick={() => handleSelectedEmployee(employee)}
                                        style={{
                                            ...styles.dropdownItem,
                                            borderBottom: index !== employeeList?.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                        }}
                                    >
                                        {employee?.client_name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <Input
                        label="Physical Token No."
                        value={physicalTokenNo}
                        onChange={(e) => setPhysicalTokenNo(e.target.value)}
                        placeholder="Enter Token No."
                        errorMessage={addPhysicalTokenError}
                        maxLength={10}
                    />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["fileUpload"] = el)}
                        isOpen={openDropdown === "fileUpload"}
                        toggleDropdown={() => toggleDropdown("fileUpload")}
                        label="USB/Mail/WhatsApp/Drive"
                        options={fileUploadFrom}
                        // value={fileUpload}
                        value={fileUploadFrom.find((option) => option.value === fileUpload)}
                        onChange={(statusOptions) => setFileUpload(statusOptions.value)}
                    />
                    <Input
                        label="Name of Client"
                        value={nameOfClient}
                        onChange={(e) => setNameOfClient(e.target.value)}
                        placeholder="Enter Client Name"
                        isEdit={!documentData}
                    />
                    <Input
                        label="Organisation Name"
                        value={organisationName}
                        onChange={(e) => setOrganisationName(e.target.value)}
                        placeholder="Enter Organisation Name"
                    />
                    <Input
                        label="PAN No"
                        value={panNo}
                        onChange={(e) => setPanNo(e.target.value)}
                        placeholder="Enter PAN No."
                        errorMessage={panError}
                        maxLength={10}
                    />
                    <Input
                        label="Email ID"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                        placeholder="Enter Email ID"
                        errorMessage={emailError}
                    />
                    {/* <Input
                        label="Phone No."
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        placeholder="Enter Phone No."
                    /> */}
                    <MobileInput
                        label="Phone No."
                        countryCode={countryCode}
                        mobileNumber={phoneNo}
                        onCountryChange={setCountryCode}
                        onMobileChange={setPhoneNo}
                        placeholder="Enter mobile number"
                        countries={[
                            { code: "IN", dialCode: "+91" },
                            { code: "US", dialCode: "+1" },
                            { code: "UK", dialCode: "+44" },
                        ]}
                        width="95%"
                    />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["status"] = el)}
                        isOpen={openDropdown === "status"}
                        toggleDropdown={() => toggleDropdown("status")}
                        label="Status"
                        options={statusOptions}
                        // value={statusOptions.find(option => option.value === status)?.label || "Select Status"}
                        value={statusOptions.find((option) => option.value === status)}
                        onChange={(selectedOption) => setStatus(selectedOption.value)}
                    />
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Valid From</label>
                        <DateInput
                            label="Valid From"
                            value={validFrom}
                            onChange={(date)=>{
                                setValidFrom(date.target.value);
                                if(date.target.value){
                                    setValidFromError('')
                                }
                            }}
                            errorMessage={validFromError}
                        />
                    </div>
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Valid To</label>
                        <DateInput
                            label="Valid To"
                            value={validTo}
                            onChange={(date) => {
                                setValidTo(date.target.value);
                                if (date.target.value) {
                                  setValidToError('');
                                }
                              }}
                            errorMessage={validToError}
                            min={new Date().toISOString().split("T")[0]}
                        />
                    </div>
                    <Input
                        label="Token Password"
                        value={tokenPassword}
                        onChange={(e) => setTokenPassword(e.target.value)}
                        placeholder="Enter Token Password"
                    />
                </div>
                <div style={styles.actionField}>
                    <label style={styles.label}>Remark</label>
                    <textarea placeholder="Add Remark" style={styles.textarea} value={addRemark} onChange={(e) => setAddRemark(e.target.value)}></textarea>
                    {addRemarkError && <div className="list-error-msg" style={{ display: 'flex', padding: '5px' }}><img
                        src={errorIcon}
                        alt="Error"
                        style={{ width: "12px", height: "12px", marginRight: '4px' }}
                    />{addRemarkError} </div>}
                </div>
                <hr className="footer-divider" />
                <div style={{ ...styles.buttonContainer, alignItems: 'center' }} >
                    <button className="add-client-button" onClick={documentData? handleUpdateDigitalSignature : handleAddDigitalSignature}
                        style={{
                            paddingLeft: '30px',
                            paddingRight: '30px',
                            gap: '10px',
                            opacity: digitalSignatureLoading ? 0.5 : 1,
                            cursor: digitalSignatureLoading ? 'not-allowed' : 'pointer',
                        }}>
                        {digitalSignatureLoading && (
                            <Loader loading={digitalSignatureLoading} color={'var(--background-color)'} size="small" />
                        )}
                        <span>{documentData ? 'Update' : 'Add'}</span>
                    </button>
                    <button className="cancel-button" onClick={() => { handleClose(); }}>
                        Cancel
                    </button>
                    {digitalSignatureSuccess && <div className="list-success-msg" style={{ marginBottom: '3px' }}>{digitalSignatureSuccess} </div>}
                    {digitalSignatureError && <div className="list-error-msg" style={{ marginBottom: '3px' }}>{digitalSignatureError} </div>}
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 2fr 2fr",
        gap: "20px",
        marginTop: "20px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        marginTop: "20px",
    },
    actionField: {
        display: "flex",
        flexDirection: "column",
        marginTop: '15px'
    },
    label: {
        fontSize: "12px",
        fontWeight: "500",
        marginBottom: "8px",
        color: "#343C44",
    },
    textarea: {
        padding: "10px",
        border: "1px solid #D6E4EC",
        borderRadius: "5px",
        resize: "none",
        height: "60px",
        fontSize: "14px",
        color: "#333",
        fontFamily: 'Roboto',
        width: '480px'
    },
    uploadContainer: {
        marginTop: "10px",
    },
    uploadBox: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #858585",
        borderRadius: "50px",
        padding: "10px",
        cursor: "pointer",
        justifyContent: 'space-between'
    },
    uploadIcon: {
        width: "20px",
        height: "20px",
        marginRight: "10px",
    },
    uploadText: {
        display: 'flex',
        color: "#40513B ",
        fontSize: "12px",
        fontWeight: 500,
        gap: "1px",
        alignItems: 'center',
    },
    fileInput: {
        display: "none",
    },

    dropdownList: {
        position: 'absolute',
        top: '90%',
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
        padding: 0, // Remove default padding
        margin: 0, // Remove default margin
        listStyleType: 'none', // Remove the dots
    },

    dropdownItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee', // Default for all items
        backgroundColor: '#fff',
        hover: {
            backgroundColor: '#f0f0f0',
        },
    },
};

export default AddDigitalSignatureModal;
