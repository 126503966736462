import React, {useEffect,useState} from "react";
import { Link } from 'react-router-dom';
import { useTheme, GlobalC } from "../../../GlobalContext/GlobalContext";
import { useNavigate } from 'react-router-dom';
import logImg from '../../../Assets/Images/loginImg.png'
import logo from '../../../Assets/Images/logo.png'
import LoginController from "../../Controllers/AuthenticationScreens/LoginScreenController";
import TextInput from "../../../Components/TextInput";
import Loader from "../../../Components/Loader";

const LoginScreen = () => {
      const { theme, setCompanyId } = useTheme();
      //     //console.log('theme:-',theme);
      const navigate = useNavigate();

      const {
            email,
            password,
            error,
            showPassword,
            loading,
            handleEmailChange,
            handlePasswordChange,
            toggleShowPassword,
            login,
            emailError,
            passwordError,
            setPasswordError,
      } = LoginController();
      const [storedCompanyInfo,setStoredCompanyInfo] = useState({});

      useEffect(()=>{
            if(localStorage.getItem('companyInfo')){
                  const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));
                  setStoredCompanyInfo(companyInfo)
            }

      },[])
      
      
      
      return (
            <div className="login">
                  <div className="frameParent">
                        <div className="artboard113WfAutomationIllWrapper">
                              <img className="logimgstyle" alt="" src={logImg} />
                        </div>
                        <div className="frameGroup" style={{ gap: '0px' }}>
                              <div className="groupParent">
                                    <img className="frameChild" alt="" src={storedCompanyInfo?.company_logo || logo} />
                                    <div className="companyName">{storedCompanyInfo?.company_name || 'Company Name'} </div>
                              </div>
                              <div className="input" style={{ marginBottom: '25px', marginTop: '25px' }}>
                                    <div className="xodimlar">Username</div>

                                    <TextInput
                                          placeholder="Enter your username"
                                          value={email || ''} // Ensure value is a string
                                          onChange={(e) => handleEmailChange(e.target.value)}
                                          errorMessage={emailError}
                                          searchBoxWidth={310}

                                    />
                              </div>
                              <div className="input" style={{ marginBottom: '25px' }}>
                                    <div className="xodimlar">Password</div>

                                    <TextInput
                                          placeholder="Enter your password"
                                          value={password || ''} // Ensure value is a string
                                          onChange={(e) => handlePasswordChange(e.target.value)}
                                          type={showPassword ? 'text' : 'password'}
                                          searchBoxWidth={310}
                                          // right={
                                          //     <button onClick={toggleShowPassword} className="toggle-button">
                                          //         {showPassword ? (
                                          //             <img src={require('../../../Assets/Icons/Eye off.png')} alt="Hide " />
                                          //         ) : (
                                          //             <img src={require('../../../Assets/Icons/Eye-2.png')} alt="Show " />
                                          //         )}
                                          //     </button>
                                          // }
                                          errorMessage={passwordError}
                                    />
                              </div>
                              <div> {error && <span style={{ textAlign: 'center', color: 'red', fontSize: '12px' }}> {error} </span>} </div>
                              {/* <div className="button" onClick={login} >
                          <div className="label flexdisplay" ><Loader loading={loading} color={'#FFFFFF'} size='small' />  Login</div>
                    </div> */}
                              <button className="button" style={{ marginBottom: '5px', maxWidth: '310px', opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer', }} onClick={login} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Login</button>
                              <div className="forgotPassword">
                                    <Link to="/forgot-password" style={{ textDecoration: 'none', color: 'inherit' }}>
                                          Forgot Password?
                                    </Link>
                              </div>
                        </div>
                  </div>

            </div>
      )

}

export default LoginScreen;

