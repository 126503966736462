import React, { useState, useRef, useEffect } from 'react';
import profileImg from '../../../Assets/Images/temp-img.png';
import rightArrowIcon from '../../../Assets/Icons/arrow-right.png';
import arrowUpIcon from '../../../Assets/Icons/arrow-up.png';
import arrowDownIcon from '../../../Assets/Icons/arrow-down.png';
import editIcon from '../../../Assets/Icons/edit.png';
import deleteIcon from '../../../Assets/Icons/delete.png';
import leftArrowIcon from '../../../Assets/Icons/chevron-down.png';
import leftArrowBtsIcon from '../../../Assets/Icons/left-btn.png';
import plusIcon from '../../../Assets/Icons/Pluse.png';
import messageIcon from '../../../Assets/Icons/message.png';
import useDashboardController from '../../Controllers/AdminScreens/DashboardController';
import { useTheme } from '../../../GlobalContext/GlobalContext';
import { useNavigate } from 'react-router-dom';
import AddCommentModal from '../../../Components/Modals/AddCommentModal';
import AddToDoModal from '../../../Components/Modals/AddToDoModal';
import TitlePage from '../../../Assets/Icons/TitlePage.png';
import ToDoCompleteModal from '../../../Components/Modals/ToDoCompleteModal';
import AddScheduleReminderModal from '../../../Components/AddScheduleReminderModal';
import Loader from '../../../Components/Loader';
import { convertToLocalTime, formatScheduleTime, convertToFormattedLocalTime } from '../../../Utils/Validation';
import toDoCircle from '../../../Assets/Icons/circle-2.png';
import DeleteConfirmationModal from '../../../Components/Modals/DeleteConformationModal';
import LoadingSkeleton from '../../../Components/LoadingSkeleton';
import NotificationCard from '../../../Components/NotificationCard';
import NoDataCard from '../../../Components/NoDataCard';

const DashboardScreen = ({ refreshDashboard, setRefreshDashboard}) => {
    const { theme } = useTheme();
    //console.log('dashboard theme:', theme);
    const {
        hloading,
        error,
        success,
        screenName,
        sampleNotifications,
        currentIndex,
        handleNext,
        handlePrevious,
        getTruncatedNotification,
        handleSheduleAndReminder,
        sampleTaskOverview,
        pendingTasksList,
        todosData,
        ItemsPerPage,
        currentPage,
        totalPages,
        handlePageChange,
        currentTasks,
        quickActions,
        appointments,
        selectedOption,
        options,
        dateOptions,
        clientData,
        handleAddClient,
        selecteTabledOption,
        handleAppointments,
        isCommentModalOpen,
        isAddToDoModalOpen,
        isAddToDoRadioButtonModalOpen,
        handleAddCommentModal,
        handleCloseCommentModal,
        handleAddToDoModal,
        handleCloseToDoModal,
        handleAddToDoRadioButtonModal,
        handleCloseToDoRadioButtonModal,
        handleCreateTask,
        setSelectedTableOption,
        setSelectedOption,
        getGreeting,
        userInfo,
        isScheduleModalOpen,
        handleCloseScheduleDoModal,

        scheduleTypeError,
        scheduleTypesLoading,
        scheduleTpyeList,

        scheduleToDoError,
        scheduleToDoList,
        scheduleToDoLoading,
        fetchToDoList,
        fetchPriorityList,
        handleEditToDo,
        selectedDocument,
        upcomingList,
        upcomingError,
        upcomingLoading,
        getStatusStyle,
        selectedUpcomingData,
        handleEditUpcomingEdit,
        handleToDoPageChange, currentPageToDoList, totalPagesToDoList,
        currentScheduleToDoList,
        toDoInfoState,
        pendingTasksResError,
        pendingTasksLoading, taskInfo, commentSuccess, handleEditPendingTask,
        taskInfoRef, onTaskDelete, isDeleteModalOpen,
        deleteTaskError, deleteTaskLoading, deleteTaskSuccess,
        handleCloseDeleteModal, callDeleteTask,
        taskOverview, taskOverviewLoading, taskOverviewError,
        onToDoItemDelete, handleCloseToDoDeleteModal, callDeleteToDoItem, toDoItemRef,
        deleteToDoItemLoading, deleteToDoItemError, deleteToDoItemSuccess, isDeleteToDoModalOpen,
        fetchUpcomingAppointemntsList,
        notificationList,
        notificationError,
        notificationLoading,

        currentUpcomingTasks,
        indexOfUpcomingFirstItem,
        indexOfUpcomingLastItem,
        handleUpcomingPageChange,
        totalUpcomingPages,
        currentUpcomingPage,
    } = useDashboardController(refreshDashboard, setRefreshDashboard);

    //console.log("scheduleTpyeList in dash", currentScheduleToDoList);
    const maxVisiblePages = 3;

    const getVisiblePages = () => {
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
    };

    const visiblePages = getVisiblePages();

    const getToDoVisiblePages = () => {
        const startPage = Math.max(1, currentPageToDoList - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPagesToDoList, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
    };
    const toDoVisiblePages = getToDoVisiblePages();

    const getUpcomingVisiblePages = () => {
        const startPage = Math.max(1, currentUpcomingPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalUpcomingPages, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
    };

    const upcomingVisiblePages = getUpcomingVisiblePages();

    return (
        <>
            <div style={{ padding: '0px 0px 0px 20px' }}>
                <h3 >
                    <div >
                        <span className='activity-title' style={{ fontSize: '25px', }}>Overview</span>
                    </div>
                </h3>
            </div>
            <div className='dashboard-container' >
                <div className="dashboard-screen" >
                    <div className="main-content" >
                        <div className="main-bachground">
                            {/* Add your other dashboard components here */}
                            <div className="good-morning-section wrap-div" style={{ paddingTop: '10px' }}>
                                <div className='clientInfo-sty'>
                                    <h2 style={{ fontSize: '30px', color: '#1F384C', fontFamily: "Roboto Slab", fontWeight: '500', fontStyle: 'normal', lineHeight: '106%', marginBottom: '25px', wordWrap: 'break-word' }}>{`${getGreeting()},`} {userInfo?.emp_name}</h2>
                                    <p style={{ fontSize: '14px', color: '#060606', fontWeight: '500', letterSpacing: '0.14px', wordWrap: 'break-word' }}>Plan your workday here, add task, activity, notes or other things to keep track of it throughout the day.</p>
                                </div>
                                {/* <div style={{display:'flex'}}> */}
                                {/* Add Task card placed separately from scrollable task container */}
                                <div className="add-task-card" style={{ height: '160px' }} onClick={handleSheduleAndReminder}>
                                    <img className="plus-sign" src={TitlePage} />
                                </div>

                                {/* Scrollable Task Cards */}
                                <div className="task-container-wrapper" style={{ paddingBottom: 0 }}>
                                    <div className="task-container" style={{ minHeight: '160px'}}>
                                        {scheduleTpyeList?.map((task, index) => (
                                            <div
                                                className="task-card"
                                                key={task?.sc_id}
                                                style={{ backgroundColor: index % 2 === 0 ? 'var(--primary-color)' : 'var(--secondary-color)' }}
                                            >
                                                <div style={{ height: 40 }}>
                                                    <span className="task-title" >{task?.sc_title}</span>
                                                </div>
                                                {task?.sc_origin === 1 && 
                                                <div style={{ height: 60 }}>
                                                    <span className="task-type">{task?.sc_desc}</span>
                                                </div>
                                                }
                                                {task?.sc_origin === 2 && 
                                                    <div style={{ height: 60 }}>
                                                        <span className="task-type">{task?.client_name}</span>
                                                    </div>
                                                }
                                                <div className='task-time-div'>
                                                    {task?.sc_time && (
                                                        <span className="task-time">
                                                            {convertToFormattedLocalTime(task?.sc_time)}
                                                        </span>
                                                    )}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'end' }}>     <button className="task-details-button" style={{ height: '20px', width: '20px' }}><img src={rightArrowIcon} alt="Right Arrow" /></button>
                                                </div>
                                            </div>
                                        ))}
                                        {scheduleTypesLoading && <Loader loading={scheduleTypesLoading} color={'var(--primary-color)'} />}
                                        {/* {scheduleTypeError && <div className="list-error-msg" style={{ display: 'flex', alignItems: 'center' }}>{scheduleTypeError} </div>} */}
                                        {scheduleTypeError && <NoDataCard
                                            // type={'info'}
                                            message={scheduleTypeError}
                                        />
                                        // </div>
                                        }
                                    </div>
                                </div>
                                {/* </div> */}


                            </div>
                            <div className="notifications-taskoverview-wrapper">
                                <div className="notifications-section">
                                    <div className="notifications-header">
                                        <h3 className='notifications-heading'>Notifications</h3>
                                        <div className="notification-message">
                                            <span key={currentIndex} className={notificationList[currentIndex]?.nb_message.length > 100 ? 'scrolling-text' : ''}>
                                            {notificationList[currentIndex]?.nb_message}
                                            </span>
                                            {notificationLoading && <LoadingSkeleton width="540px" height="15px" />}
                                            {notificationError && <div className="list-error-msg">{notificationError} </div>}
                                        </div>
                                        <div className="notification-navigation">
                                            <button className="nav-button" onClick={handlePrevious} style={{ cursor: currentIndex === 0 ? 'not-allowed' : 'pointer', pointerEvents: currentIndex === 0 ? 'none' : 'auto' }}>
                                                <img src={leftArrowBtsIcon} alt="Previous" />
                                            </button>

                                            <button className="nav-button" onClick={handleNext} style={{ cursor: currentIndex === notificationList.length - 1 ? 'not-allowed' : 'pointer', pointerEvents: currentIndex === notificationList.length - 1 ? 'none' : 'auto' }}>
                                                <img src={leftArrowBtsIcon} alt="Next" className="rotate-icon" />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div className="task-overview-section" style={{ marginTop: '-5px' }}>
                                    <div className="overview-content">
                                        <h3 className="task-overview-title" >Tasks<br /><span style={{ borderBottom: '4px solid #EFF0F4' }}>Overview</span></h3>
                                        {taskOverview?.map((item, index) => (
                                            <div className="task-overview-item" key={index}>
                                                <h4 className="task-count" style={{ paddingBottom: taskOverviewLoading ? 5 : 0 }}>{item?.value} {taskOverviewLoading &&
                                                    <LoadingSkeleton width="40px" height="10px" />}</h4>
                                                <div className="task-label-container">
                                                    <p className="task-label">{item?.label}</p>
                                                    <span className={`trend-icon ${item.trend}`}>
                                                        {item.trend === 'up' ? (
                                                            <img src={arrowUpIcon} alt="Up Arrow" className="arrow-icon" />
                                                        ) : (
                                                            <img src={arrowDownIcon} alt="Down Arrow" className="arrow-icon" />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>

                                        ))}
                                        {/* <button className="add-button">+</button> */}
                                    </div>
                                </div>

                            </div>
                            <div className="tables-container">
                                {/* Pending Tasks Table */}
                                <div className="table-section">
                                    <h3 className="table-title">Pending Tasks</h3>
                                    <div className="table-header-card" style={{ marginBottom: 0 }}>
                                        <div className="table-header">
                                            <div className="table-cell client-info"><span className="header-text">Client</span></div>
                                            <div className="table-cell tasks">Service/Category</div>
                                            <div className="table-cell due-date">Due Date</div>
                                            <div className="table-cell actions-header"></div>
                                        </div>
                                    </div>
                                    <div style={{ minHeight: 290, padding: '5px 0px',display:pendingTasksResError ? 'grid':'block'  }}>
                                        {currentTasks?.map((task, index) => (
                                            <div className="table-row" key={index}>
                                                <div className="table-cell client-info">
                                                    <img src={messageIcon} alt="Message Icon" className="message-icon" style={{ cursor: 'pointer' }} onClick={() => handleAddCommentModal(task)} />
                                                    <div>
                                                        <p className="client-name">{task?.client_name}</p>
                                                        <p className="client-phone">{task?.client_mobile_code} {task?.client_mobile_no}</p>
                                                    </div>
                                                </div>
                                                <div className="table-cell tasks">{task.service_name}, {task?.service_sub_name}</div>
                                                <div className="table-cell due-date">{convertToLocalTime(task?.task_due_date)}
                                                    {deleteTaskSuccess[task?.task_id] &&
                                                        <div style={{ color: 'green', paddingTop: 10 }} >{deleteTaskSuccess[task?.task_id]}</div>}
                                                    {deleteTaskError[task?.task_id] &&
                                                        <div style={{ color: 'red', paddingTop: 10 }} >{deleteTaskError[task?.task_id]}</div>}
                                                </div>
                                                <div className="table-cell actions">
                                                    <button className="action-button" onClick={() => handleEditPendingTask(task)}>
                                                        <img src={editIcon} alt="Edit" className="icon-image" />
                                                    </button>
                                                    <button className="action-button" onClick={() => onTaskDelete(task)}>
                                                        <img src={deleteIcon} alt="Delete" className="icon-image" />
                                                    </button>
                                                    {deleteTaskLoading[task?.task_id] &&
                                                        <span><Loader loading={deleteTaskLoading[task?.task_id]} color={'var(--primary-color)'} size='small' /></span>
                                                    }
                                                </div>

                                            </div>
                                        ))}
                                        {pendingTasksLoading && <Loader loading={pendingTasksLoading} color={'var(--primary-color)'} />}
                                        {/* {pendingTasksResError && <div className="list-error-msg" style={{ display: 'flex', alignItems: 'center' }}>{pendingTasksResError} </div>} */}
                                        {pendingTasksResError && <NoDataCard
                                            message={pendingTasksResError}
                                        />}


                                    </div>

                                    {/* Pagination and Total */}
                                    <hr className="divider" />
                                    <div className="table-footer" style={{ flexWrap: 'wrap', gap: 10 }}>
                                        <div className="total-count">Total: {pendingTasksList.length}</div>
                                        <div className="pagination">
                                            <div className="pagination-info">
                                                {`${currentPage}-${totalPages} of items`}
                                            </div>
                                            <button
                                                className="pagination-button-arrow"
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={currentPage === 1}
                                            >
                                                <img src={leftArrowIcon} alt="Previous" className="arrow-left icon-image" />
                                            </button>
                                            {/* Page Numbers */}
                                            {visiblePages.map((page) => (
                                                <button
                                                    key={page}
                                                    className={`tasksScreen-pagination-button ${currentPage === page ? "active" : ""}`}
                                                    onClick={() => handlePageChange(page)}
                                                >
                                                    {page}
                                                </button>
                                            ))}
                                            <button
                                                className="pagination-button-arrow"
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                disabled={currentPage === totalPages}
                                            >
                                                <img src={leftArrowIcon} alt="Next" className="arrow-right icon-image" />
                                            </button>
                                        </div>
                                    </div>

                                    <DeleteConfirmationModal
                                        isOpen={isDeleteModalOpen}
                                        message={`Are you sure you want to delete ${taskInfoRef.current?.task_id} Task?`}
                                        onClose={handleCloseDeleteModal}
                                        onConfirm={() => {
                                            handleCloseDeleteModal(); // Close modal
                                            callDeleteTask(taskInfoRef.current); // Perform delete operation
                                        }}
                                    />

                                </div>

                                {/* To Dos and Notes Table */}
                                <div className="table-section" style={{ minWidth: 220 }}>
                                    <div className="header-container">
                                        {/* <h3 className="table-title">To Dos and Notes</h3> */}
                                        <h3 className="table-title">To Dos</h3>
                                        <div className="toggle-container">
                                            <span
                                                className={`toggle-option ${selecteTabledOption === "ToDo's" ? 'selected' : 'not-selected'}`}
                                                onClick={() => setSelectedTableOption("ToDo's")}
                                            >
                                                ToDo's
                                            </span>
                                            {/* <span
                                            className={`toggle-option ${selecteTabledOption === "Notes" ? 'selected' : 'not-selected'}`}
                                            onClick={() => setSelectedTableOption("Notes")}
                                        >
                                            Notes
                                        </span> */}
                                        </div>
                                    </div>
                                    <div className="table-header-card" style={{ marginBottom: 0 }}>
                                        <div className="table-header" >
                                            <div style={{ width: 30 }}></div>
                                            <div className="table-cell description">Description</div>
                                            <div className="table-cell due-dates" style={{ paddingRight: '90px' }}>Due Date</div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div style={{ minHeight: 294, padding: 5,display:scheduleToDoError ? 'grid':'block' }}>
                                        {currentScheduleToDoList?.map((todo, index) => (
                                            <div className="table-row" key={index} >
                                                <div style={{ width: 30, justifyContent: 'center', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleAddToDoRadioButtonModal(todo)}>
                                                    <img src={toDoCircle} alt="ToDO" style={{ width: 20, height: 20, marginRight: '3px' }} />
                                                </div>
                                                <div className="table-cell description">
                                                    {/* Add the radio button in front of the description */}
                                                    <label className="description-radio">
                                                        <span className='todo-description'>{todo.sc_desc}</span>
                                                    </label>
                                                </div>
                                                <div className="table-cell due-dates">{convertToLocalTime(todo?.sc_time)}
                                                    {deleteToDoItemSuccess[todo?.sc_id] &&
                                                        <div style={{ color: 'green', paddingTop: 10 }} >{deleteToDoItemSuccess[todo?.sc_id]}</div>}
                                                    {deleteToDoItemError[todo?.sc_id] &&
                                                        <div style={{ color: 'red', paddingTop: 10 }} >{deleteToDoItemError[todo?.sc_id]}</div>}
                                                </div>
                                                <div style={{ width: '90px', display: 'flex', alignItems: 'center' }}><button className="tasksScreen-edit-button">
                                                    <img src={editIcon} alt="Edit" onClick={() => handleEditToDo(todo)} className="icon-image" style={{ marginRight: '3px' }} />
                                                </button>
                                                    <button className="tasksScreen-delete-button" onClick={() => onToDoItemDelete(todo)}>
                                                        <img src={deleteIcon} alt="Delete" className="icon-image" style={{ marginLeft: '3px' }} />
                                                    </button>
                                                    {deleteToDoItemLoading[todo?.sc_id] &&
                                                        <span><Loader loading={deleteToDoItemLoading[todo?.sc_id]} color={'var(--primary-color)'} size='small' /></span>
                                                    }</div>
                                            </div>
                                        ))}
                                        {scheduleToDoLoading && <Loader loading={scheduleToDoLoading} color={'var(--primary-color)'} />}
                                        {/* {scheduleToDoError && <div className="list-error-msg" style={{ display: 'flex', alignItems: 'center' }}>{scheduleToDoError} </div>} */}
                                        {scheduleToDoError && <NoDataCard
                                            // type={'info'}
                                            message={scheduleToDoError}
                                        />}
                                    </div>
                                    <hr className="divider" />
                                    <div className="table-footer" style={{ flexWrap: 'wrap', gap: 10 }}>
                                        <div>
                                            <button onClick={handleAddToDoModal} className='add-popup-buttons'>
                                                <span style={{ marginRight: '10px', padding: '0px' }}>+</span> Add To-Dos and Notes
                                            </button>
                                        </div>
                                        {/* <div className="total-count">Total: {scheduleToDoList.length}</div> */}
                                        <div className="pagination">
                                            <div className="pagination-info">
                                                {`${currentPageToDoList}-${totalPagesToDoList} of items`}
                                            </div>
                                            <button
                                                className="pagination-button-arrow"
                                                onClick={() => handleToDoPageChange(currentPageToDoList - 1)}
                                                disabled={currentPageToDoList === 1}
                                            >
                                                <img src={leftArrowIcon} alt="Previous" className="arrow-left icon-image" />
                                            </button>
                                            {toDoVisiblePages.map((page) => (
                                                <button
                                                    key={page}
                                                    className={`tasksScreen-pagination-button ${currentPageToDoList === page ? "active" : ""}`}
                                                    onClick={() => handleToDoPageChange(page)}
                                                >
                                                    {page}
                                                </button>
                                            ))}
                                            <button
                                                className="pagination-button-arrow"
                                                onClick={() => handleToDoPageChange(handleToDoPageChange + 1)}
                                                disabled={handleToDoPageChange === totalPagesToDoList}
                                            >
                                                <img src={leftArrowIcon} alt="Next" className="arrow-right icon-image" />
                                            </button>
                                        </div>
                                    </div>
                                    <DeleteConfirmationModal
                                        isOpen={isDeleteToDoModalOpen}
                                        message={`Are you sure you want to delete ${toDoItemRef.current?.sc_id} ToDo Item?`}
                                        onClose={handleCloseToDoDeleteModal}
                                        onConfirm={() => {
                                            handleCloseToDoDeleteModal(); // Close modal
                                            callDeleteToDoItem(toDoItemRef.current); // Perform delete operation
                                        }}
                                    />
                                </div>

                            </div>

                            {/* <div className="quick-actions-container">
                            <h3 className="quick-actions-title">Quick <br />Actions</h3>
                            <div className="quick-actions-cards">
                                {quickActions?.map((action, index) => (
                                    <div
                                        key={index}
                                        className={`quick-action-card ${action.isActive ? 'active' : ''}`}
                                    >
                                        <div className="quick-action-content">
                                            <p className="quick-action-label">{action.label}</p>
                                            <div className="quick-action-icon">
                                                <img src={plusIcon} alt="Plus" className="plus-icon" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div> */}


                        </div>
                        <div className="dashboard-container-Upcoming-Appointments">
                            <div className="left-section">
                                <div className="activity-header">
                                    <h4 className="activity-title">Activity</h4>
                                    <select className="dropdown-select">
                                        {dateOptions.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="right-section">
                                <div className="appointments-header">
                                    <h4 onClick={handleAppointments} className='Upcoming-Appointments-header'>Upcoming Appointments</h4>
                                    <div className="filter-options">
                                        {options?.map((option) => (
                                            <span
                                                key={option}
                                                className={`filter-option ${selectedOption === option ? 'selected' : 'not-selected'}`}
                                                onClick={() => setSelectedOption(option)}
                                            >
                                                {option}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className="appointments-list">
                                     <div style={{ minHeight: 290, padding: '5px 0px',display:upcomingError ? 'grid':'block'  }}>
                                    {currentUpcomingTasks?.map((appointment, index) => (
                                        <div className="appointment-item" key={index} >
                                            <div className="appointment-info">
                                                <img src={profileImg} alt="Appointment" className="appointment-image" />
                                                <div>
                                                    <p className="appointment-name">{appointment.client_name}</p>
                                                    <p className="appointment-company">
                                                        {appointment.sc_title} - {convertToFormattedLocalTime(appointment.sc_time)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="appointment-status">
                                                <div
                                                    style={{
                                                        display: "inline-block",
                                                        padding: "5px 5px",
                                                        borderRadius: "15px",
                                                        ...getStatusStyle(appointment?.sc_status_name),
                                                        width: "auto",
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    {appointment?.sc_status_name}
                                                </div>
                                                <div style={{ width: 30, justifyContent: 'center', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleEditUpcomingEdit(appointment)}>
                                                    <img src={toDoCircle} alt="ToDO" style={{ width: 20, height: 20, marginRight: '3px' }} />
                                                </div>
                                                {/* <input type="radio" name="appointment-select" style={{ transform: 'scale(1.5)' }} onChange={() => handleEditUpcomingEdit(appointment)} /> */}
                                            </div>
                                        </div>
                                    ))}
                                    {upcomingLoading && <Loader loading={upcomingLoading} color={'var(--primary-color)'} />}
                                    {/* {upcomingError && <div className="list-error-msg" style={{ display: 'flex', alignItems: 'center' }}>{upcomingError} </div>} */}
                                    {upcomingError && <NoDataCard
                                        // type={'info'}
                                        message={upcomingError}
                                    />}
                                    </div>
                                    <div className="table-footer" style={{ flexWrap: 'wrap', gap: 10 }}>
                                    <div style={{ paddingTop: 10 }}>
                                        <button onClick={handleSheduleAndReminder} className='add-popup-buttons'>
                                            + Add Schedule and Reminders
                                        </button>
                                    </div>
                                    <div className="pagination">
                                            <div className="pagination-info">
                                                {`${currentUpcomingPage}-${totalUpcomingPages} of items`}
                                            </div>
                                            <button
                                                className="pagination-button-arrow"
                                                onClick={() => handleUpcomingPageChange(currentUpcomingPage - 1)}
                                                disabled={currentUpcomingPage === 1}
                                            >
                                                <img src={leftArrowIcon} alt="Previous" className="arrow-left icon-image" />
                                            </button>
                                            {/* Page Numbers */}
                                            {upcomingVisiblePages?.map((page) => (
                                                <button
                                                    key={page}
                                                    className={`tasksScreen-pagination-button ${currentUpcomingPage === page ? "active" : ""}`}
                                                    onClick={() => handleUpcomingPageChange(page)}
                                                >
                                                    {page}
                                                </button>
                                            ))}
                                            <button
                                                className="pagination-button-arrow"
                                                onClick={() => handleUpcomingPageChange(currentUpcomingPage + 1)}
                                                disabled={currentUpcomingPage === totalUpcomingPages}
                                            >
                                                <img src={leftArrowIcon} alt="Next" className="arrow-right icon-image" />
                                            </button>
                                        </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <AddCommentModal isOpen={isCommentModalOpen} onClose={handleCloseCommentModal} isTaskInfo={taskInfo} onSuccess={commentSuccess} />
                <AddToDoModal isOpen={isAddToDoModalOpen} onClose={handleCloseToDoModal} fetchToDoList={() => { fetchToDoList(); fetchPriorityList(); }} documentData={selectedDocument} fetchPriorityList={() => { fetchPriorityList() }} />
                <AddScheduleReminderModal isOpen={isScheduleModalOpen} onClose={handleCloseScheduleDoModal} fetchPriorityList={() => { fetchPriorityList(); fetchUpcomingAppointemntsList() }} fetchToDoList={fetchToDoList} selectedUpcomingData={selectedUpcomingData} />
                <ToDoCompleteModal isOpen={isAddToDoRadioButtonModalOpen} onClose={handleCloseToDoRadioButtonModal} selectedToDoInfo={toDoInfoState} fetchToDoList={fetchToDoList} />
            </div>
        </>
    );
};

export default DashboardScreen;
