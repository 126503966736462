import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../../Components/Tabs';
import NoticeBoardActiveMsgList from './NoticeBoardActiveMsgList';
import NoticeBoardExpiredMsgList from './NoticeBoardExpiredMsgList';
import NoticeBoardController from '../../../../Controllers/AdminScreens/AddOns/NoticeBoard/NoticeBoardController';


const NoticeBoardScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const{
        tasks: currentTasks, // Only return tasks for the current page
        currentPage,
        totalPages,
        handlePageChange,
        filterTasks, // Expose the filter function

        noticeBoardMessagesList,
        noticeBoardMessagesError,
        noticeBoardMessagesLoading,
        setNoticeBoardMessagesError,
        setNoticeBoardMessagesList,
        setNoticeBoardMessagesLoading,
        fetchNoticeBoardMessagesList,
        noticeBoardExpiredMessagesList,
        setNoticeBoardExpiredMessagesList,
        noticeBoardExpiredMessagesError,
        noticeBoardActiveMessagesError,
        setNoticeBoardExpiredMessagesError,
        setNoticeBoardActiveMessagesError
    }= NoticeBoardController();

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{ paddingBottom: 10 }}>
                <h2 className='title'>Notice Board</h2>
            </div>

            <div >
                <Tabs tabs={['Active Messages', 'Expired Messages']}
                selectedIndex={selectedTab}
                onTabChange={handleTabChange}>
                    {/* <NoticeBoardActiveMsgList currentTasks={currentTasks} currentPage={currentPage}  totalPages={totalPages}  handlePageChange={handlePageChange}/> */}
                    <NoticeBoardActiveMsgList noticeBoardMessagesList={noticeBoardMessagesList} noticeBoardMessagesError={noticeBoardMessagesError} noticeBoardMessagesLoading={noticeBoardMessagesLoading} onRefresh={fetchNoticeBoardMessagesList} noticeBoardActiveMessagesError={noticeBoardActiveMessagesError} setNoticeBoardMessagesLoading={setNoticeBoardMessagesLoading} setNoticeBoardActiveMessagesError={setNoticeBoardActiveMessagesError} setNoticeBoardMessagesList={setNoticeBoardMessagesList}/>
                    <NoticeBoardExpiredMsgList noticeBoardExpiredMessagesList={noticeBoardExpiredMessagesList} noticeBoardMessagesError={noticeBoardMessagesError} noticeBoardMessagesLoading={noticeBoardMessagesLoading} onRefresh={fetchNoticeBoardMessagesList} noticeBoardExpiredMessagesError={noticeBoardExpiredMessagesError} setNoticeBoardMessagesLoading={setNoticeBoardMessagesLoading} setNoticeBoardExpiredMessagesError={setNoticeBoardExpiredMessagesError} setNoticeBoardExpiredMessagesList={setNoticeBoardExpiredMessagesList}/>
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default NoticeBoardScreen;