
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { postData, getData } from '../../../../ApiServices/ApiService';
import {
    ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_GET_GROUP_ESTIMATE_TASK_LIST, ORG_GET_SERVICE_LIST_SEARCH, ORG_GET_SUB_SERVICE_LIST_SEARCH,
    ORG_GET_ESTIMATE_TASK_LIST, ORG_GET_GROUP_LIST, ORG_GET_EMPLOYEE_LIST, ORG_UPDATE_INVOICE, ORG_GET_GROUP_INFO, ORG_CREATE_INVOICE, ORG_GET_INVOICE_INFO
} from '../../../../ApiServices/BaseURL';
import { useLocation } from 'react-router-dom';

const GenerateInvoiceController = () => {

    const location = useLocation();
    const rowData = location?.state?.rowData;
    const preSelectedInvoiceData = location?.state?.preInvoiceData;
    const [searchUser, setSearchUser] = useState('');
    const [clientList, setClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedClientError, setSelectedClientError] = useState('');
    const [loading, setLoading] = useState(false);

    const [groupInfo, setGroupInfo] = useState({});
    const [groupInfoError, setGroupInfoError] = useState('');
    const [groupInfoLoading, setGroupInfoLoading] = useState(false);

    const [taskListLoading, setTaskListLoading] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [taskListError, setTaskListError] = useState('');

    const [selectAll, setSelectAll] = useState(false);
    const [checkedStates, setCheckedStates] = useState([]);
    const [selectedTaskIds, setSelectedTaskIds] = useState([]);

    const [groupClientOptionsList, setGroupClientOptionsList] = useState([]);
    const [groupLoading, setGroupLoading] = useState(false);
    const [groupClientError, setGroupClientError] = useState('');
    const [groupClient, setGroupClient] = useState(null);

    const [groupTaskListLoading, setGroupTaskListLoading] = useState(false);
    const [groupTaskList, setGroupTaskList] = useState([]);
    const [groupTaskListError, setGroupTaskListError] = useState('');

    const [selectedClientsTasks, setSelectedClientsTasks] = useState({});
    const [dateError, setDateError] = useState('')

    const [expandedClients, setExpandedClients] = useState({});

    const [employeeList, setEmployeeList] = useState([]);
    const [empLoading, setEmpLoading] = useState(false);
    const [empError, setEmpError] = useState('');

    const [invoiceWithoutTask, setInvoiceWithoutTask] = useState(true);

    const [rows, setRows] = useState([
        { id: 1, serviceCategory: null, serviceType: [] }, // Default row
    ]);

    const [serviceOptions, setServiceOptions] = useState([]); // Service list
    const [serviceError, setServiceError] = useState('');
    const [serviceLoading, setServiceLoading] = useState(false);
    const [serviceCategoryOptions, setServiceCategoryOptions] = useState({}); // Map of serviceId -> serviceType options
    const [serviceCategoryError, setServiceCategoryError] = useState({});
    const [serviceCategoryLoading, setServiceCategoryLoading] = useState({});
    const [serviceSelectError, setServiceSelectError] = useState('')

    const [sendUpdateToClient, setSendUpdateToClient] = useState(false);
    const [sendUpdateToTeam, setSendUpdateToTeam] = useState(false);
    const [isShowGroupWise, setIsShowGroupWise] = useState('Client');
    const invoiceTableData = [
        {
            taskName: "GST Filling",
            financialYear: "2023-24",
            assignedTo: "Yearly",
            taskLeader: "Yearly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "In Progress"
        },
        {
            taskName: "Company Reg",
            financialYear: "2023-24",
            assignedTo: "Yearly",
            taskLeader: "Yearly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "Not Started"
        },
        {
            taskName: "Company Registration",
            financialYear: "2023-24",
            assignedTo: "Yearly",
            taskLeader: "Yearly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "Priority"
        },
        {
            taskName: "ITR",
            financialYear: "2023-24",
            assignedTo: "Yearly",
            taskLeader: "Yearly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "Priority"
        },
        {
            taskName: "Retention Charges",
            financialYear: "2023-24",
            assignedTo: "Monthly",
            taskLeader: "Monthly",
            startDate: "11/11/2024",
            etd: "11/11/2024",
            status: "Priority"
        }
    ]

    const [basicInformation, setBasicInformation] = useState({
        clientName: "",
        groupName: "",
        phoneNo: "",
        dateOfInvoice: "",
        countryCode: "",
    });

    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState('');

    const [filedErrors, setFiledErrors] = useState({
        invoiceType: "",
        systemInvoicingAmount: "",
        invoiceAmount: "",
        noTaxInvoices: "",
        followUpDate: "",
        advancePayments: ""
    })

    const handleBasicInformationChange = (field, value) => {
        setBasicInformation((prevInfo) => ({
            ...prevInfo,
            [field]: value,
        }));
        setDateError('');
    };

    const [invoiceDetails, setInvoiceDetails] = useState({
        invoiceType: null,
        noTaxInvoices: null,
        generateSeperateInvoices: null,
        estimateNumber: "",
        advancePayments: 0,
        systemInvoicingAmount: 0,
        invoiceAmount: 0,
        taskCompletedBy: null,
        generatedBy: null,
        organization: "",
        receiptBook: "",
        followUpDate: "",
    });

    const [invoiceInfo, setInvoiceInfo] = useState({});
    const [invoiceError, setInvoiceError] = useState('');
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [isGenReceiptModalOpen, setIsGenReceiptModalOpen] = useState(false);

    useEffect(() => {
        fetchGroupList();
        fetchUserList();
        fetchServiceList();
    }, [])

    useEffect(() => {
        if (rowData) {
            fetchInvoiceInfo(rowData?.invoice_id)
        }
    }, [rowData])

    useEffect(() => {
        if (preSelectedInvoiceData) {
            handleToSetPreSelectedData(preSelectedInvoiceData);
        }

    }, [preSelectedInvoiceData])

    // Get Invoice Details 

    const fetchInvoiceInfo = async (invoiceId) => {
        try {
            setInvoiceError('');
            setInvoiceInfo({});
            setInvoiceLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            // Make the API call
            const response = await getData(`${ORG_GET_INVOICE_INFO}?emp_id_auth=${storedUserInfo?.emp_id}&invoice_id=${invoiceId}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setInvoiceInfo(responseData?.dataJ[0]);
                            handleSetResponseData(responseData?.dataJ[0])
                        } else {
                            setInvoiceError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setInvoiceError(responseData?.info || 'Failed to fetch invoice data. Please try again.');
                    }
                } else {
                    setInvoiceError(responseData?.info || 'Failed to fetch invoice data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setInvoiceError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setInvoiceError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setInvoiceError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setInvoiceError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            setInvoiceError(`Failed to fetch invoice data. Please check your network connection and try again.`);

        } finally {
            setInvoiceLoading(false);
        }
    }

    const fetchGroupInfo = async (groupIdValue) => {
        try {
            setGroupInfoLoading(true);
            setGroupInfoError('');
            // setTeamInfo({});


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_GROUP_INFO}?group_id=${groupIdValue}&emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setGroupInfo(responseData?.dataJ[0]);
                        } else {
                            setGroupInfoError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setGroupInfoError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setGroupInfoError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGroupInfoError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setGroupInfoError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setGroupInfoError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setGroupInfoError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setGroupInfoError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Group Data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setGroupInfoError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setGroupInfoError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setGroupInfoLoading(false);
        }
    }

    const handleSetResponseData = async (resInvoiceInfo) => {
        console.log('resInvoiceInfo:--', resInvoiceInfo);

        setIsShowGroupWise(resInvoiceInfo?.invoice_for === 1 ? 'Client' : 'Group');
        setInvoiceWithoutTask(resInvoiceInfo?.invoice_type === 1 ? false : true);

        setSearchUser(resInvoiceInfo?.client_name);
        setSelectedClient(resInvoiceInfo);
        setSendUpdateToClient(resInvoiceInfo?.send_update_client);
        setSendUpdateToTeam(resInvoiceInfo?.send_update_team);

        setInvoiceDetails((prev) => ({
            ...prev,
            invoiceAmount: resInvoiceInfo?.task_bill_amount || 0,
            systemInvoicingAmount: resInvoiceInfo?.system_invoice_amount || 0,
            invoiceType: { label: '', value: resInvoiceInfo?.invoice_type || 0 },
            noTaxInvoices: { label: '', value: resInvoiceInfo?.is_tax_invoice },
            generateSeperateInvoices: { label: resInvoiceInfo?.is_gen_seperate_invoice ? 'Yes' : 'No', value: resInvoiceInfo?.is_gen_seperate_invoice },
            advancePayments: resInvoiceInfo?.advance_amount || 0,
            generatedBy: { label: '', value: resInvoiceInfo?.invoice_gen_emp_id || 0 },
            followUpDate: resInvoiceInfo?.follow_up_date || ''

        }));

        setBasicInformation((prev) => ({
            ...prev,
            dateOfInvoice: resInvoiceInfo?.invoice_date || '',
        }))


        if (resInvoiceInfo?.invoice_for === 1) {
            if (resInvoiceInfo?.invoice_type === 1) {
                const filterData = await convertResponseData(resInvoiceInfo?.service_list || [])
                setRows(filterData);
            } else {
                fetchTaskList(resInvoiceInfo?.client_id, resInvoiceInfo?.tasks_list);
            }
        } else {
            let groupInfo = { label: resInvoiceInfo?.group_name || '', value: resInvoiceInfo?.group_id || 0 }
            setGroupClient(groupInfo);
            fetchGroupInfo(resInvoiceInfo?.group_id);
            if (resInvoiceInfo?.invoice_type === 1) {
                const filterData = await convertResponseData(resInvoiceInfo?.service_list || [])
                setRows(filterData);
            } else {
                fetchGroupClientTaskList(resInvoiceInfo?.group_id, resInvoiceInfo?.tasks_list);
            }
        }



    }

    const convertResponseData = (data) => {
        return data?.map((item, index) => {
            // Call the function for each item
            let serviceId = { value: item?.service_id }
            fetchServiceCategoryList(serviceId, item?.estimate_service_id);

            return {
                id: item?.estimate_service_id,
                serviceCategory: {
                    label: item?.service_name,
                    value: item?.service_id
                },
                serviceType: item?.service_sub_details?.map((sub) => ({
                    label: sub?.service_sub_name,
                    value: sub?.service_sub_id
                })),
            };
        });
    };

    const processSelectedClientsTasks = (data) => {
        const groupedTasks = data?.reduce((acc, item) => {
            if (!acc[item?.client_id]) {
                acc[item?.client_id] = [];
            }
            acc[item?.client_id].push(item?.task_id);
            return acc;
        }, {});

        setSelectedClientsTasks(groupedTasks);
    };

    const handleInvoiceDetailsChange = (field, value) => {
        setInvoiceDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
        // if (field === 'invoiceType') {
            setFiledErrors((prevDetails) => ({
                ...prevDetails,
                [field] : ''
            }));
        // }
    };
    const handleShowIsClient = (value) => {
        setIsShowGroupWise(value);
        clearAllFileds();
    }
    const navigate = useNavigate()
    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };


    const getStatusStyle = (status) => {
        switch (status) {
            case "Not Started":
                return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
            case "Priority":
                return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
            case "In Progress":
                return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
            default:
                return {};
        }
    };

    const handleSelectAll = (isChecked) => {
        setSelectAll(isChecked);
        // setCheckedStates(taskList.map(() => isChecked));
        // Update taskList to mark all as checked or unchecked
        const updatedTaskList = taskList.map((task) => ({
            ...task,
            checked: isChecked,
        }));
        setTaskList(updatedTaskList);
        setInvoiceDetails((prev) => ({
            ...prev,
            invoiceAmount: 0,
        }));

        if (isChecked) {
            // Add all task IDs to the selectedTaskIds list
            setSelectedTaskIds(taskList.map((task) => task.task_id));

            // Compute total estimated amount for all tasks
            const totalEstimatedAmount = taskList.reduce((sum, task) => sum + Number(task.task_bill_amount || 0), 0);

            // Set estimateAmount by adding all selected tasks
            setInvoiceDetails((prev) => ({
                ...prev,
                invoiceAmount: Number(prev.estimateAmount) + Number(totalEstimatedAmount),
            }));
        } else {
            // Clear the selectedTaskIds list
            setSelectedTaskIds([]);

            // Reset estimateAmount to 0 
            setInvoiceDetails((prev) => ({
                ...prev,
                invoiceAmount: 0,
            }));
        }
    };

    const handleCheckboxChange = (index, isChecked) => {
        setTaskListError('');
        const taskId = taskList[index]?.task_id;
        const taskBillAmountEstimated = taskList[index]?.task_bill_amount || 0;

        // Update the checked status of the task
        const updatedTaskList = [...taskList];
        updatedTaskList[index] = { ...updatedTaskList[index], checked: isChecked };
        setTaskList(updatedTaskList);

        // Manage selected task IDs
        if (isChecked) {
            setSelectedTaskIds((prev) => [...prev, taskId]);
        } else {
            setSelectedTaskIds((prev) => prev.filter((id) => id !== taskId));
        }

        // Reset estimateAmount to 0
        setInvoiceDetails((prev) => ({
            ...prev,
            invoiceAmount: 0,
        }));

        // Calculate the total of checked items' amounts
        const totalEstimateAmount = updatedTaskList
            .filter((task) => task.checked)
            .reduce((sum, task) => sum + Number(task.task_bill_amount || 0), 0);

        // Update the estimateOptions with the new total
        setInvoiceDetails((prev) => ({
            ...prev,
            invoiceAmount: totalEstimateAmount,
        }));

        // Check if all tasks are checked
        const allChecked = updatedTaskList.every((task) => task.checked);
        setSelectAll(allChecked);
    };

    // Client search code

    const handleSearchClient = async () => {
        try {
            setSelectedClientError('');
            setLoading(true);
            setClientList([]);
            if (!searchUser) {
                setSelectedClientError('Please enter client name'); return;
            }
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchUser }

            //console.log('team Data', payLoad);
            // Make the API call
            const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
            const responseData = await response.json();
            //console.log('response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setClientList(responseData?.dataJ || []);
                    } else {
                        setSelectedClientError(responseData?.info || 'Failed to fetch Client. Please try again.');
                    }
                } else {
                    setSelectedClientError(responseData?.info || 'Failed to fetch Client.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setSelectedClientError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setSelectedClientError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setSelectedClientError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setSelectedClientError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            setSelectedClientError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);

        } finally {
            setLoading(false);
        }
    };


    const handleSearchInputText = (value) => {
        setSearchUser(value);
        setSelectedClientError('');
        setClientList([]);
        if (!value) {
            setSelectedClient(null);
        }

    }

    const handleSelectClient = (client) => {
        setSelectedClient(client); // Set selected client data
        setSearchUser(client?.client_name);
        fetchTaskList(client?.client_id, '');
        setClientList([]); // Clear the dropdown
        setSelectedClientError('');
    };

    const fetchTaskList = async (clientId, editTaskList) => {
        try {
            setTaskListError('');
            setTaskList([]);
            setSelectedTaskIds([]);
            setCheckedStates([]);
            setSelectAll(false);
            setTaskListLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_ESTIMATE_TASK_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            if (editTaskList?.length > 0) {
                                const updatedTaskList = responseData?.dataJ.map((task) => ({
                                    ...task,
                                    checked: editTaskList.some((item) => item.task_id === task.task_id), // Set checked true if task_id exists in res
                                }));
                                setTaskList(updatedTaskList);
                                // Extract the task IDs of the checked items and update selectedTaskIds
                                const checkedTaskIds = updatedTaskList
                                    .filter((task) => task.checked) // Get tasks with checked: true
                                    .map((task) => task.task_id);  // Extract their task_id

                                setSelectedTaskIds(checkedTaskIds);
                            } else {
                                setTaskList(responseData?.dataJ);
                            }
                        } else {
                            setTaskListError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setTaskListError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setTaskListError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setTaskListError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setTaskListError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setTaskListError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setTaskListError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setTaskListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setTaskListError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setTaskListError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setTaskListLoading(false);
        }
    }

    // Group DropDown selection code 
    const fetchGroupList = async () => {
        try {
            setGroupClientError('')
            setGroupLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            // Transforming the data
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service?.group_name,
                                value: service?.group_id,
                                group_clients_count: service?.group_clients_count
                            }));
                            setGroupClientOptionsList(filterData)
                        } else {
                            setGroupClientError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setGroupClientError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setGroupClientError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGroupClientError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setGroupClientError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setGroupClientError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setGroupClientError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setGroupClientError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setGroupClientError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setGroupClientError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setGroupLoading(false);
        }
    }
    const handleSetGroupClient = (value) => {
        setGroupClient(value);
        setGroupClientError('');
        setGroupTaskList([]);
        setSelectedClientsTasks({});
        fetchGroupInfo(value?.value);
    }

    const showGroupInvoiceList = () => {
        setGroupClientError('');
        setTaskListError('')
        if (groupClient?.value) {
            fetchGroupClientTaskList(groupClient?.value, '');
        } else {
            setGroupClientError('Select group')
        }
    }

    const fetchGroupClientTaskList = async (groupId, editTaskList) => {
        try {
            // setIsShowGroupWise(true)
            setGroupTaskListError('');
            setGroupTaskList([]);
            setSelectedClientsTasks({});
            setGroupTaskListLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_GROUP_ESTIMATE_TASK_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&group_id=${groupId}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setGroupTaskList(responseData?.dataJ);
                            if (editTaskList) {
                                processSelectedClientsTasks(editTaskList);
                            }
                        } else {
                            setGroupTaskListError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setGroupTaskListError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setGroupTaskListError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGroupTaskListError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setGroupTaskListError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setGroupTaskListError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setGroupTaskListError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setGroupTaskListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setGroupTaskListError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setGroupTaskListError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setGroupTaskListLoading(false);
        }
    }



    // Handle Task Checkbox Selection
    const handleTaskCheckboxChange = (clientId, taskId, taskEstimatedAmount, isChecked) => {
        setSelectedClientsTasks((prev) => {
            const prevSelected = prev[clientId] || [];
            return {
                ...prev,
                [clientId]: isChecked ? [...prevSelected, taskId] : prevSelected.filter((id) => id !== taskId),
            };
        });

        // Reset setInvoice to 0
        setInvoiceDetails((prev) => ({
            ...prev,
            invoiceAmount: 0,
        }));

        // Update Estimate Amount based on all checked tasks
        setSelectedClientsTasks((prevSelected) => {
            const selectedTasks = Object.values(prevSelected).flat();
            const totalEstimateAmount = groupTaskList
                .flatMap(client => client.task_list)
                .filter(task => selectedTasks.includes(task.task_id))
                .reduce((sum, task) => sum + Number(task.task_bill_amount || 0), 0);

            setInvoiceDetails((prev) => ({
                ...prev,
                invoiceAmount: totalEstimateAmount,
            }));

            return prevSelected;
        });
    };

    // Handle Select All for Client
    const handleClientSelectAll = (clientId, isChecked) => {
        const clientTasks = groupTaskList.find(client => client.client_id === clientId)?.task_list || [];
        const allTaskIds = clientTasks.map(task => task.task_id);

        setSelectedClientsTasks((prev) => ({
            ...prev,
            [clientId]: isChecked ? allTaskIds : [], // Select or Deselect all
        }));

        // Reset invoiceAmount to 0
        setInvoiceDetails((prev) => ({
            ...prev,
            invoiceAmount: 0,
        }));

        // Update Estimate Amount based on all checked tasks
        setSelectedClientsTasks((prevSelected) => {
            const selectedTasks = Object.values(prevSelected).flat();
            const totalEstimateAmount = groupTaskList
                .flatMap(client => client.task_list)
                .filter(task => selectedTasks.includes(task.task_id))
                .reduce((sum, task) => sum + Number(task.task_bill_amount || 0), 0);

            setInvoiceDetails((prev) => ({
                ...prev,
                invoiceAmount: totalEstimateAmount,
            }));

            return prevSelected;
        });
    };
    // Toggle Client Expansion
    const toggleClient = (clientId) => {
        setExpandedClients((prev) => ({ ...prev, [clientId]: !prev[clientId] }));
    };


    // Employees list 
    const fetchUserList = async () => {
        try {
            setEmpLoading(true);
            setEmpError('');
            setEmployeeList([]);


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service?.emp_name,
                                value: service?.emp_id
                            }));
                            setEmployeeList(filterData);
                        } else {
                            setEmpError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setEmpError(responseData?.info || 'Failed to fetch User data. Please try again.');
                    }
                } else {
                    setEmpError(responseData?.info || 'Failed to fetch User data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setEmpError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setEmpError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setEmpError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setEmpError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setEmpError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setEmpError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setEmpError(`Failed to fetch User data. Please check your network connection and try again.`);
            }
        } finally {
            setEmpLoading(false);
        }
    }
    // service list 

    const fetchServiceList = async () => {
        try {
            setServiceError('');
            setServiceLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id
            }

            //console.log('fetchTaskCategorieList payload', payLoad);

            // Make the API call
            const response = await postData(ORG_GET_SERVICE_LIST_SEARCH, payLoad);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            // Transforming the data
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service.service_name,
                                value: service.service_id
                            }));
                            setServiceOptions(filterData)
                        } else {
                            setServiceError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setServiceError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setServiceError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setServiceError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setServiceError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setServiceError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setServiceError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setServiceError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setServiceError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setServiceError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setServiceLoading(false);
        }
    }

    const fetchServiceCategoryList = async (serviceId, rowId) => {
        try {
            setServiceCategoryError({});
            setServiceCategoryLoading((prev) => ({ ...prev, [rowId]: true }));
            setServiceCategoryOptions((prev) => ({ ...prev, [rowId]: [] }));
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "service_id": serviceId?.value || 0
            }

            //console.log('--fetchTaskSubCategorieList payload--', payLoad);

            // Make the API call service_id=10006&
            const response = await postData(ORG_GET_SUB_SERVICE_LIST_SEARCH, payLoad);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            // Transforming the data
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service.service_sub_name,
                                value: service.service_sub_id
                            }));
                            setServiceCategoryOptions((prev) => ({ ...prev, [rowId]: filterData })); // Store per row
                        } else {
                            setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Data Not Found' }));
                        }
                    } else {
                        setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Failed to fetch inventory data. Please try again.' }));
                    }
                } else {
                    setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Failed to fetch Task Categorie data.' }));
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setServiceCategoryError((prev) => ({ ...prev, [rowId]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Bad request. Please check the request parameters.' }));
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Internal Server Error. Please try again later.' }));
            }
            // Unexpected or network-related errors
            else {
                setServiceCategoryError((prev) => ({ ...prev, [rowId]: 'Unexpected error occurred. Please try again later.' }));
            }
        } catch (error) {

            setServiceCategoryError((prev) => ({ ...prev, [rowId]: 'Failed to fetch Task Categorie data. Please check your network connection and try again.' }));

        } finally {
            setServiceCategoryLoading((prev) => ({ ...prev, [rowId]: false }));
        }
    }


    const handleSelectServiceOrTask = (value) => {
        if (rowData) { return }
        setInvoiceWithoutTask(value);

        if (isShowGroupWise === 'Group') {
            setSelectedClientsTasks({});
        } else {
            setCheckedStates([]);
            setSelectedTaskIds([]);
            handleSelectAll(false);
        }
        setRows([{ id: 1, serviceCategory: null, serviceType: [] }]);
        setInvoiceDetails((prev) => ({
            ...prev,
            invoiceAmount: 0,
        }));
    }

    // Function to add a new service row
    const addServiceRow = () => {
        setRows([...rows, { id: Date.now(), serviceCategory: null, serviceType: [] }]);
    };

    // Function to remove a service row
    const removeServiceRow = (id) => {
        setRows(rows.filter((row) => row.id !== id));
    };


    const handleSubmitInvoice = async () => {
        try {
            setSubmitError('');
            setGroupClientError('');
            setSelectedClientError('');
            setTaskListError('');
            setServiceSelectError('');
            setDateError('');
            setFiledErrors({
                invoiceType: "",
                systemInvoicingAmount: "",
                invoiceAmount: "",
                noTaxInvoices: "",
                followUpDate: "",
            })

            //  Extract all Clents task IDs into a single list
            const allTaskIds = Object.values(selectedClientsTasks).flat();

            const servicesListSelected = rows
                .filter(item => item.serviceCategory?.value) // Filter out items without serviceCategory value
                .map(item => ({
                    service_id: item.serviceCategory.value, // Use serviceCategory value as service_id
                    service_sub_list: item.serviceType ? item.serviceType.map(type => type.value) : [] // Map serviceType values
                }));

            let hasError = false;

            if (isShowGroupWise === 'Group') {
                if (!groupClient?.value) {
                    setGroupClientError('Select group name.'); hasError = true
                }
                if (!basicInformation?.dateOfInvoice) {
                    setDateError('Please select date'); hasError = true
                }
                if (invoiceWithoutTask) {
                    if (allTaskIds.length <= 0) {
                        setTaskListError('Please select at least one task.'); hasError = true
                    }

                } else {
                    if (servicesListSelected.length <= 0) {
                        setServiceSelectError('Please select requested services'); hasError = true
                    }
                    // Check if any serviceType is empty
                    const hasEmptyServiceType = servicesListSelected.some(
                        (service) => service.service_sub_list.length === 0
                    );
                    console.log('hasEmptyServiceType:', hasEmptyServiceType)

                    if (hasEmptyServiceType) {
                        setServiceSelectError('Please select at least one service category for service');
                        return
                    }
                }
            } else {
                if (!selectedClient?.client_id) {
                    setSelectedClientError('Enter client name, search, and select.'); hasError = true
                }
                if (!basicInformation?.dateOfInvoice) {
                    setDateError('Please select date'); hasError = true
                }
                if (invoiceWithoutTask) {
                    if (selectedTaskIds.length <= 0) {
                        setTaskListError('Please select at least one task.'); hasError = true
                    }

                } else {
                    if (servicesListSelected.length <= 0) {
                        setServiceSelectError('Please select requested services'); hasError = true
                    }
                    // Check if any serviceType is empty
                    const hasEmptyServiceType = servicesListSelected.some(
                        (service) => service.service_sub_list.length === 0
                    );
                    console.log('hasEmptyServiceType:', hasEmptyServiceType)

                    if (hasEmptyServiceType) {
                        setServiceSelectError('Please select at least one service category for service');
                        return
                    }
                }
            }
            if (!invoiceDetails?.invoiceType?.value) {
                setFiledErrors((prev) => ({
                    ...prev,
                    invoiceType: "Invoice type is required.",
                })); hasError = true
            }
            // if (!invoiceDetails?.noTaxInvoices?.value) {
            //     setFiledErrors((prev) => ({
            //         ...prev,
            //         noTaxInvoices: "No tax invoice value is required.",
            //     })); hasError = true
            // }

            if (!invoiceDetails?.advancePayments) {
                setFiledErrors((prev) => ({
                    ...prev,
                    advancePayments: "Advance payments is required.",
                })); hasError = true
            }

            if (!invoiceDetails?.systemInvoicingAmount) {
                setFiledErrors((prev) => ({
                    ...prev,
                    systemInvoicingAmount: "System invoicing amount is required.",
                })); hasError = true
            }

            if (!invoiceDetails?.invoiceAmount) {
                setFiledErrors((prev) => ({
                    ...prev,
                    invoiceAmount: "Invoice amount is required.",
                })); hasError = true
            }

            if (!invoiceDetails?.generatedBy) {
                setFiledErrors((prev) => ({
                    ...prev,
                    generatedBy: "Generated by is required.",
                })); hasError = true
            }

            if (!invoiceDetails?.followUpDate) {
                setFiledErrors((prev) => ({
                    ...prev,
                    followUpDate: "Follow-up date is required.",
                })); hasError = true
            }

            if (hasError) {
                return
            }

            setSubmitLoading(true)
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad =
            {
                "emp_id_auth": storedUserInfo?.emp_id,
                "client_id": selectedClient?.client_id || 0,
                "invoice_date": basicInformation?.dateOfInvoice.replace('T', ' ') + ' 00:00:00',
                "follow_up_date": invoiceDetails?.followUpDate.replace('T', ' ') + ' 00:00:00' || '',
                "group_id": groupClient?.value || 0,
                "invoice_gen_emp_id": invoiceDetails?.generatedBy?.value || '',
                "is_group_invoice": invoiceDetails?.generateSeperateInvoices?.value || 0,
                "invoice_for": isShowGroupWise === 'Group' ? 2 : 1,
                "invoice_type": invoiceWithoutTask ? 2 : 1,
                "system_invoice_amount": invoiceDetails?.systemInvoicingAmount || 0,
                "task_bill_amount": invoiceDetails?.invoiceAmount || 0,
                "send_update_client": sendUpdateToClient ? 1 : 0,
                "send_update_team": sendUpdateToTeam ? 1 : 0,
                "is_tax_invoice": invoiceDetails?.noTaxInvoices?.value || 0,
                "is_gen_seperate_invoice": invoiceDetails?.generateSeperateInvoices?.value || 0,
                "advance_amount": invoiceDetails?.advancePayments || 0,
                "invoice_mode": invoiceDetails?.invoiceType?.value || 0,
                "tasks_list_selected": !invoiceWithoutTask ? [] : isShowGroupWise === 'Group' ? allTaskIds : selectedTaskIds,
                "services_list_selected": !invoiceWithoutTask ? servicesListSelected : [],
                "estimate_id":preSelectedInvoiceData?.estimate_id || invoiceDetails?.estimateNumber || 0
            }

            console.log('payload:--', payLoad);
            // Make the API call service_id=10006&
            const response = await postData(ORG_CREATE_INVOICE, payLoad);
            const responseData = await response.json();
            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setSubmitSuccess(responseData?.info || '');
                        clearAllFileds()
                        setTimeout(() => {
                            setSubmitSuccess('');
                            navigate('/bills-receipts', { state: { selectedTab: 1 } });
                        }, 3000);
                    } else {
                        setSubmitError(responseData?.info || 'Failed to create estimate. Please try again.');
                    }
                } else {
                    setSubmitError(responseData?.info || 'Failed to create estimate.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setSubmitError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setSubmitError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setSubmitError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setSubmitError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            setSubmitError('No response received from the server. Please check your network connection and try again.');
        } finally {
            setSubmitLoading(false)
        }


    }

    const clearAllFileds = () => {
        setSelectAll(false);
        setCheckedStates([]);
        setSelectedTaskIds([]);
        setSendUpdateToClient(false);
        setSendUpdateToTeam(false);
        setInvoiceWithoutTask(true);
        setSearchUser('');
        setClientList([]);
        setSelectedClient(null);
        setSelectedClientError('');
        setLoading(false);
        setGroupLoading(false);
        setGroupClientError('');
        setGroupClient(null);
        setTaskListLoading(false);
        setTaskList([]);
        setTaskListError('');
        setDateError('');
        setEmpLoading(false);
        setEmpError('');
        setSubmitLoading(false);
        setSubmitError('');
        setServiceSelectError('');
        setGroupTaskListLoading(false);
        setGroupTaskListError('');
        setExpandedClients({});
        setRows([{ id: 1, serviceCategory: null, serviceType: [] }]);
        setServiceError('');
        setServiceLoading(false);
        setServiceCategoryOptions({});
        setServiceCategoryError({});
        setServiceCategoryLoading({});

        setSelectedClientsTasks({});
        setGroupTaskList([]);
        setBasicInformation((prevInfo) => ({
            ...prevInfo,
            dateOfInvoice: '',
        }));
        setGroupInfo({})

    }

    const handleGenReceiptConfirmModal = () => {
        setIsGenReceiptModalOpen(true);
    }

    const handleCloseGenReceiptModal = () => {
        setIsGenReceiptModalOpen(false); // Close the modal
    };

    const handleGenerateReceipt = () => {
        const prepReceiptData = {
            searchInvoice: rowData?.invoice_id_gen || '',
            advancePayment: 0,
            clientId: 0
        }
        navigate('/generate-edit-receipt', { state: { pageType: 'editinvoice', clientInvoiceInfo: null, prepReceiptData: prepReceiptData } });
    }

    const handleUpdateInvoice = async () => { 
        try {
            setSubmitError('');
            setGroupClientError('');
            setSelectedClientError('');
            setTaskListError('');
            setServiceSelectError('');
            setDateError('');
            setFiledErrors({
                invoiceType: "",
                systemInvoicingAmount: "",
                invoiceAmount: "",
                noTaxInvoices: "",
                followUpDate: "",
            })

            //  Extract all Clents task IDs into a single list
            const allTaskIds = Object.values(selectedClientsTasks).flat();

            const servicesListSelected = rows
                .filter(item => item.serviceCategory?.value) // Filter out items without serviceCategory value
                .map(item => ({
                    service_id: item.serviceCategory.value, // Use serviceCategory value as service_id
                    service_sub_list: item.serviceType ? item.serviceType.map(type => type.value) : [] // Map serviceType values
                }));

            let hasError = false;

            if (isShowGroupWise === 'Group') {
                if (!groupClient?.value) {
                    setGroupClientError('Select group name.'); hasError = true
                }
                if (!basicInformation?.dateOfInvoice) {
                    setDateError('Please select date'); hasError = true
                }
                if (invoiceWithoutTask) {
                    if (allTaskIds.length <= 0) {
                        setTaskListError('Please select at least one task.'); hasError = true
                    }

                } else {
                    if (servicesListSelected.length <= 0) {
                        setServiceSelectError('Please select requested services'); hasError = true
                    }
                    // Check if any serviceType is empty
                    const hasEmptyServiceType = servicesListSelected.some(
                        (service) => service.service_sub_list.length === 0
                    );
                    console.log('hasEmptyServiceType:', hasEmptyServiceType)

                    if (hasEmptyServiceType) {
                        setServiceSelectError('Please select at least one service category for service');
                        return
                    }
                }
            } else {
                if (!selectedClient?.client_id) {
                    setSelectedClientError('Enter client name, search, and select.'); hasError = true
                }
                if (!basicInformation?.dateOfInvoice) {
                    setDateError('Please select date'); hasError = true
                }
                if (invoiceWithoutTask) {
                    if (selectedTaskIds.length <= 0) {
                        setTaskListError('Please select at least one task.'); hasError = true
                    }

                } else {
                    if (servicesListSelected.length <= 0) {
                        setServiceSelectError('Please select requested services'); hasError = true
                    }
                    // Check if any serviceType is empty
                    const hasEmptyServiceType = servicesListSelected.some(
                        (service) => service.service_sub_list.length === 0
                    );
                    console.log('hasEmptyServiceType:', hasEmptyServiceType)

                    if (hasEmptyServiceType) {
                        setServiceSelectError('Please select at least one service category for service');
                        return
                    }
                }
            }
            if (!invoiceDetails?.invoiceType?.value) {
                setFiledErrors((prev) => ({
                    ...prev,
                    invoiceType: "Invoice type is required.",
                })); hasError = true
            }

            if (!invoiceDetails?.advancePayments) {
                setFiledErrors((prev) => ({
                    ...prev,
                    advancePayments: "Advance payments is required.",
                })); hasError = true
            }

            if (!invoiceDetails?.systemInvoicingAmount) {
                setFiledErrors((prev) => ({
                    ...prev,
                    systemInvoicingAmount: "System invoicing amount is required.",
                })); hasError = true
            }

            if (!invoiceDetails?.invoiceAmount) {
                setFiledErrors((prev) => ({
                    ...prev,
                    invoiceAmount: "Invoice amount is required.",
                })); hasError = true
            }

            if (!invoiceDetails?.followUpDate) {
                setFiledErrors((prev) => ({
                    ...prev,
                    followUpDate: "Follow-up date is required.",
                })); hasError = true
            }

            if (hasError) {
                return
            }

            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad =
            {
                "emp_id_auth": storedUserInfo?.emp_id,
                "client_id": selectedClient?.client_id || 0,
                "invoice_date": basicInformation?.dateOfInvoice,
                "follow_up_date": invoiceDetails?.followUpDate || '',
                "group_id": groupClient?.value || 0,
                "invoice_gen_emp_id": invoiceDetails?.generatedBy?.value || '',
                "is_group_invoice": invoiceDetails?.generateSeperateInvoices?.value || 0,
                "invoice_for": isShowGroupWise === 'Group' ? 2 : 1,
                "invoice_type": invoiceWithoutTask ? 2 : 1,
                "system_invoice_amount": invoiceDetails?.systemInvoicingAmount || 0,
                "task_bill_amount": invoiceDetails?.invoiceAmount || 0,
                "send_update_client": sendUpdateToClient ? 1 : 0,
                "send_update_team": sendUpdateToTeam ? 1 : 0,
                "is_tax_invoice": invoiceDetails?.noTaxInvoices?.value || 0,
                "is_gen_seperate_invoice": invoiceDetails?.generateSeperateInvoices?.value || 0,
                "advance_amount": invoiceDetails?.advancePayments || 0,
                "invoice_mode": invoiceDetails?.invoiceType?.value || 0,
                "tasks_list_selected": !invoiceWithoutTask ? [] : isShowGroupWise === 'Group' ? allTaskIds : selectedTaskIds,
                "services_list_selected": !invoiceWithoutTask ? servicesListSelected : [],
                "estimate_id":preSelectedInvoiceData?.estimate_id || invoiceDetails?.estimateNumber || 0

            }

            console.log('update payload:--', payLoad);
            setSubmitLoading(true)
            // Make the API call service_id=10006&
            const response = await postData(ORG_UPDATE_INVOICE, payLoad);
            const responseData = await response.json();
            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setSubmitSuccess(responseData?.info || '');
                        setTimeout(() => {
                            setSubmitSuccess('');
                            navigate('/bills-receipts', { state: { selectedTab: 1 } });
                        }, 3000);
                    } else {
                        setSubmitError(responseData?.info || 'Failed to create estimate. Please try again.');
                    }
                } else {
                    setSubmitError(responseData?.info || 'Failed to create estimate.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setSubmitError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setSubmitError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setSubmitError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setSubmitError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            setSubmitError('No response received from the server. Please check your network connection and try again.');
        } finally {
            setSubmitLoading(false)
        }
    }

    const handleToSetPreSelectedData = async (preData) => {
        console.log('resInvoiceInfo:--', preData);
        try {
            setIsShowGroupWise(preData?.estimate_for || '');
            setInvoiceWithoutTask(preData?.estimate_type);

            setSearchUser(preData?.clientSelectedInfo?.client_name);
            setSelectedClient(preData?.clientSelectedInfo);
            setSendUpdateToClient(preData?.send_update_client);
            setSendUpdateToTeam(preData?.send_update_team);

            setInvoiceDetails((prev) => ({
                ...prev,
                invoiceAmount: preData?.estimateAmount || 0,
                systemInvoicingAmount: preData?.systemBillingAmount || 0,
                generateSeperateInvoices: preData?.groupEstimate || null,
                generatedBy: preData?.generatedBy || null,
                estimateNumber: preData?.estimate_id || 0
            }));

            // setBasicInformation((prev) => ({
            //     ...prev,
            //     dateOfInvoice: preData?.invoice_date || '',
            // }))


            if (preData?.estimate_for === 'Client') {
                if (!preData?.estimate_type) {
                    await callSubServiceApis(preData?.services_list_selected)
                    setRows(preData?.services_list_selected || []);
                } else {
                    setTaskList(preData?.taskList || []);
                    setSelectedTaskIds(preData?.tasks_list_selected || [])
                }
            } else {
                setGroupClient(preData?.groupClient || null);
                setGroupInfo(preData?.groupInfo || null)
                if (!preData?.estimate_type) {
                    await callSubServiceApis(preData?.services_list_selected)
                    setRows(preData?.services_list_selected || []);
                } else {
                    setGroupTaskList(preData?.groupTaskList || []);
                    setSelectedClientsTasks(preData?.tasks_list_selected || {})
                }
            }
        } catch (error) {
            console.log('error predata:-', error)
        }
    }

    const callSubServiceApis = (data) => {
        // console.log('data sub:-', data)
        return data?.map((item, index) => {
            let serviceId = { value: item?.serviceCategory?.value }
            fetchServiceCategoryList(serviceId, item?.id);

        });
    };

    return {
        basicInformation, rowData,
        setBasicInformation,
        handleBasicInformationChange,
        invoiceTableData,
        invoiceDetails,
        setInvoiceDetails,
        handleInvoiceDetailsChange,
        sendUpdateToClient,
        setSendUpdateToClient, handleBack,
        sendUpdateToTeam, getStatusStyle, selectAll, checkedStates,
        setSendUpdateToTeam, handleCheckboxChange, handleSelectAll,
        handleShowIsClient, isShowGroupWise,
        handleSelectClient,
        handleSearchInputText,
        handleSearchClient,
        searchUser,
        selectedClientError,
        clientList,
        loading,
        groupClientOptionsList,
        groupLoading,
        groupClientError, selectedClient,
        groupClient, showGroupInvoiceList, handleSetGroupClient,
        groupTaskListLoading, groupTaskList, groupTaskListError, dateError,
        taskListLoading, taskList, taskListError, selectedClientsTasks,
        handleClientSelectAll, handleTaskCheckboxChange, toggleClient, expandedClients,
        employeeList, empLoading, empError, invoiceWithoutTask,
        handleSelectServiceOrTask, removeServiceRow, addServiceRow, rows, setRows,
        fetchServiceCategoryList, serviceCategoryOptions, serviceOptions,
        serviceCategoryError, serviceCategoryLoading, serviceError, serviceLoading,
        serviceSelectError, submitLoading, submitError, submitSuccess, handleSubmitInvoice, filedErrors,
        handleGenerateReceipt, invoiceLoading, invoiceError, groupInfo, handleUpdateInvoice,
        isGenReceiptModalOpen, handleCloseGenReceiptModal, handleGenReceiptConfirmModal
    }
}

export default GenerateInvoiceController