import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../../Components/Tabs';
import ServiceEstimateScreen from '../ServiceEstimateScreen';
import InvoiceBillScreen from '../InvoiceBillScreen';
import InvoiceReceiptScreen from '../InvoiceReceiptScreen';
import InvoiceSettingsController from '../../../../Controllers/AdminScreens/Invoices/Settings/InvoiceSettingsController';
import InvoiceSettingsConfigurationsScreen from './InvoiceSettingsConfigurationsScreen';
import InvoiceReceiptNotesScreen from './InvoiceReceiptNotesScreen';
import InvoiceOrganizationScreen from './InvoiceOrganizationScreen';
import InvoiceReceiptBooksScreen from './InvoiceReceiptBooksScreen';
import InvoiceTaxationScreen from './InvoiceTaxationScreen';
import InvoiceOtherSettingsScreen from './InvoiceOtherSettingsScreen';
import InvoiceServiceEstimateSeriesScreen from './InvoiceServiceEstimateSeriesScreen';
const InvoiceSettingsScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);
    const [selectedSubTab, setSelectedSubTab] = useState(state?.selectedTab || 0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    const handleSubTabChange = (index) => {
        setSelectedSubTab(index);
    };

    // const {

    // } = InvoiceSettingsController()

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{ paddingBottom: 10 }}>
                <h2 className='title'>Invoice Settings</h2>
            </div>

            <div >
                {/* <Tabs tabs={['Configuration','Receipt Narration & Notes','Organization','Receipt Books','Taxation','Auto Invoice']} */}
                <Tabs tabs={['Configuration', 'Receipt Narration & Notes', 'Taxation', 'Auto Invoice', 'Other Setting']}
                    selectedIndex={selectedTab}
                    onTabChange={handleTabChange}>
                    <InvoiceSettingsConfigurationsScreen />
                    <InvoiceReceiptNotesScreen />
                    {/* <InvoiceOrganizationScreen /> */}
                    <InvoiceTaxationScreen />
                    <InvoiceReceiptBooksScreen />
                    <InvoiceOtherSettingsScreen />
                </Tabs>
            </div>

            {selectedTab === 0 ? (
                <div >
                    <Tabs tabs={['Service Estimate Series', 'Invoice Series', 'Receipt Series']}
                        selectedIndex={selectedSubTab}
                        onTabChange={handleSubTabChange}>
                        <InvoiceServiceEstimateSeriesScreen />
                        <InvoiceServiceEstimateSeriesScreen />
                        <InvoiceServiceEstimateSeriesScreen />
                    </Tabs>
                </div>
            ) : (
                null
            )
            }

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default InvoiceSettingsScreen;