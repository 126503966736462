import React, { useRef, useEffect, useState } from 'react';
import InvoiceSettingsConfigurationsController from '../../../../Controllers/AdminScreens/Invoices/Settings/InvoiceSettingsConfigurationsController';
import Dropdown from '../../../../../Components/Dropdown';
import DateInput from '../../../../../Components/DateInput';
import Input from '../../../../../Components/Input';

const InvoiceSettingsConfigurationsScreen = () => {
    const {
        serviceEstimateSeries,
        setServiceEstimateSeries,
        invoiceSeries,
        setInvoiceSeries,
        receiptSeriesType,
        setReceiptSeriesType,
        receiptPrefix,
        setReceiptPrefix,
        startDates,
        setStartDates,
        endDates,
        setEndDates
    } = InvoiceSettingsConfigurationsController();

    const dropdownRefs = useRef({});
    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown

    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Configuration</h2>
            <div style={styles.form}>
                <div>
                    {/* Service Estimate Series */}
                    <div style={styles.row}>
                        <label style={styles.label}>Service Estimate Series</label>
                        <Dropdown
                            ref={(el) => (dropdownRefs.current["ServiceEstimateSeries"] = el)}
                            isOpen={openDropdown === "ServiceEstimateSeries"}
                            toggleDropdown={() => toggleDropdown("ServiceEstimateSeries")}
                            value={serviceEstimateSeries}
                            onChange={(selectedOption) => setServiceEstimateSeries(selectedOption?.value)}
                            options={[
                                { "label": "Manual", "value": "manual" },
                                { "label": "Automatic", "value": "automatic" }
                            ]}
                            searchBoxWidth={120}
                        />
                        <span></span>
                        <DateInput
                            placeholder="Start Date"
                            value={startDates["Service Estimate"] || ""}
                            onChange={(e) =>
                                setStartDates((prev) => ({ ...prev, ["Service Estimate"]: e.target.value }))
                            }
                        />
                        <DateInput
                            placeholder="End Date"
                            value={endDates["Service Estimate"] || ""}
                            onChange={(e) =>
                                setEndDates((prev) => ({ ...prev, ["Service Estimate"]: e.target.value }))
                            }
                        />
                    </div>

                    {/* Invoice Series */}
                    <div style={styles.row}>
                        <label style={styles.label}>Invoice Series</label>
                        <Dropdown
                            ref={(el) => (dropdownRefs.current["InvoiceSeries"] = el)}
                            isOpen={openDropdown === "InvoiceSeries"}
                            toggleDropdown={() => toggleDropdown("InvoiceSeries")}
                            value={invoiceSeries}
                            onChange={(selectedOption) => setInvoiceSeries(selectedOption?.value)}
                            options={[
                                { "label": "Manual", "value": "manual" },
                                { "label": "Automatic", "value": "automatic" }
                            ]}
                            searchBoxWidth={120}
                        />
                        <span></span>
                        <DateInput
                            placeholder="Start Date"
                            value={startDates["Service Estimate"] || ""}
                            onChange={(e) =>
                                setStartDates((prev) => ({ ...prev, ["Service Estimate"]: e.target.value }))
                            }
                        />
                        <DateInput
                            placeholder="End Date"
                            value={endDates["Service Estimate"] || ""}
                            onChange={(e) =>
                                setEndDates((prev) => ({ ...prev, ["Service Estimate"]: e.target.value }))
                            }
                        />
                    </div>

                    {/* Receipt Series */}
                    <div style={styles.row}>
                        <label style={styles.label}>Receipt Series</label>
                        <Dropdown
                            ref={(el) => (dropdownRefs.current["ReceiptSeries"] = el)}
                            isOpen={openDropdown === "ReceiptSeries"}
                            toggleDropdown={() => toggleDropdown("ReceiptSeries")}
                            value={receiptSeriesType}
                            onChange={(selectedOption) => setReceiptSeriesType(selectedOption?.value)}
                            options={[
                                { "label": "Prefix", "value": "prefix" },
                                { "label": "Suffix", "value": "suffix" }
                            ]}
                            searchBoxWidth={120}
                        />

                        <Input
                            type="text"
                            placeholder="Enter"
                            value={receiptPrefix}
                            onChange={(e) => setReceiptPrefix(e.target.value)}
                            style={styles.input}
                            searchBoxWidth={120}
                        />
                        <DateInput
                            placeholder="Start Date"
                            value={startDates["Service Estimate"] || ""}
                            onChange={(e) =>
                                setStartDates((prev) => ({ ...prev, ["Service Estimate"]: e.target.value }))
                            }
                        />
                        <DateInput
                            placeholder="End Date"
                            value={endDates["Service Estimate"] || ""}
                            onChange={(e) =>
                                setEndDates((prev) => ({ ...prev, ["Service Estimate"]: e.target.value }))
                            }
                        />
                        {/* </div> */}
                    </div>
                </div>
            </div>

            <hr className="footer-divider" />

            {/* Buttons */}
            <div className="button-container" style={{ gap: "10px" }}>
                <button className="add-client-button">Update</button>
                <button style={styles.cancelButton}>Cancel</button>
            </div>
        </div>
    );
};

const styles = {
    // container: {
    //   width: "600px",
    //   margin: "0 auto",
    //   fontFamily: "Arial, sans-serif",
    // },
    title: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: '500',
        marginBottom: '10px'
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
    },
    row: {
        display: "grid",
        gridTemplateColumns: "200px 150px 150px 200px 200px",
        gap: "10px",
        alignItems: "center",
    },
    dateRow: {
        display: "flex",
        alignItems: "center",
        gap: "10px"
    },
    label: {
        width: "200px",
        fontSize: 14,
        fontWeight: "400",
    },
    input: {
        flex: 1,
        padding: "8px",
        border: "1px solid #d1d5db",
        borderRadius: "4px",
        fontSize: "14px",
    },
    updateButton: {
        backgroundColor: "#40513B",
        color: "white",
        border: "none",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    },
    cancelButton: {
        backgroundColor: "white",
        color: "#40513B",
        border: "1px solid #858585",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    },
    spacer: {
        width: "140px",
    },
};

export default InvoiceSettingsConfigurationsScreen;
