import { useState, useRef, useEffect } from "react";
import { getData } from "../../../ApiServices/ApiService";
import { ORG_GET_CALENDER_LIST } from "../../../ApiServices/BaseURL";

const ScheduleRemindersController = () => {
  const [filteredSchedules, setFilteredSchedules] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // useEffect(() => {
  //   fetchSchedulesList();
  // }, []);

  const fetchSchedulesList = async (month_id,year_id) => {
    try {
      setFilteredSchedules({});
      setLoading(true);

      const storedUserInfo = JSON.parse(localStorage.getItem("_userInfo_"));

      // Get current month and year
      const currentDate = new Date();
      // const month_id = currentDate.getMonth() + 1; // JavaScript months are 0-based, add 1
      // const year_id = currentDate.getFullYear();

      // API call with current month and year
      const response = await getData(
        `${ORG_GET_CALENDER_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&month_id=${month_id}&year_id=${year_id}`
      );
      const responseData = await response.json();

      if (response?.status === 200 && responseData?.statusCode === 200) {
        if (responseData?.message.toLowerCase() === "success") {
          if (responseData?.dataJ?.length > 0) {
            const formattedSchedules = formatSchedules(responseData.dataJ);
            setFilteredSchedules(formattedSchedules);
            console.log('formattedSchedules:-------',formattedSchedules)
          } else {
            setError(responseData?.info || "Data Not Found");
          }
        } else {
          setError(responseData?.info || "Failed to fetch schedules data.");
        }
      } else {
        handleErrorResponse(response, responseData);
      }
    } catch (error) {
      handleNetworkError(error);
    } finally {
      setLoading(false);
    }
  };

  const formatSchedules = (data) => {
    const formatted = {};

    data.forEach((item) => {
      const [date, time] = item.sc_time.split(" ");
      const formattedTime = new Date(`2024-01-01T${time}`).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      if (!formatted[date]) {
        formatted[date] = [];
      }

      formatted[date].push({
        time: formattedTime,
        title: item.sc_title,
        sc_desc: item.sc_desc,
        sc_status: item.sc_status,
        sc_location: item.sc_location,
        sc_status_name: item.sc_status_name,
      });
    });

    return formatted;
  };

  const handleErrorResponse = (response, responseData) => {
    if ([400, 401, 403].includes(response?.status)) {
      setError(
        responseData?.info ||
          "Unauthorized access. Your session may have expired. Please log in again."
      );
    } else if (response?.status === 500) {
      setError(responseData?.info || "Internal Server Error. Please try again later.");
    } else {
      setError("Unexpected error occurred. Please try again later.");
    }
  };

  const handleNetworkError = (error) => {
    if (error.response) {
      setError(
        `Error: ${error.response.status}. ${
          error.response.data?.message || "Failed to fetch data. Please try again."
        }`
      );
    } else if (error.request) {
      setError(
        "No response received from the server. Please check your network connection and try again."
      );
    } else {
      setError("Failed to fetch data. Please check your network connection and try again.");
    }
  };

  return {
    filteredSchedules,
    loading,
    error,
    fetchSchedulesList,
  };
};

export default ScheduleRemindersController;
