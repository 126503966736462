
import React, { useState } from 'react';
import Input from '../../../../../Components/Input';
import uploadIcon from '../../../../../Assets/Icons/upload.png'
import InvoiceTaxationController from '../../../../Controllers/AdminScreens/Invoices/Settings/InvoiceTaxationController';


const InvoiceTaxationScreen = () => {
    const { cgst, setCgst, sgst, setSgst, igst, setIgst, conveyanceFee, setConveyanceFee } = InvoiceTaxationController();

    const [isEditing, setIsEditing] = useState(false);
    const [tempValues, setTempValues] = useState({ cgst, sgst, igst, conveyanceFee });

    const handleInputChange = (field, value) => {
        setTempValues({ ...tempValues, [field]: value });
    };

    const handleUpdate = () => {
        setCgst(tempValues.cgst);
        setSgst(tempValues.sgst);
        setIgst(tempValues.igst);
        setConveyanceFee(tempValues.conveyanceFee);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setTempValues({ cgst, sgst, igst, conveyanceFee });
        setIsEditing(false);
    };

    return (
        <div style={styles.container}>
            <h2 style={{ ...styles.title, ...(isEditing ? { marginBottom: '10px' } : {}) }}>Taxation</h2>
            {["CGST", "SGST", "IGST", "Conveyance Fee"].map((field, index) => {
                const fieldKey = field.toLowerCase().replace(" ", "");
                return (
                    <div key={index} style={{ ...styles.field, ...(isEditing ? { marginBottom: '0px', marginTop: '0px' } : {}) }}>
                        <label style={styles.label}>{field}</label>
                        {isEditing ? (
                            <Input
                                value={tempValues[fieldKey]}
                                onChange={(e) => handleInputChange(fieldKey, e.target.value)}
                            />
                        ) : (
                            <span style={styles.value}>{tempValues[fieldKey] || "--"}</span>
                        )}
                    </div>
                );
            })}

            <hr className="footer-divider" />

            {isEditing ? (
                <div className="button-container" style={{ gap: "10px" }}>
                    <button className="add-client-button" onClick={handleUpdate}>Update</button>
                    <button style={styles.cancelButton} onClick={handleCancel}>Cancel</button>
                </div>
            ) : (
                <button className="add-client-button" onClick={() => setIsEditing(true)}>Edit</button>
            )}
        </div>
    );

}

const styles = {
    // container: {
    //   width: "600px",
    //   margin: "0 auto",
    //   fontFamily: "Arial, sans-serif",
    // },
    title: {
        color: 'black',
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: '500',
    },
    field: {
        display: "flex",
        alignItems: "center",
        marginBottom: "25px",
        marginTop: "25px",
    },
    label: {
        flex: "0 0 200px", // Ensures all labels are the same width
        color: 'black',
        fontSize: 14,
        fontFamily: 'Roboto',
        fontWeight: '400',
    },
    // input: {
    //   flex: "1",
    //   height: "30px",
    //   padding: "5px 10px",
    //   fontSize: "14px",
    //   border: "1px solid #d1d5db",
    //   borderRadius: "4px",
    // },

    addServiceButton: {
        backgroundColor: "transparent",
        border: "1px #858585 solid",
        borderRadius: "5px",
        padding: "8px 12px",
        color: "#40513B",
        cursor: "pointer",
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: '400',
        borderRadius: '28px',
        // marginBottom:'16px'
        display: "flex", // Use flexbox for alignment
        alignItems: "center", // Vertically align icon and text
        justifyContent: "center",
    },
    icon: {
        width: "12px",
        height: "12px",
        marginRight: "10px",
    },
    cancelButton: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        color: "#40513B",
        border: "1px solid #858585",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    }
};

export default InvoiceTaxationScreen