import React, { useState, useRef, useEffect } from 'react';
import UserAccessController from '../../../Controllers/AdminScreens/Users/UserAccessController';
import Toggle from '../../../../Components/Toggle';
import editIcon from '../../../../Assets/Icons/edit.png';
import ManageUserAccessModal from '../../../../Components/Modals/ManageUserAccessModal';
import Loader from '../../../../Components/Loader';

const UserAccessScreen = ({ userInfo, userAccessPermissionList, userAccessPermissionLoading, userAccessPermissionError, onRefresh }) => {

    const { handleEditUserAccess,
        handleAccessToggleChange,
        isManageAccessModalOpen,
        handleCloseManageAccessModal,
        selUserAccessService,
        getActiveSubPerNames,
        userAccessToggleUpdateLoading, userAccessToggleUpdateError, userAccessToggleUpdateSuccess
    } = UserAccessController(userInfo, onRefresh);

    return (
        <div className='basic-info-container'>
            <h3 className='basic-info-header'>User Access</h3>
            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>
                        <tr>
                            <th style={{ width: '25%' }}>Module</th>
                            <th style={{ width: '20%' }}>Access Level</th>
                            <th style={{ width: '40%' }}>Page Access</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                    </thead>
                    {userAccessPermissionList?.length > 0 && <tbody style={{ backgroundColor: 'white' }}>
                        {userAccessPermissionList?.map((userAccess, index) => {
                            const { names, isActive } = getActiveSubPerNames(userAccess?.per_name_sub_list);
                            return (
                                <tr key={index}
                                    className="tasksScreen-main-task-row"
                                    style={{ borderBottom: "1px solid #dee2e6" }}
                                >
                                    <td style={{ ...styles.tableLink, width: '25%' }}>{userAccess?.per_name}</td>
                                    <td style={{ width: '20%' }}>{userAccess?.accessLevel || '--'}</td>
                                    <td style={{ width: '40%' }}>{names}
                                        {userAccessToggleUpdateSuccess[userAccess?.per_name_code] &&
                                            <div style={{ color: 'green', paddingTop: 10 }} >{userAccessToggleUpdateSuccess[userAccess?.per_name_code]}</div>}
                                        {userAccessToggleUpdateError[userAccess?.per_name_code] &&
                                            <div style={{ color: 'red', paddingTop: 10 }} >{userAccessToggleUpdateError[userAccess?.per_name_code]}</div>}
                                    </td>
                                    <td style={{ display: 'flex', gap: 20 }}>
                                        <button
                                            className="tasksScreen-edit-button"
                                            style={{
                                                border: "none",
                                                background: "transparent",
                                                cursor: userAccessToggleUpdateLoading[userAccess?.per_name_code],
                                                pointerEvents: userAccessToggleUpdateLoading[userAccess?.per_name_code],
                                                opacity: userAccessToggleUpdateLoading[userAccess?.per_name_code] ? 0.3 : 1
                                            }}
                                            onClick={() => handleEditUserAccess(userAccess)}
                                        >
                                            <img
                                                src={editIcon}
                                                alt="Edit"
                                                style={{ width: "16px", height: "16px" }}
                                            />
                                        </button>
                                        {/* <Toggle initialStatus={userAccess?.toggleState === 'true' ? true : false} onToggle={handleToggleChange} size='small' /> */}
                                        <Toggle initialStatus={isActive} onToggle={(status) => handleAccessToggleChange(userAccess, status)} size='small' disabled={userAccessToggleUpdateLoading[userAccess?.per_name_code]} />
                                        {userAccessToggleUpdateLoading[userAccess?.per_name_code] &&
                                            <span style={{ paddingTop: 2 }}><Loader loading={userAccessToggleUpdateLoading[userAccess?.per_name_code]} color={'var(--primary-color)'} size='small' /></span>
                                        }
                                    </td>

                                </tr>
                            );
                        })}

                    </tbody>}

                </table>
                {userAccessPermissionLoading && <Loader loading={userAccessPermissionLoading} color={'var(--primary-color)'} />}
                {userAccessPermissionError && <div className="list-error-msg">{userAccessPermissionError} </div>}
            </div>
            {/* Manage Access Modal */}
            {isManageAccessModalOpen && (
                <ManageUserAccessModal
                    isOpen={isManageAccessModalOpen}
                    onClose={handleCloseManageAccessModal} userInfo={userInfo}
                    selUserAccessService={selUserAccessService} onValueChange={onRefresh}
                />
            )}
        </div>

    )

};

const styles = {
    tableLink: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        textDecoration: 'underline',
        color: '#5A55D2'
    },
}

export default UserAccessScreen;