
import InvoiceAccountsController from "../../../../Controllers/AdminScreens/Invoices/Accounts/InvoiceAccountsController";
import React, { useState, useRef, useEffect} from 'react';
import Dropdown from "../../../../../Components/Dropdown";
import filterIcon from '../../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../../Assets/Icons/back.png'
import useManageGroupsController from '../../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageGroupsController';
import Loader from '../../../../../Components/Loader';
import { useNavigate } from "react-router-dom";

const InvoiceAccountsScreen = () => {
  const navigator = useNavigate();
  const [showFilters, setShowFilters] = useState(false);

  const handleClientNameNavigate = () => {
    navigator('/invoice/client-info')
  }

  const {
    accountsData,
    getStatusStyle,
    setFilterValues,
    filterValues,
    handleFilterChange
  } = InvoiceAccountsController()

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ padding: '20px 40px' }}>
      <div className='basic-info-container ' style={styles.searchFilterDiv} >
        <div style={{ display: 'flex' }}>
          <h3 style={styles.categoryHeader}>Search Clients</h3>
        </div>
        <div style={styles.container}>
          <div style={styles.filtersHeader}>
            <button style={styles.filterButton} onClick={toggleFilters}> <img src={filterIcon} alt="Filter" />  <span>
              {showFilters ? 'Filters' : 'Filters'}
            </span></button>
            <div >
              <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
              <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
            </div>
          </div>
        </div>
        {/* Filters using Flexbox */}
        {showFilters && (
          <div style={styles.filtersContainer}>
            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '20px', rowGap: '30px' }}>
              <Dropdown
                ref={(el) => (dropdownRefs.current["Client Name"] = el)}
                isOpen={openDropdown === "Client Name"}
                toggleDropdown={() => toggleDropdown("Client Name")}
                label="Client Name"
                placeholder="------"
                value={filterValues?.clientName}
                onChange={(value) => handleFilterChange("clientName", value)}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Client Type"] = el)}
                isOpen={openDropdown === "Client Type"}
                toggleDropdown={() => toggleDropdown("Client Type")}
                label="Client Type"
                placeholder="------"
                value={filterValues?.clientType}
                onChange={(value) => handleFilterChange("clientType", value)}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["GSTIN NO"] = el)}
                isOpen={openDropdown === "GSTIN NO"}
                toggleDropdown={() => toggleDropdown("GSTIN NO")}
                label="GSTIN NO"
                placeholder="------"
                value={filterValues?.gstinNo}
                onChange={(value) => handleFilterChange("gstinNo", value)}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Pending Task"] = el)}
                isOpen={openDropdown === "Pending Task"}
                toggleDropdown={() => toggleDropdown("Pending Task")}
                label="Pending Task"
                placeholder="------"
                value={filterValues?.pendingTask}
                onChange={(value) => handleFilterChange("pendingTask", value)}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["City"] = el)}
                isOpen={openDropdown === "City"}
                toggleDropdown={() => toggleDropdown("City")}
                label="City"
                placeholder="------"
                value={filterValues?.city}
                onChange={(value) => handleFilterChange("city", value)}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Assigned To"] = el)}
                isOpen={openDropdown === "Assigned To"}
                toggleDropdown={() => toggleDropdown("Assigned To")}
                label="Assigned To"
                placeholder="------"
                value={filterValues?.assignedTo}
                onChange={(value) => handleFilterChange("assignedTo", value)}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Group Name"] = el)}
                isOpen={openDropdown === "Group Name"}
                toggleDropdown={() => toggleDropdown("Group Name")}
                label="Group Name"
                placeholder="------"
                value={filterValues?.groupName}
                onChange={(value) => handleFilterChange("groupName", value)}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Services"] = el)}
                isOpen={openDropdown === "Services"}
                toggleDropdown={() => toggleDropdown("Services")}
                label="Services"
                placeholder="------"
                value={filterValues?.services}
                onChange={(value) => handleFilterChange("services", value)}
              />
            </div>
            <div className="button-container" style={{ marginBottom: '15px' }}>
              <button className="add-client-button" style={{ marginRight: '8px', paddingLeft: '30px', paddingRight: '30px' }}>Apply</button>
              <button className="cancel-button" style={{ border: '1px #40513B solid', borderRadius: '20px' }} >Cancel</button>
              <button className="cancel-button">Reset</button>
            </div>
          </div>
        )}

      </div>

      <div className='basic-info-card' style={{ border: 'none' }}>
        <div style={styles.tableWrapper}>
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Client Name</th>
                <th style={{ width: "10%" }}>Outstanding Amount</th>
                <th style={{ width: "15%" }}>Client Type</th>
                {/* <th style={{ width: "10%" }}>Created By</th> */}
                <th style={{ width: "10%" }}>GSTIN No.</th>
                <th style={{ width: "20%" }}>Group Name</th>
                <th style={{ width: "13%" }}>Assigned To</th>
                <th style={{ width: "12%" }}>Account Status</th>
                {/* <th style={{ width: "5%" }}></th> */}
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {accountsData?.map((category, index) => (
                <tr key={index}>
                  <td style={{ ...styles.tableLink, cursor: 'pointer' }} onClick={handleClientNameNavigate}>{category?.groupName}</td>
                  <td
                    style={{
                      ...styles.tableValue,
                      color: category?.accountStatus === "Advance Payment" ? "red" : "",
                      textAlign: "right", // Align values to the right under the header
                    }}
                  >
                    {category?.outstandingAmount}
                  </td>
                  <td style={styles.tableValue}>{category?.clientType}</td>
                  <td style={styles.tableValue}>{category?.gstinNo}</td>
                  <td style={styles.tableValue}>{category?.groupName}</td>
                  <td style={styles.tableValue}>{category?.assignedTo}</td>
                  {/* <td style={styles.tableValue}>{category.createdby}</td> */}
                  <td style={styles.tableValue}>
                    {/* Status Label */}
                    <div
                      style={{
                        display: "inline-block", // Makes the div wrap content and have its own width
                        padding: "5px 5px", // Padding for the button-like appearance
                        borderRadius: "15px",
                        ...getStatusStyle(category?.accountStatus), // Dynamic styles based on the status
                        width: "auto", // Width adjusts automatically to content
                      }}
                    >
                      {category?.accountStatus}
                    </div>

                    {/* Action Buttons */}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
          {/* {loading && <Loader loading={loading} color={'var(--primary-color)'} />} */}
          {/* {error && <div className="list-error-msg">{error} </div>} */}
          {/* <AddGroupModal isOpen={isModalOpen} onClose={handleCloseModal} onValueChange={OnAddUpdateSucess}/> */}
        </div>

      </div>
    </div>

  )

};

const styles = {
  searchFilterDiv: {
    padding: '15px 20px',
    marginBottom: '20px',
    borderRadius: '10px',
    //   borderBottomLeftRadius: '10px',
    //   borderLeft: '1px solid #9DC08B'
  },
  categoryHeader: {
    color: '#1F384C',
    fontSize: 28,
    // fontFamily:'Roboto Slab',
    fontWeight: 500,
    paddingBottom: 20
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    // marginBottom:50
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2'
  },
  container: {
    padding: "5px",
    backgroundColor: "#EFF0F4",
    border: "none",
    // borderRadius: "5px",
    border: '1px #EFF0F4 solid'
  },
  filtersHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "15px",
  },
  filterButton: {
    backgroundColor: "white",
    color: "#40513B",
    border: "none",
    borderRadius: "5px",
    padding: "5px 8px",
    cursor: "pointer",
    borderRadius: '20px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    border: '1px #40513B solid'

  },
  filterIcon: {
    width: '10px',
    height: '10px',
    paddingRight: '5px'
  },

  paginationButtons: {
    display: "flex",
    gap: "5px",
  },
  paginationButton: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  filtersContainer: {
    // display: "flex",
    flexWrap: "wrap", // Wrap inputs to the next line if needed
    gap: "20px", // Space between inputs
    marginBottom: "15px",
    // justifyContent: "space-between", // Distribute inputs evenly
    border: '1px #EFF0F4 solid',
    padding: '10px'
  },
  buttonsContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  applyButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  resetButton: {
    backgroundColor: "transparent",
    color: "#555",
    border: "none",
    cursor: "pointer",
  },


}

export default InvoiceAccountsScreen;