import React from 'react';
import floaderIcon from '../Assets/Icons/floader.png'

const NoDataCard = ({ message = "No Data to display", }) => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        padding: '16px',
        // backgroundColor: '#f9fafb', // Light gray background
        // borderRadius: '8px',
        // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    };

    const iconStyle = {
        width: '60px',
        height: '60px'
    };

    const messageStyle = {
        paddingTop: '10px',
        fontFamily: 'Roboto Slab',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '23.74px',
        letterSpacing: '0.01em',
    };

    const subMessageStyle = {
        fontSize: '14px',
        color: '#718096', // Medium gray
        marginTop: '8px',
    };

return (
    <div style={containerStyle}>
        <img src={floaderIcon} alt={''} style={iconStyle} />
        <h2 style={messageStyle}>{message}</h2>
    </div>
);
};

export default NoDataCard;
