import { useState,useEffect,useRef } from 'react';
import { validateEmail } from '../../../Utils/Validation';
import { useTheme } from '../../../GlobalContext/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { getParamsData, getData,postData } from '../../../ApiServices/ApiService';
import { ORG_DELETE_CLIENT_TASK ,ORG_SCHEDULE_PRIORITY_LIST_DASHBOARD, ORG_TODO_SCHEDULE_LIST_DASHBOARD,
  ORG_GET_PENDING_TASK_LIST, ORG_DELETE_TO_DO_ITEM, ORG_UPCOMING_APPOINTMENT_DASHBOARD, ORG_GET_TASKS_OVERVIEW_DASHBOARD,ORG_GET_NOTIFICATIONS_LIST_DASHBOARD} from '../../../ApiServices/BaseURL';
import { getCurrentFormattedDate } from '../../../Utils/Validation';

const useDashboardController = (refreshDashboard, setRefreshDashboard)=>{
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const screenName = 'DashboardScreen';
  const navigate = useNavigate(); 
  const [userInfo, setUserInfo] = useState({});
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [scheduleTypeError, setScheduleTypeError] = useState('');
  const [scheduleTypesLoading, setScheduleTypesLoading] = useState(false);
  const [scheduleTpyeList, setScheduleTypeList] = useState([]);
  const [isToDoModalOpen, setIsToDoModalOpen] = useState(false);
  const [scheduleToDoLoading, setScheduleToDoLoading] = useState(false);
  const [scheduleToDoError, setScheduleToDoError] = useState('');
  const [scheduleToDoList, setScheduleToDoList] = useState([]);
  const [toDoInfoState, setToDoInfoState] = useState('');
  const [pendingTasksList, setPendingTasksList] = useState([]);
  const [pendingTasksResError, setPendingTasksResError] = useState('');
  const [pendingTasksLoading, setPendingTasksLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const taskInfoRef = useRef(null);
    const [deleteTaskLoading, setDeleteTaskLoading] = useState({});
    const [deleteTaskError, setDeleteTaskError] = useState({});
    const [deleteTaskSuccess, setDeleteTaskSuccess] = useState({});

    const [isDeleteToDoModalOpen, setIsDeleteToDoModalOpen] = useState(false)
    const [deleteToDoItemLoading,setDeleteToDoItemLoading] = useState({})
    const [deleteToDoItemError,setDeleteToDoItemError] = useState({})
    const [deleteToDoItemSuccess,setDeleteToDoItemSuccess] = useState({})
    const toDoItemRef = useRef(null)

  const [taskOverview, setTaskOverview] = useState([
    { label: "Completed", key: 'completed', value: "" , trend: 'up' },
    { label: "Pending", key: 'pending', value: "" , trend: 'down' },
    { label: "Priority", key: 'priority', value: "" , trend: 'up' },
    { label: "Not Started", key: 'not_started', value: "" , trend: 'up' }
  ])
  const [taskOverviewError, setTaskOverviewError] = useState('');
  const [taskOverviewLoading, setTaskOverviewLoading] = useState(false);


  useEffect(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    setUserInfo(storedUserInfo);
  }, []);

  useEffect(()=>{
    fetchPriorityList();
    fetchToDoList();
    fetchUpcomingAppointemntsList();
    fetchPendingTaskList();
    fetchTaskOverview();
    fetchNotificationstsList();
},[])

  useEffect(() => {
    if (refreshDashboard) {
      fetchPriorityList();
      fetchToDoList();
      setRefreshDashboard(false);
    }
  }, [refreshDashboard]);
 
  const sampleNotifications = [
      { message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
      { message: "Curabitur accumsan turpis pharetra augue tincidunt." },
      { message: "Pellentesque habitant morbi tristique senectus et netus." },
      { message: "Suspendisse potenti. Sed porttitor lectus nibh." }
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to navigate to the next notification
  const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % notificationList?.length);
  };

  // Function to navigate to the previous notification
  const handlePrevious = () => {
      setCurrentIndex((prevIndex) =>
          (prevIndex - 1 + notificationList?.length) % notificationList?.length
      );
  };
  
  // Function to truncate notification if it exceeds 100 characters
  const getTruncatedNotification = (notification) => {
      return notification?.nb_message.length > 100 ? `${notification?.nb_message.substring(0, 100)}...` : notification?.nb_message;
  };
  const handleSheduleAndReminder=()=>{
      // navigate('/schedule-reminder');return
    setSelectedUpcomingData(null)
    setIsScheduleModalOpen(true);
  }

  const handleCloseScheduleDoModal = () => {
    //console.log("Closing Schedule Modal");
    setIsScheduleModalOpen(false); 
  };
  


  const sampleTaskOverview = [
      { task_status: "Completed", count: 1203, trend: 'up' },
      { task_status: "Pending", count: 21, trend: 'down' },
      { task_status: "Priority", count: '04', trend: 'up' },
      { task_status: "Not Started", count: 34, trend: 'up' }
  ];
  const pendingTasksData = [
      {
          client: "Sierra Ferguson",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      {
          client: "Sierra Ferguson1",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      {
          client: "Sierra Ferguson2",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      {
          client: "Sierra Ferguson3",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      {
          client: "Sierra Ferguson4",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      {
          client: "Sierra Ferguson5",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      {
          client: "Sierra Ferguson6",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      {
          client: "Sierra Ferguson7",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      {
          client: "Sierra Ferguson8",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      {
          client: "Sierra Ferguson9",
          phone: "+91 (99) 436-46-15",
          task: "GST Filling, E-Tax File",
          dueDate: "Nov 25, 2024",
      },
      // Add more sample data as needed
  ];

  const todosData = [
      {
          description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
          dueDate: "Nov 25, 2024",
      },
      {
          description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
          dueDate: "Nov 25, 2024",
      },
      {
          description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
          dueDate: "Nov 25, 2024",
      },
      {
          description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
          dueDate: "Nov 25, 2024",
      },
      {
          description: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet...",
          dueDate: "Nov 25, 2024",
      },
      // Add more sample data as needed
  ];

  const fetchPendingTaskList =async ()=>{
    try {
      setPendingTasksLoading(true);
      setPendingTasksResError('');
        setPendingTasksList([])
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_GET_PENDING_TASK_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                setPendingTasksList(responseData?.dataJ);
              } else {
                setPendingTasksResError(responseData?.info || 'Data Not Found');
              }
            } else {
              setPendingTasksResError(responseData?.info || 'Failed to fetch priority list. Please try again.');
            }
          } else {
            setPendingTasksResError(responseData?.info || 'Failed to fetch  priority list.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setPendingTasksResError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setPendingTasksResError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setPendingTasksResError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setPendingTasksResError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setPendingTasksResError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch  priority list. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setPendingTasksResError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setPendingTasksResError(`Failed to  priority list. Please check your network connection and try again.`);
        }
      } finally {
        setPendingTasksLoading(false);
      }
  }
  const ItemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(pendingTasksList.length / ItemsPerPage);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
  }
  };

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * ItemsPerPage;
  const indexOfFirstItem = indexOfLastItem - ItemsPerPage;

  // Slice the data for the current page
  const currentTasks = pendingTasksList.slice(indexOfFirstItem, indexOfLastItem);




  const quickActions = [
      { label: 'Post Time Sheet' },
      { label: 'Leave/Attendance' },
      { label: 'Add Expense' },
      { label: 'Add Notes' },
      { label: 'Create Task', isActive: true },
  ];
  const appointments = [
      {
          id: 1,
          name_of_person: 'Amitabh Kanth',
          company: 'Nelsa Web LLC',
          date_of_schedule: 'Monday, Oct 14th, 6:30 PM',
          status: 'In Progress',
          statusColor: '#D1FAE5',
          textColor: '#059669'
      },
      {
          id: 2,
          name_of_person: 'Srikanth Reddy Venna',
          company: 'Datascale Pvt. Ltd.',
          date_of_schedule: 'Monday, Oct 14th, 6:30 PM',
          status: 'Start in 1 hour',
          statusColor: '#FEF3C7',
          textColor: '#D97706'
      },
      {
          id: 3,
          name_of_person: 'Srinivas Arutla',
          company: 'Media Channel Ltd.',
          date_of_schedule: 'Monday, Oct 14th, 6:30 PM',
          status: 'Overdue',
          statusColor: '#FECACA',
          textColor: '#DC2626'
      },
      {
          id: 4,
          name_of_person: 'Manish Singh',
          company: 'Corlax Company Ltd.',
          date_of_schedule: 'Monday, Oct 14th, 6:30 PM',
          status: 'Reschedule',
          statusColor: '#E0E7FF',
          textColor: '#4F46E5'
      },
  ];
  const [selectedOption, setSelectedOption] = useState('Follow ups'); // Default selected option
  const options = ['Follow ups', 'Appointments', 'Meeting'];
  const dateOptions = ['Last 7 days', 'Last Month', 'Last 6 Months', 'Last Year'];
  const [clientData, setClientData] = useState({
      clientName: '',
      clientType: '',
      identificationType: '',
      identificationNumber: '',
      groupClient: '',
      mobileNumber: '',
      existingClient: false,
  });

  // Function to handle all values from AddClientModal
  const handleAddClient = (data) => {
      setClientData(data); // Update the state with all data at once
      //console.log('Client Data:', data); // For debugging
  };
  const [selecteTabledOption, setSelectedTableOption] = useState("ToDo's");
  const handleAppointments =(appointment)=>{
      navigate('/schedule-reminder')
  }

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [taskInfo,setTaskInfo] = useState(null)
  const [isAddToDoModalOpen, setIsAddToDoModalOpen] = useState(false);
  const [isAddToDoRadioButtonModalOpen, setIsAddToDoRadioButtonModalOpen] = useState(false);

  const handleAddCommentModal = (taskData) => {
      setIsCommentModalOpen(true); // Open the modal
      setTaskInfo(taskData)
    };

    const commentSuccess = ()=>{
      fetchPendingTaskList();
    }

    const handleEditPendingTask =(taskInfo)=>{
      navigate(`/manage-clients`, { state: { task: 'Tasks', clientId: taskInfo?.client_id ,taskInfo:taskInfo} }); // Pass task in state
    }
  
    const handleCloseCommentModal = () => {
      setIsCommentModalOpen(false); // Close the modal
    };

    const handleAddToDoModal = () => {
        setSelectedDocument(null)
      setIsAddToDoModalOpen(true); // Open the modal
    };
  
    const handleCloseToDoModal = () => {
      //console.log("Closing To-Do Modal");
      setIsAddToDoModalOpen(false); // Close the modal
    };

  const handleAddToDoRadioButtonModal = (toDoInfo)=>{
      setIsAddToDoRadioButtonModalOpen(true);
      setToDoInfoState(toDoInfo)
  };
  const handleCloseToDoRadioButtonModal =()=>{
      setIsAddToDoRadioButtonModalOpen(false);
  };

  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleEditToDo = (ToDo) => {
    setSelectedDocument(ToDo);
    setIsAddToDoModalOpen(true);
  };

  const [selectedUpcomingData, setSelectedUpcomingData] = useState(null)
  const handleEditUpcomingEdit = (ToDo) => {
    setSelectedUpcomingData(ToDo);
    setIsScheduleModalOpen(true);
  };

  const handleCreateTask = () =>{
    navigate('/create-task')
  }

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good Morning';
    if (hour < 16) return 'Good Afternoon';
    return 'Good Evening';
  };

  const fetchTaskOverview = async () => {
    try {
      setTaskOverviewLoading(true);
      setTaskOverviewError('');

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
  
      // Make the API call
      const response = await getData(`${ORG_GET_TASKS_OVERVIEW_DASHBOARD}?emp_id_auth=${storedUserInfo?.emp_id}&date=${getCurrentFormattedDate()}`);
      const responseData = await response.json();
  
      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const overViewReport = taskOverview.map((task) => ({
                ...task,
                value: responseData?.dataJ[0][task.key] ?? "--",
              }));
              setTaskOverview(overViewReport);
            } else {
              setTaskOverviewError(responseData?.info || 'Data Not Found');
            }
          } else {
            setTaskOverviewError(responseData?.info || 'Failed to fetch task overview. Please try again.');
          }
        } else {
          setTaskOverviewError(responseData?.info || 'Failed to fetch task overview.');
        }
      } 
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setTaskOverviewError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setTaskOverviewError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } 
      else if (response?.status === 500) {
        setTaskOverviewError(responseData?.info || 'Internal Server Error. Please try again later.');
      } 
      else {
        setTaskOverviewError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setTaskOverviewError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch task overview. Please try again.'}`);
      } else if (error.request) {
        setTaskOverviewError('No response received from the server. Please check your network connection and try again.');
      } else {
        setTaskOverviewError(`Failed to fetch task overview. Please check your network connection and try again.`);
      }
    } finally {
      setTaskOverviewLoading(false);
    }
  }

  const fetchPriorityList =async ()=>{
    try {
        setScheduleTypesLoading(true);
        setScheduleTypeError('');
        setScheduleTypeList([])
        // setTeamInfo({});
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_SCHEDULE_PRIORITY_LIST_DASHBOARD}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                const fetchedList = responseData?.dataJ
                setScheduleTypeList(fetchedList);
                // selTeamInfo.current = responseData?.dataJ[0];
              } else {
                setScheduleTypeError(responseData?.info || 'Data Not Found');
              }
            } else {
              setScheduleTypeError(responseData?.info || 'Failed to fetch priority list. Please try again.');
            }
          } else {
            setScheduleTypeError(responseData?.info || 'Failed to fetch  priority list.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setScheduleTypeError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setScheduleTypeError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setScheduleTypeError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setScheduleTypeError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setScheduleTypeError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch  priority list. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setScheduleTypeError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setScheduleTypeError(`Failed to  priority list. Please check your network connection and try again.`);
        }
      } finally {
        setScheduleTypesLoading(false);
      }
  }

  const fetchToDoList =async ()=>{
    try {
        setScheduleToDoLoading(true);
        setScheduleToDoError('');
        setScheduleToDoList([])
        // setTeamInfo({});
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_TODO_SCHEDULE_LIST_DASHBOARD}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                const fetchedList = responseData?.dataJ
                setScheduleToDoList(fetchedList);
                // selTeamInfo.current = responseData?.dataJ[0];
              } else {
                setScheduleToDoError(responseData?.info || 'Data Not Found');
              }
            } else {
                setScheduleToDoError(responseData?.info || 'Failed to fetch  todo list. Please try again.');
            }
          } else {
            setScheduleToDoError(responseData?.info || 'Failed to fetch todo list.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setScheduleToDoError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setScheduleToDoError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
            setScheduleToDoError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
            setScheduleToDoError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setScheduleToDoError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch todo list. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setScheduleToDoError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setScheduleToDoError(`Failed to fetch todo list. Please check your network connection and try again.`);
        }
      } finally {
        setScheduleToDoLoading(false);
      }
  }

  const [upcomingLoading, setUpcomingoading] = useState(false);
  const [upcomingError, setUpcomingError] = useState('');
  const [upcomingList, setUpcomingList] = useState([]);

  const fetchUpcomingAppointemntsList =async ()=>{
    try {
      setUpcomingoading(true);
      setUpcomingError('');
      setUpcomingList([])
        // setTeamInfo({});
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_UPCOMING_APPOINTMENT_DASHBOARD}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                const fetchedList = responseData?.dataJ
                setUpcomingList(fetchedList);
                // selTeamInfo.current = responseData?.dataJ[0];
              } else {
                setUpcomingError(responseData?.info || 'Data Not Found');
              }
            } else {
              setUpcomingError(responseData?.info || 'Failed to fetch  todo list. Please try again.');
            }
          } else {
            setUpcomingError(responseData?.info || 'Failed to fetch todo list.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setUpcomingError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setUpcomingError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setUpcomingError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setUpcomingError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setUpcomingError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch todo list. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setUpcomingError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setUpcomingError(`Failed to fetch todo list. Please check your network connection and try again.`);
        }
      } finally {
        setUpcomingoading(false);
      }
  }


  const [currentUpcomingPage, setCurrentUpcomingPage] = useState(1);
  const totalUpcomingPages = Math.ceil(upcomingList?.length / ItemsPerPage);

  // Function to handle page change
  const handleUpcomingPageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalUpcomingPages) {
      setCurrentUpcomingPage(pageNumber);
  }
  };

  // Calculate the index of the first and last items on the current page
  const indexOfUpcomingLastItem = currentUpcomingPage * ItemsPerPage;
  const indexOfUpcomingFirstItem = indexOfUpcomingLastItem - ItemsPerPage;

  // Slice the data for the current page
  const currentUpcomingTasks = upcomingList?.slice(indexOfUpcomingFirstItem, indexOfUpcomingLastItem);

  const getStatusStyle = (status) => {
    switch (status) {
    //   case "2":
    //     return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" };
      case 'New':
        //Active
        return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" };
      case 'Inprogress':
        //Disabled
        return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" };
      default:
        return {};
    }
  };




const [currentPageToDoList, setCurrentPageToDoList] = useState(1);

  const totalPagesToDoList = Math.ceil(scheduleToDoList.length / ItemsPerPage);

  // Function to handle page change
  const handleToDoPageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPagesToDoList) {
      setCurrentPageToDoList(pageNumber);
    }
  };

  // Calculate the index of the first and last items on the current page
  const indexOfLastItemToDo = currentPageToDoList * ItemsPerPage;
  const indexOfFirstItemToDo = indexOfLastItemToDo - ItemsPerPage;

  // Slice the data for the current page
  const currentScheduleToDoList = scheduleToDoList.slice(
    indexOfFirstItemToDo,
    indexOfLastItemToDo
  );

  const onTaskDelete = async (taskInfo) => {
    //console.log('taskInfo:-', taskInfo?.task_id);
    taskInfoRef.current = taskInfo;
    setIsDeleteModalOpen(true);
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
  }

  const callDeleteTask = async (taskInfo) => {
    try {
      setDeleteTaskError({});
      setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: '' }));
      setDeleteTaskLoading((prev) => ({ ...prev, [taskInfo?.task_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "task_id": taskInfo?.task_id
      }

      //console.log('callDeleteTask payload', payLoad);

      // Make the API call
      const response = await postData(ORG_DELETE_CLIENT_TASK, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setDeleteTaskSuccess((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info }));
            setTimeout(() => {
              setDeleteTaskSuccess((prev) => ({ ...prev, [taskInfo?.task_id]: '' }));
              fetchPendingTaskList();
            }, 3000);
          } else {
            setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Failed to delete task. Please try again.' }));
          }
        } else {
          setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Failed to delete task. Please try again...' }));
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
        } else {
          // setError(responseData?.info || 'Bad request. Please check the request parameters.');
          setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      // Unexpected or network-related errors
      else {
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete task. Please try again.'}` }));
      } else if (error.request) {
        // Request was made but no response was received
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        // An error occurred in setting up the request
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: `Failed to delete task. Please check your network connection and try again.` }));
      }
    } finally {
      setDeleteTaskLoading((prev) => ({ ...prev, [taskInfo?.task_id]: false }));
    }
  }

  const onToDoItemDelete = async (todoItem) => {
    //console.log('taskInfo:-', todoItem?.sc_id);
    toDoItemRef.current = todoItem;
    setIsDeleteToDoModalOpen(true);
  }

  const handleCloseToDoDeleteModal = () => {
    setIsDeleteToDoModalOpen(false); // Close the modal
  }

  const callDeleteToDoItem = async (todoItem) => {
    try {
      setDeleteToDoItemError({});
      setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: '' }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "sc_id": todoItem?.sc_id
      }

      //console.log('callDeleteTask payload', payLoad);
      // return

      setDeleteToDoItemLoading((prev) => ({ ...prev, [todoItem?.sc_id]: true }));
      // Make the API call
      const response = await postData(ORG_DELETE_TO_DO_ITEM, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setDeleteToDoItemSuccess((prev) => ({ ...prev, [todoItem?.sc_id]: responseData?.info }));
            setTimeout(() => {
              setDeleteToDoItemSuccess((prev) => ({ ...prev, [todoItem?.sc_id]: '' }));
              fetchToDoList();
              fetchPriorityList();
            }, 3000);
          } else {
            setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: responseData?.info || 'Failed to delete task. Please try again.' }));
          }
        } else {
          setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: responseData?.info || 'Failed to delete task. Please try again...' }));
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
        } else {
          // setError(responseData?.info || 'Bad request. Please check the request parameters.');
          setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      // Unexpected or network-related errors
      else {
        setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete task. Please try again.'}` }));
      } else if (error.request) {
        // Request was made but no response was received
        setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        // An error occurred in setting up the request
        setDeleteToDoItemError((prev) => ({ ...prev, [todoItem?.sc_id]: `Failed to delete task. Please check your network connection and try again.` }));
      }
    } finally {
      setDeleteToDoItemLoading((prev) => ({ ...prev, [todoItem?.sc_id]: false }));
    }
  }

  const[notificationLoading, setNotificationLoading] = useState(false);
  const[notificationError, setNotificationError] = useState('');
  const[notificationList,setNotificationList] = useState([])
  const fetchNotificationstsList =async ()=>{
    try {
      setNotificationLoading(true);
      setNotificationError('');
      setNotificationList([])
        // setTeamInfo({});
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_GET_NOTIFICATIONS_LIST_DASHBOARD}?emp_id_auth=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                const fetchedList = responseData?.dataJ
                setNotificationList(fetchedList);
                // selTeamInfo.current = responseData?.dataJ[0];
              } else {
                setNotificationError(responseData?.info || 'Data Not Found');
              }
            } else {
              setNotificationError(responseData?.info || 'Failed to fetch  todo list. Please try again.');
            }
          } else {
            setNotificationError(responseData?.info || 'Failed to fetch todo list.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setNotificationError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setNotificationError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setNotificationError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setNotificationError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setNotificationError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch todo list. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setNotificationError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setNotificationError(`Failed to fetch todo list. Please check your network connection and try again.`);
        }
      } finally {
        setNotificationLoading(false);
      }
  }

  return {
    loading,
    error,
    success,
    screenName,
    sampleNotifications,
    currentIndex,
    handleNext,
    handlePrevious,
    getTruncatedNotification,
    handleSheduleAndReminder,
    sampleTaskOverview,
    pendingTasksList,
    todosData,
    ItemsPerPage,
    currentPage,
    totalPages,
    handlePageChange,
    currentTasks,
    quickActions,
    appointments,
    selectedOption,
    options,
    dateOptions,
    clientData,
    handleAddClient,
    selecteTabledOption,
    handleAppointments,
    isCommentModalOpen,
    isAddToDoModalOpen,
    isAddToDoRadioButtonModalOpen,
    handleAddCommentModal,
    handleCloseCommentModal,
    handleAddToDoModal,
    handleCloseToDoModal,
    handleAddToDoRadioButtonModal,
    handleCloseToDoRadioButtonModal,
    handleCreateTask,
    setSelectedTableOption,
    setSelectedOption,
    getGreeting,
    userInfo,
    isScheduleModalOpen,
    handleCloseScheduleDoModal,

    scheduleTypeError,
    scheduleTypesLoading,
    scheduleTpyeList,

    scheduleToDoError,
    scheduleToDoList,
    scheduleToDoLoading,
    fetchToDoList,
    fetchPriorityList,
    handleEditToDo,
    selectedDocument,
    upcomingList,
    upcomingError,
    upcomingLoading,
    getStatusStyle,
    selectedUpcomingData,
    handleEditUpcomingEdit,
    handleToDoPageChange,currentPageToDoList,totalPagesToDoList,
    currentScheduleToDoList,
    toDoInfoState,
    pendingTasksResError,
    pendingTasksLoading,taskInfo,commentSuccess,handleEditPendingTask,
    taskInfoRef,onTaskDelete,isDeleteModalOpen,    
    deleteTaskError,deleteTaskLoading,deleteTaskSuccess,
    handleCloseDeleteModal,callDeleteTask,
    taskOverview, taskOverviewLoading, taskOverviewError,
    onToDoItemDelete,handleCloseToDoDeleteModal,callDeleteToDoItem,toDoItemRef,
    deleteToDoItemLoading,deleteToDoItemError,deleteToDoItemSuccess,isDeleteToDoModalOpen,
    fetchUpcomingAppointemntsList,
    notificationList,
    notificationError,
    notificationLoading,

    currentUpcomingTasks,
    indexOfUpcomingFirstItem,
    indexOfUpcomingLastItem,
    handleUpcomingPageChange,
    totalUpcomingPages,
    currentUpcomingPage,

  };
}

export default useDashboardController;