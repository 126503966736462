import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../Components/Tabs';
import ServiceEstimateScreen from './ServiceEstimateScreen';
import InvoiceBillScreen from './InvoiceBillScreen';
import InvoiceReceiptScreen from './InvoiceReceiptScreen';
import BillingReceiptEstimateController from '../../../Controllers/AdminScreens/Invoices/BillingReceiptEstimateController';
const BillingReceiptEstimateScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const {
        serviceEstimateList,
        loading,
        error,  
        serviceEstimateRefresh,
        invoiceList,
        invoiceError,
        invoiceLoading,invoiceRefresh,
        receiptList,
        receiptError, receiptLoading,
        receiptRefresh
    } = BillingReceiptEstimateController();

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{paddingBottom:10}}>
                <h2 className='title'>Invoices</h2>
            </div>
            
            <div >
                <Tabs tabs={['Service Estimates','Invoices','Receipt']}
                selectedIndex={selectedTab}
                onTabChange={handleTabChange}>
                    <ServiceEstimateScreen serviceEstimateList={serviceEstimateList} loading={loading} error={error} onRefresh={serviceEstimateRefresh} />
                    <InvoiceBillScreen invoiceList={invoiceList} loading={invoiceLoading} error={invoiceError} onRefresh={invoiceRefresh}/> 
                    <InvoiceReceiptScreen receiptList={receiptList} loading={receiptLoading} error={receiptError} onRefresh={receiptRefresh}/>
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default BillingReceiptEstimateScreen;