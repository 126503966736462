import { useState, useRef, useEffect } from 'react';
import {ORG_GET_NOTIFICATIONS_LIST_HEADER, ORG_CHANGE_NOTIFICATION_READ_STATUS_HEADER} from '../../../ApiServices/BaseURL';
import { postData,getData } from '../../../ApiServices/ApiService'

const ResourcesListController = () => {
    const [resources, setResources] = useState([]);
    const [filteredResources, setFilteredResources] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const[NotificationsList,setNofificationsList] = useState([]);
  const[notificationError,setNotifcationError] =useState('');
  const[notificationLoading, setNofificationLoading]=useState(false)

  const handleNotificationsList = async () => {
    try {
      setNotifcationError('');
      setNofificationLoading(true);
      setNofificationsList([]);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      //console.log('handleSearchClient header payload', payLoad);
      const response = await getData(`${ORG_GET_NOTIFICATIONS_LIST_HEADER}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();
      //console.log('response Data', responseData);
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setNofificationsList(responseData?.dataJ || []);
          } else {
            setNotifcationError(responseData?.info || 'Failed to fetch Client. Please try again.');
          }
        } else {
          setNotifcationError(responseData?.info || 'Failed to fetch Client.');
        }
      } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setNotifcationError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setNotifcationError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else {
        setNotifcationError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      setNotifcationError(`Failed to fetch client. Please check your network connection and try again.`);
    } finally {
      setNofificationLoading(false);
    }
  };

  const[notificationReadSuccess, setNotificationReadSuccess] = useState({});
    const[notificationReadError, setNotificationReadError] = useState({});
    const[notificationReadLoading, setNotificationReadLoading] = useState({});
  
    const handleNotificationRead = async (nftId) => {
       
            try {
              setNotificationReadSuccess((prev) => ({ ...prev, [nftId]: '' }));
              setNotificationReadError((prev) => ({ ...prev, [nftId]: '' }))
                // if (!searchUser) {
                //   setError('Please enter valid text'); return;
                // }         
                setNotificationReadLoading((prev) => ({ ...prev, [nftId]: true }));
                const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
                const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, ntfn_id:nftId, is_read:1}
                console.log("notification read payLoad", payLoad)
                // return
                // Make the API call
                const response = await postData(ORG_CHANGE_NOTIFICATION_READ_STATUS_HEADER,payLoad);
                const responseData = await response.json();
                //console.log('response Data',responseData);
            
                // Check for success (200) and internal status code
                if (response?.status === 200) {
                  if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                      setNotificationReadError((prev) => ({ ...prev, [nftId]: '' }))
                      setNotificationReadSuccess((prev) => ({ ...prev, [nftId]: responseData?.info }));
                        // setUnreadCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
                        setTimeout(()=>{
                          setNotificationReadSuccess((prev) => ({ ...prev, [nftId]: '' }));
                          handleNotificationsList();
                          // onRefresh();
                        },2000);
                        // setEmployeeList(responseData?.dataJ || []);
                    } else {
                      // setNotificationReadError(responseData?.info || 'Failed to delete kyc document. Please try again.');
                      setNotificationReadError((prev) => ({ ...prev, [nftId]: responseData?.info || 'Failed to update notification status.Please try again.' }));
                    }
                  } else {
                    setNotificationReadError((prev) => ({ ...prev, [nftId]: responseData?.info || 'Failed to update notification status' }));
                  }
                } 
                // Token-related errors: 400, 401, 403
                else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                  if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setNotificationReadError((prev) => ({ ...prev, [nftId]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                  } else {
                    setNotificationReadError((prev) => ({ ...prev, [nftId]: responseData?.info || 'Bad request. Please check the request parameters.' }));
                  }
                } 
                // Internal Server Error: 500
                else if (response?.status === 500) {
                  setNotificationReadError((prev) => ({ ...prev, [nftId]: responseData?.info || 'Internal Server Error. Please try again later.' }));
                } 
                // Unexpected or network-related errors
                else {
                  setNotificationReadError((prev) => ({ ...prev, [nftId]: 'Unexpected error occurred. Please try again later.' }));
                }
              } catch (error) {
                // Handle various error scenarios
                if (error.response) {
                  // Server responded with a status outside the 2xx range
                  const errorMessage = error.response
                  ? `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update notification status. Please try again.'}`
                  : 'Unexpected error occurred. Please try again later.';
  
                  setNotificationReadError((prev) => ({ ...prev, [nftId]: errorMessage }));
                } else if (error.request) {
                  // Request was made but no response was received
                  setNotificationReadError((prev) => ({ ...prev, [nftId]: 'No response received from the server. Please check your network connection and try again.' }));
                } else {
                  // An error occurred in setting up the request
                  setNotificationReadError((prev) => ({ ...prev, [nftId]: 'Failed to update notification status. Please check your network connection and try again.' }));
                }
              } finally {
                setNotificationReadLoading((prev) => ({ ...prev, [nftId]: false }));
              }
        
            // onValueChange(teamData);
            // onClose();
        };

    useEffect(() => {
        // Sample data to match the UI
        const data = Array(10).fill({
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            description: "Phasellus ut felis metus. Pellentesque quis metus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.",
        });
        setResources(data);
        setFilteredResources(data);
        handleNotificationsList();
    }, []);

    useEffect(() => {
        const filtered = resources.filter((resource) =>
            resource.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredResources(filtered);
        setCurrentPage(1); // Reset to first page on search
    }, [searchTerm, resources]);

    const totalPages = Math.ceil(NotificationsList.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentResources = NotificationsList.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
          setCurrentPage(pageNumber);
      }
      };

    return {
        currentResources,
        currentPage,
        totalPages,
        handlePageChange,
        setSearchTerm,
        notificationLoading,
        notificationError,
        NotificationsList,
        handleNotificationRead,
        notificationReadLoading,
        notificationReadError,
        notificationReadSuccess
    };
};

export default ResourcesListController;
