import React, { useState } from "react";

const InvoiceReceiptNotesController = () => {
    const [narration, setNarration] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut felis metus.");
    const [notes, setNotes] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut felis metus.");

    return {
        narration,
        setNarration,
        notes,
        setNotes
    };
}

export default InvoiceReceiptNotesController;
