
import React, { useState, useEffect } from "react";
import {ORG_DSC_CERTIFICATE_DELETE,ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER,ORG_DSC_CERTIFICATES_FLITER_LIST } from '../../../../../ApiServices/BaseURL';
import { getData, postData } from "../../../../../ApiServices/ApiService";

const DSCActiveCertificateController = (activeCertificatesList, activeCertificatesError, activeCertificatesLoading, onRefresh,  setActiveCertificatesList,setActiveCertificatesLoading,setActiveCertificatesError,showFilter) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const itemsPerPage = 10;
    const [status, setStatus] = useState("");
    const [validFrom, setValidFrom] = useState("");
    const [addedOn, setAddedOn] = useState("");

    const totalPages = Math.ceil(activeCertificatesList?.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentDSCActiveCertificates = activeCertificatesList?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleDelete = (activeCertificate, index) => {
        console.log("active Certificate",activeCertificate )
        setSelectedDocument({ activeCertificate, index });
        setIsDeleteModalOpen(true);
      };
    
      const handleOpenDeleteModal = () => {
        setIsDeleteModalOpen(true); // Open the modal
      };
    
      const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false); // Close the modal
      };

      const [loading,setLoading] = useState(false);
  const [activeCertificateDeleteError, setActiveCertificateDeleteError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentActiveCertificateDeleteIndex, setCurrentActiveCertificateDeleteIndex] = useState(null);
  const [deletedMessages, setDeletedMessages] = useState([]);

  const handleDSCActiveCertificateDelete = async () => {
   
    try {
      const { activeCertificate, index } = selectedDocument;
      setCurrentActiveCertificateDeleteIndex(null)
      setActiveCertificateDeleteError('');  
        setSuccess('')      
        setLoading(true);
        setCurrentActiveCertificateDeleteIndex(index)
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, dsc_id:activeCertificate.dsc_id}
    
        //console.log('handleSearchEmployee payload',payLoad);
        // Make the API call
        const response = await postData(ORG_DSC_CERTIFICATE_DELETE,payLoad);
        const responseData = await response.json();
        //console.log('response Data',responseData);
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
                setActiveCertificateDeleteError('');
              setDeletedMessages((prevDeleted) => [...prevDeleted, activeCertificate.dsc_id]);
                setSuccess(responseData?.info);
                setTimeout(()=>{
                  setSuccess('');
                  onRefresh();
                },3000);
                // setEmployeeList(responseData?.dataJ || []);
            } else {
                setActiveCertificateDeleteError(responseData?.info || 'Failed to delete notice board message. Please try again.');
            }
          } else {
            setActiveCertificateDeleteError(responseData?.info || 'Failed to delete notice board message. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setActiveCertificateDeleteError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setActiveCertificateDeleteError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
            setActiveCertificateDeleteError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
            setActiveCertificateDeleteError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setActiveCertificateDeleteError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete notice board message. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setActiveCertificateDeleteError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setActiveCertificateDeleteError(`Failed to delete notice board message. Please check your network connection and try again.`);
        }
      } finally {
        setLoading(false);
        // setCurrentNoticeDeleteIndex(null)
      }

    // onValueChange(teamData);
    // onClose();
};

const [searchUser, setSearchUser] = useState('');
const [error, setError] = useState('');
const [employeeList, setEmployeeList] = useState([]);
const [clientSearchError, setClientSearchError] = useState('');
const [employeesListLoading, setEmployeeListLoading] = useState(false)
    const handleSearchEmployee = async () => {

        try {
            setError('');
            setClientSearchError('');
            setDscFliterError('')
            if (!searchUser) {
                setClientSearchError('Please enter valid text'); return;
            }
            setEmployeeListLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchUser }

            //console.log('handleSearchEmployee payload', payLoad);
            // Make the API call
            const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
            const responseData = await response.json();
            //console.log('response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        // setAddEmployeeError('')
                        setEmployeeList(responseData?.dataJ || []);
                        //   setError(responseData?.info);
                        setTimeout(() => {
                            // handleClose();
                            // onValueChange();
                        }, 2000);
                    } else {
                        setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setEmployeeListLoading(false);
        }

        // onValueChange(teamData);
        // onClose();
    };

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const handleSearchInputChange = (value) => {
        setSearchUser(value);
        if (!value) {
            setSelectedEmployee(null)
        }
    }
    const handleSelectedEmployee = (employee) => {
        setSelectedEmployee(employee);
        setSearchUser(employee?.client_name);

        setEmployeeList([]); // Clear the dropdown
    };

    const[dscFilterError, setDscFliterError] = useState('')
    const fetchDSCActiveCertificatesFilterList = async () => {
            try {
              if(!selectedEmployee?.client_id && !status && !validFrom && !addedOn){
                setDscFliterError('Please fill at least one field to search');
                return
              }
              setDscFliterError('');
                setActiveCertificatesLoading(true);
                setActiveCertificatesError('')
                setActiveCertificatesList([])
                setClientSearchError('');
                setCurrentPage(1);
                // setTeamInfo({});
                const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
                const payLoad = {
                  emp_id_auth: storedUserInfo?.emp_id,
                  client_id: selectedEmployee?.client_id,
                  client_name: searchUser || '',
                  dsc_status:status || 0,
                  valid_from: validFrom || '',
                  create_at:addedOn ||'',
                  dsc_type:'active'
                }
                console.log("payLoad in DSC Filter", payLoad)
                // return
                // Make the API call
                const response = await postData(ORG_DSC_CERTIFICATES_FLITER_LIST, payLoad);
                const responseData = await response.json();
    
                // Check for success (200) and internal status code
                if (response?.status === 200) {
                    if (responseData?.statusCode === 200) {
                        if (responseData?.message.toLowerCase() === 'success') {
                            if (responseData?.dataJ?.length > 0) {
                                setActiveCertificatesList(responseData?.dataJ);
                            } else {
                                setActiveCertificatesError(responseData?.info || 'Data Not Found');
                            }
                        } else {
                            setActiveCertificatesError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                        }
                    } else {
                        setActiveCertificatesError(responseData?.info || 'Failed to fetch Team Information.');
                    }
                }
                // Token-related errors: 400, 401, 403
                else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                    if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                        setActiveCertificatesError('Unauthorized access. Your session may have expired. Please log in again.');
                        // Optionally, redirect to the login page or refresh the token
                        // Example: redirectToLogin();
                    } else {
                        setActiveCertificatesError(responseData?.info || 'Bad request. Please check the request parameters.');
                    }
                }
                // Internal Server Error: 500
                else if (response?.status === 500) {
                    setActiveCertificatesError(responseData?.info || 'Internal Server Error. Please try again later.');
                }
                // Unexpected or network-related errors
                else {
                    setActiveCertificatesError('Unexpected error occurred. Please try again later.');
                }
            } catch (error) {
                // Handle various error scenarios
                if (error.response) {
                    // Server responded with a status outside the 2xx range
                    setActiveCertificatesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
                } else if (error.request) {
                    // Request was made but no response was received
                    setActiveCertificatesError('No response received from the server. Please check your network connection and try again.');
                } else {
                    // An error occurred in setting up the request
                    setActiveCertificatesError(`Failed to fetch Team Information. Please check your network connection and try again.`);
                }
            } finally {
                setActiveCertificatesLoading(false);
                showFilter()
            }
        }

        const resetFilter = ()=>{
          setValidFrom('');
          setAddedOn('');
          setSearchUser('');
          setSelectedEmployee(null);
          setStatus('')
          setDscFliterError('');
          setClientSearchError('');
          setError('')
        }

    return {
        handlePageChange,
        currentDSCActiveCertificates,
        currentPage,
        totalPages,

        handleCloseDeleteModal,
        handleOpenDeleteModal,
        handleDelete,
        isDeleteModalOpen,
        selectedDocument,
        setSelectedDocument,

        deletedMessages,
        currentActiveCertificateDeleteIndex,
        success,
        activeCertificateDeleteError,
        loading,
        handleDSCActiveCertificateDelete,

        clientSearchError,
        employeeList,
        error,
        employeesListLoading,
        handleSelectedEmployee,
        handleSearchInputChange,
        searchUser,
        handleSearchEmployee,
        setAddedOn,
        addedOn,
        setValidFrom,
        validFrom,
        setStatus,
        status,
        fetchDSCActiveCertificatesFilterList,
        dscFilterError,
        resetFilter
    }

}

export default DSCActiveCertificateController