import React, { useState, forwardRef } from "react";
import dropDownIcon from "../Assets/Icons/chevron-down.png";
import error from "../Assets/Icons/error_message.png"
import Loader from "./Loader";

const Dropdown = forwardRef(({
  label,
  options = [],
  width = 200,
  searchBoxWidth = null,
  value,
  onChange,
  isMulti = false,
  style = {},
  loading,
  errorMessage,
  isOpen, // Added prop to determine if the dropdown is open
  toggleDropdown, // Added prop to toggle dropdown
  sideLabel = '',
  labelClick,
  disabled = false,
}, ref) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    //console.log('options:-', option)
    if (disabled) return;
    if (isMulti) {
      const updatedValue = Array.isArray(value)
        ? value.some((v) => v.value === option.value)
          ? value.filter((item) => item.value !== option.value)
          : [...value, option]
        : [option];
      onChange(updatedValue);
    } else {
      onChange(option);
      // setIsOpen(false);
      toggleDropdown();
    }
  };

  // const getDisplayValue = () => {
  //   if (isMulti) {
  //     return Array.isArray(value) && value?.length > 0
  //       ? value?.map((item) => item?.label || "").join(", ")
  //       : "Select options";
  //   }
  //   return value?.label || (typeof value === "string" ? value : "Select")|| "Select";
  // };
  const handleLabelClick = () =>{
    if (!disabled && labelClick) labelClick();
    // labelClick()
  }

  const getDisplayValue = () => {
    // if (isMulti) {
    //   return Array.isArray(value) && value?.length > 0
    //     ? value?.map((item) => item?.label || "").join(", ")
    //     : "Select options";
    // }
    if (isMulti) {
      if (Array.isArray(value) && value.length > 0) {
          if (value.length > 2) {
              return `${value[0]?.label}, ${value[1]?.label} ... +${value.length - 2} more`;
          }
          return value.map((item) => item?.label || "").join(", ");
      }
      return "Select options";
  }
    // Check if the provided `value` exists in the `options`
    //console.log('selected value:----', value)
    const selectedOption = options.find((option) => option?.value === (value?.value || value));
    return selectedOption?.label || value?.label || (typeof value === "string" ? value : "Select") || "Select";
  };

  return (
    <div style={styles.container} ref={ref}>
      <div style={styles.labelDiv}>
      <label style={styles.label}>{label}</label>
      {sideLabel && <label onClick={handleLabelClick} style={styles.sideLabel}>{sideLabel}</label>}
      </div>
      {loading ? (
        <div
          style={{
            ...styles.dropdown,
            width: searchBoxWidth,
            ...style,
            borderColor: errorMessage ? "red" : "#D6E4EC",
            backgroundColor: disabled ? "var(--read-input-box-bg-color)" : "#fff",
            cursor: disabled ? "not-allowed" : "pointer",
          }}
        >
          <span style={styles.selectedOption}>Loading...</span>
          <Loader loading={loading} color="#000" size="small" />
        </div>
      ) : (
        <div
          style={{
            ...styles.dropdown,
            width: searchBoxWidth,
            ...style,
            borderColor: errorMessage ? "red" : "#D6E4EC",
            backgroundColor: disabled ? "var(--read-input-box-bg-color)" : "#fff",
            cursor: disabled ? "not-allowed" : "pointer",
            pointerEvents: disabled ? "none" : "auto",
          }}
          onClick={disabled ? null : toggleDropdown}
        >
          <span style={{ ...styles.selectedOption, color: "#000" }}>{getDisplayValue()}</span>
          <img src={dropDownIcon} alt="Down Arrow" style={{ ...styles.arrow, opacity: disabled ? 0.5 : 1 }} />
        </div>
      )}

      {isOpen && !disabled && options.length > 0 && (
        <div
          style={{
            ...styles.dropdownMenu,
            width: '90%',
            maxHeight: 250,
            overflowY: "auto",
          }}
        >
          {options.map((option, index) => (
            <div
              key={index}
              style={{
                ...styles.option,
                backgroundColor:
                  isMulti &&
                    Array.isArray(value) &&
                    value.some((item) => item.value === option?.value)
                    ? "#d1e7dd"
                    : "#fff",
                borderBottom:
                  index !== options.length - 1 ? "1px solid #d1e3ed" : "none",
              }}
              onClick={() => handleOptionClick(option)}
            >
              {isMulti && (
                <input
                  type="checkbox"
                  checked={
                    Array.isArray(value) &&
                    value.some((item) => item?.value === option?.value)
                  }
                  onChange={() => handleOptionClick(option)}
                  style={styles.checkbox}
                />
              )}
              {option?.label ? option.label : (typeof option === "string" ? option : "Unnamed Option") || "Unnamed Option"}
            </div>
          ))}
        </div>
      )}

      {errorMessage && (
        <div style={styles.errorMessage}>
          <img
            src={error}
            alt="Error"
            style={{ width: "12px", height: "12px" }}
          />
          {errorMessage}
        </div>
      )}
    </div>
  );
});


const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  label: {
    fontSize: "12px",
    marginBottom: "5px",
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#343C44",
  },
  dropdown: {
    // width: "200px",
    height: "19px",
    border: "1px solid #d1e3ed",
    borderRadius: "5px",
    padding: "10px 10px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  selectedOption: {
    color: "#343C44",
  },
  arrow: {
    fontSize: "12px",
    width: 20,
    height: 20,
    marginLeft: 4,
    verticalAlign: "middle",
  },
  dropdownMenu: {
    position: "absolute",
    top: "60px",//100%
    // minWidth: "100%",
    // maxWidth: "300px",
    border: "1px solid #d1e3ed",
    borderRadius: "5px",
    backgroundColor: "#fff",
    zIndex: 1000,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "10px 10px",
    Hight: '200px',
    OverflowY: 'auto',
  },
  option: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "10px 0px",
    cursor: "pointer",
    fontSize: "14px",
    color: "#343C44",
  },
  checkbox: {
    margin: 0,
  },
  errorMessage: {
    color: "#EE201C",
    fontSize: "11px",
    marginTop: "4px",
    display: "flex",
    gap: "4px",
    fontWeight: '400'
  },
  labelDiv:{
    display:'flex',
    alignItems: "center",
    justifyContent: "space-between",
  },
  sideLabel:{
    fontFamily:'Roboto',
    fontWeight:400,
    fontSize:'12px',
    textDecoration:'underline',
    color: '#5A55D2',
    cursor:'pointer'
  }
};

export default Dropdown;



// const Dropdown2 = ({
//   label,
//   options = [],
//   width = 200,
//   searchBoxWidth = null,
//   value,
//   onChange,
//   isMulti = false,
//   style = {},
//   loading,
//   errorMessage,
// }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleDropdown = () => setIsOpen(!isOpen);

//   const handleOptionClick = (option) => {
//     //console.log('options:-',option)
//     if (isMulti) {
//       const updatedValue = Array.isArray(value)
//         ? value.some((v) => v.value === option.value)
//           ? value.filter((item) => item.value !== option.value)
//           : [...value, option]
//         : [option];
//       onChange(updatedValue);
//     } else {
//       onChange(option);
//       setIsOpen(false);
//     }
//   };

//   const getDisplayValue = () => {
//     if (isMulti) {
//       return Array.isArray(value) && value?.length > 0
//         ? value?.map((item) => item?.label || "").join(", ")
//         : "Select options";
//     }
//     return value?.label || (typeof value === "string" ? value : "Select")|| "Select";
//   };

//   return (
//     <div style={styles.container}>
//       <label style={styles.label}>{label}</label>
//       {loading ? (
//         <div
//           style={{
//             ...styles.dropdown,
//             width: searchBoxWidth,
//             ...style,
//             borderColor: errorMessage ? "red" : "#D6E4EC",
//           }}
//         >
//           <span style={styles.selectedOption}>Loading...</span>
//           <Loader loading={loading} color="#000" size="small" />
//         </div>
//       ) : (
//         <div
//           style={{
//             ...styles.dropdown,
//             width: searchBoxWidth,
//             ...style,
//             borderColor: errorMessage ? "red" : "#D6E4EC",
//           }}
//           onClick={toggleDropdown}
//         >
//           <span style={styles.selectedOption}>{getDisplayValue()}</span>
//           <img src={dropDownIcon} alt="Down Arrow" style={styles.arrow} />
//         </div>
//       )}

//       {isOpen && options.length > 0 && (
//         <div
//           style={{
//             ...styles.dropdownMenu,
//             width: '90%',
//             maxHeight: 250,
//             overflowY: "auto",
//           }}
//         >
//           {options.map((option, index) => (
//             <div
//               key={index}
//               style={{
//                 ...styles.option,
//                 backgroundColor:
//                   isMulti &&
//                   Array.isArray(value) &&
//                   value.some((item) => item.value === option?.value)
//                     ? "#d1e7dd"
//                     : "#fff",
//                 borderBottom:
//                   index !== options.length - 1 ? "1px solid #d1e3ed" : "none",
//               }}
//               onClick={() => handleOptionClick(option)}
//             >
//               {isMulti && (
//                 <input
//                   type="checkbox"
//                   checked={
//                     Array.isArray(value) &&
//                     value.some((item) => item?.value === option?.value)
//                   }
//                   onChange={() => handleOptionClick(option)}
//                   style={styles.checkbox}
//                 />
//               )}
//               {option?.label ? option.label : (typeof option === "string" ? option : "Unnamed Option") || "Unnamed Option"}
//             </div>
//           ))}
//         </div>
//       )}

//       {errorMessage && (
//         <div style={styles.errorMessage}>
//           <img
//             src={error}
//             alt="Error"
//             style={{ width: "12px", height: "12px" }}
//           />
//           {errorMessage}
//         </div>
//       )}
//     </div>
//   );
// };




