import React, { useState, useRef, useEffect } from 'react';
import Tabs from '../../../../Components/Tabs';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddTaskCategoryModal from '../../../../Components/Modals/AddTaskCategoryModal';
import Input from '../../../../Components/Input';
import filterIcon from '../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../Assets/Icons/back.png'
import Dropdown from '../../../../Components/Dropdown';
import ManageTeamsController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageTeamsController';
import Loader from '../../../../Components/Loader';
import AddTeamModal from '../../../../Components/Modals/AddTeamModal';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';
import downArrow from '../../../../Assets/Icons/chevron-down.png';

const ManageTeamsScreen = ({ teamList, error, loading, onRefresh }) => {



  const {
    teamListLoading, teamListError,
    teamName, setTeamName, teamNameError,
    teamManager, setTeamManager, teamManagerError,
    teamLead, setTeamLead, teamLeadError,
    employeeList, teamLeaderLoading,
    group, setGroup,
    teamListRef,
    manageTeamList,
    getStatusStyle,
    handleCreateTeam,
    handleEditTeam,
    handleDeleteTeam,
    deleteTeamLoading,
    deleteTeamError,
    handleCreateTeamModal,
    handleCloseModal,
    isModalOpen,
    showFilters,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    callDeleteTeam,
    teamInfoRef,
    filterError,
    filters,
    handleFilterChange,
    applyFilters, resetFilters, cancelFilters,
    toggleFilters,
    currentPage,
    totalPages,
    handlePageChange,
    currentPageTeams,
    deleteTeamSuccess,

    managerFiletrError,
    managerList,
    managerLoading,

    usersList,
    empLoading,
    deletedMessages,
    visiblePages
  } = ManageTeamsController(teamList, onRefresh, loading, error);
  // //console.log('teamListRef:------', teamListRef.current)

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className='basic-info-container-border'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={styles.categoryHeader}>Search Teams</h3>
          <div style={{ alignSelf: 'center' }}>
            <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleCreateTeamModal}>
              Create Team
            </button>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.filtersHeader}>
            <button style={styles.filterButton} onClick={toggleFilters}> <img src={filterIcon} alt="Filter" />  <span>
              {showFilters ? 'Filters' : 'Filters'}
            </span></button>
            <div >
              <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
              <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
            </div>
          </div>
        </div>
        {/* Filters using Flexbox */}
        {showFilters && (
          <div style={styles.filtersContainer}>
            <div className="tasksScreen-filters" style={{ display: 'flex' }}>
              <Input
                label="Team Name"
                placeholder="Enter Team Name"
                value={filters?.team_name}
                onChange={(e) => handleFilterChange('team_name', e.target.value)}
                errorMessage={teamNameError}
                searchBoxWidth={230}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Team Manager"] = el)}
                isOpen={openDropdown === "Team Manager"}
                toggleDropdown={() => toggleDropdown("Team Manager")}
                label="Team Manager"
                options={managerList}
                value={filters?.team_manager}
                onChange={(value) => handleFilterChange('team_manager', value)}
                errorMessage={managerFiletrError}
                loading={managerLoading}
                searchBoxWidth={230}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Team Leader"] = el)}
                isOpen={openDropdown === "Team Leader"}
                toggleDropdown={() => toggleDropdown("Team Leader")}
                label="Team Leader"
                options={employeeList}
                value={filters?.team_leader}
                onChange={(value) => handleFilterChange('team_leader', value)}
                errorMessage={teamLeadError}
                loading={teamLeaderLoading}
                searchBoxWidth={230}
              />
              {/* <Input
          label="Group"
          placeholder="------"
          value={group}
          onChange={(e) => setGroup(e.target.value)}
        /> */}
            </div>
            {filterError && <div className="list-error-msg" style={{ textAlign: 'left', paddingBottom: 5 }}> {filterError}</div>}

            <div className="tasksScreen-filter-buttons">
              <button onClick={applyFilters} className="tasksScreen-apply-button" style={{ cursor: teamListLoading ? 'not-allowed' : 'pointer', opacity: teamListLoading ? 0.5 : 1 }} disabled={teamListLoading}>Apply</button>
              <button onClick={cancelFilters} className="tasksScreen-cancel-button">Cancel</button>
              <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
            </div>
          </div>
        )}

      </div>

      <div className='basic-info-card' style={{ border: 'none' }}>
        <div style={styles.tableWrapper}>
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Team Name</th>
                <th style={{ width: "15%" }}>Team Manager</th>
                <th style={{ width: "15%" }}>Team Leader</th>
                <th style={{ width: "25%" }}>Team Members</th>
                <th style={{ width: "10%" }}>Created By</th>
                <th style={{ width: "5%" }}>Status</th>
                <th style={{ width: "5%" }}></th>
              </tr>
            </thead>
            {currentPageTeams?.length > 0 &&
              <tbody style={{ backgroundColor: "white" }}>
                {currentPageTeams?.map((category, index) => (
                  <tr key={index}>
                    <td style={styles.tableLink} onClick={() => handleEditTeam(category, true)}>{category?.team_name || '--'}</td>
                    <td style={styles.tableValue}>{category?.emp_id_team_manager_name || '--'}</td>
                    <td style={styles.tableValue}>{category?.emp_id_team_lead_name || '--'}</td>
                    <td style={styles.tableValue}>{category?.team_size ?? '--'}
                      {deleteTeamSuccess[category?.team_id] &&
                        <div style={{ color: 'green', paddingTop: 10 }} >{deleteTeamSuccess[category?.team_id]}</div>}
                      {deleteTeamError[category?.team_id] &&
                        <div style={{ ...styles.deleteErrorMsg, paddingTop: 10 }} >{deleteTeamError[category?.team_id]}</div>}
                    </td>
                    <td style={styles.tableValue}>{category?.create_by_name || '--'}</td>
                    <td style={styles.tableValue}>
                      {/* Status Label */}
                      <div
                        style={{
                          display: "inline-block", // Makes the div wrap content and have its own width
                          padding: "5px 5px", // Padding for the button-like appearance
                          borderRadius: "15px",
                          ...getStatusStyle(Number(category?.team_status)), // Dynamic styles based on the status
                          width: "auto", // Width adjusts automatically to content
                        }}
                      >
                        {category?.team_status === 0 ? 'InActive' : 'Active'}
                      </div>

                      {/* Action Buttons */}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "2px", // Space between edit and delete buttons
                        }}
                      >
                        {/* Edit Button */}
                        <button
                          className="tasksScreen-edit-button"
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: deleteTeamLoading[category?.team_id] || deletedMessages.includes(category?.team_id) ? 'not-allowed' : 'pointer',
                            pointerEvents: deleteTeamLoading[category?.team_id] || deletedMessages.includes(category?.team_id) ? 'none' : 'auto',
                            opacity: deleteTeamLoading[category?.team_id] ? 0.3 : 1
                          }}
                          onClick={() => handleEditTeam(category, true)}
                        >
                          <img
                            src={editIcon}
                            alt="Edit"
                            style={{ width: "16px", height: "16px" }}
                          />
                        </button>

                        {/* Delete Button */}
                        <button
                          className="tasksScreen-delete-button"
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            opacity: deleteTeamLoading[category?.team_id] ? 0.3 : 1,
                            pointerEvents: deleteTeamLoading[category?.team_id] || deletedMessages.includes(category?.team_id) ? 'none' : 'auto',
                          }}
                          onClick={() => { handleDeleteTeam(category) }}
                          disabled={deleteTeamLoading[category?.team_id]}
                        >
                          <img
                            src={deleteIcon}
                            alt="Delete"
                            style={{ width: "16px", height: "16px" }}
                          />
                        </button>
                        {deleteTeamLoading[category?.team_id] &&
                          <span><Loader loading={deleteTeamLoading[category?.team_id]} color={'var(--primary-color)'} size='small' /></span>
                        }
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>}

          </table>
          {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
          {teamListLoading && <Loader loading={teamListLoading} color={'var(--primary-color)'} />}
          {teamListError && <div className="list-error-msg">{teamListError} </div>}
          {/* <AddTaskCategoryModal isOpen={isModalOpen} onClose={handleCloseModal}/> */}
        </div>
        {/* Pagination */}
        <div className="tasksScreen-table-footer" >
          <span className="tasksScreen-pagination-info">
            {`${currentPage}-${totalPages} of items`}
          </span>
          <div className="tasksScreen-pagination">
            <button
              className="tasksScreen-pagination-button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || totalPages === 0}
            >
              <img
                src={downArrow}
                alt="Previous"
                className="tasksScreen-arrow-left tasksScreen-icon-image"
              />
            </button>
            {visiblePages.map((page) => (
                <button
                    key={page}
                    className={`tasksScreen-pagination-button ${currentPage === page ? "active" : ""}`}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </button>
            ))}
            <button
              className="tasksScreen-pagination-button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || totalPages === 0}
            >
              <img
                src={downArrow}
                alt="Next"
                className="tasksScreen-arrow-right tasksScreen-icon-image"
              />
            </button>
          </div>
        </div>
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          message={`Are you sure you want to delete ${teamInfoRef.current?.team_name} ?`}
          onClose={handleCloseDeleteModal}
          onConfirm={() => {
            handleCloseDeleteModal(); // Close modal
            callDeleteTeam(teamInfoRef.current); // Perform delete operation
          }}
        />
        {/* {isModalOpen &&
          <AddTeamModal isOpen={isModalOpen} onClose={handleCloseModal} onValueChange={onRefresh()}/>} */}
      </div>
    </>

  )

};

const styles = {
  categoryHeader: {
    color: '#1F384C',
    fontSize: 28,
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    paddingBottom: 20
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    // marginBottom:50
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2',
    cursor: 'pointer'
  },
  container: {
    padding: "5px",
    backgroundColor: "#EFF0F4",
    border: "none",
    // borderRadius: "5px",
    border: '1px #EFF0F4 solid'
  },
  filtersHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "15px",
  },
  filterButton: {
    backgroundColor: "white",
    color: "#40513B",
    border: "none",
    borderRadius: "5px",
    padding: "5px 8px",
    cursor: "pointer",
    borderRadius: '20px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    border: '1px #40513B solid'

  },
  filterIcon: {
    width: '10px',
    height: '10px',
    paddingRight: '5px'
  },

  paginationButtons: {
    display: "flex",
    gap: "5px",
  },
  paginationButton: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  filtersContainer: {
    // display: "flex",
    flexWrap: "wrap", // Wrap inputs to the next line if needed
    gap: "20px", // Space between inputs
    marginBottom: "15px",
    // justifyContent: "space-between", // Distribute inputs evenly
    border: '1px #EFF0F4 solid',
    padding: '10px'
  },
  buttonsContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  applyButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  resetButton: {
    backgroundColor: "transparent",
    color: "#555",
    border: "none",
    cursor: "pointer",
  },
  deleteErrorMsg: {
    color: 'red',
  }


}

export default ManageTeamsScreen;