import React, { useRef } from "react";
import "../App.css";
import errorIcon from "../Assets/Icons/error_message.png";
import calendarIcon from "../Assets/Icons/today.png";
import { formatToLocalTime } from "../Utils/Validation";
import { IS_DATE_MANUAL } from "../ApiServices/BaseURL";

const DateInput = ({
  placeholder = "DD-MM-YYYY",
  value,
  onChange,
  errorMessage,
  width = null,
  maxwidth= null,
  isDateTime = false,
  ...props
}) => {
  const dateInputRef = useRef(null);

  // Trigger the date input when the calendar icon is clicked
  const handleIconClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker(); // This triggers the native date picker
    }
  };

  const formatDate = (date) => {
    if (!date) return placeholder;
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    // Blur the input field after selection to close the picker
    if (dateInputRef.current) {
      dateInputRef.current.blur();
    }
  };

  return (
    <div className="input-container" style={{ position: "relative", width: "100%" }}>
      {/* Display Box */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "11px 10px",
          fontSize: "14px",
          border: errorMessage ? "1px solid red" : "1px solid #d6e4ec",
          borderRadius: "4px",
          cursor: IS_DATE_MANUAL ? "text" : "pointer", // Allow text cursor if manual entry
          marginTop: "5px",
          backgroundColor: "#fff",
          minWidth: width,
          maxWidth: maxwidth
        }}
      >
        {IS_DATE_MANUAL ? (
          // Allow manual date-time entry
          <input
            type={isDateTime ? "datetime-local" : "date"}
            value={value || ""}
            onChange={handleChange}
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              fontSize: "14px",
              background: "transparent",
              color: "#000",
            }}
            {...props}
          />
        ) : (
          // Display formatted date and trigger picker on click
          <span onClick={handleIconClick}>
            {value ? (isDateTime ? formatToLocalTime(value) : formatDate(value)) : (
              <span style={{ color: "gray" }}>{placeholder}</span>
            )}
          </span>
        )}

        {!IS_DATE_MANUAL && (
          <img
            src={calendarIcon}
            alt="Calendar"
            style={{ width: "16px", height: "16px", cursor: "pointer" }}
            onClick={handleIconClick}
          />
        )}
      </div>

      {/* Hidden Date Input for Picker */}
      {!IS_DATE_MANUAL && (
        <input
          type={isDateTime ? "datetime-local" : "date"}
          ref={dateInputRef}
          value={value || ""}
          onChange={handleChange}
          style={{
            position: "absolute",
            top: 18,
            left: 0,
            opacity: 0,
            pointerEvents: "none",
          }}
          {...props}
        />
      )}

      {/* Error Message */}
      {errorMessage && (
        <div style={styles.errorMessage}>
          <span>
            <img src={errorIcon} alt="Error" style={{ width: "12px", height: "12px" }} />
          </span>{" "}
          {errorMessage}
        </div>
      )}
    </div>
  );
};

const styles = {
  errorMessage: {
    color: "#EE201C",
    fontSize: "11px",
    marginTop: "4px",
    display: "flex",
    gap: "4px",
    fontWeight: "400",
  },
};

export default DateInput;
