// InputComponent.js
import React from 'react';
import error from '../Assets/Icons/error_message.png';

const Input = ({ label,inputType = "text", placeholder, value, onChange,width=200,errorMessage ,isEdit = true, searchBoxWidth = null,maxLength = null }) => {
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if(inputType === 'number'){
      //if (/^\d*$/.test(inputValue))
      if (/^\d*(\.\d{0,2})?$/.test(inputValue)) {
        // Notify parent of the mobile number change
        onChange(e);
    }
    }else{
      onChange(e);
    }
};
  return (
    <div style={styles.container}>
      <label style={styles.label}>{label}</label>
      <input
        type="text"
        placeholder={placeholder}
        style={{...styles.input,width:searchBoxWidth,borderColor: errorMessage ? "red" : "#D6E4EC",background:isEdit ? '#fff' : "var(--read-input-box-bg-color)" }}
        value={value || ''}       // Controlled input
        onChange={handleInputChange} // Controlled input handler
        readOnly={!isEdit} 
        maxLength={maxLength}
      />
      {errorMessage && (
                <div style={styles.errorMessage}>
                    <span><img
                          src={error}
                          alt="Error"
                          style={{ width: "12px", height: "12px" }}
                        /></span> {errorMessage}
                </div>
            )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    // marginRight: '20px',
  },
  label: {
    fontSize: '12px',
    marginBottom: '5px',
    color:'#343C44',
    fontWeight:400,
    fontFamily:'Roboto'
  },
  input: {
    // maxWidth: '200px',
    // minWidth:'200px',
    height: '19px',
    borderRadius: '5px',
    border: '1px solid #d1e3ed',
    padding: '5px 10px',
    fontSize: '14px',
    padding:'10px'
  },
  errorMessage: {
    color: "#EE201C",
    fontSize: "11px",
    marginTop: "4px",
    display: "flex",
    gap: "4px",
    fontWeight:'400'
},
};

export default Input;
