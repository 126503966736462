import React, { useState, useRef, useEffect } from 'react';
import TaskListController from '../../Controllers/AdminScreens/TaskListController';
import editImg from '../../../Assets/Icons/edit.png';
import deleteImg from '../../../Assets/Icons/delete.png';
import downArrow from '../../../Assets/Icons/chevron-down.png';
import filter from '../../../Assets/Icons/filter.png';
import Dropdown from '../../../Components/Dropdown';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Components/Loader';
import backIcon from '../../../Assets/Icons/back.png';
import leftBtuIcon from '../../../Assets/Icons/back.png';
import DeleteConfirmationModal from '../../../Components/Modals/DeleteConformationModal';

const TaskListScreen = () => {
  const navigate = useNavigate();

  const handleNav = (pageName, task, clientId,taskInfo) => {
    //console.log('page', pageName, 'task', task);
   navigate(`/${pageName}`, { state: { task: task, clientId: clientId,taskInfo:taskInfo } }); // Pass task in state
  };
  const {
    tasks,
    currentPage,
    totalPages,
    handlePageChange,
    toggleDetails,
    loading,
    error,
    visiblePages,
    handleBack,
    serviceList, serviceLoading, serviceError,
    serviceCategoryList, serviceCategoryListRef, serviceCategoryLoading, serviceCategoryError,
    yearList, periodList, yearListLoading, yearListError,
    taskStatusList,
    showFilters,
    filters, handleFilterChange, filterError, searchTaskListLoading,
    applyFilters, resetFilters, cancelFilters, toggleFilters,
    onTaskDelete,
    deleteTaskError, deleteTaskLoading, deleteTaskSuccess,
    isDeleteModalOpen, handleCloseDeleteModal,
    callDeleteTask,taskInfoRef
  } = TaskListController();

  // const [showFilters, setShowFilters] = useState(false); // Control the visibility of filters
  // const [filters, setFilters] = useState({
  //   taskType: '',
  //   financialYear: '',
  //   period: '',
  //   status: '',
  // });

  // const handleFilterChange = (name, value) => {
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [name]: value,
  //   }));
  // };

  // const applyFilters = () => {
  //   //console.log('Applying filters:', filters);
  //   setShowFilters(false);
  // };

  // const resetFilters = () => {
  //   setFilters({
  //     taskType: '',
  //     financialYear: '',
  //     period: '',
  //     status: '',
  //   });
  // };

  // const toggleFilters = () => {
  //   setShowFilters(!showFilters);
  // };
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ width: '93%', margin: 'auto' }}>
        <h3 style={styles.header}>
          <div style={styles.headerContainer}>
            <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleBack}
            />
            <span>Pending Tasks</span>
          </div>
        </h3>
      </div>
      {/* <div className="tasksScreen-task-screen"> */}
      <div className="tasksScreen-main-content" style={{ width: 'unset' }}>
        <div className="tasksScreen-tasks-container" >
          <div className="tasksScreen-tasks-header" style={{ paddingBottom: 10 }}>
            <h2 className="tasksScreen-tasks-title">Tasks Details</h2>
          </div>

          {/* Filters Row */}
          <div className="tasksScreen-filters-row" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0px 5px 0px' }}>
            <div className="tasksScreen-filters-button-wrapper">
              <button className="tasksScreen-filters-button" onClick={toggleFilters}>
                <img src={filter} alt="Filter" />
                {showFilters ? 'Filters' : 'Filters'}
              </button>
            </div>
            <div style={{ paddingRight: '12px' }}>
              <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
              <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
            </div>
          </div>

          {showFilters && (
            <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
              <div className="tasksScreen-filters">
                {/* Replace each filter select with Dropdown component */}
                {/* <Dropdown
                  ref={(el) => (dropdownRefs.current["Task Type"] = el)}
                  isOpen={openDropdown === "Task Type"}
                  toggleDropdown={() => toggleDropdown("Task Type")}
                  label="Task Type"
                  options={['Type 1', 'Type 2']}
                  value={filters.taskType}
                  onChange={(value) => handleFilterChange('taskType', value)}
                /> */}
                <Dropdown
                  ref={(el) => (dropdownRefs.current["Service"] = el)}
                  isOpen={openDropdown === "Service"}
                  toggleDropdown={() => toggleDropdown("Service")}
                  label="Service "
                  options={serviceList || []}
                  value={filters?.service}
                  onChange={(value) => handleFilterChange('service', value)}
                  errorMessage={serviceError}
                  loading={serviceLoading}
                />
                <Dropdown
                  ref={(el) => (dropdownRefs.current["subCategory"] = el)}
                  isOpen={openDropdown === "subCategory"}
                  toggleDropdown={() => toggleDropdown("subCategory")}
                  label="Service Category"
                  options={serviceCategoryListRef.current || []}
                  value={filters?.service_category}
                  onChange={(value) => handleFilterChange('service_category', value)}
                  loading={serviceCategoryLoading}
                  errorMessage={serviceCategoryError}
                />

                <Dropdown
                  ref={(el) => (dropdownRefs.current["Financial Year"] = el)}
                  isOpen={openDropdown === "Financial Year"}
                  toggleDropdown={() => toggleDropdown("Financial Year")}
                  label="Financial Year"
                  options={yearList || []}
                  value={filters?.financial_year}
                  onChange={(value) => handleFilterChange('financial_year', value)}
                  loading={yearListLoading}
                  errorMessage={yearListError}
                />

                <Dropdown
                  ref={(el) => (dropdownRefs.current["Period"] = el)}
                  isOpen={openDropdown === "Period"}
                  toggleDropdown={() => toggleDropdown("Period")}
                  label="Period"
                  options={periodList || []}
                  value={filters?.period}
                  onChange={(value) => handleFilterChange('period', value)}
                  loading={yearListLoading}
                  errorMessage={yearListError}
                />

                <Dropdown
                  ref={(el) => (dropdownRefs.current["Status"] = el)}
                  isOpen={openDropdown === "Status"}
                  toggleDropdown={() => toggleDropdown("Status")}
                  label="Status"
                  options={taskStatusList}
                  value={filters?.status}
                  onChange={(value) => handleFilterChange('status', value)}
                />
              </div>
              <div>

                {filterError && <div className='list-error-msg' style={{ textAlign: 'left', paddingBottom: 5 }}> {filterError} </div>}
                <div className="tasksScreen-filter-buttons">
                  <button onClick={applyFilters} className="tasksScreen-apply-button" style={{ cursor: searchTaskListLoading ? "not-allowed" : "pointer", opacity: searchTaskListLoading ? 0.5 : 1 }} disabled={searchTaskListLoading}>Apply</button>
                  <button onClick={cancelFilters} className="tasksScreen-cancel-button">Cancel</button>
                  <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                </div>
              </div>
            </div>
          )}

          <div className="tasksScreen-task-table-wrapper">
            <table className="tasksScreen-task-table">
              <thead>

                {/* Table Headers */}
                <tr>
                  <th></th>
                  <th>Client Name</th>
                  <th>Service Category</th>
                  <th>Start Date</th>
                  <th>ETD</th>
                  <th>Financial Year</th>
                  <th>Group Name</th>
                  <th>Assigned To</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {tasks?.length > 0 &&
                <tbody style={{ backgroundColor: "white" }}>
                  {tasks.map((task, index) => (
                    <React.Fragment key={index}>
                      <tr className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}>
                        <td>
                          <button onClick={() => toggleDetails(task?.task_id)}>
                            {task.showDetails ? <img src={downArrow} alt="Up Arrow" className="tasksScreen-arrow-up " /> : <img src={downArrow} alt="Down Arrow" />}
                          </button>
                        </td>
                        <td>{task?.client_name || '--'}</td>
                        <td>{task?.service_sub_name || '--'}</td>
                        <td>{task?.task_start_date || '--'}</td>
                        <td>{task?.task_due_date || '--'}</td>
                        <td>{task?.financial_year || '--'}</td>
                        <td>{task.group_name || '--'}
                        {deleteTaskSuccess[task?.task_id] &&
                                            <div style={{ color: 'green', textDecoration: 'none', paddingTop: 10 }} >{deleteTaskSuccess[task?.task_id]}</div>}
                        {deleteTaskError[task?.task_id] &&
                                            <div style={{ ...styles.deleteErrorMsg, paddingTop: 10 }} >{deleteTaskError[task?.task_id]}</div>}
                        </td>
                        <td>{task?.emp_id_assigned_name || '--'}</td>
                        <td>
                          <span className={`tasksScreen-status-badge ${task?.task_status_name?.toLowerCase().replace(' ', '-')}`}>
                            {task?.task_status_name || '--'}
                          </span>
                        </td>
                        <td>
                        <div
                                            style={{
                                                display: "flex",
                                                gap: "2px", // Space between edit and delete buttons
                                            }}
                                        >
                          <button className="tasksScreen-edit-button">
                            <img src={editImg} alt="Edit" onClick={() => handleNav('manage-clients', 'Tasks', task?.client_id, task)} />
                          </button>
                          <button className="tasksScreen-delete-button"
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: deleteTaskLoading[task?.task_id] ? "not-allowed" : "pointer",
                            opacity: deleteTaskLoading[task?.task_id] ? 0.3 : 1,
                          }}
                          disabled={deleteTaskLoading[task?.task_id]}>
                            <img src={deleteImg} alt="Delete" onClick={() => onTaskDelete(task)} />
                          </button>
                          {deleteTaskLoading[task?.task_id] &&
                              <span><Loader loading={deleteTaskLoading[task?.task_id]} color={'var(--primary-color)'} size='small' /></span>
                          }
                           </div>
                        </td>
                      </tr>
                      {task.showDetails && (
                        <tr className="tasksScreen-additional-info-row">
                          <td colSpan="10">
                            <div className="tasksScreen-additional-info">
                              <div>
                                <span>Task ID</span>
                                <p>{task?.task_id || '--'}</p>
                              </div>
                              <div>
                                <span>File No.</span>
                                <p>{task?.client_file_id || '--'}</p>
                              </div>
                              <div>
                                <span>Task Leader</span>
                                <p>{task?.emp_id_tl_name || '--'}</p>
                              </div>
                              <div>
                                <span>Completed Check</span>
                                <p>{task?.task_completed_check_status === 1 ? 'Yes' : 'No'}</p>
                              </div>
                              <div>
                                <span>Completed By</span>
                                <p>{task?.completed_by || '--'}</p>
                              </div>
                              <div>
                                <span>Period</span>
                                <p>{task?.financial_period || '--'}</p>
                              </div>
                              <div>
                                <span>Pending Issues</span>
                                <p>{task?.pendingIssues || '--'}</p>
                              </div>
                              <div>
                                <span>Description</span>
                                <p>{task?.description || '--'}</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              }
            </table>
            {(loading || searchTaskListLoading) && <Loader loading={loading || searchTaskListLoading} color={'var(--primary-color)'} />}
            {error && <div className="list-error-msg">{error} </div>}
          </div>

          <div className="tasksScreen-table-footer">
            <span className="tasksScreen-pagination-info">
              {`${currentPage}-${totalPages} of items`}
            </span>
            <div className="tasksScreen-pagination">
              <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
              </button>
              {visiblePages.map((page) => (
                <button
                  key={page}
                  className={`tasksScreen-pagination-button ${currentPage === page ? "active" : ""}`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
              <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {isDeleteModalOpen && <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          message={`Are you sure you want to delete ${taskInfoRef.current?.task_id} Task?`}
          onClose={handleCloseDeleteModal}
          onConfirm={() => {
            handleCloseDeleteModal(); // Close modal
            callDeleteTask(taskInfoRef.current); // Perform delete operation
          }}
        />
      }
    </>
  );
};

const styles = {
  header: {
    fontSize: "22px",
    color: "#060606",
    marginBottom: "10px",
    fontWeight: 500,
    // fontFamily:'Montserrat',
    //   marginLeft:'20px'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center', // Vertically align items
  },
  leftHeader: {
    marginTop: '-5px'
  },
  deleteErrorMsg: {
    color: 'red',
}
};

export default TaskListScreen;
