import { useState, useRef, useEffect } from 'react';
import { postData, getData } from '../../../ApiServices/ApiService';
import { ORG_GET_TASK_LIST, ORG_GET_SERVICE_LIST_SEARCH, ORG_GET_SUB_SERVICE_LIST_SEARCH, ORG_GET_ALL_CODES_LIST, ORG_DELETE_CLIENT_TASK } from '../../../ApiServices/BaseURL';
import { useNavigate, useLocation } from 'react-router-dom';

const TaskListController = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selServiceInfo, setSelServiceInfo] = useState(location?.state?.selectedInfo || {});
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');

  const [serviceList, setServiceList] = useState([]);
  const [serviceError, setServiceError] = useState('');
  const [serviceLoading, setServiceLoading] = useState(false);
  const [serviceCategoryList, setServiceCategoryList] = useState([]);
  const serviceCategoryListRef = useRef([]);
  const [serviceCategoryError, setServiceCategoryError] = useState('');
  const [serviceCategoryLoading, setServiceCategoryLoading] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [yearListError, setYearListError] = useState('');
  const [yearListLoading, setYearListLoading] = useState(false);
  const [periodList, setPeriodList] = useState([]);

  const [taskStatusList, setTaskStatusList] = useState([
    { "value": 1, "label": "Not Started" },
    { "value": 2, "label": "In Progress" },
    { "value": 3, "label": "Completed" },
    // { "value": 11, "label": "Reopen" },
    // { "value": 12, "label": "Issue" },
    { "value": 13, "label": "Hold" },
    { "value": 21, "label": "Priority" }
  ])

  const [filterError, setFilterError] = useState('');
  const [searchTaskListLoading, setSearchTaskListLoading] = useState(false);
  const [deleteTaskLoading, setDeleteTaskLoading] = useState({});
  const [deleteTaskError, setDeleteTaskError] = useState({});
  const [deleteTaskSuccess, setDeleteTaskSuccess] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const taskInfoRef = useRef(null)
  useEffect(() => {
    // Sample data with status and showDetails property

    // setTasks(data);
    // setFilteredTasks(data);
    console.log('useEffect location : ', location)
    if (location?.state?.selectedInfo) {
      setSelServiceInfo(location?.state?.selectedInfo);
      fetchTaskList();
      handleFilterChange('service', location?.state?.selectedInfo?.selService)
      handleFilterChange('service_category', location?.state?.selectedInfo?.selServiceCategory)
    }
  }, [location?.state?.selectedInfo]);

  useEffect(() => {
    fetchServiceList();
    fetchYearList();
  }, [])

  const fetchServiceList = async () => {
    try {
      setServiceError('');
      setServiceLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id
      }

      //console.log('fetchTaskCategorieList payload', payLoad);

      // Make the API call
      const response = await postData(ORG_GET_SERVICE_LIST_SEARCH, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              const filterData = responseData?.dataJ.map(service => ({
                label: service.service_name,
                value: service.service_id
              }));
              setServiceList(filterData);
            } else {
              setServiceError(responseData?.info || 'Data Not Found');
            }
          } else {
            setServiceError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setServiceError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setServiceError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setServiceError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setServiceError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setServiceError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setServiceError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setServiceError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setServiceError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setServiceLoading(false);
    }
  }

  const fetchTaskList = async () => {
    //console.log('get client')
    try {

      setTasks([]);
      setFilteredTasks([]);
      setCurrentPage(1);
      setError('');
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_id": selServiceInfo?.selService?.value || 0,
        "service_sub_id": selServiceInfo?.selServiceCategory?.value || 0,
        "task_type": selServiceInfo?.taskType || '',
        "task_type_name": selServiceInfo?.taskTypeName || '',
        "service_check_id": selServiceInfo?.serviceCheckId || 0
      }
      const response = await postData(ORG_GET_TASK_LIST, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              setTasks(responseData?.dataJ);
              setFilteredTasks(responseData?.dataJ);
            } else {
              setError(responseData?.info || 'No task available.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch tasks. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch tasks.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch tasks. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch tasks. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  }

  const fetchServiceCategoryList = async (serviceId) => {
    try {
      //console.log('fetchServiceCategoryList serviceId @@',serviceId)
      setServiceCategoryLoading(true);
      setServiceCategoryError('');
      setFilters((prevFilters) => ({
        ...prevFilters,
        service_category: null,
      }));
      serviceCategoryListRef.current = [];
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_id": serviceId
      }

      //console.log('fetchTaskSubCategorieList payload',payLoad);

      // Make the API call service_id=10006&
      const response = await postData(ORG_GET_SUB_SERVICE_LIST_SEARCH, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              serviceCategoryListRef.current = responseData?.dataJ.map(service => ({
                label: service?.service_sub_name,
                value: service?.service_sub_id
              }));
            } else {
              setServiceCategoryError(responseData?.info || 'Data Not Found');
            }
          } else {
            setServiceCategoryError(responseData?.info || 'Failed to fetch service category data. Please try again.');
          }
        } else {
          setServiceCategoryError(responseData?.info || 'Failed to fetch service category data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setServiceCategoryError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setServiceCategoryError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setServiceCategoryError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setServiceCategoryError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setServiceCategoryError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch service category data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setServiceCategoryError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setServiceCategoryError(`Failed to fetch service category data. Please check your network connection and try again.`);
      }
    } finally {
      setServiceCategoryLoading(false);
    }
  }

  const fetchYearList = async () => {
    try {
      setYearListLoading(true);
      setYearListError('')
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const payLoad = { "code_id": "org_all_codes" }
      const response = await postData(ORG_GET_ALL_CODES_LIST, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ) {
              // Transforming the data
              const yerarListOption = await responseData?.dataJ?.financial_year_codes_list.map(service => ({
                label: service.year_name,
                value: service.year_id
              }));
              setYearList(yerarListOption);

              // Transforming the data
              const periodOption = await responseData?.dataJ?.financial_period_codes_list.map(service => ({
                label: service.pc_name,
                value: service.pc_id
              }));
              setPeriodList(periodOption)
            } else {
              setYearListError(responseData?.info || 'Data Not Found');
            }
          } else {
            setYearListError(responseData?.info || 'Failed to fetch financial year. Please try again.');
          }
        } else {
          setYearListError(responseData?.info || 'Failed to fetch financial year.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setYearListError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setYearListError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setYearListError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setYearListError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setYearListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch financial year. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setYearListError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setYearListError(`Failed to fetch financial year. Please check your network connection and try again.`);
      }
    } finally {
      setYearListLoading(false);
    }
  }

  const toggleDetails = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task, i) =>
        task?.task_id === taskId ? { ...task, showDetails: !task.showDetails } : task
      )
    );
  };

  const totalPages = Math.ceil(tasks.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = tasks.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const maxVisiblePages = 3;

  const getVisiblePages = () => {
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust startPage if we're near the end
    const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

    return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
  };

  const visiblePages = getVisiblePages();

  const handleBack = () => {
    navigate(-1);
  }

  const [showFilters, setShowFilters] = useState(false); // Control the visibility of filters
  const [filters, setFilters] = useState({
    service: null,
    service_category: null,
    taskType: '',
    financial_year: null,
    period: null,
    status: null,
  });

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setServiceError('');
    setServiceCategoryError('');
    if (name === 'service') {
      fetchServiceCategoryList(value?.value);
    }
  };

  const applyFilters = () => {
    fetchSearchTaskList();
  };

  const resetFilters = () => {
    setFilters({
      service: null,
      service_category: null,
      taskType: '',
      financial_year: null,
      period: null,
      status: null,
    });
    setServiceCategoryError('');
    setServiceError('');
    setFilterError('');
  };

  const cancelFilters = () => {
    setShowFilters(!showFilters);
    resetFilters();
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const fetchSearchTaskList = async () => {
    try {
      console.log('fetchSearchTaskList', filters);
      setFilterError('');
      setError('');
      if (!filters?.service && !filters?.service_category && !filters?.financial_year && !filters?.period && !filters?.status) {
        console.log('fetchSearchTaskList if ', filters);
        setFilterError('Please fill at least one field to search.'); return;
      }

      setSearchTaskListLoading(true);
      setTasks([]);
      setFilteredTasks([]);
      setCurrentPage(1);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_id": filters?.service?.value || 0,
        "service_sub_id": filters?.service_category?.value || 0,
        "task_type": 'filter',
        "financial_year": filters?.financial_year?.value || '',
        "period": filters?.period?.value || '',
        "task_status": filters?.status?.value || 0
      }
      const response = await postData(ORG_GET_TASK_LIST, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              setTasks(responseData?.dataJ);
              setFilteredTasks(responseData?.dataJ);
            } else {
              setError(responseData?.info || 'No task available.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch tasks. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch tasks.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch tasks. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch tasks. Please check your network connection and try again.`);
      }
    } finally {
      setSearchTaskListLoading(false);
    }
  }

  const onTaskDelete = async (taskInfo) => {
    //console.log('taskInfo:-', taskInfo?.task_id);
    taskInfoRef.current = taskInfo;
    setIsDeleteModalOpen(true);

  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
  }
  const callDeleteTask = async (taskInfo) => {
    try {
      setDeleteTaskError({});
      setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: '' }));
      setDeleteTaskLoading((prev) => ({ ...prev, [taskInfo?.task_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "task_id": taskInfo?.task_id
      }

      console.log('callDeleteTask payload', payLoad);

      // Make the API call
      const response = await postData(ORG_DELETE_CLIENT_TASK, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setDeleteTaskSuccess((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info }));
            setTimeout(() => {
              setDeleteTaskSuccess((prev) => ({ ...prev, [taskInfo?.task_id]: '' }));
              fetchTaskList();
            }, 3000);
          } else {
            setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Failed to delete task. Please try again.' }));
          }
        } else {
          setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Failed to delete task. Please try again...' }));
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
        } else {
          // setError(responseData?.info || 'Bad request. Please check the request parameters.');
          setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      // Unexpected or network-related errors
      else {
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete task. Please try again.'}` }));
      } else if (error.request) {
        // Request was made but no response was received
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        // An error occurred in setting up the request
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: `Failed to delete task. Please check your network connection and try again.` }));
      }
    } finally {
      setDeleteTaskLoading((prev) => ({ ...prev, [taskInfo?.task_id]: false }));
    }
  }

  return {
    tasks: currentTasks,
    currentPage,
    totalPages,
    handlePageChange,
    toggleDetails,
    loading,
    error,
    visiblePages,
    handleBack,
    serviceList, serviceLoading, serviceError,
    serviceCategoryList, serviceCategoryListRef, serviceCategoryLoading, serviceCategoryError,
    yearList, periodList, yearListLoading, yearListError,
    taskStatusList,
    showFilters,
    filters, handleFilterChange, filterError, searchTaskListLoading,
    applyFilters, resetFilters, cancelFilters, toggleFilters,
    onTaskDelete,
    deleteTaskError, deleteTaskLoading, deleteTaskSuccess,
    isDeleteModalOpen, handleCloseDeleteModal,
    callDeleteTask,taskInfoRef
  };
};

export default TaskListController;

