import React ,{ useEffect,useState, useRef } from "react";
import { ORG_GET_ESTIMATE_LIST,ORG_GET_INVOICE_LIST } from '../../../../ApiServices/BaseURL';
import { postData,getData } from "../../../../ApiServices/ApiService";

const BillingReceiptEstimateController = () => {

    const [serviceEstimateList, setServiceEstimateList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [invoiceList, setInvoiceList] = useState([]);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [invoiceError, setInvoiceError] = useState('');

    const [receiptList, setReceiptList] = useState([]);
    const [receiptLoading, setReceiptLoading] = useState(false);
    const [receiptError, setReceiptError] = useState('');

    useEffect(()=>{
        fetchServiceEstimateList();
        fetchInvoiceList();
        fetchReceiptList();
    },[])

      const fetchServiceEstimateList =async ()=>{
        try {
            //console.log('get categoty')
            setLoading(true);
            setServiceEstimateList([]);
            setError('');
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_ESTIMATE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setServiceEstimateList(responseData?.dataJ);
                  } else {
                    setError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setError(responseData?.info || 'Failed to fetch service estimate. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch service estimate. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch service estimate. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch service estimate. Please check your network connection and try again.`);
            }
          } finally {
            setLoading(false);
          }
      }

      const fetchInvoiceList =async ()=>{
        try {
            //console.log('get categoty')
            setInvoiceLoading(true);
            setInvoiceList([]);
            setInvoiceError('');
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
              "emp_id_auth": storedUserInfo?.emp_id,
              "query_type":"list"
            }
            // Make the API call
            const response = await postData(ORG_GET_INVOICE_LIST,payLoad);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setInvoiceList(responseData?.dataJ);
                  } else {
                    setInvoiceError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setInvoiceError(responseData?.info || 'Failed to fetch Invoice list. Please try again.');
                }
              } else {
                setInvoiceError(responseData?.info || 'Failed to fetch Invoice list. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setInvoiceError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setInvoiceError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setInvoiceError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setInvoiceError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios            
            setInvoiceError(`Failed to fetch Invoice list. Please check your network connection and try again.`);
          } finally {
            setInvoiceLoading(false);
          }
      }

      const fetchReceiptList =async ()=>{
        try {
            //console.log('get categoty')
            setReceiptLoading(true);
            setReceiptList([]);
            setReceiptError('');
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
              "emp_id_auth": storedUserInfo?.emp_id,
              "query_type":"list"
            }
            // Make the API call
            const response = await postData(ORG_GET_INVOICE_LIST,payLoad);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setReceiptList(responseData?.dataJ);
                  } else {
                    setReceiptError(responseData?.info || 'No receipts available.');
                  }
                } else {
                  setReceiptError(responseData?.info || 'Failed to fetch receipt list. Please try again.');
                }
              } else {
                setReceiptError(responseData?.info || 'Failed to fetch receipt list. Status code error.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setReceiptError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setReceiptError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setReceiptError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setReceiptError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios            
            setReceiptError(`Failed to fetch receipt list. Please check your network connection and try again.`);
          } finally {
            setReceiptLoading(false);
          }
      }

      const serviceEstimateRefresh = ()=>{
        fetchServiceEstimateList();
      }

      const invoiceRefresh = ()=>{
        fetchInvoiceList();
      }

      const receiptRefresh = () => {
        fetchReceiptList();
      }

      return {
        serviceEstimateList,
        loading,
        error, 
        serviceEstimateRefresh, 
        invoiceList,
        invoiceError,
        invoiceLoading,invoiceRefresh,
        receiptList,
        receiptError, receiptLoading,
        receiptRefresh

      }

}

export default BillingReceiptEstimateController;