import React, { useState, useRef, useEffect } from 'react';
import Tabs from '../../../../Components/Tabs';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddTaskCategoryModal from '../../../../Components/Modals/AddTaskCategoryModal';
import Input from '../../../../Components/Input';
import filterIcon from '../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../Assets/Icons/back.png'
import AddUserModal from '../../../../Components/AddUserModal';
import leftArrowIcon from '../../../../Assets/Icons/chevron-down.png';
import { useNavigate } from "react-router-dom";
import ServiceEstimateController from '../../../Controllers/AdminScreens/Invoices/ServiceEstimateController';
import TextInput from '../../../../Components/TextInput';
import search from '../../../../Assets/Icons/search.png';
import Dropdown from '../../../../Components/Dropdown';
import DateInput from '../../../../Components/DateInput';
import Loader from '../../../../Components/Loader';
import { splitFormatDMY } from '../../../../Utils/Validation';
import printIcon from '../../../../Assets/Icons/Printer.png';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal'; 

const ServiceEstimateScreen = ({ serviceEstimateList, loading, error, onRefresh }) => {

  const {
    serviceEstimates,
    handlePageChange,
    currentTasks,
    totalPages,
    currentPage,
    setFilterData,
    filterData,
    handleInputChange,
    handleSearchClient,
    searchLoading,
    searchError,
    clientList,
    searchUser,
    handleSearchInputText,
    handleSelectClient,
    servicesLoading,
    serviceOptions,
    servicesError,
    serviceListError,
    serviceListLoading,
    applyFilters,
    cancelFilters,
    filterError,
    recipientOptions,
    userLoading,
    userError,
    showFilters,
    toggleFilters,
    resetFilters,
    printEstimateLoading,
    currentDocIndex,
    printEstimateDocument,
    printEstimateError,successMesg,
    visiblePages, isDeleteModalOpen, handleCloseDeleteModal,
    handleDeleteEstimateFile, estimateInfoRef, confirmDeleteEstimate, deleteLoading
  } = ServiceEstimateController(serviceEstimateList, error, onRefresh);

  const navigate = useNavigate();
  const handleCreateEstimate = () => {
    navigate("/create-edit-estimates"); // Navigate to the Create Estimates page
  };

  const handleEdit = (rowData) => {
    navigate("/create-edit-estimates", {
      state: rowData, // Pass the row data as state
    });
  };


  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className='basic-info-container'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={styles.categoryHeader}>Service Estimates</h3>
          <div>
            <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleCreateEstimate}>
              Create Estimate
            </button>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.filtersHeader}>
            <button style={styles.filterButton} onClick={toggleFilters}> <img src={filterIcon} style={styles.filterIcon} /> Filters</button>
            <div >
              <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
              <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
            </div>
          </div>
        </div>
        {/* Filters using Flexbox */}
        {showFilters && (
          <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
            <div className="tasksScreen-filters">
              {/* Client Name Input */}
              <div className="filter-field">
                <label>Client Name</label>

                <TextInput
                  type="text"
                  placeholder="Client Name"
                  value={searchUser}
                  onChange={(e) => handleSearchInputText(e.target.value)}
                  onRightClick={handleSearchClient}
                  right={
                    searchLoading ? (
                      <Loader loading={searchLoading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                    ) : (
                      <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                    )
                  }
                  style={{ padding: '20px 10px' }}
                  errorMessage={searchError}
                />
                {clientList.length > 0 && (
                  <ul style={styles.dropdownList}>
                    {clientList.map((client, index) => (
                      <li
                        key={client?.client_id}
                        onClick={() => handleSelectClient(client)}
                        style={{
                          ...styles.dropdownItem,
                          borderBottom: index !== clientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                        }}
                      >
                        {client?.client_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <Dropdown
                ref={(el) => (dropdownRefs.current["Services"] = el)}
                isOpen={openDropdown === "Services"}
                toggleDropdown={() => toggleDropdown("Services")}
                label="Services"
                options={serviceOptions || []}
                value={filterData?.services}
                onChange={(value) => handleInputChange('services', value)}
                loading={servicesLoading}
                errorMessage={servicesError}
              />

              {/* Generated Date Range */}
              <div >
                <label style={{ fontSize: '12px', color: '#343C44' }}>Generated Date</label>
                <DateInput
                  label="Generated Date"
                  value={filterData.generatedDate || ""}
                  onChange={(e) => handleInputChange("generatedDate", e.target.value)}
                // minWidth={140}
                // errorMessage={noticeBoardPublishedDateError}
                // max={new Date().toISOString().split("T")[0]}
                />
              </div>

              <Dropdown
                ref={(el) => (dropdownRefs.current["Generated By"] = el)}
                isOpen={openDropdown === "Generated By"}
                toggleDropdown={() => toggleDropdown("Generated By")}
                label="Generated By"
                options={recipientOptions || []}
                placeholder="------"
                value={filterData?.generatedBy}
                onChange={(value) => handleInputChange('generatedBy', value)}
                loading={userLoading}
                errorMessage={userError}
              />

            </div>
            <div>
              {filterError && <div className="list-error-msg" style={{ textAlign: 'left', paddingBottom: 5 }}> {filterError}</div>}

              {/* Filter Buttons */}
              <div className="tasksScreen-filter-buttons">
                <button
                  onClick={applyFilters}
                  className="tasksScreen-apply-button"
                  style={{ cursor: serviceListLoading ? 'not-allowed' : 'pointer', opacity: serviceListLoading ? 0.5 : 1 }} disabled={serviceListLoading}
                >
                  Apply
                </button>
                <button
                  onClick={cancelFilters}
                  className="tasksScreen-cancel-button"
                >
                  Cancel
                </button>
                <button
                  onClick={resetFilters}
                  className="tasksScreen-reset-button"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='basic-info-card' style={{ border: 'none', padding: '0px' }}>
        <div style={styles.tableWrapper}>
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Estimate No.</th>
                {/* <th style={{ width: "20%" }}>Estimate Type</th> */}
                <th style={{ width: "15%" }}>Client Name</th>
                {/* <th style={{ width: "20%" }}>Group Name</th> */}
                <th style={{ width: "20%" }}>Group Name</th>
                <th style={{ width: "25%" }}>Services/Category</th>
                <th style={{ width: "10%" }}>Generated Date</th>
                <th style={{ width: "10%" }}>Generated By</th>
                <th style={{ width: "10%" }}>Amount</th>
                <th style={{ width: "5%" }}></th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {currentTasks?.map((category, index) => (
                <tr key={index}>
                  <td onClick={() => handleEdit(category)} style={styles.tableLink}>{category?.estimate_id_gen}</td>
                  {/* <td style={styles.tableValue}>
                    {category?.estimate_for === 1 ? <span style={{ color: 'green' }}> Client /</span> : <span style={{ color: 'red' }}>Group /</span>} {category?.estimate_type === 1 ? <span > Without Task</span> : <span > With Task</span>}
                  </td> */}
                  <td style={styles.tableValue}>
                    {/* {category?.client_name || '--'} */}
                    {category?.estimate_for === 1 ? category?.client_name : category?.group_name}
                  </td>
                  {/* <td style={styles.tableValue}>{category?.group_name || '--'}</td> */}
                  <td style={styles.tableValue}>{category?.group_name_client || '--'}</td>
                  <td style={styles.tableValue}>
                    {category?.service_category_names || '--'}
                    {/* {category?.estimate_type === 1 ? <span style={{color:'green'}}> Service  /</span>:<span style={{color:'red'}}>Task /</span>} {category?.estimate_type === 1 ? category?.service_names:category?.group_name} */}

                  </td>
                  <td style={styles.tableValue}>{splitFormatDMY(category?.estimate_date)}</td>
                  <td style={styles.tableValue}>{category?.estimate_gen_emp_name || '--'}
                    {printEstimateError[category?.estimate_id] &&
                      <div style={{ color: 'red', paddingTop: 10 }} >{printEstimateError[category?.estimate_id]}</div>}
                            {/* {successMesg[category?.estimate_id] &&
                      <div style={{ color: 'green', paddingTop: 10 }} >{successMesg[category?.estimate_id]}</div>} */}
                  </td>
                  <td style={styles.tableValue}>{category?.task_bill_amount_estimated || '--'}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "2px", // Space between edit and delete buttons
                      }}
                    >

                      {/* Print Button */}
                      {deleteLoading[category?.estimate_id] ? (
                        <span style={{ marginLeft: '5px' }}>
                          <Loader loading={deleteLoading[category?.estimate_id]} color={'var(--primary-color)'} size="small" />
                        </span>
                      ) :
                        <button
                          className="tasksScreen-edit-button"
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: deleteLoading[category?.estimate_id] ? "not-allowed" : "pointer",
                            opacity: deleteLoading[category?.estimate_id] ? 0.5 : 1,
                          }}
                          disabled={deleteLoading[category?.estimate_id]}
                        >
                          <img
                            src={printIcon}
                            alt="Download"
                            style={{ width: "16px", height: "16px" }}
                            onClick={() => printEstimateDocument(category)}
                          />
                        </button>
                      }

                      {/* Edit Button */}
                      <button
                        className="tasksScreen-edit-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: deleteLoading[category?.estimate_id] ? "not-allowed" : "pointer",
                          opacity: deleteLoading[category?.estimate_id] ? 0.5 : 1,
                        }}
                        disabled={deleteLoading[category?.estimate_id]}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          style={{ width: "16px", height: "16px" }}
                          onClick={() => handleEdit(category)}
                        />
                      </button>

                      {/* Delete Button */}
                      <button
                        className="tasksScreen-delete-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: deleteLoading[category?.estimate_id] ? "not-allowed" : "pointer",
                          opacity: deleteLoading[category?.estimate_id] ? 0.5 : 1,
                        }}
                        disabled={deleteLoading[category?.estimate_id]}
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{ width: "16px", height: "16px" }}
                          onClick={() => confirmDeleteEstimate(category)}
                        />
                      </button>
                      {/* {deleteLoading[category?.estimate_id] &&
                          <span><Loader loading={deleteLoading[category?.estimate_id]} color={'var(--primary-color)'} size='small' /></span>
                        } */}
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
          {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
          {serviceListLoading && <Loader loading={serviceListLoading} color={'var(--primary-color)'} />}
          {serviceListError && <div className="list-error-msg">{serviceListError} </div>}
          {error && <div className="list-error-msg">{error} </div>}
        </div>

      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        message={`Are you sure you want to delete ${estimateInfoRef.current?.estimate_id_gen}?`}
        onClose={handleCloseDeleteModal}
        onConfirm={() => {
          handleCloseDeleteModal(); // Close modal
          handleDeleteEstimateFile(estimateInfoRef.current); // Perform delete operation
        }}
      />
      <hr className="divider" style={{ marginTop: '20px' }} />
      <div className="table-footer">
        <div className="total-count"></div>
        <div className="pagination">
          <div className="pagination-info">
            {`${currentPage}-${totalPages} of items`}
          </div>
          <button
            className="pagination-button-arrow"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src={leftArrowIcon} alt="Previous" className="tasksScreen-arrow-left" style={{ width: '17px', height: '17px' }} />
          </button>
          {visiblePages.map((page) => (
            <button
              key={page}
              className={`tasksScreen-pagination-button ${currentPage === page ? "active" : ""}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}
          <button
            className="pagination-button-arrow"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img src={leftArrowIcon} alt="Next" className="tasksScreen-arrow-right" style={{ width: '17px', height: '17px' }} />
          </button>
        </div>
      </div>
    </>

  )

};

const styles = {
  categoryHeader: {
    color: '#1F384C',
    fontSize: 18,
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    paddingBottom: 20
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    // marginBottom:50
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2',
    cursor: 'pointer'
  },
  container: {
    padding: "5px",
    backgroundColor: "#EFF0F4",
    border: "none",
    // borderRadius: "5px",
    border: '1px #EFF0F4 solid'
  },
  filtersHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "15px",
  },
  filterButton: {
    backgroundColor: "white",
    color: "#40513B",
    border: "none",
    borderRadius: "5px",
    padding: "5px 8px",
    cursor: "pointer",
    borderRadius: '20px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    border: '1px #40513B solid'

  },
  filterIcon: {
    width: '10px',
    height: '10px',
    paddingRight: '5px'
  },

  paginationButtons: {
    display: "flex",
    gap: "5px",
  },
  paginationButton: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  filtersContainer: {
    // display: "flex",
    flexWrap: "wrap", // Wrap inputs to the next line if needed
    gap: "20px", // Space between inputs
    marginBottom: "15px",
    // justifyContent: "space-between", // Distribute inputs evenly
    border: '1px #EFF0F4 solid',
    padding: '10px'
  },
  buttonsContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  applyButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  resetButton: {
    backgroundColor: "transparent",
    color: "#555",
    border: "none",
    cursor: "pointer",
  },
  labaleStyle: {
    fontSize: '12px',
    // marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },


}

export default ServiceEstimateScreen;