import react,{ useState, useEffect, useRef } from 'react';
import { getData,postData } from '../../../../ApiServices/ApiService';
import { ORG_GET_CLIENT_INFO, ORG_GET_CLIENT_CONTACT_LIST, ORG_DELETE_CLIENT_CONTACT,
  ORG_GET_GROUP_LIST, ORG_GET_CLIENT_TYPE_LIST,ORG_GET_ALL_CODES_LIST,ORG_UPDATE_CLIENT_INFO,ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER,ORG_CLIENT_AVAILED_SERVICES } from '../../../../ApiServices/BaseURL';
import { useLocation } from 'react-router-dom';

const PrimaryInformationController = (clientInfo) => {
    const [basicInformation, setBasicInformation] = useState({
        client_name: "",
        client_type_id: null,
        group_id: null,
        client_email: "",
        client_status: null,
        client_file_id: "",
        client_mobile_no: null,
        client_mobile_code: null,
        client_pan_no: null,
        client_gstin: null,
        client_aadhar_no: null,
        client_enrolled_on: null,
        client_address: null,
        client_state: null,
        client_city: null,
        client_pin: null,
        emp_id_incharge: null,
        emp_id_incharge_name: '',
        is_india_client: null,
        client_type_name: "",
        group_name: "",
        is_india_client:null,
        client_id:null,
        emp_id_incharge_name:''
      });
      const [basicInformationDisplay, setBasicInformationDisplay] = useState({
        client_name: "",
        client_type_id: null,
        group_id: null,
        client_email: "",
        client_status: null,
        client_file_id: "",
        client_mobile_no: null,
        client_mobile_code: null,
        client_pan_no: null,
        client_gstin: null,
        client_aadhar_no: null,
        client_enrolled_on: null,
        client_address: null,
        client_state: null,
        client_city: null,
        client_pin: null,
        emp_id_incharge: null,
        emp_id_incharge_name: '',
        is_india_client: null,
        client_type_name: "",
        group_name: "",
        is_india_client:null,
        client_id:null,
        emp_id_incharge_name:''
      });
      const location = useLocation();
      const {clientId} = location.state || '';
      //console.log('clientId:----------',clientId)
      const [clientContactList, setClientContactList] = useState([]);
      const [clientContactListLoading, setClientContactListLoading] =  useState(false);
      const [clientContactListError, setClientContactListError] = useState('');
      const contactInfoRef = useRef(null);
      const [deleteContactLoading, setDeleteContactLoading] = useState({});
      const [deleteContactError, setDeleteContactError] = useState({});
      const [deleteContactSuccess, setDeleteContactSuccess] = useState({});
      const clientTypeOptionsRef=useRef([]);
      const groupClientOptionsRef=useRef([]);
      const stateListRef=useRef([]);
      const [updateLoading,setUpdateLoading]=useState(false);
      const [updateStatusError,setUpdateStatusError]=useState('');
      const [updateStatusSuccess,setUpdateStatusSuccess]=useState('');
      const [clientAssignedUserError, setClientAssignedUserError] = useState('');
      const [searchUser, setSearchUser] = useState('');
      const [addEmployeeError, setAddEmployeeError] = useState('');
      const [employeeList, setEmployeeList] = useState([]);
      const [selectedEmployee, setSelectedEmployee] = useState(null);
      const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  
      const [fieldConfigs, setFieldConfigs] = useState([
        { label: "File No.", key: "client_file_id", type: "inputTypeNone" },
        { label: "Client Name", key: "client_name", type: "text",error:"" },
        { label: "Client Type", key: "client_type_name", type: "dropdown", options: clientTypeOptionsRef.current || [],error:""},
        { label: "Date of Enrollment", key: "client_enrolled_on", type: "date",error:"" },
        { label: "Group Name", key: "group_name", type: "dropdown", options: groupClientOptionsRef.current || [], style:'textDecoration',error:"" },
        { label: "Address", key: "client_address", type: "textarea",error:"" },
        { label: "City", key: "client_city", type: "text",error:"" },
        { label: "State", key: "client_state", type: "dropdown", options: stateListRef.current || [],error:"" },
        { label: "Pin Code", key: "client_pin", type: "text",inputType:'number',maxLength:6,error:"" },
        { label: "Mobile No", key: "client_mobile_no", type: "mobile",error:"" },
        { label: "GSTIN No.", key: "client_gstin", type: "text",error:"" },
        { label: "AADHAAR No. (UIDAI)", key: "client_aadhar_no", type: "text",error:"" },
        // { label: "Fix Assigned User", key: "fix_assigned_user", type: "text" },
        { label: "Client Assigned User", key: "emp_id_incharge_name", type: "search",error:clientAssignedUserError,error:"" },
        { label: "NRI", key: "is_india_client", type: "dropdown", options: ["Yes", "No"],error:"" },
        { label: "Email ID", key: "client_email", type: "inputTypeNone",error:"" },
      ]);
      

      const [clientInformation,setclientInformation]=useState({})

      const dropdownFields = {
        ClientType: ["Company", "Individual", "Partnership", "LLP"],
        GroupName: ["Group A", "Group B", "Group C"],
        City: ["Hyderabad", "Bangalore", "Chennai", "Delhi"],
        State: ["Telangana", "Andhra Pradesh", "Karnataka", "Tamil Nadu"],
        OutOfIndiaClient: ["Yes", "No"],
      };
  
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [infoLoading, setInfoLoading] = useState(false)
    const [error, setError] = useState('');

    useEffect(()=>{
        fetchClientInfo();
        fetchClientContacts();
        fetchClientTypesList();
        fetchGroupList();
        fetchStateList();
        fetchClientAvailedServices();
    },[location.state.clientId])

    const fetchClientInfo =async ()=>{
        try {
          clearData();
          setInfoLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_CLIENT_INFO}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    // setclientInformation(responseData?.dataJ[0]);
                    setBasicInformation(responseData?.dataJ[0]);
                    setBasicInformationDisplay(responseData?.dataJ[0]);
                    clientInfo(responseData?.dataJ[0]);
                    
                  } else {
                    setError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Task Categorie data.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
          } finally {
            setInfoLoading(false);
          }
      }

  const fetchClientContacts = async () => {
      try {
        setClientContactList([]);
        setClientContactListLoading(true);
        setClientContactListError('');
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      
          // Make the API call
          const response = await getData(`${ORG_GET_CLIENT_CONTACT_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
          const responseData = await response.json();
      
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                //console.log('responseData @@@', responseData)
                if (responseData?.dataJ?.length > 0) {
                  setClientContactList(responseData?.dataJ);
                  
                } else {
                  setClientContactListError(responseData?.info || 'Data Not Found');
                }
              } else {
                setClientContactListError(responseData?.info || 'Failed to Get Client Contact List. Please try again.');
              }
            } else {
              setClientContactListError(responseData?.info || 'Failed to Get Client Contact List.');
            }
          } 
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
              setClientContactListError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
              setClientContactListError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          } 
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setClientContactListError(responseData?.info || 'Internal Server Error. Please try again later.');
          } 
          // Unexpected or network-related errors
          else {
            setClientContactListError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setClientContactListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to Get Client Contact List. Please try again.'}`);
          } else if (error.request) {
            // Request was made but no response was received
            setClientContactListError('No response received from the server. Please check your network connection and try again.');
          } else {
            // An error occurred in setting up the request
            setClientContactListError(`Failed to Get Client Contact List. Please check your network connection and try again.`);
          }
        } finally {
          setClientContactListLoading(false);
        }
  }

  const handleAddContactClick = () => {
    contactInfoRef.current = null;
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchClientTypesList = async () => {
    try {
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_CLIENT_TYPE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              clientTypeOptionsRef.current = await responseData?.dataJ.map(service => ({
                label: service.client_type_name,
                value: service.client_type_id
              }));
              
              //console.log('type list',clientTypeOptionsRef.current)
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  }

  const fetchGroupList = async () => {
    try {
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              groupClientOptionsRef.current = await responseData?.dataJ.map(service => ({
                label: service.group_name,
                value: service.group_id
              }));
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  }

  const fetchStateList =async ()=>{
    try {
        setLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const payLoad = {"code_id": "states_list"}
        const response = await postData(ORG_GET_ALL_CODES_LIST,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ) {               
                      // Transforming the data
                  // Transforming the data
              stateListRef.current = await responseData?.dataJ.map(service => ({
                label: service.state_name,
                value: service.state_id
              }));                         
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        }
      } finally {
        setLoading(false);
      }
  }

  const handleFieldChange = (key, fieldData) => {
    setBasicInformation((prev) => {
      // If the key is "client_type_name", update label and related ID
      if (key === "client_type_name") {
        return {
          ...prev,
          [key]: fieldData.label, // Set the label in client_type_name
          client_type_id: fieldData.value, // Set the value in client_type_id
        };
      }
      if (key === "client_state") {
        return {
          ...prev,
          [key]: fieldData.label, // Set the label in client_type_name
          // client_status_id: fieldData.value, // Set the value in client_type_id
        };
      }
      if (key === "group_name") {
        return {
          ...prev,
          [key]: fieldData.label, // Set the label in client_type_name
          group_id: fieldData.value, // Set the value in client_type_id
        };
      }
      // For other keys, update normally
      return {
        ...prev,
        [key]: fieldData, // Update with the provided value
      };
    });
    if (key === 'emp_id_incharge_name') {
      console.log('handleFieldChange key',key);
      setEmployeeList([]); 
    }
  };
  

  const clearData = ()=>{
    setBasicInformation({
      client_name: "",
        client_type_id: null,
        group_id: null,
        client_email: "",
        client_status: null,
        client_file_id: "",
        client_mobile_no: null,
        client_mobile_code: null,
        client_pan_no: null,
        client_gstin: null,
        client_aadhar_no: null,
        client_enrolled_on: null,
        client_address: null,
        client_state: null,
        client_city: null,
        client_pin: null,
        emp_id_incharge: null,
        emp_id_incharge_name: '',
        is_india_client: null,
        client_type_name: "",
        group_name: "",
        is_india_client:null,
        client_id:null,
        emp_id_incharge_name:''
    })
  }

  const handleEditClientContact = (contact) => {
    contactInfoRef.current = contact;
    setIsModalOpen(true);
  }

  const onAddUpdateSuccess = () => {
    //console.log('onAddUpdateSuccess')
    fetchClientContacts();
  }

  const handleDeleteClientContact = (contact) => {
    // //console.log('handleDeleteTaskCategory service @', service);
    // showConfirmation(contact);
    contactInfoRef.current = contact;
    setIsDeleteModalOpen(true);
  }

  // const showConfirmation = (contact) => {
  //   const confirmed = window.confirm("Are you sure you want to delete?");
  //   if (confirmed) {
  //     callDeleteClientContact(contact);
  //   } else {
  //     //console.log("Delete canceled");
  //   }
  // }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
};

const [deletedMessages, setDeletedMessages] = useState([]);
  const callDeleteClientContact = async (contact) => {
    try {
      //console.log('callDeleteClientContact')
      setDeleteContactError({});
      setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: ''}));
      setDeleteContactLoading((prev) => ({ ...prev, [contact?.contact_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "contact_id" : contact?.contact_id
      }
  
      //console.log('callDeleteClientContact payload',payLoad);
  
      // Make the API call
      const response = await postData(ORG_DELETE_CLIENT_CONTACT,payLoad);
      const responseData = await response.json();
      //console.log('callDeleteClientContact response Data',responseData);
  
      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setDeletedMessages((prevDeleted) => [...prevDeleted, contact?.contact_id]);
            setDeleteContactSuccess((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info}));
            setTimeout(() => {
              setDeleteContactSuccess((prev) => ({ ...prev, [contact?.contact_id]: ''}));
              fetchClientContacts();
            }, 3000);
          } else {
            setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info || 'Failed to delete client contact. Please try again.'}));
          }
        } else {
          setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info || 'Failed to delete client contact. Please try again...'}));
        }
      } 
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: 'Unauthorized access. Your session may have expired. Please log in again.'}));
        } else {
          setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info || 'Bad request. Please check the request parameters.'}));
        }
      } 
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: responseData?.info || 'Internal Server Error. Please try again later.'}));
      } 
      // Unexpected or network-related errors
      else {
        setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: 'Unexpected error occurred. Please try again later.'}));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete client contact. Please try again.'}`}));
      } else if (error.request) {
        setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: 'No response received from the server. Please check your network connection and try again.'}));
      } else {
        setDeleteContactError((prev) => ({ ...prev, [contact?.contact_id]: `Failed to delete client contact. Please check your network connection and try again.` }));
      }
    } finally {
        setDeleteContactLoading((prev) => ({ ...prev, [contact?.contact_id]: false })); 
    }
  }


  const onUpdateClientInfo=async ()=>{
    //console.log('update');

  const updatedFieldConfigs = fieldConfigs.map((field) => ({
      ...field,
      error: "", // Reset errors initially for all fields
  }));
  
  let hasError = false;
  
  // Validate each field based on its key
  const validatedFields = updatedFieldConfigs.map((field) => {
      let error = "";
      const value = basicInformation?.[field.key]; // Ensure value is treated as a string for validation
  
      switch (field.key) {
          case "client_name":
          case "client_city":
          case "client_state":
          case "client_address":
              if (!value?.trim()) {
                  error = `${field.label} is required.`;
                  hasError = true;
              }
              break;
  
          case "client_mobile_no":
              if (!value || value.length !== 10 || !/^\d+$/.test(value)) {
                  error = `${field.label} must be a valid 10-digit number.`;
                  hasError = true;
              }
              break;
  
          case "client_pan_no":
              if (!value?.trim() || value.length !== 10) {
                  error = `${field.label} must be a valid PAN number.`;
                  hasError = true;
              }
              break;
          
          case "client_pin":
            if (!value?.trim() || value.length !== 6) {
                error = `${field.label} must be a valid pin code.`;
                hasError = true;
            }
            break;
  
          case "client_gstin":
              if (basicInformation?.client_type_name?.toLowerCase() !== 'individual' && (!value?.trim() || value.length !== 15)) {
                  error = `${field.label} must be a valid GSTIN.`;
                  hasError = true;
              }
              break;
  
          case "client_aadhar_no":
              if (!value?.trim() || value.length !== 12) {
                  error = `${field.label} must be a valid 12-digit Aadhaar number.`;
                  hasError = true;
              }
              break;
  
          case "client_type_id":
          case "group_id":
          case "emp_id_incharge":
              if (!value) {
                  error = `${field.label} must be selected.`;
                  hasError = true;
              }
              break;
  
          default:
              break;
      }
  
      return { ...field, error }; // Attach the error message to the field
  });
  
  // Update fieldConfigs with errors
  setFieldConfigs(validatedFields);
  
  // Prevent form submission if errors exist
  if (hasError) {
      return;
  }  

    setClientAssignedUserError('');
    setUpdateStatusError('');

    if(!basicInformation?.client_enrolled_on){
      setUpdateStatusError('Date of enrollment is required.');
      return;
    }

  if (!selectedEmployee?.emp_id && !basicInformation?.emp_id_incharge) {
    setUpdateStatusError('Client assigned user is required.');
    return;
  }

    try {
      setUpdateLoading(true);

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "client_id": basicInformation?.client_id,
        "client_type_id": basicInformation?.client_type_id,
        "client_name": basicInformation?.client_name,
        "client_email": basicInformation?.client_email,
        "client_file_id": basicInformation?.client_file_id,
        "client_mobile_no": basicInformation?.client_mobile_no,
        "client_mobile_code": basicInformation?.client_mobile_code,
        "client_pan_no": basicInformation?.client_pan_no,
        "client_gstin": basicInformation?.client_gstin,
        "client_aadhar_no": basicInformation?.client_aadhar_no,
        "client_address": basicInformation?.client_address,
        "client_state": basicInformation?.client_state,
        "client_city": basicInformation?.client_city,
        "client_pin": basicInformation?.client_pin,
        "emp_id_incharge": selectedEmployee?.emp_id || basicInformation?.emp_id_incharge,
        "client_enrolled_on": basicInformation?.client_enrolled_on,
        "client_status":basicInformation?.client_status,
        "group_id":basicInformation?.group_id
      }
      //console.log('team status:---',payLoad);
      const response = await postData(ORG_UPDATE_CLIENT_INFO,payLoad);
      const responseData = await response.json();
  
      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            //console.log("Team update success");
            setUpdateStatusSuccess(responseData?.info || 'Team update success.');
            setTimeout(() => {
              setUpdateStatusSuccess('')
            }, 3000);
          } else {
            setUpdateStatusError(responseData?.info || 'Failed to update team status. Please try again.');
          }
        } else {
          setUpdateStatusError(responseData?.info || 'Failed to update team status.');
        }
      } 
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setUpdateStatusError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setUpdateStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } 
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setUpdateStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
      } 
      // Unexpected or network-related errors
      else {
        setUpdateStatusError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setUpdateStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update team status. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setUpdateStatusError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setUpdateStatusError(`Failed to update team status. Please check your network connection and try again.`);
      }
    } finally {
      setUpdateLoading(false);
    }
  }

  const handleSearchEmployee = async () => {
   
    try {
        setError('');    
        if (!basicInformation?.emp_id_incharge_name) {
          setError('Please enter valid text'); return;
      }       
        setLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad = {  emp_id_auth: storedUserInfo?.emp_id,  search_type: "GENERIC", search_word: basicInformation?.emp_id_incharge_name}
    
        //console.log('handleSearchEmployee payload',payLoad);
        // Make the API call
        const response = await postData(ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER,payLoad);
        const responseData = await response.json();
        //console.log('response Data',responseData);
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
                setAddEmployeeError('')
                setEmployeeList(responseData?.dataJ || []);
            //   setError(responseData?.info);
              setTimeout(() => {
                // handleClose();
                // onValueChange();
              }, 3000);
            } else {
              setError(responseData?.info || 'Failed to fetch assigned user. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch assigned user.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch assigned user. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch assigned user. Please check your network connection and try again.`);
        }
      } finally {
        setLoading(false);
      }

    // onValueChange(teamData);
    // onClose();
};

const handleSelectedEmployee = (employee) => {
  setSelectedEmployee(employee); // Set selected client data
  setSearchUser(employee?.emp_name); // Update TextInput with the selected client's name
  handleFieldChange('emp_id_incharge_name',employee?.emp_name);
  setEmployeeList([]); // Clear the dropdown
};

const[availedServices, setAvailedServices] = useState([]);
const[availedServiceLoading, setAvailedServiceLoading] = useState(false);
const[availedServiceError, setAvailedServiceError] = useState('')

const fetchClientAvailedServices = async () => {
  //console.log('Fetching user credentials');
  try {
    setAvailedServices([]); // Reset credentials list
    setAvailedServiceLoading(true); // Start loading
    setAvailedServiceError(''); // Clear any existing error

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_CLIENT_AVAILED_SERVICES}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                      const availedServices = responseData?.dataJ || [];
                      setAvailedServices(availedServices)
                      
                  } else {
                    setAvailedServiceError(responseData?.info || 'Availed services not found.');
                  }
              } else {
                setAvailedServiceError(responseData?.info || 'Failed to fetch availed services.');
              }
          } else {
            setAvailedServiceError(responseData?.info || 'Failed to fetch availed services.');
          }
      }
      // Handle specific token-related errors
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setAvailedServiceError('Unauthorized access. Your session may have expired. Please log in again.');
          } else {
            setAvailedServiceError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
      }
      // Internal Server Error
      else if (response?.status === 500) {
        setAvailedServiceError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Handle unexpected errors
      else {
        setAvailedServiceError('Unexpected error occurred. Please try again later.');
      }
  } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        setAvailedServiceError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch availed services. Please try again.'}`);
      } else if (error.request) {
        setAvailedServiceError('No response received from the server. Please check your network connection and try again.');
      } else {
        setAvailedServiceError(`Failed to fetch credentials. Please check your network connection and try again.`);
      }
  } finally {
    setAvailedServiceLoading(false); // Stop loading
  }
};

const toggleService = (serviceId) => {
  const updatedServices = availedServices.map((service) =>
      service.service_id === serviceId
          ? { ...service, is_active: service.is_active === 1 ? 0 : 1 }
          : service
  );
  setAvailedServices(updatedServices);
};

    return {
        basicInformation,
        clientInformation,
        isModalOpen,
        clientContactList,
        dropdownFields,
        handleFieldChange,
        handleCloseModal,
        handleAddContactClick,
        fieldConfigs,
        loading,
        error,
        clientContactListLoading,
        clientContactListError,
        handleEditClientContact,
        handleDeleteClientContact,
        contactInfoRef,
        onAddUpdateSuccess,
        clientId,
        deleteContactLoading,
        deleteContactError,
        onUpdateClientInfo,
        updateLoading,
        updateStatusError,
        basicInformationDisplay,
        clientAssignedUserError,
        addEmployeeError,
        employeeList,
        searchUser,
        setSearchUser,
        handleSearchEmployee,
        handleSelectedEmployee,
        isDeleteModalOpen,
        handleCloseDeleteModal,
        callDeleteClientContact,
        availedServiceError,
        availedServiceLoading,
        availedServices,
        toggleService,
        infoLoading,
        deleteContactSuccess,
        updateStatusSuccess,
        deletedMessages
    };
};

export default PrimaryInformationController;
