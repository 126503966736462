import React, { useState, useRef, useEffect } from "react";
import closeIcon from "../../Assets/Icons/close.png";

const ViewServiceModal = ({ isOpen, onClose, selService }) => {
    const [subServiceList, setSubServiceList] = useState(selService?.service_sub_details || []);

    useEffect(() => {
        console.log('selService @@@', selService);
        if (selService?.service_sub_details) {
            setSubServiceList(selService?.service_sub_details);
        }
    }, [selService])

    const handleCheckboxChange = (subService, isChecked) => {
        //console.log('handleCheckboxChange subService @@@', subService, isChecked);
        const updatedList = subServiceList.map((item) =>
            item?.service_sub_id === subService?.service_sub_id ?
                { ...item, is_active: isChecked ? 1 : 0 } : item
        );

        setSubServiceList(updatedList);
    };

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);


    if (!isOpen) return null;

    const handleClose = () => {
        onClose();
    }

    const resetForm = () => {
        setSubServiceList([]);
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{ width: '664px' }}>
                <button className="close-button" onClick={() => { resetForm(); handleClose(); }}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">View {selService?.service_name}</h2>
                <hr className="title-divider" />

                <div style={{ marginBottom: "18px" }}>
                    <label style={styles.label}>Service</label>
                    <div style={styles.categoryValue}>{selService?.service_name}</div>
                </div>

                <div>
                    <label style={{ ...styles.label, color: '#000', fontSize: 14, paddingBottom: 5 }}>Categories</label>
                    {subServiceList?.length > 0 && <div style={styles.specializationsGrid}>
                        {subServiceList?.map((subService, index) => (
                            <div key={index} style={styles.specializationItem}>
                                <div style={{ ...styles.label, color: subService?.service_sub_status === 1 ? '#343C44' : '#928787' }}>{subService?.service_sub_name}</div>
                                {/* <CheckBox
                                    label={subService?.service_sub_name}
                                    checked={subService?.is_active === 1}
                                    onChange={(isChecked) =>
                                        handleCheckboxChange(subService, isChecked)
                                    }
                                    style={styles.checkbox}
                                /> */}
                            </div>
                        ))}
                    </div>
                    }
                    {subServiceList?.length === 0 &&
                        <div className="list-error-msg" style={{ paddingTop: 0 }}>No Categories Available</div>
                    }
                </div>

                <hr className="footer-divider" />

                <div className="button-container" style={{ alignItems: 'center' }}>
                    <button className="add-client-button" onClick={() => { resetForm(); handleClose(); }}>
                        Okay
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    label: {
        display: "block",
        fontWeight: "400",
        marginBottom: "10px",
        color: "#767676",
        fontSize: "12px",
    },
    categoryValue: {
        color: "#060606",
        fontSize: "14px",
        fontWeight: "400",
    },
    specializationsGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(143px, 1fr))",
        gap: "10px",
    },
    specializationItem: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        color: "#343C44",
    },
    checkbox: {
        marginRight: "10px",
        color: "#343C44",
    },
    labelName: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

export default ViewServiceModal;
