import { useState } from "react";

const InvoiceSettingsConfigurationsController = () => {
    const [serviceEstimateSeries, setServiceEstimateSeries] = useState("");
    const [invoiceSeries, setInvoiceSeries] = useState("");
    const [receiptSeriesType, setReceiptSeriesType] = useState("");
    const [receiptPrefix, setReceiptPrefix] = useState("");
    const [startDates, setStartDates] = useState({
        "Service Estimate": "",
        "Invoice": "",
        "Receipt": ""
    });
    const [endDates, setEndDates] = useState({
        "Service Estimate": "",
        "Invoice": "",
        "Receipt": ""
    });

    return {
        serviceEstimateSeries,
        setServiceEstimateSeries,
        invoiceSeries,
        setInvoiceSeries,
        receiptSeriesType,
        setReceiptSeriesType,
        receiptPrefix,
        setReceiptPrefix,
        startDates,
        setStartDates,
        endDates,
        setEndDates
    };
};

export default InvoiceSettingsConfigurationsController;
