import React, {useEffect,useState} from "react";
import { useTheme, GlobalC } from "../../../GlobalContext/GlobalContext";
import { useNavigate } from 'react-router-dom';
import logImg from '../../../Assets/Images/loginImg.png'
import logo from '../../../Assets/Images/logo.png'
import MFAScreenController from "../../Controllers/AuthenticationScreens/MFAScreenController";
import TextInput from "../../../Components/TextInput";
import Loader from "../../../Components/Loader";

const MFAScreen = () => {
    const { theme, setCompanyId } = useTheme();
    // console.log('theme:-', theme);
    const navigate = useNavigate();

    const {
        mfa,
        setMfa,
        handleMfaChange,
        loading,
        error,
        setError,
        verify,
        resendTimer,
    } = MFAScreenController();

      const [storedCompanyInfo,setStoredCompanyInfo] = useState({});

      useEffect(()=>{
            if(localStorage.getItem('companyInfo')){
                  const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));
                  setStoredCompanyInfo(companyInfo)
            }

      },[])
    return (
        <div className="login">
            <div className="frameParent">
                <div className="artboard113WfAutomationIllWrapper">
                    <img className="logimgstyle" alt="" src={logImg} />
                </div>
                <div className="frameGroup" style={{ gap: '10px' }}>
                    <div className="groupParent">
                        <img className="frameChild" alt="" src={storedCompanyInfo?.company_logo || logo} />
                        <div className="companyName">{storedCompanyInfo?.company_name || 'Company Name'} </div>
                    </div>
                    <div className="input">
                        <div className="xodimlar">Enter 6 digit code sent to your email ID to verify your identity</div>

                        <TextInput
                            placeholder="OTP"
                            value={mfa || ''}
                            onChange={(e) => handleMfaChange(e.target.value)}
                            style={{ minWidth: '360px' }}
                            maxLength={6}
                            searchBoxWidth={350}
                        />
                    </div>
                    <div> {error && <span style={{ textAlign: 'center', color: 'red' }}> {error} </span>} </div>
                    {/* <div className="button" onClick={login} >
                          <div className="label flexdisplay" ><Loader loading={loading} color={'#FFFFFF'} size='small' />  Login</div>
                    </div> */}
                    <button className="button" style={{ marginBottom: '5px', maxWidth: '350px', opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer', }} onClick={verify} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Verify</button>
                    {/* <button className="verifyButton" onClick={verify} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0,opacity: loading ? 1 : 0.5,cursor: loading ? 'not-allowed' : 'pointer', }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Verify</button> */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '125px' }}>
                        <div className="forgotPassword" style={{ whiteSpace: 'nowrap', cursor: resendTimer > 0 ? 'not-allowed' : 'pointer', opacity: resendTimer > 0 ? 0.5 : 1, }}>
                            Resend code in {resendTimer} second{resendTimer !== 1 ? 's' : ''}
                        </div>
                        <div className="forgotPassword" style={{ whiteSpace: 'nowrap' }}>Cancel Sign In</div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default MFAScreen;

