import React, { useState, useEffect, useRef } from 'react';
import DateInput from '../../../../../Components/DateInput';
import Dropdown from '../../../../../Components/Dropdown';
import TextInput from '../../../../../Components/TextInput';
import editImg from '../../../../../Assets/Icons/edit.png';
import deleteImg from '../../../../../Assets/Icons/delete.png';
import downArrow from '../../../../../Assets/Icons/chevron-down.png';
import filter from '../../../../../Assets/Icons/filter.png';
import search from '../../../../../Assets/Icons/search.png';
import AddDigitalSignatureModal from '../../../../../Components/Modals/AddDigitalSignatureModal';
import { useNavigate } from 'react-router-dom';
import DSCActiveCertificateController from '../../../../Controllers/AdminScreens/AddOns/DSCCenter/DSCActiveCertificateController';
import Loader from '../../../../../Components/Loader';
import DeleteConfirmationModal from '../../../../../Components/Modals/DeleteConformationModal';

const DSCActiveCertificate = ({ activeCertificatesList, activeCertificatesError, activeCertificatesLoading, onRefresh, setActiveCertificatesList,setActiveCertificatesLoading,setActiveCertificatesError }) => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const showFilter = () => {
        setShowFilters(!showFilters);
    }
    const{
        handlePageChange,
        currentDSCActiveCertificates,
        currentPage,
        totalPages,

        handleCloseDeleteModal,
        handleOpenDeleteModal,
        handleDelete,
        isDeleteModalOpen,
        selectedDocument,
        setSelectedDocument,

        deletedMessages,
        currentActiveCertificateDeleteIndex,
        success,
        activeCertificateDeleteError,
        loading,
        handleDSCActiveCertificateDelete,

        clientSearchError,
        employeeList,
        error,
        employeesListLoading,
        handleSelectedEmployee,
        handleSearchInputChange,
        searchUser,
        handleSearchEmployee,
        setAddedOn,
        addedOn,
        setValidFrom,
        validFrom,
        setStatus,
        status,
        fetchDSCActiveCertificatesFilterList,
        dscFilterError,
        resetFilter
    } = DSCActiveCertificateController(activeCertificatesList, activeCertificatesError, activeCertificatesLoading, onRefresh, setActiveCertificatesList,setActiveCertificatesLoading,setActiveCertificatesError,showFilter);

    const addNewDigitalSignature = () => {
        setModalVisible(true);
        setSelectedDSCCertificate(null);
    }

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const statusOptions = [
        { label: "Downloaded", value: 1 },
        { label: "Pending", value: 2 },
        // { label: "Rejected", value: "rejected" },
    ];

    const toggleFilters = () => {
        onRefresh();
        setShowFilters(!showFilters);
        resetFilter()
    };

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const formatDate = (dateString) => {
        if (!dateString || dateString === "--" || typeof dateString !== "string" || dateString.trim() === "") {
            return "--";
        }
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
    };

    const [selectedDSCCertificate, setSelectedDSCCertificate] = useState(null);
    const handleEditDocument = (activeCertificate) => {
        setSelectedDSCCertificate(activeCertificate);
        setModalVisible(true);
    };

    const maxVisiblePages = 3;

    const getVisiblePages = () => {
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
    };

    const visiblePages = getVisiblePages();

    return (
        <>
            <div className="tasksScreen-tasks-header">
                <h2 style={{ color: '#1F384C', fontSize: '18px', fontWeight: '500', lineHeight: '19.08px', wordWrap: 'break-word' }}>Digital Certificates</h2>
                <button className="tasksScreen-apply-button" style={{ marginBottom: '10px' }} onClick={addNewDigitalSignature}>Add New Digital Signature</button>
            </div>

            <div className="tasksScreen-filters-row" style={{ position: 'relative' }}>
                <div className="tasksScreen-filters-button-wrapper">
                    <button
                        className="tasksScreen-filters-button"
                        onClick={showFilter}
                        style={{padding:'8px 12px'}}
                    >
                        <img src={filter} alt="Filter" />
                        Filters
                    </button>
                </div>
                {showFilters && (
                    <div className="tasksScreen-filters-container-overlay">
                        <div
                            className="tasksScreen-filters-flex"
                            style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}
                        >
                            {/* Client Name Input */}
                            {/* <div> */}
                                <div className="filter-field" style={{ flex: '1 1 20%' }}>
                                    <label style={{ fontSize: '12px', color: '#343C44' }}>Client Name</label>
                                    <TextInput
                                        type="text"
                                        placeholder="Client Name"
                                        value={searchUser}
                                        onChange={(e) => handleSearchInputChange(e.target.value)}
                                        onRightClick={handleSearchEmployee}
                                        width={370}
                                        right={
                                            employeesListLoading ? (
                                                <Loader loading={employeesListLoading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                                            ) : (
                                                <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                                            )
                                        }
                                        style={{ padding: '20px 10px' }}
                                        errorMessage={error || clientSearchError}
                                    />
                                    {employeeList?.length > 0 && (
                                        <ul style={styles.dropdownList}>
                                            {employeeList?.map((employee, index) => (
                                                <li
                                                    key={employee?.client_id}
                                                    onClick={() => handleSelectedEmployee(employee)}
                                                    style={{
                                                        ...styles.dropdownItem,
                                                        borderBottom: index !== employeeList?.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                                    }}
                                                >
                                                    {employee?.client_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            {/* </div> */}

                            {/* Status Dropdown */}
                            <div className="filter-field" style={{ flex: '1 1 20%',marginTop:'3px' }}>
                            <Dropdown
                                ref={(el) => (dropdownRefs.current["status"] = el)}
                                isOpen={openDropdown === "status"}
                                toggleDropdown={() => toggleDropdown("status")}
                                label="Status"
                                options={statusOptions}
                                // value={statusOptions.find(option => option.value === status)?.label || "Select Status"}
                                value={statusOptions.find((option) => option.value === status)}
                                onChange={(selectedOption) => setStatus(selectedOption.value)}
                            />
                            </div>

                            {/* Valid From Date Picker */}
                            <div className="filter-field" style={{ flex: '1 1 20%' }}>
                                <label style={{ fontSize: '12px', color: '#343C44' }}>Valid From</label>
                                <DateInput
                                    value={validFrom}
                                    onChange={(date) => setValidFrom(date.target.value)}
                                />
                            </div>

                            {/* Added On Date Picker */}
                            <div className="filter-field" style={{ flex: '1 1 20%' }}>
                                <label style={{ fontSize: '12px', color: '#343C44' }}>Added On</label>
                                <DateInput
                                    value={addedOn}
                                    onChange={(date) => setAddedOn(date.target.value)}
                                    // errorMessage={validToError}
                                    // min={new Date().toISOString().split("T")[0]}
                                />
                            </div>
                        </div>
                        {dscFilterError && <div style={{fontSize:'12px', color:'red', marginTop:'5px'}}>{dscFilterError} </div>}
                        {/* Filter Buttons */}
                        <div className="tasksScreen-filter-buttons">
                            <button
                                onClick={fetchDSCActiveCertificatesFilterList}
                                className="tasksScreen-apply-button"
                                style={{
                                    cursor: activeCertificatesLoading ? "not-allowed" : "pointer",
                                    opacity: activeCertificatesLoading ? 0.6 : 1,
                                    pointerEvents:activeCertificatesLoading? 'none' :'auto',
                                    gap: '5px',
                                    display:'flex'
                                }} disabled={activeCertificatesLoading}
                            >
                                {activeCertificatesLoading && (
                            <Loader loading={activeCertificatesLoading} color={'var(--background-color)'} size="small" />
                        )}
                                <span>Apply</span>
                            </button>
                            <button
                                onClick={toggleFilters}
                                className="tasksScreen-cancel-button"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={resetFilter}
                                className="tasksScreen-reset-button"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                )}
            </div>


            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>

                        {/* Table Headers */}
                        <tr>
                            <th>Client Name</th>
                            <th>PAN No.</th>
                            <th>Physical Token No.</th>
                            <th>Organisation Name</th>
                            <th>Status</th>
                            <th>Valid From</th>
                            <th>Valid To</th>
                            <th>Added By</th>
                            <th>Added On</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff' }}>
                        {currentDSCActiveCertificates?.map((activeCertificate, index) => (
                            <React.Fragment key={index}>
                                <tr className={`tasksScreen-main-task-row `}>
                                    <td>{activeCertificate?.client_name || '--'}</td>
                                    <td>{activeCertificate?.pan_no || '--'}</td>
                                    <td style={{ cursor: 'pointer', textDecoration: activeCertificate?.physical_token_no ? 'underline' : 'none', color: '#5a55d2' }}>{activeCertificate?.physical_token_no || '--'}</td>
                                    <td>{activeCertificate?.org_name || '--'}</td>
                                    <td>{activeCertificate?.dsc_status ===1 ? 'Downloaded' : 'Pending' || '--'}</td>
                                    <td>{formatDate(activeCertificate?.valid_from || '--')}</td>
                                    <td>{formatDate(activeCertificate?.valid_to || '--')}</td>
                                    <td>{activeCertificate?.added_by || '--'}</td>
                                    <td>{formatDate(activeCertificate?.added_on || '--')}
                                    {activeCertificateDeleteError && currentActiveCertificateDeleteIndex=== index &&
                                            <div style={{ color: 'red', textDecoration: 'none' }}>{activeCertificateDeleteError}</div>}
                                        {success && currentActiveCertificateDeleteIndex=== index &&
                                            <div style={{ color: 'green', textDecoration: 'none' }}>{success}</div>}
                                    </td>
                                    <td>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px",justifyContent: "end", }}>
                                        <button className="tasksScreen-edit-button" onClick={() => handleEditDocument(activeCertificate)} 
                                            style={{
                                                opacity: (loading && currentActiveCertificateDeleteIndex === index) || deletedMessages.includes(activeCertificate.dsc_id) ? 0.5 : 1,
                                                cursor: (loading && currentActiveCertificateDeleteIndex === index) || deletedMessages.includes(activeCertificate.dsc_id) ? 'not-allowed' : 'pointer',
                                                pointerEvents: (loading && currentActiveCertificateDeleteIndex === index) || deletedMessages.includes(activeCertificate.dsc_id) ? 'none' : 'auto',
                                            }}>
                                            <img src={editImg} alt="Edit" />
                                        </button>
                                        <button className="tasksScreen-delete-button" onClick={() => { handleDelete(activeCertificate, index) }}
                                             style={{
                                                opacity: (loading && currentActiveCertificateDeleteIndex === index) || deletedMessages.includes(activeCertificate.dsc_id) ? 0.5 : 1,
                                                cursor: (loading && currentActiveCertificateDeleteIndex === index) || deletedMessages.includes(activeCertificate.dsc_id) ? 'not-allowed' : 'pointer',
                                                pointerEvents: (loading && currentActiveCertificateDeleteIndex === index) || deletedMessages.includes(activeCertificate.dsc_id) ? 'none' : 'auto',
                                            }}>
                                            <img src={deleteImg} alt="Delete" />
                                        </button>
                                        {loading && currentActiveCertificateDeleteIndex === index && (
                                                <span style={{ marginLeft: '5px' }}>
                                                    <Loader loading={loading} color={'var(--primary-color)'} size="small" />
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                {activeCertificatesError && <div className="list-error-msg">{activeCertificatesError} </div>}
                { activeCertificatesLoading&& <Loader loading={activeCertificatesLoading} color={'var(--primary-color)'} />}
            </div>

            <div className="tasksScreen-table-footer">
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || totalPages === 0}>
                        <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                    </button>
                    {/* {[...Array(totalPages)].map((_, index) => (
                        <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    ))} */}
                    {/* Page Numbers */}
                    {visiblePages?.map((page) => (
                        <button
                            key={page}
                            className={`tasksScreen-pagination-button ${currentPage === page ? "active" : ""}`}
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </button>
                    ))}
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || totalPages === 0}>
                        <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                    </button>
                </div>
                <AddDigitalSignatureModal isOpen={modalVisible} onClose={handleCloseModal} onValueChange={onRefresh} documentData={selectedDSCCertificate}/>
                <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    message={`Are you sure you want to delete ${selectedDocument?.activeCertificate?.client_name} digital signature?`}
                    onClose={handleCloseDeleteModal}
                    onConfirm={() => {
                    handleCloseDeleteModal();
                    handleDSCActiveCertificateDelete();
                    }}
                />
            </div>
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    },
    dropdownList: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
        padding: 0, // Remove default padding
        margin: 0, // Remove default margin
        listStyleType: 'none', // Remove the dots
    },

    dropdownItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee', // Default for all items
        backgroundColor: '#fff',
        hover: {
            backgroundColor: '#f0f0f0',
        },
    },
};

export default DSCActiveCertificate;
