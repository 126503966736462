import { useState, useEffect } from "react";

const InvoiceServiceEstimateSeriesController = () => {
    const [seriesHistory, setSeriesHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    // Simulating API Call with Dummy Data
    useEffect(() => {
        fetchSeriesHistory();
    }, []);

    const fetchSeriesHistory = async () => {
        try {
            setLoading(true);
            setError("");

            // Dummy Data
            const dummyData = [
                {
                    seriesType: "Automatic",
                    startDate: "01/04/2024",
                    endDate: "31/03/2025",
                    addedBy: "John Doe",
                },
                {
                    seriesType: "Manual",
                    startDate: "01/04/2024",
                    endDate: "31/03/2025",
                    addedBy: "John Doe",
                },
                {
                    seriesType: "Prefix (DLS24-)",
                    startDate: "01/04/2024",
                    endDate: "31/03/2025",
                    addedBy: "John Doe",
                },
                {
                    seriesType: "Prefix (DLS24-)",
                    startDate: "01/04/2024",
                    endDate: "31/03/2025",
                    addedBy: "John Doe",
                },
                {
                    seriesType: "Prefix (DLS24-)",
                    startDate: "01/04/2024",
                    endDate: "31/03/2025",
                    addedBy: "John Doe",
                }
            ];

            // Simulating a network delay
            setTimeout(() => {
                setSeriesHistory(dummyData);
                setLoading(false);
            }, 1000);

        } catch (error) {
            setError("Failed to load data. Please try again later.");
            setLoading(false);
        }
    };

    const handleEdit = (item) => {
        console.log("Edit item:", item);
        // Implement edit logic here
    };

    const handleDelete = (item) => {
        console.log("Delete item:", item);
        // Implement delete logic here
    };

    return {
        seriesHistory,
        loading,
        error,
        handleEdit,
        handleDelete,
    };
};

export default InvoiceServiceEstimateSeriesController;
