
import React, { useState, useRef, useEffect } from 'react';
import {
  ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_GET_SERVICE_LIST_SEARCH, ORG_ESTIMATE_SEARCH,
  ORG_DELETE_ESTIMATE, ORG_GET_EMPLOYEE_LIST, ORG_DOWNLOAD_ESTIMATE_TASK
} from '../../../../ApiServices/BaseURL';
import { getData, postData } from '../../../../ApiServices/ApiService';


const ServiceEstimateController = (serviceEstimateList, error, onRefresh) => {

  const [serviceEstimates, setServiceEstimates] = useState([]);
  const [serviceListLoading, setServiceListLoading] = useState(false);
  const [serviceListError, setServiceListError] = useState('');
  const [clientList, setClientList] = useState([]);
  const [searchUser, setSearchUser] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [serviceOptions, setServiceOptions] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(false);
  const [servicesError, setServicesError] = useState('');
  const [filterError, setFilterError] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  const [userError, setUserError] = useState('');
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [printEstimateLoading, setPrintEstimateLoading] = useState(false);
  const [currentDocIndex, setCurrentDocIndex] = useState(null);
  const [printEstimateError, setPrintEstimateError] = useState({});
  const [deleteLoading, setDeleteLoading] = useState({});
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [deleteError, setDeleteError] = useState({});
  const estimateInfoRef = useRef(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [successMesg, setSuccessMesg] = useState({});


  const ItemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(serviceEstimates.length / ItemsPerPage);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * ItemsPerPage;
  const indexOfFirstItem = indexOfLastItem - ItemsPerPage;

  // Slice the data for the current page
  const currentTasks = serviceEstimates.slice(indexOfFirstItem, indexOfLastItem);

  const [filterData, setFilterData] = useState({
    clientName: null,
    services: null,
    generatedDate: "",
    generatedBy: null,
  });

  const handleInputChange = (field, value) => {
    setFilterData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    setServiceEstimates(serviceEstimateList);
  }, [serviceEstimateList]);

  useEffect(() => {
    fetchServices();
    fetchUserList();
  }, []);

  const toggleFilters = () => {
    setShowFilters((prev) => !prev); // Toggle the filter visibility
  };

  const applyFilters = () => {
    fetchServiceEstimatesList();
  };

  const resetFilters = () => {
    if (filterData?.clientName || filterData?.services || filterData?.generatedDate || filterData?.generatedBy) {
      onRefresh();
      setCurrentPage(1);
    }
    setFilterError('');
    setFilterData({
      clientName: null,
      services: null,
      generatedDate: "",
      generatedBy: null,
    });
    setClientList([]);
    setSearchUser('');
    setSearchError('');
    setServiceListError('');
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
  };

  const cancelFilters = () => {
    resetFilters();
    setShowFilters(!showFilters);
  }

  const handleSearchInputText = (value) => {
    setSearchUser(value);
    setSearchError('');
    setClientList([]);
    if (!value) {
      setFilterData((prevData) => ({
        ...prevData,
        clientName: null,
      }));
    }
  };

  const handleSelectClient = (client) => {
    handleInputChange('clientName', client);
    setSearchUser(client?.client_name);
    setClientList([]);
  };

  const handleSearchClient = async () => {
    try {
      setSearchError('');
      setSearchLoading(true);
      setClientList([]);
      if (!searchUser) {
        setSearchError('Please enter valid text'); return;
      }
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchUser }

      //console.log('team Data', payLoad);
      // Make the API call
      const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setClientList(responseData?.dataJ || []);
          } else {
            setSearchError(responseData?.info || 'Failed to fetch client. Please try again.');
          }
        } else {
          setSearchError(responseData?.info || 'Failed to fetch client. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setSearchError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setSearchError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setSearchError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setSearchError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setSearchError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch client. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setSearchError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setSearchError(`Failed to fetch client. Please check your network connection and try again.`);
      }
    } finally {
      setSearchLoading(false);
    }
  };

  const fetchServices = async () => {
    setServicesLoading(true);
    setServiceOptions([]);
    setServicesError('');
    try {
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_')); // Get user info from localStorage
      const payload = {
        emp_id_auth: storedUserInfo?.emp_id,
      };

      // API call to fetch GST services
      const response = await postData(ORG_GET_SERVICE_LIST_SEARCH, payload);
      const responseData = await response.json();

      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message?.toLowerCase() === "success") {
            const services = responseData?.dataJ.map((service) => ({
              label: service.service_name,
              value: service.service_id,
            }));
            setServiceOptions(services);
          } else {
            setServicesError(responseData?.info || 'Service search failed.');
          }
        } else {
          setServicesError(responseData?.info || 'Unexpected response code from the server.');
        }
      }
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setServicesError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setServicesError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setServicesError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setServicesError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setServicesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to get service options. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setServicesError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setServicesError(`Failed to search service options. Please check your network connection and try again.`);
      }
    } finally {
      setServicesLoading(false);
    }
  };

  const fetchUserList = async () => {
    try {
      setUserLoading(true);
      setUserError('');

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const formattedTeams = responseData.dataJ.map(service => ({
                label: service.emp_name,
                value: service.emp_id
              }));
              setRecipientOptions(formattedTeams);
            } else {
              setUserError(responseData?.info || 'Data Not Found');
            }
          } else {
            setUserError(responseData?.info || 'Failed to fetch User data. Please try again.');
          }
        } else {
          setUserError(responseData?.info || 'Failed to fetch User data. Status code error.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setUserError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setUserError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setUserError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setUserError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setUserError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setUserError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setUserError(`Failed to fetch User data. Please check your network connection and try again.`);
      }
    } finally {
      setUserLoading(false);
    }
  }

  const fetchServiceEstimatesList = async () => {
    try {
      setFilterError('');
      if (!(filterData?.clientName || filterData?.services || filterData?.generatedDate || filterData?.generatedBy)) {
        setFilterError('Please enter/select any one of the above');
        return;
      }
      setServiceListLoading(true);
      setServiceListError('');

      setServiceEstimates([]);
      setCurrentPage(1);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "client_id": filterData?.clientName?.client_id,
        "service_id": filterData?.services?.value,
        "estimate_date": filterData?.generatedDate,
        "estimate_gen_emp_id": filterData?.generatedBy?.value,
      }

      console.log('---team status:---', payLoad);

      const response = await postData(ORG_ESTIMATE_SEARCH, payLoad);
      const responseData = await response.json();
      //console.log('---team responseData:---',responseData);

      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // teamListRef.current = responseData?.dataJ;
              setServiceEstimates(responseData?.dataJ);
            } else {
              setServiceListError(responseData?.info || 'No Teams Available');
            }
          } else {
            setServiceListError(responseData?.info || 'Failed to fetch service estimate. Please try again.');
          }
        } else {
          setServiceListError(responseData?.info || 'Failed to fetch service estimate. Status code error.');
        }
      }
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setServiceListError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setServiceListError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      else if (response?.status === 500) {
        setServiceListError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      else {
        setServiceListError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setServiceListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch service estimate. Please try again.'}`);
      } else if (error.request) {
        setServiceListError('No response received from the server. Please check your network connection and try again.');
      } else {
        setServiceListError(`Failed to fetch service estimate. Please check your network connection and try again.`);
      }
    } finally {
      setServiceListLoading(false);
    }
  }

  const printEstimateDocument = async (estimateInfo) => {
    try {
      // setPrintEstimateLoading(true);
      setDeleteLoading((prev) => ({ ...prev, [estimateInfo?.estimate_id]: true }));
      setPrintEstimateError({});
      setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: '' }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_DOWNLOAD_ESTIMATE_TASK}?emp_id_auth=${storedUserInfo?.emp_id}&estimate_id=${estimateInfo?.estimate_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const downloadUrl = responseData?.dataJ?.[0]?.download_url;
              if (downloadUrl) {
                try {
                  // Fetch the file as a blob
                  const fileResponse = await fetch(downloadUrl);
                  const blob = await fileResponse.blob();

                  const url = URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));

                  let iframe = document.createElement("iframe");
                  iframe.style.position = "absolute";
                  iframe.style.width = "0px";
                  iframe.style.height = "0px";
                  iframe.style.border = "none";
                  document.body.appendChild(iframe);

                  // Load the PDF into the iframe and trigger print
                  iframe.src = url;
                  iframe.onload = () => {
                    iframe.contentWindow.focus();
                    iframe.contentWindow.print();
                  };

                } catch (error) {
                  console.error('Error downloading file:', error);
                  setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: 'An error occurred while attempting to download the file. Please try again.' }));
                }
              } else {
                setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: 'Download URL not available.' }));
              }
            } else {
              setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || 'Data Not Found' }));
            }
          } else {
            console.log(responseData?.info || 'Failed to fetch document. Please try again.');
            setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || 'Failed to fetch document. Please try again.' }));
          }
        } else {
          setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || 'Failed to fetch document. Status code error.' }));
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      // Unexpected or network-related errors
      else {
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch document. Please try again.'}` }));
      } else if (error.request) {
        // Request was made but no response was received
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        // An error occurred in setting up the request
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: `Failed to fetch document. Please check your network connection and try again.` }));
      }
    } finally {
      // setPrintEstimateLoading(false);
      setDeleteLoading((prev) => ({ ...prev, [estimateInfo?.estimate_id]: false }));
    }
  }
  const confirmDeleteEstimate = (estimate) => {
    estimateInfoRef.current = estimate;
    setIsDeleteModalOpen(true);
  }

  const handleDeleteEstimateFile = async (estimateInfo) => {
    try {
      setDeleteLoading((prev) => ({ ...prev, [estimateInfo?.estimate_id]: true }));
      setPrintEstimateError({});
      setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: '' }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "estimate_id": estimateInfo?.estimate_id
      }

      // Make the API call
      const response = await postData(ORG_DELETE_ESTIMATE, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSuccessMesg((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || '' }));
            setTimeout(() => {
              setSuccessMesg((prev) => ({ ...prev, [estimateInfo?.estimate_id]: '' }));
              onRefresh();
            }, 3000);

          } else {
            console.log(responseData?.info || 'Failed to delete Estimate. Please try again.');
            setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || 'Failed to delete Estimate. Please try again.' }));
          }
        } else {
          setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || 'Failed to delete Estimate. Status code error.' }));
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      // Unexpected or network-related errors
      else {
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete Estimate. Please try again.'}` }));
      } else if (error.request) {
        // Request was made but no response was received
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        // An error occurred in setting up the request
        setPrintEstimateError((prev) => ({ ...prev, [estimateInfo?.estimate_id]: `Failed to delete Estimate. Please check your network connection and try again.` }));
      }
    } finally {
      setDeleteLoading((prev) => ({ ...prev, [estimateInfo?.estimate_id]: false }));
    }
  }

  const maxVisiblePages = 3;

  const getVisiblePages = () => {
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust startPage if we're near the end
    const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

    return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
  };

  const visiblePages = getVisiblePages();


  return {
    serviceEstimates,
    handlePageChange,
    currentTasks,
    totalPages,
    currentPage,
    setFilterData,
    filterData,
    handleInputChange,
    handleSearchClient,
    handleSearchInputText,
    searchLoading,
    searchError,
    clientList,
    searchUser,
    handleSelectClient,
    servicesError,
    servicesLoading,
    serviceOptions,
    serviceListError,
    serviceListLoading,
    applyFilters,
    cancelFilters,
    filterError,
    recipientOptions,
    userLoading,
    userError,
    showFilters,
    toggleFilters,
    resetFilters,
    printEstimateLoading,
    currentDocIndex,
    printEstimateDocument,
    printEstimateError,
    visiblePages, isDeleteModalOpen, handleCloseDeleteModal,
    handleDeleteEstimateFile, estimateInfoRef, confirmDeleteEstimate,deleteLoading

  }
}

export default ServiceEstimateController