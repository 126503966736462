import React, { useState, useRef, useEffect } from "react";
import Input from "../../../../Components/Input"; // Import Input Component
import Dropdown from "../../../../Components/Dropdown"; // Import Dropdown Component
import CheckBox from "../../../../Components/CheckBox";
import MobileInput from "../../../../Components/MobileInput";
import closeIcon from '../../../../Assets/Icons/X circle.png'
import DateInput from '../../../../Components/DateInput';
import backIcon from '../../../../Assets/Icons/back.png';
import search from '../../../../Assets/Icons/search.png';
import TextInput from '../../../../Components/TextInput';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import GenerateInvoiceController from "../../../Controllers/AdminScreens/Invoices/GenerateInvoiceController";
import Loader from "../../../../Components/Loader";
import LoadingSkeleton from "../../../../Components/LoadingSkeleton";
import downIcon from '../../../../Assets/Icons/chevron-down.png';
import circleTick from "../../../../Assets/Icons/circle-tick.png";
import circleIcon from "../../../../Assets/Icons/circle.png";
import DeleteConfirmationModal from "../../../../Components/Modals/DeleteConformationModal";

const GenerateInvoiceScreen = () => {


  const {
    basicInformation, rowData,
    setBasicInformation,
    handleBasicInformationChange,
    invoiceTableData,
    invoiceDetails,
    setInvoiceDetails,
    handleInvoiceDetailsChange,
    sendUpdateToClient,
    setSendUpdateToClient, handleBack,
    sendUpdateToTeam, getStatusStyle, selectAll, checkedStates,
    setSendUpdateToTeam, handleCheckboxChange, handleSelectAll,
    handleShowIsClient, isShowGroupWise,
    handleSelectClient,
    handleSearchInputText,
    handleSearchClient,
    searchUser,
    selectedClientError,
    clientList,
    loading,
    groupClientOptionsList,
    groupLoading,
    groupClientError, selectedClient,
    groupClient, showGroupInvoiceList, handleSetGroupClient,
    groupTaskListLoading, groupTaskList, groupTaskListError, dateError,
    taskListLoading, taskList, taskListError, selectedClientsTasks,
    handleClientSelectAll, handleTaskCheckboxChange, toggleClient, expandedClients,
    employeeList, empLoading, empError, invoiceWithoutTask,
    handleSelectServiceOrTask, removeServiceRow, addServiceRow, rows, setRows,
    fetchServiceCategoryList, serviceCategoryOptions, serviceOptions,
    serviceCategoryError, serviceCategoryLoading, serviceError, serviceLoading,
    serviceSelectError, submitLoading, submitError, submitSuccess, handleSubmitInvoice, filedErrors,
    handleGenerateReceipt, invoiceLoading, invoiceError,groupInfo, handleUpdateInvoice,
    isGenReceiptModalOpen, handleCloseGenReceiptModal, handleGenReceiptConfirmModal
  } = GenerateInvoiceController();



  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ width: '93%', margin: 'auto' }}>
        <h3 style={styles.header}>
          <div style={styles.headerContainer} >
            <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleBack}
            />
            <span>Generate Invoice</span>
          </div>
        </h3>
      </div>

      <div style={{ position: 'relative' }}>
        <div style={styles.container}>
          <div className="basic-info-row" style={{ marginBottom: 20 }}>
            <h2 style={styles.title}>{rowData ? `Invoice - ${rowData?.invoice_id_gen}` : "Generate Invoice"}</h2>
            {invoiceError && <div className="list-error-msg"> {invoiceError} </div>}
          </div>

          {/* Basic Information */}
          <div style={styles.section}>
            <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Basic Information</h3>
            <div className="basic-info-row" style={styles.rowBorder}>
              {/* {!rowData && */}
              <div style={styles.containerdiv}>
                <label style={styles.label}>Invoice for</label>
                <div style={styles.optionsContainer}>
                  <label style={styles.optionLabel}>
                    <input
                      type="radio"
                      value="Client"
                      checked={isShowGroupWise === 'Client'}
                      onChange={() => handleShowIsClient('Client')}
                      style={styles.radio}
                      disabled={rowData}
                    />
                    <span >Client</span>
                  </label>
                  <label style={styles.optionLabel}>
                    <input
                      type="radio"
                      value="Group"
                      checked={isShowGroupWise === 'Group'}
                      onChange={() => handleShowIsClient('Group')}
                      style={styles.radio}
                      disabled={rowData}
                    />
                    <span>Group</span>
                  </label>
                </div>
              </div>

              {rowData ? <>
                {isShowGroupWise === 'Client' ?
                  <Input
                  label="Client Name"
                  placeholder="--"
                  value={searchUser}
                  isEdit={false}
                  searchBoxWidth={220}
                />:
                <Input
                  label="Group Name"
                  placeholder="--"
                  value={groupClient?.label || ''}
                  isEdit={false}
                  searchBoxWidth={220}
                />}
              </>:
              <>
              {isShowGroupWise === 'Client' &&
                <div className="filter-field">
                  <label
                    style={{
                      ...styles.labaleStyle,
                      display: 'block',
                    }}
                  >
                    Client Name
                  </label>
                  <TextInput
                    type="text"
                    placeholder="Client Name"
                    value={searchUser}
                    onChange={(e) => handleSearchInputText(e.target.value)}
                    onRightClick={handleSearchClient}
                    right={
                      loading ? (
                        <Loader loading={loading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                      ) : (
                        <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                      )
                    }
                    style={{ padding: '20px 10px' }}
                    searchBoxWidth={240}
                    errorMessage={selectedClientError}
                  />
                  {clientList.length > 0 && (
                    <ul style={styles.dropdownList}>
                      {clientList.map((client, index) => (
                        <li
                          key={client?.client_id}
                          onClick={() => handleSelectClient(client)}
                          style={{
                            ...styles.dropdownItem,
                            borderBottom: index !== clientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                          }}
                        >
                          {client?.client_name}
                        </li>
                      ))}
                    </ul>
                  )}

                </div>
              }
              {isShowGroupWise === 'Group' &&
                <Dropdown
                  ref={(el) => (dropdownRefs.current["groupName"] = el)}
                  isOpen={openDropdown === "groupName"}
                  toggleDropdown={() => toggleDropdown("groupName")}
                  label="Group Name" options={groupClientOptionsList || []}
                  sideLabel="Group Invoice"
                  labelClick={showGroupInvoiceList}
                  value={groupClient} onChange={handleSetGroupClient}
                  searchBoxWidth={220}
                  errorMessage={groupClientError} loading={groupLoading} />
              }</>}

              {/* <MobileInput
              label="Phone No."
              countryCode={basicInformation?.countryCode}
              mobileNumber={basicInformation?.phoneNo}
              onMobileChange={(value) => handleBasicInformationChange('phoneNo', value)}
              onCountryChange={(value) => handleBasicInformationChange('countryCode', value)}
              placeholder="Enter mobile number"
              countries={[
                { code: "IN", dialCode: "+91" },
                { code: "US", dialCode: "+1" },
                { code: "UK", dialCode: "+44" },
              ]}
              width="100%"
            /> */}
              <div>
                <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
                  <label style={{ ...styles.labaleStyle, display: "block" }}>
                    Date of Invoicing
                  </label>
                  <DateInput
                    value={basicInformation?.dateOfInvoice.split(" ")[0]}
                    onChange={(e) => handleBasicInformationChange("dateOfInvoice", e.target.value)}
                    width={220}
                    isDateTime={false}
                    errorMessage={dateError}
                  />
                </div>
              </div>
              {isShowGroupWise === 'Client' ? 
              <>
              {selectedClient &&
                <div className="taskScreen-filters-grid-container">
                  <div>
                    <label className="basic-info-label">File No.</label>
                    <div className="basic-info-value">{selectedClient?.client_file_id || '--'}</div>
                  </div>

                  <div>
                    <label className="basic-info-label">Client Name </label>
                    <div className="basic-info-value">{selectedClient?.client_name} </div>
                  </div>

                  <div>
                    <label className="basic-info-label">Client Type</label>
                    <div className="basic-info-value">{selectedClient?.client_type_name || '--'}</div>
                  </div>

                  <div>
                    <label className="basic-info-label">Group Name</label>
                    <div className="basic-info-value">{selectedClient?.group_name || '--'}</div>
                  </div>

                  <div>
                    <label className="basic-info-label">Address</label>
                    <div className="basic-info-value">{selectedClient?.client_address || '--'}</div>
                  </div>

                  <div>
                    <label className="basic-info-label">City</label>
                    <div className="basic-info-value">{selectedClient?.client_city || '--'}</div>
                  </div>

                  <div>
                    <label className="basic-info-label">State</label>
                    <div className="basic-info-value">{selectedClient?.client_state || '--'}</div>
                  </div>

                  <div>
                    <label className="basic-info-label">Mobile No</label>
                    <div className="basic-info-value">{selectedClient?.client_mobile_code} {selectedClient?.client_mobile_no || '--'}</div>
                  </div>

                </div>}
              </>:<>
              {groupInfo?.group_name &&
                <div className="taskScreen-filters-grid-container" >
                  <div>
                    <label className="basic-info-label">Group Name</label>
                    <div className="basic-info-value">{groupInfo?.group_name || '--'}</div>
                  </div>

                  <div>
                    <label className="basic-info-label">Group Master </label>
                    <div className="basic-info-value">{groupInfo?.client_id_primary_name || '--'} </div>
                  </div>

                  <div>
                    <label className="basic-info-label">Primary Contact</label>
                    <div className="basic-info-value">{groupInfo?.client_id_primary_mobile_code} {groupInfo?.client_id_primary_mobile_no || '--'}</div>
                  </div>

                  <div>
                    <label className="basic-info-label">Primary Email</label>
                    <div className="basic-info-value">{groupInfo?.client_id_primary_email || '--'}</div>
                  </div>
                </div>}
              </>}
              
            </div>

          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: 10, paddingBottom: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, cursor: 'pointer' }} onClick={() => handleSelectServiceOrTask(true)}>
              <div style={{...styles.checkbox,opacity:rowData ? 0.5:1}}>
                {invoiceWithoutTask ? (
                  <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleTick} alt="Tick" style={styles.circleTickIcon} /></span>
                ) : (
                  <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleIcon} alt="Tick" style={styles.circleTickIcon} /></span>
                )}
              </div>
              <div>
                With Task
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, cursor: 'pointer' }} onClick={() => handleSelectServiceOrTask(false)}>
              <div style={{...styles.checkbox,opacity:rowData ? 0.5:1}}>
                {!invoiceWithoutTask ? (
                  <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleTick} alt="Tick" style={styles.circleTickIcon} /></span>
                ) : (
                  <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleIcon} alt="Tick" style={styles.circleTickIcon} /></span>
                )}
              </div>
              <div>
                Without Task
              </div>
            </div>


          </div>

          {isShowGroupWise === 'Client' ? <>
            {/* {invoiceWithoutTask && */}
              <div style={{...styles.tableBorder,opacity:!invoiceWithoutTask ? 0.5:1}}>
                <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Select Tasks Without Invoices</h3>
                <div className='basic-info-card' style={{ border: 'none', padding: '0px' }}>
                  <div style={styles.tableWrapper}>
                    <table className="tasksScreen-task-table">
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>
                            {/* <input type="checkbox" /> */}
                            <CheckBox
                              checked={selectAll}
                              onChange={handleSelectAll}
                              disabled={!invoiceWithoutTask}
                            />
                          </th>
                          <th style={{ width: "10%" }}>Service Name</th>
                          <th style={{ width: "10%" }}>Financial Year</th>
                          <th style={{ width: "10%" }}>Agreed Amt</th>
                          <th style={{ width: "10%" }}>Est Amt</th>
                          <th style={{ width: "10%" }}>Assigned To</th>
                          <th style={{ width: "10%" }}>Task Leader</th>
                          <th style={{ width: "10%" }}>Start Date</th>
                          <th style={{ width: "10%" }}>ETD</th>
                          <th style={{ width: "10%" }}>Status</th>
                        </tr>
                      </thead>
                      <tbody style={{ backgroundColor: "white" }}>
                        {taskList?.map((category, index) => (
                          <tr key={index}>
                            <td>
                              {/* <input type="checkbox" /> */}
                              <CheckBox
                                checked={category?.checked || false}
                                onChange={(isChecked) => handleCheckboxChange(index, isChecked)}
                                disabled={!invoiceWithoutTask}
                              />
                            </td>
                            <td >{category?.service_name}</td>
                            <td >{category?.financial_year}</td>
                            <td>{category?.task_bill_amount}</td>
                            <td>{category?.task_bill_amount_estimated}</td>
                            <td >{category?.emp_id_assigned_name}</td>
                            <td >{category?.emp_id_tl_name}</td>
                            <td >{category?.task_start_date}</td>
                            <td >{category?.task_due_date}</td>
                            <td ><div
                              style={{
                                display: "inline-block", // Makes the div wrap content and have its own width
                                padding: "5px 5px", // Padding for the button-like appearance
                                borderRadius: "15px",
                                ...getStatusStyle(category?.task_status_name), // Dynamic styles based on the status
                                width: "auto", // Width adjusts automatically to content
                              }}
                            >
                              {category?.task_status_name}
                            </div></td>


                          </tr>
                        ))}
                        {taskListLoading && <tr>
                          <td><LoadingSkeleton width="40%" height="10px" /></td>
                          <td><LoadingSkeleton width="40%" height="10px" /></td>
                          <td><LoadingSkeleton width="40%" height="10px" /></td>
                          <td><LoadingSkeleton width="40%" height="10px" /></td>
                          <td><LoadingSkeleton width="40%" height="10px" /></td>
                          <td><LoadingSkeleton width="40%" height="10px" /></td>
                          <td><LoadingSkeleton width="40%" height="10px" /></td>
                        </tr>}
                      </tbody>
                    </table>
                    {taskListError && <div className="list-error-msg"> {taskListError} </div>}
                  </div>

                </div>
              </div>
              {/* } */}
          </> :
            <>
              {/* Group All Client Task List  */}
              {/* {invoiceWithoutTask && */}
                <div style={{opacity:!invoiceWithoutTask ? 0.5:1}}>
                  <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Clients in Group</h3>
                  {groupTaskListLoading && <Loader loading={groupTaskListLoading} color={'var(--primary-color)'} />}
                  {groupTaskListError && <div className="list-error-msg" > {groupTaskListError} </div>}
                  {taskListError && <div className="list-error-msg"> {taskListError} </div>}

                  {/* Clients List */}
                  {groupTaskList?.length > 0 &&
                    <div>
                      {groupTaskList.map((client) => (
                        <div key={client.client_id} style={styles.clientContainer}>
                          {/* Client Header */}
                          <div style={{ display: 'flex', alignItems: "center", gap: 5, borderBottom: '1px solid #eff0f4', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.07), 0px 1px 1px -1px rgba(0, 0, 0, 0.25) inset' }}>

                            <img
                              src={downIcon}
                              alt="downIcon"
                              style={{ width: '20px', height: '20px', cursor: 'pointer', padding: 10 }}
                              onClick={() => toggleClient(client.client_id)}
                            />
                            <div style={{ borderLeft: "1px solid #eff0f4", display: "flex", flex: 'auto', justifyContent: "space-between", alignItems: "center", cursor: "pointer", padding: '10px' }} onClick={() => toggleClient(client.client_id)}>
                              <div >
                                <div style={styles.clientNameSty}>{client.client_id} | {client.client_name}</div>
                              </div>
                              <span style={{ background: "#D4EDDA", padding: "5px 10px", borderRadius: "5px", fontSize: "12px" }}>
                                {client.task_list.length} Tasks
                              </span>
                            </div>
                          </div>

                          {/* Task List (Expandable) */}
                          {expandedClients[client.client_id] && (
                            <div style={{ padding: 10 }}>
                              <h3 style={{ ...styles.sectionTitle, paddingBottom: "10px" }}>Select Tasks without Invoices</h3>
                              <table className="tasksScreen-task-table">
                                <thead>
                                  <tr>
                                    <th style={{ width: "5%" }}>
                                      {/* <input type="checkbox" /> */}
                                      <CheckBox
                                        checked={selectedClientsTasks[client.client_id]?.length === client.task_list.length}
                                        onChange={(isChecked) => handleClientSelectAll(client.client_id, isChecked)}
                                        disabled={!invoiceWithoutTask}
                                      />
                                    </th>
                                    <th style={{ width: "10%" }}>Service Name</th>
                                    <th style={{ width: "10%" }}>Financial Year</th>
                                    <th style={{ width: "10%" }}>Agreed Amt</th>
                                    <th style={{ width: "10%" }}>Est Amt</th>
                                    <th style={{ width: "10%" }}>Assigned To</th>
                                    <th style={{ width: "10%" }}>Task Leader</th>
                                    <th style={{ width: "10%" }}>Start Date</th>
                                    <th style={{ width: "10%" }}>ETD</th>
                                    <th style={{ width: "10%" }}>Status</th>
                                  </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "white" }}>
                                  {client.task_list.map((task) => (
                                    <tr key={task.task_id}>
                                      <td style={styles.tableCell}>
                                        <CheckBox
                                          checked={selectedClientsTasks[client.client_id]?.includes(task.task_id) || false}
                                          onChange={(isChecked) => handleTaskCheckboxChange(client.client_id, task.task_id, task.task_bill_amount_estimated, isChecked)}
                                          disabled={!invoiceWithoutTask}
                                        />
                                      </td>
                                      <td style={styles.tableCell}>{task.service_name}</td>
                                      <td style={styles.tableCell}>{task.financial_year}</td>
                                      <td style={styles.tableCell}>{task.task_bill_amount}</td>
                                      <td style={styles.tableCell}>{task.task_bill_amount_estimated}</td>
                                      <td style={styles.tableCell}>{task.emp_id_assigned_name}</td>
                                      <td style={styles.tableCell}>{task.emp_id_tl_name}</td>
                                      <td style={styles.tableCell}>{task.task_start_date}</td>
                                      <td style={styles.tableCell}>{task.task_due_date}</td>
                                      <td >

                                        <div
                                          style={{
                                            display: "inline-block", // Makes the div wrap content and have its own width
                                            padding: "5px 5px", // Padding for the button-like appearance
                                            borderRadius: "15px",
                                            ...getStatusStyle(task.task_status_name), // Dynamic styles based on the status
                                            width: "auto", // Width adjusts automatically to content
                                          }}
                                        >
                                          {task.task_status_name}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>}

                </div>

              {/* } */}
            </>}
            {/* f:invoiceWithoutTask ? 'none' :   d:!invoiceWithoutTask ? 'flex' : 'block'*/}
          <div style={{ ...styles.mainContainer, paddingTop:20 }}>
            <div style={{ flex: 'auto',opacity:invoiceWithoutTask ? 0.5:1 }}>
              {/* {!invoiceWithoutTask && */}
                <> <div style={{ display: 'flex', gap: '10px', marginBottom: '12px' }}>
                  <h3 style={styles.sectionTitle}>Requested Services</h3>

                </div>
                  <div style={{ ...styles.row }}>
                    <div style={{ height: 210, overflow: 'auto' }}>
                      {rows.map((row, index) => (
                        <div key={row.id} style={{ display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center', paddingBottom: 8 }}  >
                          <Dropdown
                            ref={(el) => (dropdownRefs.current[`serviceCategory${row.id}`] = el)}
                            isOpen={openDropdown === `serviceCategory${row.id}`}
                            toggleDropdown={() => toggleDropdown(`serviceCategory${row.id}`)}
                            label="Service"
                            options={serviceOptions || []}
                            value={row.serviceCategory}
                            onChange={(value) => {
                              const updatedRows = rows.map((r) =>
                                r.id === row.id ? { ...r, serviceCategory: value, serviceType:[] } : r
                              );
                              setRows(updatedRows);
                              fetchServiceCategoryList(value, row.id);
                            }}
                            loading={serviceLoading}
                            errorMessage={serviceError}
                            searchBoxWidth={200}
                            disabled={invoiceWithoutTask}
                          />
                          {/* <div> */}
                          <Dropdown
                            ref={(el) => (dropdownRefs.current[`serviceType${row.id}`] = el)}
                            isOpen={openDropdown === `serviceType${row.id}`}
                            toggleDropdown={() => toggleDropdown(`serviceType${row.id}`)}
                            label="Service Category"
                            options={serviceCategoryOptions[row.id] || []}
                            value={row.serviceType}
                            onChange={(value) => {
                              const updatedRows = rows.map((r) =>
                                r.id === row.id ? { ...r, serviceType: value } : r
                              );
                              setRows(updatedRows);
                            }}
                            loading={serviceCategoryLoading[row.id]}
                            errorMessage={serviceCategoryError[row.id]}
                            searchBoxWidth={200}
                            isMulti
                            disabled={invoiceWithoutTask}
                          />
                          <div>
                            {index > 0 && ( // Show close icon only for dynamically added rows
                              <img
                                src={closeIcon}
                                style={{ cursor: "pointer", marginLeft: "10px", width: "20px", height: "20px", paddingTop: 20 }}
                                alt="Remove"
                                onClick={() => removeServiceRow(row.id)}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    {serviceSelectError && <div className="list-error-msg"> {serviceSelectError} </div>}
                    <button onClick={addServiceRow} style={{ ...styles.addServiceButton }} disabled={invoiceWithoutTask}>
                      <span style={{ marginRight: "10px" }}>+</span> Add More Services
                    </button>
                  </div></>
              {/* } */}
            </div>

            <div style={{ flex:'10 1 auto' }}>

              <div style={{ display: 'flex', gap: '10px', marginBottom: '12px' }}>
                <h3 style={styles.sectionTitle}>Invoice Options</h3>

              </div>
              <div className="div-grid-container" style={{ ...styles.row, flex: '10 1 auto' }}>

                <Dropdown
                  ref={(el) => (dropdownRefs.current["invoiceType"] = el)}
                  isOpen={openDropdown === "invoiceType"}
                  toggleDropdown={() => toggleDropdown("invoiceType")} label="Invoice Type" options={[{ label: 'One Time', value: 1 }, { label: 'Monthly', value: 2 }, { label: 'Quarterly', value: 3 }, { label: 'Halfyearly', value: 4 }, { label: 'Annually', value: 5 }]} value={invoiceDetails?.invoiceType}
                  onChange={(value) => handleInvoiceDetailsChange("invoiceType", value)}
                  errorMessage={filedErrors?.invoiceType} />
                <Dropdown
                  ref={(el) => (dropdownRefs.current["noTaxInvoices"] = el)}
                  isOpen={openDropdown === "noTaxInvoices"}
                  toggleDropdown={() => toggleDropdown("noTaxInvoices")} label="No Tax Invoices" options={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]} value={invoiceDetails?.noTaxInvoices}
                  onChange={(value) => handleInvoiceDetailsChange("noTaxInvoices", value)}
                  errorMessage={filedErrors?.noTaxInvoices} />
                <Dropdown
                  ref={(el) => (dropdownRefs.current["generateSeperateInvoices"] = el)}
                  isOpen={openDropdown === "generateSeperateInvoices"}
                  toggleDropdown={() => toggleDropdown("generateSeperateInvoices")} label="Generate Seperate Invoices" options={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]} value={invoiceDetails?.generateSeperateInvoices}
                  onChange={(value) => handleInvoiceDetailsChange("generateSeperateInvoices", value)} />
                <div className="filter-field"> {/* Add margin-bottom here */}
                  <label style={{ ...styles.labaleStyle, display: "block" }}>
                    Estimate Number
                  </label>
                  <TextInput
                    type="text"
                    placeholder="Search"
                    value={invoiceDetails?.estimateNumber}
                    onChange={(e) => handleInvoiceDetailsChange("estimateNumber", e.target.value)}
                    right={<img src={search} alt="Search" className="search-icon" />}
                    style={{ padding: "20px 10px" }} // Add padding inside the TextInput
                  />
                </div>
                <Input label="Advance Payment" placeholder="Enter Amount" value={invoiceDetails?.advancePayments}
                  onChange={(e) => handleInvoiceDetailsChange("advancePayments", e.target.value)}
                  inputType="number"
                  errorMessage={filedErrors?.advancePayments} />
                <Input label="System Invoicing Amount" placeholder="Enter Amount" value={invoiceDetails?.systemInvoicingAmount}
                  onChange={(e) => handleInvoiceDetailsChange("systemInvoicingAmount", e.target.value)}
                  inputType="number" 
                  errorMessage={filedErrors?.systemInvoicingAmount}/>
                <Input label="Invoice Amount" placeholder="Enter Amount" value={invoiceDetails?.invoiceAmount}
                  onChange={(e) => handleInvoiceDetailsChange("invoiceAmount", e.target.value)}
                  inputType="number"
                  errorMessage={filedErrors?.invoiceAmount} />
                <Dropdown
                  ref={(el) => (dropdownRefs.current["taskCompletedBy"] = el)}
                  isOpen={openDropdown === "taskCompletedBy"}
                  toggleDropdown={() => toggleDropdown("taskCompletedBy")} label="Task Completed By" options={employeeList || []} value={invoiceDetails?.taskCompletedBy}
                  onChange={(value) => handleInvoiceDetailsChange("taskCompletedBy", value)}
                  loading={empLoading}
                  errorMessage={empError} />
                <Dropdown
                  ref={(el) => (dropdownRefs.current["Generated"] = el)}
                  isOpen={openDropdown === "Generated"}
                  toggleDropdown={() => toggleDropdown("Generated")} label="Generated By" options={employeeList || []} value={invoiceDetails?.generatedBy}
                  onChange={(value) => handleInvoiceDetailsChange("generatedBy", value)}
                  loading={empLoading}
                  // errorMessage={empError}
                  errorMessage={empError || filedErrors?.generatedBy} />
                {/* <Dropdown
              ref={(el) => (dropdownRefs.current["organization"] = el)}
              isOpen={openDropdown === "organization"}
              toggleDropdown={() => toggleDropdown("organization")} label="Organization" options={["Option 1", "Option 2"]} value={invoiceDetails?.organization}
              onChange={(e) => handleInvoiceDetailsChange("organization", e.target.value)} />
              <Dropdown
              ref={(el) => (dropdownRefs.current["receiptBook"] = el)}
              isOpen={openDropdown === "receiptBook"}
              toggleDropdown={() => toggleDropdown("receiptBook")} label="Receipt Book" options={["Option 1", "Option 2"]} value={invoiceDetails?.receiptBook}
              onChange={(e) => handleInvoiceDetailsChange("receiptBook", e.target.value)} /> */}
                <div className="filter-field" style={{ marginBottom: "15px" }}>
                  <label style={{ ...styles.labaleStyle, display: "block" }}>
                    Follow Up Date
                  </label>
                  <DateInput
                    value={invoiceDetails?.followUpDate.split(" ")[0]}
                    onChange={(e) => handleInvoiceDetailsChange("followUpDate", e.target.value)}
                    isDateTime={false}
                    errorMessage={filedErrors?.followUpDate}
                    // min={new Date().toISOString().slice(0, 16)}
                    min={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>
            </div>



          </div>

          {/* Footer Options */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '18px' }}>
            <div>
              <div style={{ marginBottom: '15px', display: "flex", alignItems: "center", }}>
                {/* <input type="checkbox" id="sendUpdateClient" checked={sendUpdateToClient}
                onChange={(e) => setSendUpdateToClient(e.target.checked)} />
              <label htmlFor="sendUpdateClient" style={styles.checkboxLabel}>
                Send Update to Client
              </label> */}
                <CheckBox
                  label="Send Update to Client"
                  checked={sendUpdateToClient}
                  onChange={(isChecked) => setSendUpdateToClient(isChecked)}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center", }}>
                {/* <input type="checkbox" id="sendUpdateTeam" checked={sendUpdateToTeam}
                onChange={(e) => setSendUpdateToTeam(e.target.checked)} />
              <label htmlFor="sendUpdateTeam" style={styles.checkboxLabel}>
                Send Update to Team
              </label> */}
                <CheckBox
                  label="Send Update to Team"
                  checked={sendUpdateToTeam}
                  onChange={(isChecked) => setSendUpdateToTeam(isChecked)}
                />
              </div>
            </div>
          </div>

          {/* Footer Buttons */}
          {submitError && <div className="list-error-msg"> {submitError} </div>}
          {submitSuccess && <div className="list-success-msg"> {submitSuccess} </div>}
          {rowData ?
            <div className="button-container" style={{ marginTop: '32px', gap: 10 }}>
              <button
                className="add-client-button"
                style={{ paddingLeft: '30px', paddingRight: '30px', opacity: submitLoading ? 0.5 : 1 }}
                onClick={handleUpdateInvoice}
                disabled={submitLoading}
              >
                {submitLoading && (
                  <span style={{ paddingRight: 10 }}><Loader loading={submitLoading} color={'var(--background-color)'} size="small" /></span>
                )} <span>Update Invoice </span>
              </button>
              <button className="cancel-button" onClick={handleGenReceiptConfirmModal} style={{ border: '1px #40513B solid', borderRadius: '20px' }}>Generate Receipt for Invoice</button>
              <button className="cancel-button" >Cancel</button>
            </div> :
            <div className="button-container" style={{ marginTop: '32px' }}>
              <button
                className="add-client-button"
                style={{ paddingLeft: '30px', paddingRight: '30px', opacity: submitLoading ? 0.5 : 1 }}
                onClick={handleSubmitInvoice}
                disabled={submitLoading}
              >
                {submitLoading && (
                  <span style={{ paddingRight: 10 }}><Loader loading={submitLoading} color={'var(--background-color)'} size="small" /></span>
                )} <span>Generate Invoice </span>
              </button>
              <button className="cancel-button" >Cancel</button>
            </div>}
        </div>
        {/* Loading overlay */}
        {invoiceLoading && (
          <div className="overlay-div">
            <div className="loader-overlay"></div>
          </div>
        )}
      </div>
      {isGenReceiptModalOpen && <DeleteConfirmationModal
        isOpen={isGenReceiptModalOpen}
        message={`Are you sure you want to generate receipt for ${rowData?.invoice_id_gen}?`}
        onClose={handleCloseGenReceiptModal}
        onConfirm={() => {
          handleCloseGenReceiptModal();
          handleGenerateReceipt(); 
        }}
      /> }
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "90%",
    margin: "auto",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "28px",
    fontWeight: "500",
    color: "#1F384C",
    // fontFamily: "Roboto Slab"
  },
  section: {
    marginBottom: "21px",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#609966",
    fontFamily: 'Roboto Slab'
  },
  rowBorder: {
    padding: '16px 11px',
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid'
  },
  row: {
    padding: '16px 11px',
    // display: "flex",
    // flexWrap: "wrap",
    // columnGap: "70px",
    // rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid'
  },
  estimatedRow: {
    padding: '16px 11px',
    display: "flex",
    flexWrap: "wrap",
    columnGap: "70px",
    rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    paddingBottom: '63px'
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  checkboxLabel: {
    fontSize: "12px",
    color: "#343C44",
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  serviceRow: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px 70px", // Gap between two elements in the same row
    marginBottom: "15px", // Space between rows
    flexWrap: "wrap",
  },
  dropdownWithClose: {
    display: "flex",
    alignItems: "center", // Vertically aligns the dropdown and close icon
    gap: "26px", // Gap between dropdown and close icon
  },
  closeButton: {
    width: '15px',
    height: '15px',
    cursor: "pointer",
    verticalAlign: "middle",
    objectFit: "contain",
  },
  addServiceButton: {
    backgroundColor: "transparent",
    border: "1px #858585 solid",
    borderRadius: "5px",
    padding: "8px 12px",
    color: "#40513B",
    cursor: "pointer",
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    borderRadius: '28px',
    // marginBottom:'16px'
  },
  removeButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "#f00",
    fontSize: "16px",
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  createButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  servicesContainer: {
    // display: "flex",
    // flexWrap: "wrap", // Allows items to wrap to the next row if needed
    // gap: "20px", // Space between rows and items
    padding: '16px 11px',
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
  },
  mainContainer: {
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
    // overflowX: "auto",
    // whiteSpace: "nowrap",
  },
  "@media (max-width: 768px)": {
    // For tablets and smaller screens
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',

    }
  },
  "@media (max-width: 768px)": {
    serviceRow: {
      flexDirection: "column", // Stack items vertically
      gap: "15px", // Reduce the gap for smaller screens
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center', // Vertically align items
  },
  header: {
    fontSize: "22px",
    color: "#060606",
    marginBottom: "10px",
    fontWeight: 500,
    // fontFamily: 'Montserrat',
    //   marginLeft:'20px'
  },
  leftHeader: {
    marginTop: '-5px'
  },
  labaleStyle: {
    fontSize: '12px',
    // marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    // marginBottom:50
  },
  tableBorder: {
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    padding: '10px'
  },
  containerdiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.5rem',
    minWidth: '240px'
  },
  label: {
    fontWeight: '500',
    color: '#4a4a4a', // Dark gray for the label
    fontSize: '13px',
    marginBottom: '10px'
  },
  optionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '2.8rem',
  },
  optionLabel: {
    display: 'flex',
    // alignItems: 'center',
    gap: '0.5rem',
    color: '#1f2937', // Slightly darker gray for the text
    fontSize: '12px',
    cursor: 'pointer',
  },
  radio: {
    accentColor: '#000', // Purple for the radio button
    cursor: 'pointer',
    margin: '0px'
  },
  dropdownList: {
    position: 'absolute',
    // top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  circleTickIcon: {
    width: "18px",
    height: "18px",
  },
  clientNameSty: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#060606'
  },
  clientContainer: { borderBottom: "2px solid #000", borderTop: "1px solid #eff0f4", borderRight: "1px solid #eff0f4", borderLeft: "1px solid #eff0f4", marginBottom: "10px" },
  taskTable: { width: "100%", borderCollapse: "collapse", marginTop: "10px" },
  tableHeader: { background: "#f4f4f4", fontWeight: "bold" },
  tableCell: {},
  statusBadge: { padding: "5px 10px", borderRadius: "5px", fontSize: "12px" },
};

export default GenerateInvoiceScreen;
