import React, { useState, useRef, useEffect } from 'react';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import AddNewContactModal from '../../../../Components/Modals/AddNewContactModal';
import Input from '../../../../Components/Input';
import Dropdown from '../../../../Components/Dropdown';
import DateInput from '../../../../Components/DateInput';
import MobileInput from '../../../../Components/MobileInput';
import ClientAvailedServicesScreen from './ClientAvailedServicesScreen';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import Loader from '../../../../Components/Loader';
import TextInput from '../../../../Components/TextInput';
import search from '../../../../Assets/Icons/search.png';
import AddPrimaryInformationController from '../../../Controllers/AdminScreens/Clients/AddPrimaryInformationController';

const AddPrimaryInformationScreen = ({ isEditable, onUpdate, onCancel, clientInfo }) => {

  const {
    basicInformation,
    fieldConfigs,
    handleFieldChange,
    handleAddContactClick,
    loading,
    error,
    onAddClientInfo,
    updateLoading,
    addStatusError,
    addEmployeeError,
    employeeList,
    fieldConfigs2,
    handleSearchEmployee,
    handleSelectedEmployee
  } = AddPrimaryInformationController(clientInfo);

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='basic-info-container'>
      {/* Basic Information Section */}
      <h3 className='basic-info-header'>Basic Information</h3>
      <div className='basic-info-card'>
        <div className='task-fields'>
          {fieldConfigs.map((field) => (
            <div className="basic-info-item-2" key={field?.key} style={{ display: field.type === "other" && basicInformation.client_type_name?.toLowerCase() !== "other" ? "none" : "" }}>
              {field.type === "dropdown" ? (
                <Dropdown
                  ref={(el) => (dropdownRefs.current[`${field.label}`] = el)}
                  isOpen={openDropdown === `${field.label}`}
                  toggleDropdown={() => toggleDropdown(`${field.label}`)}
                  label={field?.label} // Dropdown already renders the label internally
                  options={field?.options}
                  value={basicInformation[field?.key]}
                  onChange={(selectedValue) => handleFieldChange(field?.key, selectedValue)}
                  loading={field?.loading}
                  width={230}
                  errorMessage={field?.error}

                />
              ) : field.type === "date" ? (
                <div style={styles.dateLabel}>
                  <label >{field?.label}</label>
                  <DateInput
                    value={basicInformation[field?.key] || ""}
                    onChange={(e) => handleFieldChange(field?.key, e.target.value)}
                    // width={251}
                    errorMessage={field?.error}
                  />
                </div>
              ) : field.type === "mobile" ? (
                <MobileInput
                  label={field?.label}
                  countryCode={basicInformation?.client_mobile_code || ""}
                  mobileNumber={basicInformation[field?.key] || ""}
                  onCountryChange={(value) => handleFieldChange("client_mobile_code", value)}
                  onMobileChange={(value) => handleFieldChange(field?.key, value)}
                  placeholder={`Enter ${field?.label}`}
                  countries={[
                    { code: "IN", dialCode: "+91" },
                    { code: "US", dialCode: "+1" },
                    { code: "UK", dialCode: "+44" },
                  ]}
                  width={250}
                  errorMessage={field?.error}
                />
              ) : field.type === "search" ? (
                <div className="filter-field" style={{ width: '100%' }}>
                  <label
                    style={{
                      ...styles.labaleStyle,
                      // marginBottom: '8px',
                      display: 'block',
                    }}
                  >
                    Client Assigned User
                  </label>
                  <TextInput
                    type="text"
                    placeholder="Search User"
                    value={basicInformation[field?.key]}
                    onChange={(e) => handleFieldChange(field?.key, e.target.value)}
                    onRightClick={handleSearchEmployee}
                    right={
                      loading ? (
                        <Loader loading={loading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                      ) : (
                        <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                      )
                    }
                    style={{ padding: '20px 10px' }}
                    errorMessage={field?.error}
                    width={255}
                  />
                  {employeeList.length > 0 && (
                    <ul style={styles.dropdownList}>
                      {employeeList.map((employee, index) => (
                        <li
                          key={employee?.emp_id}
                          onClick={() => handleSelectedEmployee(employee)}
                          style={{
                            ...styles.dropdownItem,
                            borderBottom: index !== employeeList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                          }}
                        >
                          {employee?.emp_name}
                        </li>
                      ))}
                    </ul>
                  )}

                </div>
              ) : (
                <Input
                  label={field?.label} // Input already renders the label internally
                  inputType={field?.inputType}
                  placeholder={`Enter ${field?.label} ${field?.key === "client_pin" ? "(optional)" : "" }`}
                  value={basicInformation[field?.key]}
                  onChange={(e) => handleFieldChange(field?.key, e.target.value)}
                  errorMessage={field?.error}
                  width={230}
                  maxLength={field?.maxLength}
                />
              )}
            </div>
          ))}

        </div>
        {error && <div className="list-error-msg">{error} </div>}

      </div>

      {/* Client Contacts Section */}
      <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 17 }}>
        <h3 className='basic-info-header'>Client Contacts</h3>
        <div className="basic-info-outer-card">
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "25%" }}>Name</th>
                <th style={{ width: "15%", }}>Phone No.</th>
                <th style={{ width: "35%", }}>Email ID</th>
                <th style={{ width: "15%" }}>Role</th>
                <th style={{ width: "10%", cursor: 'pointer' }} onClick={handleAddContactClick}> <span style={{ fontSize: '15px' }}>+</span> New Contact</th>
              </tr>
            </thead>

          </table>
        </div>
      </div>
      <h3 className='basic-info-header' style={{ paddingTop: 20 }}>Availed Services</h3>
      {/* <div className='basic-info-card' style={{ paddingBottom: "50px" }}> Ensures card padding at the bottom */}
      {/* <div style={styles.servicesGrid}>
            {services?.map((service, index) => (
            <label key={index} style={styles.serviceItem}>
                <input
                type="checkbox"
                checked={selectedServices.includes(service)}
                onChange={() => toggleService(service)}
                style={styles.checkbox}
                />
                {service}
            </label>
            ))}
        </div> */}
      {/* </div> */}
      <div style={{ paddingTop: 10 }}></div>
      {/* <ClientAvailedServicesScreen /> */}
      {isEditable && (<>
        {addStatusError && <div className="list-error-msg">{addStatusError}</div>}
        <div className="button-container">
          <button className="add-client-button" style={{ padding: '0px 30px', opacity: updateLoading ? 0.5 : 1, cursor: updateLoading ? 'not-allowed' : 'pointer', }} onClick={onAddClientInfo} disabled={updateLoading}>
            {updateLoading && <span style={{ paddingRight: 10 }}><Loader loading={updateLoading} color='#FFFFFF' size='small' /></span>} Add Client
          </button>
          <button style={styles.cancelButton} onClick={onCancel}>
            Cancel
          </button>
        </div>
      </>

      )}
    </div>
  );
};

const styles = {
  container: {
    margin: "20px",
    //   fontFamily: "Arial, sans-serif",
    backgroundColor: '#fff',
    marginTop: '-18px'
  },
  header: {
    fontSize: "18px",
    color: "#609966",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Roboto Slab'
  },
  dateLabel: {
    fontSize: '12px',
    color: '#343c44',
    fontWeight: 400,
    fontFamily: 'Roboto'
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid black",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  item: {
    flex: "1 1 calc(25% - 20px)",
    minWidth: "200px",
  },
  label: {
    fontWeight: 400,
    color: "#767676",
    display: "block",
    marginBottom: "5px",
    fontSize: '12px',
    fontFamily: 'Roboto'
  },
  value: {
    fontSize: "14px",
    color: '#060606',
    fontWeight: 400,
    fontFamily: 'Roboto'
  },
  tableHeaderRow: {
    display: "flex",
    borderBottom: "2px solid #ddd",
    padding: "15px 0",
    fontWeight: "bold",
    color: "#333",
    backgroundColor: "#f5f5f5",
  },
  tableHeaderCell: {
    padding: "0 10px",
    fontSize: "14px",
  },
  tableRow: {
    display: "flex",
    borderBottom: "1px solid #ddd",
    padding: "10px 0",
    alignItems: "center",
  },
  tableCell: {
    padding: "0 10px",
    fontSize: "14px",
    color: "#555",
  },
  primaryIcon: {
    color: "#2b542c",
    marginLeft: "5px",
  },
  actionButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "#2b542c",
    fontSize: "16px",
    marginLeft: "10px",
  },
  newContactButton: {
    backgroundColor: "#2b542c",
    color: "#fff",
    border: "none",
    borderRadius: "3px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  servicesGrid: {
    display: "flex",
    flexWrap: "wrap",
    // Reduced gap between items for better alignment
    rowGap: "10px", // Adds consistent spacing between rows
  },
  serviceItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: "#343C44",
    gap: "5px",
    paddingBottom: "5px",
    marginRight: '80px',// Adds spacing at the bottom of each service item
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  checkbox: {
    marginRight: "3px",
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#2b542c",
    border: "1px solid #2b542c",
    borderRadius: "25px",
    padding: "8px 25px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Roboto",
    marginLeft: '10px'
  },
  tableValue: {
    color: "#192A3E",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
  },
  deleteErrorMsg: {
    color: 'red',
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },

  },
};

export default AddPrimaryInformationScreen;