import React, { useState, useRef, useEffect } from "react";
import Input from "../../../../Components/Input"; // Import Input Component
import Dropdown from "../../../../Components/Dropdown"; // Import Dropdown Component
import CheckBox from "../../../../Components/CheckBox";
import closeIcon from '../../../../Assets/Icons/X circle.png'
import DateInput from '../../../../Components/DateInput';
import TextInput from "../../../../Components/TextInput";
import backIcon from '../../../../Assets/Icons/back.png'
import GenerateEditReceiptController from "../../../Controllers/AdminScreens/Invoices/GenerateEditReceiptController";
import LoadingSkeleton from "../../../../Components/LoadingSkeleton";
import Loader from "../../../../Components/Loader";
import DeleteConfirmationModal from "../../../../Components/Modals/DeleteConformationModal";

const GenerateEditReceiptScreen = () => {

  const {
    loading, error,
    clientInvoiceInfo, prepReceiptData,
    fieldConfigs,
    handleValidateFieldsReceipt, handleGenerateReceipt, handlePrintReceipt,
    generateReceiptLoading, generateReceiptError, generateReceiptSuccess,
    printReceiptLoading, printReceiptError, printReceiptSuccess,
    employeeList, empLoading, empError,
    paymentList, paymentModeError,
    paymentDetails,
    handlePaymentDetailsChange,
    handleInputChange,
    sendUpdateToClient, setSendUpdateToClient,
    sendUpdateToTeam, setSendUpdateToTeam,
    amountReceivedErrMsg, advancePaymentErrMsg,
    handleCancel,
    isGenReceiptModalOpen, handleCloseGenReceiptModal, handleGenReceiptConfirmModal
  } = GenerateEditReceiptController()

  const [isEditable, setIsEditable] = useState(false);

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ width: '93%', margin: 'auto' }}>
        <h3 style={styles.headerTop}>
          <div style={styles.headerContainer} >
            <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleCancel}
            />
            <span>Generate Receipt</span>
          </div>
        </h3>
      </div>

      <div style={styles.container}>
        <h2 style={styles.title}>Generate Receipt</h2>

        {/* Basic Information */}
        <h3 className='basic-info-header'>Basic Information</h3>
        <div className='basic-info-card'>
          <div className='basic-info-row'>
            {fieldConfigs.map((field) => (
              <div className="basic-info-item" key={field.key}>
                {/* {isUserEditable ? (
                  field.type === "dropdown" ? (
                    <Dropdown
                      ref={(el) => (dropdownRefs.current[`${field.label}`] = el)}
                      isOpen={openDropdown === `${field.label}`}
                      toggleDropdown={() => toggleDropdown(`${field.label}`)}
                      label={field.label} // Dropdown already renders the label internally
                      options={field.options}
                      value={primaryData[field.key]}
                      onChange={(selectedValue) => handleInputChange(field.key, selectedValue)}
                      loading={field?.loading}
                    />
                  ) : field.type === "date" ? (
                    <div className="basic-info-label">
                      <label style={styles.label}>{field.label}</label>
                      <DateInput
                        value={primaryData[field.key] || ""}
                        onChange={(e) => handleInputChange(field.key, e.target.value)}
                        errorMessage={field?.error}
                      />
                    </div>
                  ) : field.type === "display" ? (
                    <><span className="basic-info-label">{field.label}</span>
                      {field.key === 'emp_mobile_no' ? <span> {primaryData?.emp_mobile_code}{primaryData[field.key]}{loading && <LoadingSkeleton width="40%" height="10px" />} </span> :
                        <span className="basic-info-value"> {primaryData[field.key]} {!primaryData[field.key] && !loading && <span>--</span>} {loading && <LoadingSkeleton width="40%" height="10px" />}</span>}</>
                  ) : field.type === "mobile" ? (
                    <MobileInput
                      label={field.label}
                      countryCode={primaryData?.emp_mobile_code || ""}
                      mobileNumber={primaryData[field.key] || ""}
                      onCountryChange={(value) => handleInputChange("emp_mobile_code", value)}
                      onMobileChange={(value) => handleInputChange(field.key, value)}
                      placeholder={`Enter ${field.label}`}
                      countries={[
                        { code: "IN", dialCode: "+91" },
                        { code: "US", dialCode: "+1" },
                        { code: "UK", dialCode: "+44" },
                      ]}
                      errorMessage={field?.error}
                    />
                  ) : (
                    <Input
                      label={field.label} // Input already renders the label internally
                      placeholder={`Enter ${field.label}`}
                      value={primaryData[field.key] || '--'}
                      onChange={(e) => handleInputChange(field.key, e.target.value)}
                      isEdit={!(field.label === 'Team Leader' || field.label === 'Manager' || field.label === 'Email ID')}
                      errorMessage={field?.error}

                    />
                  )
                ) : ( */}
                <div><span className="basic-info-label">{field.label}</span>
                  {loading ? <LoadingSkeleton width="40%" height="10px" /> :
                    <span className="basic-info-value" style={
                      (field.key === 'invoice_id_gen' && !prepReceiptData?.advancePayment)
                        ? { textDecoration: 'underline', textDecorationColor: '#5A55D2', color: '#5A55D2' }
                        : {}
                    }>
                      {clientInvoiceInfo[field.key] || "--"}

                    </span>}

                </div>
              </div>
            ))}
          </div>
          {error && <div className="list-error-msg" style={{ paddingBottom: 0 }}>{error}</div>}
        </div>

        {/* Editable Fields Section */}
        <div className='taskScreen-filters-grid-container' style={{ marginTop: 20, display: 'flex' }}>
          <Dropdown
            ref={(el) => (dropdownRefs.current["Payment"] = el)}
            isOpen={openDropdown === "Payment"}
            toggleDropdown={() => toggleDropdown("Payment")}
            label="Mode of Payment"
            options={paymentList}
            value={paymentDetails?.paymentMode}
            onChange={(value) => handlePaymentDetailsChange("paymentMode", value)}
            errorMessage={paymentModeError}
            searchBoxWidth={230}
            disabled={clientInvoiceInfo?.receipt_id}
          />
          <Dropdown
            ref={(el) => (dropdownRefs.current["Collected By"] = el)}
            isOpen={openDropdown === "Collected By"}
            toggleDropdown={() => toggleDropdown("Collected By")}
            label="Collected by"
            options={employeeList || []}
            value={paymentDetails?.collectedBy}
            onChange={(value) => handlePaymentDetailsChange("collectedBy", value)}
            loading={empLoading}
            errorMessage={empError}
            searchBoxWidth={230}
            disabled={clientInvoiceInfo?.receipt_id}
          />
          {/* <div className="filter-field" style={{ marginBottom: "15px" }}>
            <label style={{ ...styles.labaleStyle, display: "block" }}>
              Follow Up Date
            </label>
            <DateInput
              value={paymentDetails?.followUpDate}
              onChange={(e) => handlePaymentDetailsChange("followUpDate", e.target.value)}
            />
          </div>
          <Dropdown
            ref={(el) => (dropdownRefs.current["Receipt Book"] = el)}
            isOpen={openDropdown === "Receipt Book"}
            toggleDropdown={() => toggleDropdown("Receipt Book")}
            label="Receipt Book"
            options={["Username", "Book A", "Book B"]}
            value={paymentDetails?.receiptBook}
            onChange={(e) => handlePaymentDetailsChange("receiptBook", e.target.value)}
            searchBoxWidth={230}
          /> */}
        </div>

        {/* Payment Details Section */}
        <div style={{ marginTop: 25 }}>
          <h3 className='basic-info-header' style={{ color: 'black' }}>Payment Details</h3>
        </div>

        <div style={styles.paymentDetails}>
          {/* Invoice Amount */}
          {!prepReceiptData?.advancePayment && (<div style={{ ...styles.sameRow, alignItems: 'center', columnGap: 36 }}>
            <label style={styles.paymentLabel}>Invoice Amount</label>
            <span style={styles.amountvalue}>{clientInvoiceInfo?.invoice_amount || '--'}</span>
          </div>)
          }

          {/* Amount Received */}
          {!prepReceiptData?.advancePayment && (<div style={{ ...styles.sameRow, alignItems: 'center', columnGap: 35 }}>
            <label style={styles.paymentLabel}>Amount Received</label>
            <TextInput
              placeholder="Amount Received"
              value={clientInvoiceInfo?.amount_received}
              onChange={(e) =>
                handleInputChange("amount_received", e.target.value)
              }
              style={{ backgroundColor: clientInvoiceInfo?.receipt_id ? "var(--read-input-box-bg-color)" : "#fff" }}
              inputType="number"
              errorMessage={amountReceivedErrMsg}
              readOnly={clientInvoiceInfo?.receipt_id}
            />
          </div>)
          }

          {/* Advance Payment */}
          <div style={{ ...styles.sameRow, alignItems: 'center', columnGap: 30 }}>
            <p style={styles.paymentLabel}>Advance Payment <span style={styles.noPaymentText}>No Advance Payment</span></p>
            <TextInput
              placeholder="Advance Payment"
              value={clientInvoiceInfo?.advance_paid_amount}
              onChange={(e) =>
                handleInputChange("advance_paid_amount", e.target.value)
              }
              style={{ backgroundColor: (clientInvoiceInfo?.receipt_id) ? "var(--read-input-box-bg-color)" : "#fff" }}
              inputType="number"
              errorMessage={advancePaymentErrMsg}
              readOnly={clientInvoiceInfo?.receipt_id}
            />
          </div>

          {/* Balance */}
          {!prepReceiptData?.advancePayment && <hr
            className="title-divider"
            style={{ width: "40%", margin: "10px auto 10px 0px" }}
          />}
          {!prepReceiptData?.advancePayment && <div style={{ display: 'flex', alignItems: 'center', columnGap: '41px', marginBottom: '5px', marginTop: '5px' }}>
            <label style={styles.paymentLabel}>Balance</label>
            <span style={styles.amountvalue}> {clientInvoiceInfo?.balance_due_amount === 0
              ? '0'
              : clientInvoiceInfo?.balance_due_amount || '--'}</span>
          </div>}
          {!prepReceiptData?.advancePayment && <hr
            className="title-divider"
            style={{ width: "40%", margin: "10px auto 10px 0px" }}
          />}
        </div>

        <div style={{ marginTop: '18px' }}>
          <div style={{ marginBottom: '15px', display: "flex", alignItems: "center", }}>
            <CheckBox
              label="Send Update to Client"
              checked={sendUpdateToClient}
              onChange={(isChecked) => setSendUpdateToClient(isChecked)}
              disabled={clientInvoiceInfo?.receipt_id}
              disabledColor="#808080"
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", }}>
            <CheckBox
              label="Send Update to Team"
              checked={sendUpdateToTeam}
              onChange={(isChecked) => setSendUpdateToTeam(isChecked)}
              disabled={clientInvoiceInfo?.receipt_id}
              disabledColor="#808080"
            />
          </div>

        </div>

        {/* Footer Buttons */}
        {generateReceiptError && <div className="list-error-msg"> {generateReceiptError} </div>}
        {generateReceiptSuccess && <div className="list-success-msg"> {generateReceiptSuccess} </div>}
        <div className="button-container" style={{ marginTop: '32px' }}>
          <button className="add-client-button"
            style={{ paddingLeft: '30px', paddingRight: '30px', marginRight: '10px', opacity: generateReceiptLoading || clientInvoiceInfo?.receipt_id ? 0.5 : 1, cursor: generateReceiptLoading || clientInvoiceInfo?.receipt_id ? 'not-allowed' : 'pointer' }}
            onClick={handleValidateFieldsReceipt}
            disabled={generateReceiptLoading || clientInvoiceInfo?.receipt_id}
          > {generateReceiptLoading && (
            <span style={{ paddingRight: 10 }}><Loader loading={generateReceiptLoading} color={'var(--background-color)'} size="small" /></span>
          )} Generate Receipt</button>
          <button className="add-client-button" style={{ ...styles.addServiceButton, paddingLeft: '30px', paddingRight: '30px', opacity: printReceiptLoading || !clientInvoiceInfo?.receipt_id ? 0.5 : 1, cursor: (printReceiptLoading || !clientInvoiceInfo?.receipt_id) ? 'not-allowed' : 'pointer', }}
            onClick={handlePrintReceipt}
            disabled={printReceiptLoading || !clientInvoiceInfo?.receipt_id}>{printReceiptLoading && (
              <span style={{ paddingRight: 10 }}><Loader loading={printReceiptLoading} color={'var(--primary-color)'} size="small" /></span>
            )}Print</button>
          <button className="cancel-button" onClick={handleCancel}>Cancel</button>
        </div>
      </div>
      {isGenReceiptModalOpen && <DeleteConfirmationModal
        isOpen={isGenReceiptModalOpen}
        message={`Are you sure you want to generate receipt ?`}
        onClose={handleCloseGenReceiptModal}
        onConfirm={() => {
          handleCloseGenReceiptModal();
          handleGenerateReceipt(); 
        }}
      /> }
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "90%",
    margin: "auto",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: '50px'
  },
  title: {
    fontSize: "28px",
    marginBottom: "23px",
    fontWeight: "500",
    color: "#1F384C",
    // fontFamily: "Roboto Slab"
  },
  checkboxLabel: {
    fontSize: "12px",
    color: "#343C44",
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  addServiceButton: {
    backgroundColor: "transparent",
    border: "1px #858585 solid",
    borderRadius: "5px",
    padding: "8px 12px",
    color: "#40513B",
    cursor: "pointer",
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    borderRadius: '28px',
    // marginBottom:'16px'
  },
  label: {
    fontSize: "12px",
    marginBottom: "2px",
    color: "#767676",
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  header: {
    marginBottom: "36px",
    marginTop: '30px',
    color: "black",
    fontSize: '18px',
    fontWeight: '500',
    fontWeight: 'Roboto Slab'
  },
  sameRow: {
    display: 'flex',
    marginBottom: '10px',
    // width: "100%",
  },
  paymentDetails: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
  },
  paymentLabel: {
    fontSize: "14px",
    marginBottom: "2px",
    color: "black",
    fontFamily: 'Roboto',
    fontWeight: '400',
    whiteSpace: "nowrap",
    flex: "0 0 110px",
    display: "flex",
    flexDirection: "column"
  },
  noPaymentText: {
    fontSize: "12px",
    color: "#767676", // 🔥 Light gray color for distinction
    // fontStyle: "italic",
    display: "block", // 🔥 Ensures it appears on a new line
    marginTop: "2px" // 🔥 Small spacing between main text and subtext
  },
  amountvalue: {
    fontSize: "18px",
    color: "black",
    fontFamily: 'Roboto Slab',
    fontWeight: '700'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center', // Vertically align items
  },
  headerTop: {
    fontSize: "22px",
    color: "#060606",
    marginBottom: "10px",
    fontWeight: 500,
    // fontFamily: 'Montserrat',
    //   marginLeft:'20px'
  },
  labaleStyle: {
    fontSize: '12px',
    // marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  },
};

export default GenerateEditReceiptScreen;
